import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GC, GCFactory } from '@clarilog/core/services/gc/gc';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { EmailPrototypeCoreService } from '@clarilog/core/services2/graphql/generated-types/services/email-prototype.service';
import { TextBoxHelperService } from '@clarilog/core/services2/graphql/generated-types/services/service-helper/textbox-helper.service';
import { CoreWorkFormComponent } from '@clarilog/shared2/components/form/work-form/work-form.component';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { CoreModelCompilerService } from '../../../services/compiler/model-compiler.service';
import model from './model.json';

/**
 * Formulaire relatif aux prototype d'email.
 */

@Component({
  selector: 'cl-email-prototype',
  templateUrl: './email-prototype.component.html',
  styleUrls: ['./email-prototype.component.scss'],
})
export class CoreEmailPrototypeComponent implements OnInit {
  model: ModelState;
  backUrl: any;

  @ViewChild(CoreWorkFormComponent, { static: true })
  workForm: CoreWorkFormComponent;
  _gc: GC;
  type: string;

  constructor(
    public emailPrototypeService: EmailPrototypeCoreService,
    private modelCompilerService: CoreModelCompilerService,
    public activatedRoute: ActivatedRoute,
    public gcFactory: GCFactory,
    private textBoxHelperService: TextBoxHelperService,
  ) {
    this._gc = this.gcFactory.create();
  }

  async ngOnInit() {
    // Vérification si mode edit et si ID
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    if (id) {
      let result = await this.emailPrototypeService
        .get([GqlSubField.create('data', [GqlField.create('type')])], id)
        .toPromise();
      if (result?.data != undefined) {
        this.type = result.data.type;
      }
    } else {
      this.activatedRoute.data.subscribe((data) => {
        this.type = data.type;
        if (this.type == undefined) {
          this.type = this.activatedRoute.snapshot.queryParams.type;
        }
        this.backUrl = data.backUrl;
      });
    }
    let useModel = model;
    let modelJsonName: string = this.type;
    if (this.type === 'ticketEmail') {
      modelJsonName = 'incident';
    }

    try {
      useModel = require(`./model-${modelJsonName}.json`);
    } catch (error) {
      useModel = model;
    }

    if (useModel == undefined) {
      useModel = model;
    }

    this.modelCompilerService.compile(useModel).subscribe((model) => {
      this.model = model;
      if (this.type != null) {
        this.model.sharedContext.entry.set('type', () => this.type);
        this.model.sharedContext.params.set('type', () => this.type);
      }
    });
  }

  ngAfterViewInit(): void {
    this._gc.forDispose(
      this.workForm.formReady.subscribe((data: any) => {
        this.textBoxHelperService.translateEntityType(
          this.workForm,
          this.type,
          'typeTranslate',
        );
      }),
    );
  }

  onSaved(_) {
    this.textBoxHelperService.translateEntityType(
      this.workForm,
      this.type,
      'typeTranslate',
    );
  }
}
