import { AfterViewInit, Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { CoreGraphQLSource } from '@clarilog/core/services2/graphql/graphql-store.service';
import { ModelDataSourceContext } from '@clarilog/shared2/services/compiler/model-state';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import DataSource from 'devextreme/data/data_source';
import { camelCase } from 'lodash';
import { map } from 'rxjs/operators';
import { TranslatedFieldHelperService } from '../../translate-field/translate-field-helper-service';
import Timeout = NodeJS.Timeout;

/** Représente la classe du composent cl-dashboard-user-aera-list. */
@Component({
  selector: 'clc-dashboard-user-aera-list',
  templateUrl: './dashboard-user-aera-list.component.html',
  styleUrls: ['./dashboard-user-aera-list.component.scss'],
})
export class CoreDashboardUserAeraListComponent implements OnInit, OnDestroy, AfterViewInit {
  /** Définit le titre de la zone */
  @Input() title: string;
  /** Définit le lien du titre de la zone */
  @Input() linkTitle: string;
  /** Définit l'icone dans la list */
  @Input() icon: string;
  /** Définit les couleurs de la zone*/
  @Input() color: string;
  @Input() secondColor: string;
  /** Obtient ou définit la source de données. */
  source: DataSource;
  /** Obtient ou définit le context de la source de données. */
  @Input() sourceContext: ModelDataSourceContext;

  @Input() reloadTimer: number;

  /** Permet de rendre visible la zone des évaluation */
  @Input() showEvaluateZone: string;

  /** Definit si le service de comptage est appeler */
  @Input() countService: string;
  /** Definit si la methode du service de comptage est appeler */
  @Input() countMethodeService: string;

  /** Definit si le titre doit etre traduit */
  @Input() titleTranslatable: boolean = false;
  /** Definit si le content doit etre traduit */
  @Input() contentTranslatable: boolean = false;

  idEntity: string;
  showViewer: boolean = false;
  titleItem: string;
  contentItem: string;
  reloader: Timeout;

  /** Définit l'injector */
  injector: Injector;

  constructor(
    injector: Injector,
    private router: Router,
    private translatedFieldHelperService: TranslatedFieldHelperService,
    private route: ActivatedRoute
  ) {
    document.documentElement.style.setProperty(
      '--primaryAeraColor',
      'rgb(213, 106, 240)',
    );
    this.injector = injector;
  }

  /** @inheritdoc */
  async ngOnInit() {
    this.loadData();
    if (this.reloadTimer != undefined && this.reloadTimer > 0) {
      this.reloader = setInterval(() => {
        this.loadData();
      }, this.reloadTimer);
    }
  }

  ngAfterViewInit(): void {
    if(this.sourceContext.serviceName === 'NewsCoreService' &&
      this.route.snapshot.queryParams['navigateTo'] != null
    ){
      let newsId = this.route.snapshot.queryParams['navigateTo'];
      this.openViewer(newsId);
    }
  }

  loadData() {
    let fieldTitle: GqlField | GqlSubField = GqlField.create('title');

    if (this.titleTranslatable === true) {
      fieldTitle = GqlSubField.create(
        'title',
        this.translatedFieldHelperService.translatedFields(),
      );
    }

    this.source = CoreGraphQLSource.create({
      context: this.sourceContext,
      query: (filter?: any, options?: any) => {
        if (options.skip >= options.limit) {
          return [];
        }

        let field = [
          GqlSubField.create('data', [GqlField.create('id'), fieldTitle]),
        ];
        if (this.sourceContext.serviceName == 'MessageCoreService') {
          field = [
            GqlSubField.create('data', [
              GqlField.create('id'),
              GqlField.create('date'),
              GqlField.create('ticketId'),
              GqlField.create('entityType'),
              GqlSubField.create('ticket', [
                GqlSubField.create('data', [GqlField.create('ticketNumber')]),
              ]),
              GqlField.create('content'),
            ]),
          ];
        }

        // TODO check - use providerToken
        return this.injector
          .get(this.sourceContext.serviceName)
          [this.sourceContext.methodeName](field)
          .pipe(
            map((result) => {
              if (this.sourceContext.serviceName == 'MessageCoreService') {
                if (result != null) {
                  let data = result['data'];
                  data.forEach((element) => {
                    var el = document.createElement('div');
                    el.innerHTML = element.content;
                    element.title =
                      new Date(element.date).toLocaleDateString() +
                      ' - ' +
                      TranslateService.get(
                        `entities/${camelCase(
                          element.ticket.data['__typename'],
                        )}/_title/singular`,
                      ) +
                      ' n°' +
                      element.ticket.data.ticketNumber +
                      ' - ' +
                      el.innerText;
                  });
                }
              }
              return result;
            }),
          );
      },
    });
    this.sourceContext.datasource = this.source;
  }

  /**
   * Ouverture du composant pop-up
   * @param id
   */
  openViewer(id: string){
    this.showViewer = false;
      this.idEntity = id;
      setTimeout(() => {
        this.showViewer = true;
      });
  }

  listSelectionChanged = (e) => {
    if (this.sourceContext.serviceName == 'MessageCoreService') {
      if (e.itemData.ticket.data['__typename'] == 'Request') {
        this.router.navigate(
          ['help-desk-request/my/edit/' + e.itemData.ticketId],
          {
            queryParams: { navigateTo: 'communication' },
          },
        );
      } else {
        this.router.navigate(
          ['help-desk-incident/my/edit/' + e.itemData.ticketId],
          {
            queryParams: { navigateTo: 'communication' },
          },
        );
      }
    } else {
      this.openViewer(e.itemData.id);
    }
  };

  ngOnDestroy() {
    if (this.reloadTimer) clearInterval(this.reloader);
  }

  getDisplay(field) {
    if (this.titleTranslatable) {
      if (field != null) {
        return field[this.translatedFieldHelperService.getTranslateKey()];
      }
    }

    return field;
  }
}
