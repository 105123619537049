<div class="link-list-container" [ngClass]="{ loading: loading }">
  <div
    [ngClass]="
      template !== undefined && template === 'selectPriorityColorTemplate'
        ? 'priorityColor'
        : ''
    "
  >
    <i
      *ngIf="
        template !== undefined &&
        template === 'selectPriorityColorTemplate' &&
        dataItem !== undefined &&
        dataItem.color !== undefined
      "
      id="cl-pictogramme"
      class="fas fa-flag"
      style="font-size: 1em; margin: 4px"
      [style.color]="dataItem?.color"
    ></i>
    <dx-text-box
      [ngClass]="
        template !== undefined && template === 'selectPriorityColorTemplate'
          ? 'cl-textbox-pictogramme'
          : ''
      "
      #textbox
      [(value)]="text"
      [showClearButton]="showClearButton"
      [placeholder]="placeholder"
      (onValueChanged)="onTextBoxValueChanged($event)"
      (onFocusOut)="onTextBoxFocusOut($event)"
      (onFocusIn)="onTextBoxFocusIn($event)"
      (onKeyUp)="keyUp.next($event)"
      (onKeyDown)="onTextBoxKeyDown($event)"
      (onContentReady)="onTextBoxContentReady($event)"
      (onInitialized)="onTextBoxInitialized($event)"
      (mouseenter)="mouseEnter($event)"
      (mouseleave)="mouseLeave($event)"
      (onEnterKey)="enterKey($event)"
      valueChangeEvent="keyup"
      [disabled]="disabled"
      [readOnly]="readOnly"
    >
      <dxi-button name="clear" *ngIf="clearVisibility != false"></dxi-button>

      <dxi-button
        name="goto"
        [options]="{
          icon: 'fas fa-arrow-right',
          elementAttr: { class: 'action-button' },
          onClick: onGoTo,
          tabIndex: -1,
          visible:
            route !== undefined &&
            !valueInTrash &&
            value != undefined &&
            !isMobile,
          disabled: value == undefined
        }"
      ></dxi-button>
      <dxi-button
        name="dropDown"
        [options]="{
          icon: 'dx-icon dx-icon-spindown',
          elementAttr: { class: 'action-button dropdown' },
          onClick: onDropDown,
          tabIndex: -1,
          activeStateEnabled: false,
          focusStateEnabled: false,
          hoverStateEnabled: false,
          visible: !readOnly
        }"
      ></dxi-button>
    </dx-text-box>
  </div>
  <dx-load-indicator [visible]="loading"></dx-load-indicator>
</div>
<dx-popover
  *ngIf="popOverVisibility && showPopup === false"
  [deferRendering]="true"
  [target]="textbox['element'].nativeElement"
  position="bottom"
  [width]="'auto'"
  [visible]="!disabled && popOverVisibility"
>
  <div *dxTemplate="let data = model; of: 'content'" [ngSwitch]="template">
    <div *ngSwitchCase="">
      {{ text }}
    </div>
    <div *ngSwitchDefault>
      <ng-template
        *ngTemplateOutlet="
          templateService.get(template);
          context: { $implicit: dataItem }
        "
      >
      </ng-template>
    </div>
  </div>
</dx-popover>

<dx-popup
  #popup
  *ngIf="showPopup && !disabled && !readOnly"
  class="cl-link-popup"
  [position]="{
    my: 'left top',
    at: 'left bottom',
    of: textbox.instance.element(),
    collision: {
      x: 'none',
      y: 'flip'
    }
  }"
  [deferRendering]="false"
  [(visible)]="showPopup"
  [hoverStateEnabled]="false"
  [focusStateEnabled]="false"
  [animation]="undefined"
  [hideOnOutsideClick]="onCloseOnOutsideClick"
  [showTitle]="false"
  [shading]="false"
  [height]="'auto'"
  [maxHeight]="'250px'"
  (onShowing)="onPopupShowing($event)"
  (onShown)="onPopupShown($event)"
>
  <div class="cl-popup-container">
    <cl-toolbar class="border-bottom">
      <cl-toolbar-items>
        <cl-toolbar-item-button
          [text]="'manage' | translate"
          [visible]="route !== undefined && !isMobile"
          (onClick)="onManageClick($event)"
        >
        </cl-toolbar-item-button>
        <cl-toolbar-item-button
          [location]="'after'"
          icon="fal fa-sync"
          (onClick)="refresh()"
          [visible]="!isMobile"
        >
        </cl-toolbar-item-button>
        @if(isMobile == false){
        <cl-toolbar-item-button
          [visible]="showOnEnlargeClick"
          [location]="'after'"
          icon="fal fa-expand-arrows"
          (onClick)="onEnlargeClick($event)"
        >
        </cl-toolbar-item-button>

        }
      </cl-toolbar-items>
    </cl-toolbar>

    <dx-list
      *ngIf="type === 'Grid' || type == undefined"
      [dataSource]="source"
      [keyExpr]="valueExpr"
      [displayExpr]="displayExpr"
      [selectionMode]="'single'"
      pageLoadMode="scrollBottom"
      [height]="'200px'"
      (onInitialized)="onListInitialized($event)"
      (onSelectionChanged)="onListSelectionChanged($event)"
      (onItemRendered)="onItemRendered($event)"
    >
      <div *dxTemplate="let item of 'item'" [ngSwitch]="template">
        <div *ngSwitchCase="">
          {{ item != null ? getExprValue(item, displayExpr) : item }}
        </div>
        <div *ngSwitchCase="'StockType'">
          {{ getExprValue(item, displayExpr) }}
        </div>
        <div *ngSwitchDefault>
          <ng-template
            *ngTemplateOutlet="
              templateService.get(template);
              context: { $implicit: item }
            "
          >
          </ng-template>
        </div>
      </div>
    </dx-list>

    <dx-tree-list
      *ngIf="type === 'Tree'"
      class="tree"
      (onInitialized)="onListInitialized($event)"
      (onSelectionChanged)="onTreeListSelectionChanged($event)"
      (onCellPrepared)="onTreeListCellPrepared($event)"
      (onCellHoverChanged)="onCellHoverChanged($event)"
      (onCellClick)="onTreeListCellClick($event)"
      [dataSource]="source"
      [columnAutoWidth]="true"
      [keyExpr]="valueExpr"
      [parentIdExpr]="parentIdExpr"
      dataStructure="plain"
      [rootValue]="null"
      [height]="'100%'"
      [hoverStateEnabled]="true"
      [showRowLines]="false"
      [showBorders]="false"
      [height]="'200px'"
      [searchPanel]="{
        visible: false,
        width: 200,
        placeholder: 'globals/search' | translate
      }"
      [columnChooser]="{ enabled: false }"
      [filterRow]="{
        visible: false
      }"
      [filterPanel]="{
        visible: false
      }"
      [showColumnHeaders]="false"
    >
      <dxo-selection mode="single"></dxo-selection>

      <dxi-column
        [dataField]="columns[0].field"
        [caption]="columns[0].field"
      ></dxi-column>
    </dx-tree-list>
  </div>
</dx-popup>

<dx-popover
  [visible]="showHint"
  *ngIf="showHint"
  [deferRendering]="true"
  [position]="{
    my: 'left top',
    at: 'left bottom',
    of: itemElementHint,
    offset: { x: 20, y: 0 },
    collision: {
      x: 'none',
      y: 'flip'
    }
  }"
  [width]="'auto'"
  [maxWidth]="
    textbox?.instance?.element()?.clientWidth != undefined
      ? textbox?.instance?.element()?.clientWidth
      : 350
  "
>
  <div [innerHTML]="itemDescriptionHint" class="cl-popover-hint"></div>
</dx-popover>

<dx-popup
  #associatePopup
  id="associatePopup"
  [width]="filters != undefined ? '80%' : '800px'"
  [height]="'80%'"
  [showTitle]="false"
  [dragEnabled]="true"
  class="class_zindex_popup != undefined ? add-item-popup z-index-popup-plus : add-item-popup"
  [(visible)]="showAssociatePopup"
  [resizeEnabled]="true"
  [hideOnOutsideClick]="true"
  (onShown)="onShowPopup($event)"
>
  <clc-select-list
    #selectList
    [mode]="'popup'"
    [filters]="filters"
    [columns]="columns"
    [multiple]="false"
    [type]="type"
    [title]="titleAssociatePopup"
    [enabledExp]="enabledExp"
    (onClosed)="showAssociatePopup = false"
  ></clc-select-list>
</dx-popup>
