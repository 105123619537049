import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetEmailConnectorSystemsBaseVariables, FirstEmailConnectorSystemsBaseVariables, CountEmailConnectorSystemsBaseVariables, FindEmailConnectorSystemsBaseVariables, SearchEmailConnectorSystemsBaseVariables, ExportSchemaEmailConnectorSystemsBaseVariables, ExportDataEmailConnectorSystemsBaseVariables, CustomQueryEmailConnectorSystemsBaseVariables, CustomQueryIdEmailConnectorSystemsBaseVariables, UsedEmailConnectorSystemsBaseVariables, ExistEmailConnectorSystemsBaseVariables, UpdateManyEmailConnectorSystemsBaseVariables, DeleteEmailConnectorSystemsBaseVariables, OrderEmailConnectorSystemsBaseVariables } from '../gqls'
import { GetEmailConnectorSystemsDocument, FirstEmailConnectorSystemsDocument, CountEmailConnectorSystemsDocument, FindEmailConnectorSystemsDocument, SearchEmailConnectorSystemsDocument, ExportSchemaEmailConnectorSystemsDocument, ExportDataEmailConnectorSystemsDocument, CustomQueryEmailConnectorSystemsDocument, CustomQueryIdEmailConnectorSystemsDocument, UsedEmailConnectorSystemsDocument, ExistEmailConnectorSystemsDocument, UpdateManyEmailConnectorSystemsDocument, DeleteEmailConnectorSystemsDocument, OrderEmailConnectorSystemsDocument } from '../gqls'
import { ServiceSingleResultOfEmailConnectorSystem, QueryContextOfEmailConnectorSystem, FilterOfEmailConnectorSystem, ServiceSingleResultOfInt64, ServiceListResultOfEmailConnectorSystem, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, FieldUpdateOperatorOfEmailConnectorSystem } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class EmailConnectorSystemBaseService {
    
public modelName = 'emailConnectorSystem';
public modelPluralName = 'emailConnectorSystems';

	private getEmailConnectorSystemsQuery: GetEmailConnectorSystemsDocument;
	private firstEmailConnectorSystemsQuery: FirstEmailConnectorSystemsDocument;
	private countEmailConnectorSystemsQuery: CountEmailConnectorSystemsDocument;
	private findEmailConnectorSystemsQuery: FindEmailConnectorSystemsDocument;
	private searchEmailConnectorSystemsQuery: SearchEmailConnectorSystemsDocument;
	private exportSchemaEmailConnectorSystemsQuery: ExportSchemaEmailConnectorSystemsDocument;
	private exportDataEmailConnectorSystemsQuery: ExportDataEmailConnectorSystemsDocument;
	private customQueryEmailConnectorSystemsQuery: CustomQueryEmailConnectorSystemsDocument;
	private customQueryIdEmailConnectorSystemsQuery: CustomQueryIdEmailConnectorSystemsDocument;
	private usedEmailConnectorSystemsQuery: UsedEmailConnectorSystemsDocument;
	private existEmailConnectorSystemsQuery: ExistEmailConnectorSystemsDocument;
	private updateManyEmailConnectorSystemsMutation: UpdateManyEmailConnectorSystemsDocument;
	private deleteEmailConnectorSystemsMutation: DeleteEmailConnectorSystemsDocument;
	private orderEmailConnectorSystemsMutation: OrderEmailConnectorSystemsDocument;

	constructor(private injector: Injector) {
		this.getEmailConnectorSystemsQuery = this.injector.get(GetEmailConnectorSystemsDocument);
		this.firstEmailConnectorSystemsQuery = this.injector.get(FirstEmailConnectorSystemsDocument);
		this.countEmailConnectorSystemsQuery = this.injector.get(CountEmailConnectorSystemsDocument);
		this.findEmailConnectorSystemsQuery = this.injector.get(FindEmailConnectorSystemsDocument);
		this.searchEmailConnectorSystemsQuery = this.injector.get(SearchEmailConnectorSystemsDocument);
		this.exportSchemaEmailConnectorSystemsQuery = this.injector.get(ExportSchemaEmailConnectorSystemsDocument);
		this.exportDataEmailConnectorSystemsQuery = this.injector.get(ExportDataEmailConnectorSystemsDocument);
		this.customQueryEmailConnectorSystemsQuery = this.injector.get(CustomQueryEmailConnectorSystemsDocument);
		this.customQueryIdEmailConnectorSystemsQuery = this.injector.get(CustomQueryIdEmailConnectorSystemsDocument);
		this.usedEmailConnectorSystemsQuery = this.injector.get(UsedEmailConnectorSystemsDocument);
		this.existEmailConnectorSystemsQuery = this.injector.get(ExistEmailConnectorSystemsDocument);
		this.updateManyEmailConnectorSystemsMutation = this.injector.get(UpdateManyEmailConnectorSystemsDocument);
		this.deleteEmailConnectorSystemsMutation = this.injector.get(DeleteEmailConnectorSystemsDocument);
		this.orderEmailConnectorSystemsMutation = this.injector.get(OrderEmailConnectorSystemsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEmailConnectorSystem> {
    
    		let variables: GetEmailConnectorSystemsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getEmailConnectorSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystems.get));
            }
            else{
                let result:ServiceSingleResultOfEmailConnectorSystem={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEmailConnectorSystem,
		@Args('filter?') filter?: FilterOfEmailConnectorSystem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEmailConnectorSystem> {
    
    		let variables: FirstEmailConnectorSystemsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstEmailConnectorSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystems.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfEmailConnectorSystem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountEmailConnectorSystemsBaseVariables = {
    			filter: filter
    		}
    				return this.countEmailConnectorSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystems.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEmailConnectorSystem,
		@Args('filter?') filter?: FilterOfEmailConnectorSystem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailConnectorSystem> {
    
    		let variables: FindEmailConnectorSystemsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findEmailConnectorSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystems.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfEmailConnectorSystem,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailConnectorSystem> {
    
    		let variables: SearchEmailConnectorSystemsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchEmailConnectorSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystems.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaEmailConnectorSystemsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaEmailConnectorSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystems.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfEmailConnectorSystem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataEmailConnectorSystemsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataEmailConnectorSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystems.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfEmailConnectorSystem,
		@Args('filter?') filter?: FilterOfEmailConnectorSystem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailConnectorSystem> {
    
    		let variables: CustomQueryEmailConnectorSystemsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryEmailConnectorSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystems.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfEmailConnectorSystem,
		@Args('filter?') filter?: FilterOfEmailConnectorSystem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailConnectorSystem> {
    
    		let variables: CustomQueryIdEmailConnectorSystemsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdEmailConnectorSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystems.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedEmailConnectorSystemsBaseVariables = {
    			ids: ids
    		}
    				return this.usedEmailConnectorSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystems.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistEmailConnectorSystemsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existEmailConnectorSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystems.exist));
    	}
    
    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfEmailConnectorSystem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyEmailConnectorSystemsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyEmailConnectorSystemsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystems.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteEmailConnectorSystemsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteEmailConnectorSystemsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystems.delete));
    	}

    	/** Permet d'ordonner l'entité. */
    	@InjectArgs
    	public order(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fromId') fromId: string,
		@Args('toId?') toId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: OrderEmailConnectorSystemsBaseVariables = {
    			fromId: fromId,
			toId: toId
    		}
    				return this.orderEmailConnectorSystemsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystems.order));
    	}
    
}