import { AssetTypeStaffingBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';

@Injectable({ providedIn: 'root' })
@Authorize({
  policies: ['asset.manage-type-staffing', 'contract.manage-type-staffing'],
  operator: 'or',
})
export class AssetTypeStaffingCoreService extends AssetTypeStaffingBaseService {
  constructor(injector: Injector) {
    super(injector);
  }
}
