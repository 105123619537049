import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAssetAcquisitionModesBaseVariables, FirstAssetAcquisitionModesBaseVariables, CountAssetAcquisitionModesBaseVariables, FindAssetAcquisitionModesBaseVariables, SearchAssetAcquisitionModesBaseVariables, ExportSchemaAssetAcquisitionModesBaseVariables, ExportDataAssetAcquisitionModesBaseVariables, CustomQueryAssetAcquisitionModesBaseVariables, CustomQueryIdAssetAcquisitionModesBaseVariables, UsedAssetAcquisitionModesBaseVariables, ExistAssetAcquisitionModesBaseVariables, FindUnassociatedContractsAssetAcquisitionModesBaseVariables, FindUnassociatedAssetsAssetAcquisitionModesBaseVariables, FindUnassociatedLicenseKeysAssetAcquisitionModesBaseVariables, InsertAssetAcquisitionModesBaseVariables, UpdateAssetAcquisitionModesBaseVariables, ImportDataAssetAcquisitionModesBaseVariables, UpdateManyAssetAcquisitionModesBaseVariables, DeleteAssetAcquisitionModesBaseVariables, AddAssetsAssetAcquisitionModesBaseVariables, RemoveAssetsAssetAcquisitionModesBaseVariables, AddContractsAssetAcquisitionModesBaseVariables, RemoveContractsAssetAcquisitionModesBaseVariables, AddLicenseKeysAssetAcquisitionModesBaseVariables, RemoveLicenseKeysAssetAcquisitionModesBaseVariables } from '../gqls'
import { GetAssetAcquisitionModesDocument, FirstAssetAcquisitionModesDocument, CountAssetAcquisitionModesDocument, FindAssetAcquisitionModesDocument, SearchAssetAcquisitionModesDocument, ExportSchemaAssetAcquisitionModesDocument, ExportDataAssetAcquisitionModesDocument, CustomQueryAssetAcquisitionModesDocument, CustomQueryIdAssetAcquisitionModesDocument, UsedAssetAcquisitionModesDocument, ExistAssetAcquisitionModesDocument, FindUnassociatedContractsAssetAcquisitionModesDocument, FindUnassociatedAssetsAssetAcquisitionModesDocument, FindUnassociatedLicenseKeysAssetAcquisitionModesDocument, InsertAssetAcquisitionModesDocument, UpdateAssetAcquisitionModesDocument, ImportDataAssetAcquisitionModesDocument, UpdateManyAssetAcquisitionModesDocument, DeleteAssetAcquisitionModesDocument, AddAssetsAssetAcquisitionModesDocument, RemoveAssetsAssetAcquisitionModesDocument, AddContractsAssetAcquisitionModesDocument, RemoveContractsAssetAcquisitionModesDocument, AddLicenseKeysAssetAcquisitionModesDocument, RemoveLicenseKeysAssetAcquisitionModesDocument } from '../gqls'
import { ServiceSingleResultOfAssetAcquisitionMode, QueryContextOfAssetAcquisitionMode, FilterOfAssetAcquisitionMode, ServiceSingleResultOfInt64, ServiceListResultOfAssetAcquisitionMode, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, QueryContextOfContract, FilterOfContract, ServiceListResultOfContract, QueryContextOfAsset, FilterOfAsset, ServiceListResultOfAsset, QueryContextOfLicenseKey, FilterOfLicenseKey, ServiceListResultOfLicenseKey, AssetAcquisitionModeInput, FieldUpdateOperatorOfAssetAcquisitionMode } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AssetAcquisitionModeBaseService {
    
public modelName = 'assetAcquisitionMode';
public modelPluralName = 'assetAcquisitionModes';

	private getAssetAcquisitionModesQuery: GetAssetAcquisitionModesDocument;
	private firstAssetAcquisitionModesQuery: FirstAssetAcquisitionModesDocument;
	private countAssetAcquisitionModesQuery: CountAssetAcquisitionModesDocument;
	private findAssetAcquisitionModesQuery: FindAssetAcquisitionModesDocument;
	private searchAssetAcquisitionModesQuery: SearchAssetAcquisitionModesDocument;
	private exportSchemaAssetAcquisitionModesQuery: ExportSchemaAssetAcquisitionModesDocument;
	private exportDataAssetAcquisitionModesQuery: ExportDataAssetAcquisitionModesDocument;
	private customQueryAssetAcquisitionModesQuery: CustomQueryAssetAcquisitionModesDocument;
	private customQueryIdAssetAcquisitionModesQuery: CustomQueryIdAssetAcquisitionModesDocument;
	private usedAssetAcquisitionModesQuery: UsedAssetAcquisitionModesDocument;
	private existAssetAcquisitionModesQuery: ExistAssetAcquisitionModesDocument;
	private findUnassociatedContractsAssetAcquisitionModesQuery: FindUnassociatedContractsAssetAcquisitionModesDocument;
	private findUnassociatedAssetsAssetAcquisitionModesQuery: FindUnassociatedAssetsAssetAcquisitionModesDocument;
	private findUnassociatedLicenseKeysAssetAcquisitionModesQuery: FindUnassociatedLicenseKeysAssetAcquisitionModesDocument;
	private insertAssetAcquisitionModesMutation: InsertAssetAcquisitionModesDocument;
	private updateAssetAcquisitionModesMutation: UpdateAssetAcquisitionModesDocument;
	private importDataAssetAcquisitionModesMutation: ImportDataAssetAcquisitionModesDocument;
	private updateManyAssetAcquisitionModesMutation: UpdateManyAssetAcquisitionModesDocument;
	private deleteAssetAcquisitionModesMutation: DeleteAssetAcquisitionModesDocument;
	private addAssetsAssetAcquisitionModesMutation: AddAssetsAssetAcquisitionModesDocument;
	private removeAssetsAssetAcquisitionModesMutation: RemoveAssetsAssetAcquisitionModesDocument;
	private addContractsAssetAcquisitionModesMutation: AddContractsAssetAcquisitionModesDocument;
	private removeContractsAssetAcquisitionModesMutation: RemoveContractsAssetAcquisitionModesDocument;
	private addLicenseKeysAssetAcquisitionModesMutation: AddLicenseKeysAssetAcquisitionModesDocument;
	private removeLicenseKeysAssetAcquisitionModesMutation: RemoveLicenseKeysAssetAcquisitionModesDocument;

	constructor(private injector: Injector) {
		this.getAssetAcquisitionModesQuery = this.injector.get(GetAssetAcquisitionModesDocument);
		this.firstAssetAcquisitionModesQuery = this.injector.get(FirstAssetAcquisitionModesDocument);
		this.countAssetAcquisitionModesQuery = this.injector.get(CountAssetAcquisitionModesDocument);
		this.findAssetAcquisitionModesQuery = this.injector.get(FindAssetAcquisitionModesDocument);
		this.searchAssetAcquisitionModesQuery = this.injector.get(SearchAssetAcquisitionModesDocument);
		this.exportSchemaAssetAcquisitionModesQuery = this.injector.get(ExportSchemaAssetAcquisitionModesDocument);
		this.exportDataAssetAcquisitionModesQuery = this.injector.get(ExportDataAssetAcquisitionModesDocument);
		this.customQueryAssetAcquisitionModesQuery = this.injector.get(CustomQueryAssetAcquisitionModesDocument);
		this.customQueryIdAssetAcquisitionModesQuery = this.injector.get(CustomQueryIdAssetAcquisitionModesDocument);
		this.usedAssetAcquisitionModesQuery = this.injector.get(UsedAssetAcquisitionModesDocument);
		this.existAssetAcquisitionModesQuery = this.injector.get(ExistAssetAcquisitionModesDocument);
		this.findUnassociatedContractsAssetAcquisitionModesQuery = this.injector.get(FindUnassociatedContractsAssetAcquisitionModesDocument);
		this.findUnassociatedAssetsAssetAcquisitionModesQuery = this.injector.get(FindUnassociatedAssetsAssetAcquisitionModesDocument);
		this.findUnassociatedLicenseKeysAssetAcquisitionModesQuery = this.injector.get(FindUnassociatedLicenseKeysAssetAcquisitionModesDocument);
		this.insertAssetAcquisitionModesMutation = this.injector.get(InsertAssetAcquisitionModesDocument);
		this.updateAssetAcquisitionModesMutation = this.injector.get(UpdateAssetAcquisitionModesDocument);
		this.importDataAssetAcquisitionModesMutation = this.injector.get(ImportDataAssetAcquisitionModesDocument);
		this.updateManyAssetAcquisitionModesMutation = this.injector.get(UpdateManyAssetAcquisitionModesDocument);
		this.deleteAssetAcquisitionModesMutation = this.injector.get(DeleteAssetAcquisitionModesDocument);
		this.addAssetsAssetAcquisitionModesMutation = this.injector.get(AddAssetsAssetAcquisitionModesDocument);
		this.removeAssetsAssetAcquisitionModesMutation = this.injector.get(RemoveAssetsAssetAcquisitionModesDocument);
		this.addContractsAssetAcquisitionModesMutation = this.injector.get(AddContractsAssetAcquisitionModesDocument);
		this.removeContractsAssetAcquisitionModesMutation = this.injector.get(RemoveContractsAssetAcquisitionModesDocument);
		this.addLicenseKeysAssetAcquisitionModesMutation = this.injector.get(AddLicenseKeysAssetAcquisitionModesDocument);
		this.removeLicenseKeysAssetAcquisitionModesMutation = this.injector.get(RemoveLicenseKeysAssetAcquisitionModesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetAcquisitionMode> {
    
    		let variables: GetAssetAcquisitionModesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAssetAcquisitionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.get));
            }
            else{
                let result:ServiceSingleResultOfAssetAcquisitionMode={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetAcquisitionMode,
		@Args('filter?') filter?: FilterOfAssetAcquisitionMode,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetAcquisitionMode> {
    
    		let variables: FirstAssetAcquisitionModesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAssetAcquisitionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetAcquisitionMode,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAssetAcquisitionModesBaseVariables = {
    			filter: filter
    		}
    				return this.countAssetAcquisitionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetAcquisitionMode,
		@Args('filter?') filter?: FilterOfAssetAcquisitionMode,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetAcquisitionMode> {
    
    		let variables: FindAssetAcquisitionModesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAssetAcquisitionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAssetAcquisitionMode,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetAcquisitionMode> {
    
    		let variables: SearchAssetAcquisitionModesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAssetAcquisitionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAssetAcquisitionModesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAssetAcquisitionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetAcquisitionMode,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAssetAcquisitionModesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAssetAcquisitionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAssetAcquisitionMode,
		@Args('filter?') filter?: FilterOfAssetAcquisitionMode,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetAcquisitionMode> {
    
    		let variables: CustomQueryAssetAcquisitionModesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAssetAcquisitionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAssetAcquisitionMode,
		@Args('filter?') filter?: FilterOfAssetAcquisitionMode,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetAcquisitionMode> {
    
    		let variables: CustomQueryIdAssetAcquisitionModesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAssetAcquisitionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAssetAcquisitionModesBaseVariables = {
    			ids: ids
    		}
    				return this.usedAssetAcquisitionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAssetAcquisitionModesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAssetAcquisitionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.exist));
    	}

	@InjectArgs
	public findAssociatedContracts(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfContract,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfContract,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfContract> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('contracts', fields, null, [
			GqlSubFieldArg.create('filterOfContracts', 'filter'),
			GqlSubFieldArg.create('optionsOfContracts', 'options'),
		]),
		])
		extendedVariables['filterOfContracts'] = filter;
		extendedVariables['optionsOfContracts'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.contracts));
            }
            else{
                let result: ServiceListResultOfContract = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedContracts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfContract,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfContract,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfContract> {
    
    		let variables: FindUnassociatedContractsAssetAcquisitionModesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedContractsAssetAcquisitionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.findUnassociatedContracts));
    	}

	@InjectArgs
	public findAssociatedAssets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAsset> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('assets', fields, null, [
			GqlSubFieldArg.create('filterOfAssets', 'filter'),
			GqlSubFieldArg.create('optionsOfAssets', 'options'),
		]),
		])
		extendedVariables['filterOfAssets'] = filter;
		extendedVariables['optionsOfAssets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.assets));
            }
            else{
                let result: ServiceListResultOfAsset = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindUnassociatedAssetsAssetAcquisitionModesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAssetsAssetAcquisitionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.findUnassociatedAssets));
    	}

	@InjectArgs
	public findAssociatedLicenseKeys(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfLicenseKey,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfLicenseKey,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfLicenseKey> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('licenseKeys', fields, null, [
			GqlSubFieldArg.create('filterOfLicenseKeys', 'filter'),
			GqlSubFieldArg.create('optionsOfLicenseKeys', 'options'),
		]),
		])
		extendedVariables['filterOfLicenseKeys'] = filter;
		extendedVariables['optionsOfLicenseKeys'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.licenseKeys));
            }
            else{
                let result: ServiceListResultOfLicenseKey = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedLicenseKeys(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLicenseKey,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfLicenseKey,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLicenseKey> {
    
    		let variables: FindUnassociatedLicenseKeysAssetAcquisitionModesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedLicenseKeysAssetAcquisitionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.findUnassociatedLicenseKeys));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AssetAcquisitionModeInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetAcquisitionMode> {
    
    		let variables: InsertAssetAcquisitionModesBaseVariables = {
    			entity: entity
    		}
    				return this.insertAssetAcquisitionModesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetAcquisitionMode,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetAcquisitionMode> {
    
    		let variables: UpdateAssetAcquisitionModesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAssetAcquisitionModesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAssetAcquisitionModesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAssetAcquisitionModesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetAcquisitionMode,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAssetAcquisitionModesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAssetAcquisitionModesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAssetAcquisitionModesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAssetAcquisitionModesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAssetsAssetAcquisitionModesBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.addAssetsAssetAcquisitionModesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.addAssets));
    	}

    	/**  */
    	@InjectArgs
    	public removeAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveAssetsAssetAcquisitionModesBaseVariables = {
    			assetIds: assetIds
    		}
    				return this.removeAssetsAssetAcquisitionModesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.removeAssets));
    	}

    	/**  */
    	@InjectArgs
    	public addContracts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('contractIds') contractIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddContractsAssetAcquisitionModesBaseVariables = {
    			id: id,
			contractIds: contractIds
    		}
    				return this.addContractsAssetAcquisitionModesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.addContracts));
    	}

    	/**  */
    	@InjectArgs
    	public removeContracts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('contractIds') contractIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveContractsAssetAcquisitionModesBaseVariables = {
    			contractIds: contractIds
    		}
    				return this.removeContractsAssetAcquisitionModesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.removeContracts));
    	}

    	/**  */
    	@InjectArgs
    	public addLicenseKeys(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('licenseKeyIds') licenseKeyIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddLicenseKeysAssetAcquisitionModesBaseVariables = {
    			id: id,
			licenseKeyIds: licenseKeyIds
    		}
    				return this.addLicenseKeysAssetAcquisitionModesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.addLicenseKeys));
    	}

    	/**  */
    	@InjectArgs
    	public removeLicenseKeys(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('licenseKeyIds') licenseKeyIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveLicenseKeysAssetAcquisitionModesBaseVariables = {
    			licenseKeyIds: licenseKeyIds
    		}
    				return this.removeLicenseKeysAssetAcquisitionModesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionModes.removeLicenseKeys));
    	}
    
}