import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { ScheduleTaskErrorBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { GqlField, GqlSubField } from '../helpers';
import {
  FilterOfScheduleTask,
  QueryContextOfScheduleTask,
  ScheduleTaskStatus,
  ServiceListResultOfScheduleTask,
  ServiceListResultOfScheduleTaskError,
} from '../types';
import { Observable } from 'rxjs/internal/Observable';
import { field } from 'devexpress-reporting/scopes/reporting-designer-controls-pivotGrid-metadata';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ScheduleTaskErrorCoreService extends ScheduleTaskErrorBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  /** Récupère une entité selon l'id. */
  @InjectArgs
  public getStack(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('id') id: string,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfScheduleTaskError> {
    return this.get(
      fields.filter((f) => f.name == 'data'),
      id,
      extendedVariables,
    ).pipe(
      map((s) => {
        let data: ServiceListResultOfScheduleTaskError = {
          totalCount: 1,
          data: [s.data],
        };
        return data;
      }),
    );
  }

  @InjectArgs
  public findInQueue(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('state') state: ScheduleTaskStatus,
    @Args('options?') options?: QueryContextOfScheduleTask,
    @Args('filter?') filter?: FilterOfScheduleTask,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfScheduleTask> {
    let dateFilter: FilterOfScheduleTask = {
      dueDate: {
        lte: new Date(),
      },
    };
    if (filter != undefined) {
      filter = {
        and: [filter, dateFilter],
      };
    } else {
      filter = dateFilter;
    }

    return this.findByState(
      fields,
      ScheduleTaskStatus.WaitForExec,
      options,
      filter,
      extendedVariables,
    );
  }

  @InjectArgs
  public findSoon(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('state') state: ScheduleTaskStatus,
    @Args('options?') options?: QueryContextOfScheduleTask,
    @Args('filter?') filter?: FilterOfScheduleTask,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfScheduleTask> {
    let dateFilter: FilterOfScheduleTask = {
      dueDate: {
        gt: new Date(),
      },
    };
    if (filter != undefined) {
      filter = {
        and: [filter, dateFilter],
      };
    } else {
      filter = dateFilter;
    }
    return this.findByState(
      fields,
      ScheduleTaskStatus.WaitForExec,
      options,
      filter,
      extendedVariables,
    );
  }

  @InjectArgs
  public findInProgress(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('state') state: ScheduleTaskStatus,
    @Args('options?') options?: QueryContextOfScheduleTask,
    @Args('filter?') filter?: FilterOfScheduleTask,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfScheduleTask> {
    return this.findByState(
      fields,
      ScheduleTaskStatus.Exec,
      options,
      filter,
      extendedVariables,
    );
  }
}
