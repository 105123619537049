import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfString, ServiceSingleResultOfFileModel, ServiceListResultOfFileModel, FilterOfFileModel, QueryContextOfFileModel, ServiceListResultOfTicket, ServiceSingleResultOfGuid, ServiceSingleResultOfBoolean } from '../types'

export type DownloadFileFileManagersResultType = {
    fileManagers: {
        downloadFile?: Maybe<ServiceSingleResultOfString>
    }
}

export type DownloadFileFileManagersBaseVariables = {
	fileId: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class DownloadFileFileManagersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fileId: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query downloadFileFileManagers ${args} {
    fileManagers {
        downloadFile(fileId: $fileId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: DownloadFileFileManagersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<DownloadFileFileManagersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FileInfoFileManagersResultType = {
    fileManagers: {
        fileInfo?: Maybe<ServiceSingleResultOfFileModel>
    }
}

export type FileInfoFileManagersBaseVariables = {
	fileId: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class FileInfoFileManagersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fileId: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query fileInfoFileManagers ${args} {
    fileManagers {
        fileInfo(fileId: $fileId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FileInfoFileManagersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FileInfoFileManagersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfFileModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type TokenFileFileManagersResultType = {
    fileManagers: {
        tokenFile?: Maybe<ServiceSingleResultOfString>
    }
}

export type TokenFileFileManagersBaseVariables = {
	fileId: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class TokenFileFileManagersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fileId: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query tokenFileFileManagers ${args} {
    fileManagers {
        tokenFile(fileId: $fileId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: TokenFileFileManagersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<TokenFileFileManagersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindTicketAttachmentsFileManagersResultType = {
    fileManagers: {
        findTicketAttachments?: Maybe<ServiceListResultOfFileModel>
    }
}

export type FindTicketAttachmentsFileManagersBaseVariables = {
	ticketType: Maybe<string>, /**  */
	filter: Maybe<FilterOfFileModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfFileModel>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindTicketAttachmentsFileManagersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ticketType: String = null, $filter: FilterOfFileModel = null, $options: QueryContextOfFileModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findTicketAttachmentsFileManagers ${args} {
    fileManagers {
        findTicketAttachments(ticketType: $ticketType, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindTicketAttachmentsFileManagersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindTicketAttachmentsFileManagersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfFileModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAttachmentTicketInfoFileManagersResultType = {
    fileManagers: {
        findAttachmentTicketInfo?: Maybe<ServiceListResultOfTicket>
    }
}

export type FindAttachmentTicketInfoFileManagersBaseVariables = {
	id: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindAttachmentTicketInfoFileManagersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAttachmentTicketInfoFileManagers ${args} {
    fileManagers {
        findAttachmentTicketInfo(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAttachmentTicketInfoFileManagersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAttachmentTicketInfoFileManagersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicket", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UploadChunkFileFileManagersResultType = {
    fileManagers: {
        uploadChunkFile?: Maybe<ServiceSingleResultOfGuid>
    }
}

export type UploadChunkFileFileManagersBaseVariables = {
	name: Maybe<string>, /**  */
	size: number, /**  */
	type: Maybe<string>, /**  */
	chunks: Maybe<string>, /**  */
	indexChunk: number, /**  */
	lastChunk: boolean, /**  */
	entityId: Maybe<string>, /**  */
	visibleByHelpDeskUser: boolean, /**  */
	outgoingEmails: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class UploadChunkFileFileManagersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$name: String = null, $size: Int!, $type: String = null, $chunks: String = null, $indexChunk: Int!, $lastChunk: Boolean!, $entityId: Uuid = null, $visibleByHelpDeskUser: Boolean!, $outgoingEmails: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation uploadChunkFileFileManagers ${args} {
    fileManagers {
        uploadChunkFile(name: $name, size: $size, type: $type, chunks: $chunks, indexChunk: $indexChunk, lastChunk: $lastChunk, entityId: $entityId, visibleByHelpDeskUser: $visibleByHelpDeskUser, outgoingEmails: $outgoingEmails) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UploadChunkFileFileManagersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UploadChunkFileFileManagersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfGuid", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddCommentaryFileManagersResultType = {
    fileManagers: {
        addCommentary?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddCommentaryFileManagersBaseVariables = {
	fileIds: Array<string>, /**  */
	commentary: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddCommentaryFileManagersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fileIds: [Uuid!], $commentary: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addCommentaryFileManagers ${args} {
    fileManagers {
        addCommentary(fileIds: $fileIds, commentary: $commentary) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddCommentaryFileManagersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddCommentaryFileManagersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateVisibilityHelpDeskFileManagersResultType = {
    fileManagers: {
        updateVisibilityHelpDesk?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateVisibilityHelpDeskFileManagersBaseVariables = {
	fileIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class UpdateVisibilityHelpDeskFileManagersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fileIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateVisibilityHelpDeskFileManagers ${args} {
    fileManagers {
        updateVisibilityHelpDesk(fileIds: $fileIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateVisibilityHelpDeskFileManagersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateVisibilityHelpDeskFileManagersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateOutgoingEmailsFileManagersResultType = {
    fileManagers: {
        updateOutgoingEmails?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateOutgoingEmailsFileManagersBaseVariables = {
	fileIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class UpdateOutgoingEmailsFileManagersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fileIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateOutgoingEmailsFileManagers ${args} {
    fileManagers {
        updateOutgoingEmails(fileIds: $fileIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateOutgoingEmailsFileManagersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateOutgoingEmailsFileManagersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteTicketAttachmentFileManagersResultType = {
    fileManagers: {
        deleteTicketAttachment?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteTicketAttachmentFileManagersBaseVariables = {
	ids: Array<string>, /**  */
	ticketType: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class DeleteTicketAttachmentFileManagersDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $ticketType: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteTicketAttachmentFileManagers ${args} {
    fileManagers {
        deleteTicketAttachment(ids: $ids, ticketType: $ticketType) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteTicketAttachmentFileManagersBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteTicketAttachmentFileManagersResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
