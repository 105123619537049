import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { LicenseKeyBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { GqlField, GqlSubField } from '../helpers';
import {
  FilterOfContract,
  FilterOfLicenseKey,
  QueryContextOfContract,
  QueryContextOfLicenseKey,
  ServiceListResultOfContract,
  ServiceListResultOfLicenseKey,
} from '../types';
import { Observable } from 'rxjs/internal/Observable';
import { ContractCoreService } from './contract.service';

@Injectable({ providedIn: 'root' })
@Authorize({
  policies: ['contract.manage-license-key', 'software.manage-license-key'],
  operator: 'or',
})
export class LicenseKeyCoreService extends LicenseKeyBaseService {
  constructor(
    injector: Injector,
    private contractService: ContractCoreService,
  ) {
    super(injector);
  }

  /** Récupère les entités selon le filtre. */
  @InjectArgs
  public findWithoutContract(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfLicenseKey,
    @Args('filter?') filter?: FilterOfLicenseKey,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfLicenseKey> {
    let addFilter: FilterOfLicenseKey = {
      contractId: {
        eq: null,
      },
    };
    if (filter != undefined) {
      filter = {
        and: [filter, addFilter],
      };
    } else {
      filter = addFilter;
    }
    return this.find(fields, options, filter, extendedVariables);
  }

  @InjectArgs
  public findNotContractLinksByLicense(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfContract,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfContract,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfContract> {
    let types = this.contractService
      .allContractType()
      .filter((s) => s != 'warranty' && s != 'license');
    return this.findNotContractLinksByType(
      fields,
      types,
      options,
      id,
      filter,
      extendedVariables,
    );
  }
}
