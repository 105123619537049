import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetHtmlFieldsSettingsBaseVariables, FirstHtmlFieldsSettingsBaseVariables, CountHtmlFieldsSettingsBaseVariables, FindHtmlFieldsSettingsBaseVariables, SearchHtmlFieldsSettingsBaseVariables, ExportSchemaHtmlFieldsSettingsBaseVariables, ExportDataHtmlFieldsSettingsBaseVariables, CustomQueryHtmlFieldsSettingsBaseVariables, CustomQueryIdHtmlFieldsSettingsBaseVariables, UsedHtmlFieldsSettingsBaseVariables, ExistHtmlFieldsSettingsBaseVariables, FindRecyclesHtmlFieldsSettingsBaseVariables, CountRecyclesHtmlFieldsSettingsBaseVariables, ReadOnlyHtmlFieldsSettingsBaseVariables, FindArchivedHtmlFieldsSettingsBaseVariables, CountAllHtmlFieldsSettingsBaseVariables, FindDynamicPropertyFieldsHtmlFieldsSettingsBaseVariables, FindUnassociatedUsersHtmlFieldsSettingsBaseVariables, InsertHtmlFieldsSettingsBaseVariables, UpdateHtmlFieldsSettingsBaseVariables, ImportDataHtmlFieldsSettingsBaseVariables, UpdateManyHtmlFieldsSettingsBaseVariables, DeleteHtmlFieldsSettingsBaseVariables, RestoreHtmlFieldsSettingsBaseVariables, RecycleHtmlFieldsSettingsBaseVariables, RestoreArchivedHtmlFieldsSettingsBaseVariables, ArchivedHtmlFieldsSettingsBaseVariables, AddFileDynamicFieldHtmlFieldsSettingsBaseVariables, RemoveFileDynamicFieldHtmlFieldsSettingsBaseVariables, AddUsersHtmlFieldsSettingsBaseVariables, RemoveUsersHtmlFieldsSettingsBaseVariables } from '../gqls'
import { GetHtmlFieldsSettingsDocument, FirstHtmlFieldsSettingsDocument, CountHtmlFieldsSettingsDocument, FindHtmlFieldsSettingsDocument, SearchHtmlFieldsSettingsDocument, ExportSchemaHtmlFieldsSettingsDocument, ExportDataHtmlFieldsSettingsDocument, CustomQueryHtmlFieldsSettingsDocument, CustomQueryIdHtmlFieldsSettingsDocument, UsedHtmlFieldsSettingsDocument, ExistHtmlFieldsSettingsDocument, FindRecyclesHtmlFieldsSettingsDocument, CountRecyclesHtmlFieldsSettingsDocument, ReadOnlyHtmlFieldsSettingsDocument, FindArchivedHtmlFieldsSettingsDocument, CountAllHtmlFieldsSettingsDocument, FindDynamicPropertyFieldsHtmlFieldsSettingsDocument, FindUnassociatedUsersHtmlFieldsSettingsDocument, InsertHtmlFieldsSettingsDocument, UpdateHtmlFieldsSettingsDocument, ImportDataHtmlFieldsSettingsDocument, UpdateManyHtmlFieldsSettingsDocument, DeleteHtmlFieldsSettingsDocument, RestoreHtmlFieldsSettingsDocument, RecycleHtmlFieldsSettingsDocument, RestoreArchivedHtmlFieldsSettingsDocument, ArchivedHtmlFieldsSettingsDocument, AddFileDynamicFieldHtmlFieldsSettingsDocument, RemoveFileDynamicFieldHtmlFieldsSettingsDocument, AddUsersHtmlFieldsSettingsDocument, RemoveUsersHtmlFieldsSettingsDocument } from '../gqls'
import { ServiceSingleResultOfHtmlFieldsSetting, QueryContextOfHtmlFieldsSetting, FilterOfHtmlFieldsSetting, ServiceSingleResultOfInt64, ServiceListResultOfHtmlFieldsSetting, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfHtmlFieldsSetting, QueryContextOfUser, FilterOfUser, ServiceListResultOfUser, HtmlFieldsSettingInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class HtmlFieldsSettingBaseService {
    
public modelName = 'htmlFieldsSetting';
public modelPluralName = 'htmlFieldsSettings';

	private getHtmlFieldsSettingsQuery: GetHtmlFieldsSettingsDocument;
	private firstHtmlFieldsSettingsQuery: FirstHtmlFieldsSettingsDocument;
	private countHtmlFieldsSettingsQuery: CountHtmlFieldsSettingsDocument;
	private findHtmlFieldsSettingsQuery: FindHtmlFieldsSettingsDocument;
	private searchHtmlFieldsSettingsQuery: SearchHtmlFieldsSettingsDocument;
	private exportSchemaHtmlFieldsSettingsQuery: ExportSchemaHtmlFieldsSettingsDocument;
	private exportDataHtmlFieldsSettingsQuery: ExportDataHtmlFieldsSettingsDocument;
	private customQueryHtmlFieldsSettingsQuery: CustomQueryHtmlFieldsSettingsDocument;
	private customQueryIdHtmlFieldsSettingsQuery: CustomQueryIdHtmlFieldsSettingsDocument;
	private usedHtmlFieldsSettingsQuery: UsedHtmlFieldsSettingsDocument;
	private existHtmlFieldsSettingsQuery: ExistHtmlFieldsSettingsDocument;
	private findRecyclesHtmlFieldsSettingsQuery: FindRecyclesHtmlFieldsSettingsDocument;
	private countRecyclesHtmlFieldsSettingsQuery: CountRecyclesHtmlFieldsSettingsDocument;
	private readOnlyHtmlFieldsSettingsQuery: ReadOnlyHtmlFieldsSettingsDocument;
	private findArchivedHtmlFieldsSettingsQuery: FindArchivedHtmlFieldsSettingsDocument;
	private countAllHtmlFieldsSettingsQuery: CountAllHtmlFieldsSettingsDocument;
	private findDynamicPropertyFieldsHtmlFieldsSettingsQuery: FindDynamicPropertyFieldsHtmlFieldsSettingsDocument;
	private findUnassociatedUsersHtmlFieldsSettingsQuery: FindUnassociatedUsersHtmlFieldsSettingsDocument;
	private insertHtmlFieldsSettingsMutation: InsertHtmlFieldsSettingsDocument;
	private updateHtmlFieldsSettingsMutation: UpdateHtmlFieldsSettingsDocument;
	private importDataHtmlFieldsSettingsMutation: ImportDataHtmlFieldsSettingsDocument;
	private updateManyHtmlFieldsSettingsMutation: UpdateManyHtmlFieldsSettingsDocument;
	private deleteHtmlFieldsSettingsMutation: DeleteHtmlFieldsSettingsDocument;
	private restoreHtmlFieldsSettingsMutation: RestoreHtmlFieldsSettingsDocument;
	private recycleHtmlFieldsSettingsMutation: RecycleHtmlFieldsSettingsDocument;
	private restoreArchivedHtmlFieldsSettingsMutation: RestoreArchivedHtmlFieldsSettingsDocument;
	private archivedHtmlFieldsSettingsMutation: ArchivedHtmlFieldsSettingsDocument;
	private addFileDynamicFieldHtmlFieldsSettingsMutation: AddFileDynamicFieldHtmlFieldsSettingsDocument;
	private removeFileDynamicFieldHtmlFieldsSettingsMutation: RemoveFileDynamicFieldHtmlFieldsSettingsDocument;
	private addUsersHtmlFieldsSettingsMutation: AddUsersHtmlFieldsSettingsDocument;
	private removeUsersHtmlFieldsSettingsMutation: RemoveUsersHtmlFieldsSettingsDocument;

	constructor(private injector: Injector) {
		this.getHtmlFieldsSettingsQuery = this.injector.get(GetHtmlFieldsSettingsDocument);
		this.firstHtmlFieldsSettingsQuery = this.injector.get(FirstHtmlFieldsSettingsDocument);
		this.countHtmlFieldsSettingsQuery = this.injector.get(CountHtmlFieldsSettingsDocument);
		this.findHtmlFieldsSettingsQuery = this.injector.get(FindHtmlFieldsSettingsDocument);
		this.searchHtmlFieldsSettingsQuery = this.injector.get(SearchHtmlFieldsSettingsDocument);
		this.exportSchemaHtmlFieldsSettingsQuery = this.injector.get(ExportSchemaHtmlFieldsSettingsDocument);
		this.exportDataHtmlFieldsSettingsQuery = this.injector.get(ExportDataHtmlFieldsSettingsDocument);
		this.customQueryHtmlFieldsSettingsQuery = this.injector.get(CustomQueryHtmlFieldsSettingsDocument);
		this.customQueryIdHtmlFieldsSettingsQuery = this.injector.get(CustomQueryIdHtmlFieldsSettingsDocument);
		this.usedHtmlFieldsSettingsQuery = this.injector.get(UsedHtmlFieldsSettingsDocument);
		this.existHtmlFieldsSettingsQuery = this.injector.get(ExistHtmlFieldsSettingsDocument);
		this.findRecyclesHtmlFieldsSettingsQuery = this.injector.get(FindRecyclesHtmlFieldsSettingsDocument);
		this.countRecyclesHtmlFieldsSettingsQuery = this.injector.get(CountRecyclesHtmlFieldsSettingsDocument);
		this.readOnlyHtmlFieldsSettingsQuery = this.injector.get(ReadOnlyHtmlFieldsSettingsDocument);
		this.findArchivedHtmlFieldsSettingsQuery = this.injector.get(FindArchivedHtmlFieldsSettingsDocument);
		this.countAllHtmlFieldsSettingsQuery = this.injector.get(CountAllHtmlFieldsSettingsDocument);
		this.findDynamicPropertyFieldsHtmlFieldsSettingsQuery = this.injector.get(FindDynamicPropertyFieldsHtmlFieldsSettingsDocument);
		this.findUnassociatedUsersHtmlFieldsSettingsQuery = this.injector.get(FindUnassociatedUsersHtmlFieldsSettingsDocument);
		this.insertHtmlFieldsSettingsMutation = this.injector.get(InsertHtmlFieldsSettingsDocument);
		this.updateHtmlFieldsSettingsMutation = this.injector.get(UpdateHtmlFieldsSettingsDocument);
		this.importDataHtmlFieldsSettingsMutation = this.injector.get(ImportDataHtmlFieldsSettingsDocument);
		this.updateManyHtmlFieldsSettingsMutation = this.injector.get(UpdateManyHtmlFieldsSettingsDocument);
		this.deleteHtmlFieldsSettingsMutation = this.injector.get(DeleteHtmlFieldsSettingsDocument);
		this.restoreHtmlFieldsSettingsMutation = this.injector.get(RestoreHtmlFieldsSettingsDocument);
		this.recycleHtmlFieldsSettingsMutation = this.injector.get(RecycleHtmlFieldsSettingsDocument);
		this.restoreArchivedHtmlFieldsSettingsMutation = this.injector.get(RestoreArchivedHtmlFieldsSettingsDocument);
		this.archivedHtmlFieldsSettingsMutation = this.injector.get(ArchivedHtmlFieldsSettingsDocument);
		this.addFileDynamicFieldHtmlFieldsSettingsMutation = this.injector.get(AddFileDynamicFieldHtmlFieldsSettingsDocument);
		this.removeFileDynamicFieldHtmlFieldsSettingsMutation = this.injector.get(RemoveFileDynamicFieldHtmlFieldsSettingsDocument);
		this.addUsersHtmlFieldsSettingsMutation = this.injector.get(AddUsersHtmlFieldsSettingsDocument);
		this.removeUsersHtmlFieldsSettingsMutation = this.injector.get(RemoveUsersHtmlFieldsSettingsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfHtmlFieldsSetting> {
    
    		let variables: GetHtmlFieldsSettingsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getHtmlFieldsSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.get));
            }
            else{
                let result:ServiceSingleResultOfHtmlFieldsSetting={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfHtmlFieldsSetting,
		@Args('filter?') filter?: FilterOfHtmlFieldsSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfHtmlFieldsSetting> {
    
    		let variables: FirstHtmlFieldsSettingsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstHtmlFieldsSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfHtmlFieldsSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountHtmlFieldsSettingsBaseVariables = {
    			filter: filter
    		}
    				return this.countHtmlFieldsSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfHtmlFieldsSetting,
		@Args('filter?') filter?: FilterOfHtmlFieldsSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfHtmlFieldsSetting> {
    
    		let variables: FindHtmlFieldsSettingsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findHtmlFieldsSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfHtmlFieldsSetting,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfHtmlFieldsSetting> {
    
    		let variables: SearchHtmlFieldsSettingsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchHtmlFieldsSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaHtmlFieldsSettingsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaHtmlFieldsSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfHtmlFieldsSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataHtmlFieldsSettingsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataHtmlFieldsSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfHtmlFieldsSetting,
		@Args('filter?') filter?: FilterOfHtmlFieldsSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfHtmlFieldsSetting> {
    
    		let variables: CustomQueryHtmlFieldsSettingsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryHtmlFieldsSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfHtmlFieldsSetting,
		@Args('filter?') filter?: FilterOfHtmlFieldsSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfHtmlFieldsSetting> {
    
    		let variables: CustomQueryIdHtmlFieldsSettingsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdHtmlFieldsSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedHtmlFieldsSettingsBaseVariables = {
    			ids: ids
    		}
    				return this.usedHtmlFieldsSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistHtmlFieldsSettingsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existHtmlFieldsSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfHtmlFieldsSetting,
		@Args('filter?') filter?: FilterOfHtmlFieldsSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfHtmlFieldsSetting> {
    
    		let variables: FindRecyclesHtmlFieldsSettingsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesHtmlFieldsSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfHtmlFieldsSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesHtmlFieldsSettingsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesHtmlFieldsSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyHtmlFieldsSettingsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyHtmlFieldsSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfHtmlFieldsSetting,
		@Args('filter?') filter?: FilterOfHtmlFieldsSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfHtmlFieldsSetting> {
    
    		let variables: FindArchivedHtmlFieldsSettingsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedHtmlFieldsSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfHtmlFieldsSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllHtmlFieldsSettingsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllHtmlFieldsSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfHtmlFieldsSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsHtmlFieldsSettingsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsHtmlFieldsSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedUsers(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfUser> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('users', fields, null, [
			GqlSubFieldArg.create('filterOfUsers', 'filter'),
			GqlSubFieldArg.create('optionsOfUsers', 'options'),
		]),
		])
		extendedVariables['filterOfUsers'] = filter;
		extendedVariables['optionsOfUsers'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.users));
            }
            else{
                let result: ServiceListResultOfUser = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindUnassociatedUsersHtmlFieldsSettingsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedUsersHtmlFieldsSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.findUnassociatedUsers));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: HtmlFieldsSettingInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfHtmlFieldsSetting> {
    
    		let variables: InsertHtmlFieldsSettingsBaseVariables = {
    			entity: entity
    		}
    				return this.insertHtmlFieldsSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfHtmlFieldsSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfHtmlFieldsSetting> {
    
    		let variables: UpdateHtmlFieldsSettingsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateHtmlFieldsSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataHtmlFieldsSettingsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataHtmlFieldsSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfHtmlFieldsSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyHtmlFieldsSettingsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyHtmlFieldsSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteHtmlFieldsSettingsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteHtmlFieldsSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreHtmlFieldsSettingsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreHtmlFieldsSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleHtmlFieldsSettingsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleHtmlFieldsSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedHtmlFieldsSettingsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedHtmlFieldsSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedHtmlFieldsSettingsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedHtmlFieldsSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldHtmlFieldsSettingsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldHtmlFieldsSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldHtmlFieldsSettingsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldHtmlFieldsSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddUsersHtmlFieldsSettingsBaseVariables = {
    			id: id,
			userIds: userIds
    		}
    				return this.addUsersHtmlFieldsSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.addUsers));
    	}

    	/**  */
    	@InjectArgs
    	public removeUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveUsersHtmlFieldsSettingsBaseVariables = {
    			userIds: userIds
    		}
    				return this.removeUsersHtmlFieldsSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.htmlFieldsSettings.removeUsers));
    	}
    
}