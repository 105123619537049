<dx-box direction="col" width="100%" height="100%">
  <!-- <dxi-item [ratio]="0" [baseSize]="40">
    <div
      class="header"
      [ngStyle]="{ 'background-color': myTheme?.helpMe?.primaryColor }"
    >
      <div class="headerText">
        {{ 'entities/helpme/serviceCatalog' | translate }}
      </div>
    </div>
  </dxi-item> -->

  <dxi-item [ratio]="1">
    <dx-box direction="row" width="100%" height="100%">
      <dxi-item [ratio]="2" class="containerTicketCategories">
        <div class="headerItem" *ngIf="visibleBack">
          <div
            class="fa-solid fa-arrow-left titleHeaderItem cursorHeader"
            (click)="backItem()"
          ></div>
          <span class="titleHeaderItem">
            {{ titleHeader }}
          </span>
        </div>

        <!-- <div
          class="headerItem"
          [ngStyle]="{ 'background-color': myTheme?.helpMe?.primaryColor }"
        >
          <div class="headerInfoItem">
            <span
              *ngIf="visibleBack"
              class="fa-solid fa-turn-left backHeader"
              (click)="backItem()"
            ></span>
            <span class="titleHeaderItem"
              >{{ 'entities/ticketCategory/_title/plural' | translate }}
              {{ titleHeader }}</span
            >
          </div>
        </div> -->
        <!-- [ngClass]="category.hasChildren === true ? 'cursor' : ''" -->
        <dx-scroll-view (onScroll)="onScroll($event)">
          <div class="container" style="overflow: hidden">
            <div *ngFor="let category of categories" style="overflow: hidden">
              <div
                class="item cursor"
                (click)="selectCategoryItem(category)"
                (mouseenter)="showPopup(category, $event)"
                (mousemove)="updateTooltipPosition($event)"
                (mouseleave)="hidePopup()"
              >
                <div class="div-img">
                  <img
                    *ngIf="category.fileId != undefined"
                    class="item-img"
                    [src]="getUrlFile(category.fileId)"
                  />
                  <div
                    *ngIf="category.fileId == undefined"
                    class="item-img noFile"
                  >
                    <div class="fa-solid fa-gears"></div>
                  </div>
                </div>
                <div class="item-name" [title]="category.name[lang]">
                  <div class="info-category-text">
                    {{ category.name[lang] }}
                  </div>
                  <!-- <div
                  class="info-category-text"
                  *ngIf="category.numberOfChildren > 0"
                >
                  ({{ category.numberOfChildren }})
                </div> -->
                </div>
                <!-- DevExtreme Tooltip lié à chaque élément -->
                <dx-tooltip
                  [visible]="hoveredCategory === category"
                  [position]="{
                    my: 'top left',
                    at: 'top left',
                    offset: { x: mouseX, y: mouseY }
                  }"
                  [hideEvent]="'mouseleave'"
                >
                  <div
                    style="
                      max-width: 300px;
                      word-wrap: break-word;
                      white-space: normal;
                      overflow-wrap: break-word;
                      color: black;
                    "
                    [innerHTML]="category.description[lang]"
                  ></div>
                </dx-tooltip>
              </div>
            </div>
          </div>
        </dx-scroll-view>
      </dxi-item>

      <dxi-item [ratio]="1" class="containerIncidentModel">
        <div
          style="
            width: 100%;
            background-color: #f5f5f5;
            border-radius: 0.2em;
            padding: 10px;
          "
        >
          <div
            class="headerItem"
            style="border-radius: 0.2em"
            [ngStyle]="{ 'background-color': myTheme?.helpMe?.primaryColor }"
          >
            <div class="headerInfoItem">
              <span class="titleHeaderItem">{{
                'entities/incidentModel/_title/plural' | translate
              }}</span>
            </div>
          </div>
        </div>

        <!-- <div class="divDataGrid"> -->
        <dx-data-grid
          [dataSource]="ticketModelSource"
          pageLoadMode="scrollBottom"
          itemTemplate="data"
          selectionMode="single"
          height="100%"
          (onRowClick)="selectTicketModelChanged($event)"
          [rowAlternationEnabled]="true"
          [hoverStateEnabled]="true"
          [showColumnHeaders]="false"
          [showColumnLines]="false"
          [showRowLines]="false"
          (onInitialized)="initGridTicketModel($event)"
          [showBorders]="false"
          [paging]="{
            pageSize: 20
          }"
          [pager]="{
            showPageSizeSelector: false,
            showNavigationButtons: true,
            allowedPageSizes: [20],
            showInfo: true,
            visible: false
          }"
        >
          <dxo-scrolling useNative="true" mode="virtual"></dxo-scrolling>
          <dxi-column
            dataField="modelName[{{ lang }}]"
            sortIndex="0"
            sortOrder="asc"
            cellTemplate="cellTemplate"
          ></dxi-column>
          <!--<div *dxTemplate="let data of 'cellTemplate'">
            <div class="itemModel">
              <a
                routerLink="/help-desk-incident/my/new"
                [queryParams]="{ incidentModelId: data.data.id }"
                ><div>{{ data.value }}</div></a
              >
            </div>
          </div>-->
          <div class="templateItem" *dxTemplate="let data of 'cellTemplate'">
            <div class="contentTemplateItem">
              {{ getDisplayTicketModel(data?.value) }}
            </div>
          </div>
        </dx-data-grid>
        <!-- </div> -->
      </dxi-item>
    </dx-box>
  </dxi-item>
</dx-box>
