import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { DynamicPropertyListBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Authorize({
  policies: ['asset.manage-dynamic-property', 'help-desk-operator.manage-dynamic-property'],
  operator: 'or',
})
export class DynamicPropertyListCoreService extends DynamicPropertyListBaseService {
  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  //   @InjectArgs
  //   findListValue(
  //     @Args('dynamicListId') dynamicListId,
  //   ): Observable<ServiceListResultOfDynamicPropertyListValue> {
  //     let fields = this.translatedFieldHelperService.translatedFields();
  //     fields.push(GqlField.create('id'));
  //     return this.findDynamicListValue(
  //       [GqlSubField.create('data', fields)],
  //       dynamicListId,
  //     );
  //   }
}
