import { Injectable, Injector } from '@angular/core';
import Globalize from 'globalize/dist/globalize';
import { forkJoin, from, Observable, of } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';
import { ModelFieldCompilerService } from './model-field-compiler.service';
import { ModelCompilerContextService } from '../compiler/model-compiler-context.service';
import {
  ModelContextService,
  ParameterService,
} from '../compiler/parameter-model-compiler-context.service';
import {
  AbstractModelContext,
  ModelFnContext,
  ModelState,
} from './model-state';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components';
import { CoreModelDynamicFieldCompilerService } from './model-dynamic-field-compiler';
import { v4 as uuidv4 } from 'uuid';
import { add } from 'lodash';

/** Représente les options passer au modèle compiler. */
@Injectable({
  providedIn: 'root',
})
export class CoreModelCompilerOption {
  /** Représente l'Id de l'entité */
  id: string;
  /** Représente des informations de mise à jour pour une nouvelle entité (cf: création d'un asset et son classement) */
  entry: {
    set?: any;
    inc?: any;
    push?: any;
    pullAll?: any;
  };
}

/** Représente un compiler du service. */
@Injectable({
  providedIn: 'root',
})
export class CoreModelCompilerService {
  constructor(
    private _context: ModelCompilerContextService,
    private _parameterService: ParameterService,
    private _modelContext: ModelContextService,
    private _modelFieldCompiler: ModelFieldCompilerService,
    private translatedFieldHelperService: TranslatedFieldHelperService,
    private _injector: Injector,
    private dynamicFieldCompilerService: CoreModelDynamicFieldCompilerService,
  ) {
    // console.log('ModelCompilerService Constructor');
  }

  // /** Obtient le context courrant. */
  // get context(): ModelContextService {
  // 	return this._modelContext;
  // }
  /** Charge les paramètres personnalisées. */
  parameters(parameters: { [k: string]: any }) {
    if (parameters != undefined) {
      this._parameterService.load(parameters);
    }
  }

  /** Charge les resources personnalisées. */
  resources(resources: { [k: string]: any }) {
    if (resources != undefined) {
      for (let key in resources) {
        resources[key] = {
          custom: resources[key],
        };
      }
      Globalize.loadMessages(resources);
      //Globalize.locale(navigator.language);
    }
  }

  private _internalCompile: boolean = false;

  compile(
    obj: { [k: string]: any },
    addId = false,
    methodProvider: any = undefined,
  ): Observable<ModelState> {
    return this.coreCompile(obj, null, addId, methodProvider);
  }

  /** Permet de recherche a ajouter dans le tableau tout les itemKeys a la racine du menu */
  checkItemKey(obj, itemKeys) {
    for (var name in obj) {
      if (name == 'itemKey') {
        itemKeys.push(obj['itemKey']);
      } else if (Array.isArray(obj[name])) {
        for (let element of obj[name]) {
          this.checkItemKey(element, itemKeys);
        }
      } else if (typeof obj[name] == 'object') {
        var newObj = obj[name];
        this.checkItemKey(newObj, itemKeys);
      }
    }
    return itemKeys;
  }

  coreCompile(
    obj: { [k: string]: any },
    option: CoreModelCompilerOption = undefined,
    addId = false,
    methodProvider: any = undefined,
  ): Observable<ModelState> {
    // Exclu des partie non visible
    if (obj?.form?.layout?.pages != undefined) {
      let pages = obj.form.layout.pages.filter(
        (f) =>
          f.visible == undefined ||
          f.visible == true ||
          f.forceLoading === true,
      );
      obj.form.layout.pages = pages;

      obj.form.layout.pages.forEach((page) => {
        let itemkeys = [];
        itemkeys = this.checkItemKey(page, itemkeys);
        if (itemkeys.length > 0) {
          page['dependBadges'] = itemkeys;
        }
        if (page.pages != undefined) {
          page.pages = page.pages.filter(
            (f) =>
              f.visible == undefined ||
              f.visible == true ||
              f.forceLoading === true,
          );
        }
      });
    }
    let modelState = new ModelState(this._injector);

    modelState.component = methodProvider;

    // Validation du schema dynamique si utilisé
    // const id = this.route.snapshot.paramMap.get('id');
    // console.log(id)

    let compileMethod = () =>
      this.coreInternalCompile(obj, modelState, obj, addId).pipe(
        map((model) => {
          modelState.model = model;
          return this._modelFieldCompiler.compile(modelState);
        }),
      );

    return compileMethod();
  }

  /** Ajout des champs perso */
  async addingDynamicField(
    obj: { [k: string]: any },
    option: CoreModelCompilerOption = undefined,
  ): Promise<{ [k: string]: any }> {
    return await this.dynamicFieldCompilerService.createDynamicField(
      obj,
      option,
      this,
    );
  }

  private coreInternalCompile(
    obj: {
      [k: string]: any;
    },
    state: ModelState,
    rootObj: object,
    addId = false,
  ): Observable<{ [k: string]: any }> {
    //TODO En attendant le scope
    // if (this._internalCompile === false) {
    // 	this.context.clear();
    // }

    const observable$ = new Observable<{ [k: string]: any }>((observer) => {
      let model = {};
      let observables$: Observable<any>[] = [];
      for (let member in obj) {
        let value = obj[member];
        // if (member === 'designerOptions') {
        //   model[member] = value;
        //   continue;
        // }
        if (value != undefined) {
          if (Array.isArray(value) && typeof value[0] === 'object') {
            model[member] = [];

            for (let i = 0, l = value.length; i < l; i++) {
              this._internalCompile = true;
              const objectObservable = this.coreInternalCompile(
                value[i],
                state,
                rootObj,
                addId,
              );
              this._internalCompile = false;
              observables$.push(
                objectObservable.pipe(first()).pipe(
                  map((result) => {
                    if (result == undefined) {
                      return undefined;
                    }
                    //console.log(result, member, i)
                    /**for designer**/
                    if (addId === true) {
                      result['id'] = uuidv4();
                    }
                    model[member][i] = result;
                  }),
                ),
              );
            }
          } else if (!Array.isArray(value) && typeof value === 'object') {
            this._internalCompile = true;
            const objectObservable = this.coreInternalCompile(
              value,
              state,
              rootObj,
              addId,
            );
            this._internalCompile = false;
            observables$.push(
              objectObservable.pipe(first()).pipe(
                map((result) => {
                  if (result == undefined) {
                    return undefined;
                  }
                  //console.log(result, member)
                  /**for designer**/
                  if (addId === true) {
                    result['id'] = uuidv4();
                  }

                  model[member] = result;
                  // state.contexts = state.contexts.concat(result.contexts);
                }),
              ),
            );
          } else {
            const objectObservable = this.compileValue(
              value,
              rootObj,
              value,
              state,
            );
            observables$.push(
              objectObservable.pipe(first()).pipe(
                map((result) => {
                  if (result == undefined) {
                    return undefined;
                  }

                  if (result.lookup === true) {
                    state.addContext(result);
                    model[member] = result;
                  } else {
                    if (result instanceof AbstractModelContext) {
                      state.addContext(result);
                      if (result instanceof ModelFnContext) {
                        result.fnCall = result.fnCall.bind(this._context);
                      }
                      model[member] = result;
                    } else {
                      model[member] = result;
                    }
                  }
                  //Cae function waning
                  return model;
                }),
              ),
            );
          }
        } else {
          observables$.push(
            of(value)
              .pipe(first())
              .pipe(
                map((result) => {
                  if (result == undefined) {
                    return undefined;
                  }
                  model[member] = result;
                  return model;
                }),
              ),
          );
        }
      }
      // S'il n'y a pas d'élément, renvoyer le model vide.
      if (observables$.length === 0) {
        observer.next(model);
        observer.complete();
      } else {
        forkJoin(observables$).subscribe(() => {
          observer.next(model);
          observer.complete();
        });
      }
    });

    return observable$;
  }

  /** Compile un model. */
  private internalCompile(
    obj: {
      [k: string]: any;
    },
    rootObj: object,
  ): Observable<{ [k: string]: any }> {
    //TODO En attendant le scope
    // if (this._internalCompile === false) {
    // 	this.context.clear();
    // }

    const observable$ = new Observable((observer) => {
      let model = {};
      let observables$: Observable<any>[] = [];
      for (let member in obj) {
        let value = obj[member];

        if (value != undefined) {
          if (Array.isArray(value) && typeof value[0] === 'object') {
            model[member] = [];

            for (let i = 0, l = value.length; i < l; i++) {
              this._internalCompile = true;
              const objectObservable = this.internalCompile(value[i], rootObj);
              this._internalCompile = false;
              observables$.push(
                objectObservable
                  .pipe(first())
                  .pipe(
                    map((result) =>
                      result != undefined
                        ? (model[member][i] = result)
                        : undefined,
                    ),
                  ),
              );
            }
          } else if (!Array.isArray(value) && typeof value === 'object') {
            this._internalCompile = true;
            const objectObservable = this.internalCompile(value, rootObj);
            this._internalCompile = false;
            observables$.push(
              objectObservable
                .pipe(first())
                .pipe(
                  map((result) =>
                    result != undefined ? (model[member] = result) : undefined,
                  ),
                ),
            );
          } else {
            const objectObservable = this.compileValue(value, rootObj, value);
            observables$.push(
              objectObservable
                .pipe(first())
                .pipe(
                  map((result) =>
                    result != undefined ? (model[member] = result) : undefined,
                  ),
                ),
            );
          }
        } else {
          observables$.push(
            of(value)
              .pipe(first())
              .pipe(
                map((result) =>
                  result != undefined ? (model[member] = result) : undefined,
                ),
              ),
          );
        }
      }
      // S'il n'y a pas d'élément, renvoyer le model vide.
      if (observables$.length === 0) {
        observer.next(model);
        observer.complete();
      } else {
        forkJoin(observables$).subscribe(() => {
          observer.next(model);
          observer.complete();
        });
      }
    });
    return observable$;
  }

  /** Récupère la fonction de la chaine. Si la chaine contient une fonction de type [maFonction()]. */
  private getFunction(value: string): string {
    let isFunctionCall = /^\[(.*?)\]$/g;
    let match;
    while ((match = isFunctionCall.exec(value))) {
      if (match.length > 1) {
        return match[1];
      }
    }
    return undefined;
  }

  /** Permet de lancer une chaine de fonctions. Si ce n'est pas une fonction alors, on renvoi une valeur par défaut. */
  public compileValue<T>(
    value: string,
    rootObj: object,
    defaultValue: T = undefined,
    state: ModelState = null,
  ): Observable<T> {
    let fn = this.getFunction(value);
    if (fn !== undefined) {
      let result = this.compileFunction<T>(fn, state, rootObj);

      return result;
    }
    if (defaultValue == undefined) {
      return of(undefined);
    }
    // Fix : Pour cloner.
    return of(JSON.parse(JSON.stringify(defaultValue)));
  }

  /** Permet de compiler et lancer une chaîne de fonctions. */
  private compileFunction<T>(
    fn: string,
    state: ModelState,
    rootObj: object,
  ): Observable<T> {
    let functionCall = /([a-zA-Z0-9_]{3,})\((.*?)/g;

    if (fn.startsWith('customResource(')) {
      let functionContext = fn.replace(functionCall, (r) => {
        if (fn.startsWith('customResource(')) {
          r += 'rootObj,';
        }
        return `(rootObj) => this.${r}`;
      });

      try {
        let code = `return (${functionContext})(arguments[0]);`;

        let result: Observable<T> = new Function(code).bind(this._context)(
          rootObj,
        );
        return result;
      } catch (e) {
        throw new Error(`Error lors de l'execution de ${fn} : ${e}.`);
      }
    } else {
      let functionContext = fn.replace(functionCall, (r) => {
        return `() => this.${r}`;
      });

      try {
        let code = `return (${functionContext})();`;
        // let result: Observable<T> = new Function(code).bind(state)();
        let result: Observable<T> = new Function(code).bind(this._context)();
        return result;
      } catch (e) {
        throw new Error(`Error lors de l'execution de ${fn} : ${e}.`);
      }
    }
  }
}
