import { NewsBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { InjectArgs, Args } from '@clarilog/core/modules';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { Observable } from 'rxjs';
import { GqlField, GqlSubField } from '../helpers';
import {
  QueryContextOfArticleKnowledge,
  FilterOfArticleKnowledge,
  ServiceListResultOfArticleKnowledge,
  QuerySortTypeArticleKnowledge,
  Sort,
  AreaListCustomization,
  QueryContextOfNews,
  ServiceListResultOfNews,
  QuerySortTypeNews,
} from '../types';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-news')
export class NewsCoreService extends NewsBaseService {
  constructor(
    injector: Injector,
    private authorizationService: AuthorizationCoreService,
  ) {
    super(injector);
  }

  @InjectArgs
  public findVisibleOnHelpMePortal(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfNews,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfNews> {
    if (options == undefined || options == null) {
      options = {};
    }

    let myHelpMePortal: AreaListCustomization;

    if (
      this.authorizationService.user.getHelpMeAreaListCustomization() !=
      undefined
    ) {
      myHelpMePortal =
        this.authorizationService.user.getHelpMeAreaListCustomization();

      if (myHelpMePortal.newsNumberEnable == true) {
        if (myHelpMePortal.newsNumber > 0) {
          options.limit = myHelpMePortal.newsNumber;
        }
      }
    }

    return this.findEnable(fields, options, extendedVariables);
  }

  /** Obtient le lien (administration / helpMe) vers l'actualité */
  getArticleLink(origin, id, isHelpMe: boolean = false) {
    let url = `${origin}/administration/service-desk/news?navigateTo=${id}`;

    if (isHelpMe) {
      url = `${origin}/home?navigateTo=${id}`;
    }

    var txtArea = document.createElement('textarea');
    txtArea.style.position = 'fixed';
    txtArea.style.top = '0';
    txtArea.style.left = '0';
    txtArea.style.opacity = '0';
    txtArea.value = url;
    document.body.appendChild(txtArea);
    txtArea.select();
    document.execCommand('copy');
    document.body.removeChild(txtArea);
  }

  /**
   * A utiliser quand "document.execCommand()" 
   * ne sera plus compatible avec les navigateurs
   */
  copyToClipboard(text: string): Promise<boolean> {
    return navigator.clipboard.writeText(text)
      .then(() => true)
      .catch((err) => {
        return false;
      });
  }
}
