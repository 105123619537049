import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetMessageModelsBaseVariables, FirstMessageModelsBaseVariables, CountMessageModelsBaseVariables, FindMessageModelsBaseVariables, SearchMessageModelsBaseVariables, ExportSchemaMessageModelsBaseVariables, ExportDataMessageModelsBaseVariables, CustomQueryMessageModelsBaseVariables, CustomQueryIdMessageModelsBaseVariables, FindUnassociatedSecurityGroupsMessageModelsBaseVariables, FindAssociatedOperatorTeamSecurityGroupsMessageModelsBaseVariables, FindFilesMessageModelsBaseVariables, FindAttachmentFilesMessageModelsBaseVariables, FindHelpDeskUserAttachmentFilesMessageModelsBaseVariables, UsedMessageModelsBaseVariables, ExistMessageModelsBaseVariables, InsertMessageModelsBaseVariables, UpdateMessageModelsBaseVariables, ImportDataMessageModelsBaseVariables, UpdateManyMessageModelsBaseVariables, DeleteMessageModelsBaseVariables, AddSecurityGroupMessageModelsBaseVariables, RemoveSecurityGroupMessageModelsBaseVariables, AddOperatorTeamSecurityGroupMessageModelsBaseVariables, RemoveOperatorTeamSecurityGroupMessageModelsBaseVariables, AddFilesMessageModelsBaseVariables, RemoveFilesMessageModelsBaseVariables, UploadChunkFileMessageModelsBaseVariables } from '../gqls'
import { GetMessageModelsDocument, FirstMessageModelsDocument, CountMessageModelsDocument, FindMessageModelsDocument, SearchMessageModelsDocument, ExportSchemaMessageModelsDocument, ExportDataMessageModelsDocument, CustomQueryMessageModelsDocument, CustomQueryIdMessageModelsDocument, FindUnassociatedSecurityGroupsMessageModelsDocument, FindAssociatedOperatorTeamSecurityGroupsMessageModelsDocument, FindFilesMessageModelsDocument, FindAttachmentFilesMessageModelsDocument, FindHelpDeskUserAttachmentFilesMessageModelsDocument, UsedMessageModelsDocument, ExistMessageModelsDocument, InsertMessageModelsDocument, UpdateMessageModelsDocument, ImportDataMessageModelsDocument, UpdateManyMessageModelsDocument, DeleteMessageModelsDocument, AddSecurityGroupMessageModelsDocument, RemoveSecurityGroupMessageModelsDocument, AddOperatorTeamSecurityGroupMessageModelsDocument, RemoveOperatorTeamSecurityGroupMessageModelsDocument, AddFilesMessageModelsDocument, RemoveFilesMessageModelsDocument, UploadChunkFileMessageModelsDocument } from '../gqls'
import { ServiceSingleResultOfMessageModel, QueryContextOfMessageModel, FilterOfMessageModel, ServiceSingleResultOfInt64, ServiceListResultOfMessageModel, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, QueryContextOfSecurityGroup, FilterOfSecurityGroup, ServiceListResultOfSecurityGroup, ServiceListResultOfOperatorTeam, ServiceListResultOfFileModel, QueryContextOfFileModel, FilterOfFileModel, ServiceSingleResultOfBoolean, MessageModelInput, FieldUpdateOperatorOfMessageModel, ServiceSingleResultOfGuid } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class MessageModelBaseService {
    
public modelName = 'messageModel';
public modelPluralName = 'messageModels';

	private getMessageModelsQuery: GetMessageModelsDocument;
	private firstMessageModelsQuery: FirstMessageModelsDocument;
	private countMessageModelsQuery: CountMessageModelsDocument;
	private findMessageModelsQuery: FindMessageModelsDocument;
	private searchMessageModelsQuery: SearchMessageModelsDocument;
	private exportSchemaMessageModelsQuery: ExportSchemaMessageModelsDocument;
	private exportDataMessageModelsQuery: ExportDataMessageModelsDocument;
	private customQueryMessageModelsQuery: CustomQueryMessageModelsDocument;
	private customQueryIdMessageModelsQuery: CustomQueryIdMessageModelsDocument;
	private findUnassociatedSecurityGroupsMessageModelsQuery: FindUnassociatedSecurityGroupsMessageModelsDocument;
	private findAssociatedOperatorTeamSecurityGroupsMessageModelsQuery: FindAssociatedOperatorTeamSecurityGroupsMessageModelsDocument;
	private findFilesMessageModelsQuery: FindFilesMessageModelsDocument;
	private findAttachmentFilesMessageModelsQuery: FindAttachmentFilesMessageModelsDocument;
	private findHelpDeskUserAttachmentFilesMessageModelsQuery: FindHelpDeskUserAttachmentFilesMessageModelsDocument;
	private usedMessageModelsQuery: UsedMessageModelsDocument;
	private existMessageModelsQuery: ExistMessageModelsDocument;
	private insertMessageModelsMutation: InsertMessageModelsDocument;
	private updateMessageModelsMutation: UpdateMessageModelsDocument;
	private importDataMessageModelsMutation: ImportDataMessageModelsDocument;
	private updateManyMessageModelsMutation: UpdateManyMessageModelsDocument;
	private deleteMessageModelsMutation: DeleteMessageModelsDocument;
	private addSecurityGroupMessageModelsMutation: AddSecurityGroupMessageModelsDocument;
	private removeSecurityGroupMessageModelsMutation: RemoveSecurityGroupMessageModelsDocument;
	private addOperatorTeamSecurityGroupMessageModelsMutation: AddOperatorTeamSecurityGroupMessageModelsDocument;
	private removeOperatorTeamSecurityGroupMessageModelsMutation: RemoveOperatorTeamSecurityGroupMessageModelsDocument;
	private addFilesMessageModelsMutation: AddFilesMessageModelsDocument;
	private removeFilesMessageModelsMutation: RemoveFilesMessageModelsDocument;
	private uploadChunkFileMessageModelsMutation: UploadChunkFileMessageModelsDocument;

	constructor(private injector: Injector) {
		this.getMessageModelsQuery = this.injector.get(GetMessageModelsDocument);
		this.firstMessageModelsQuery = this.injector.get(FirstMessageModelsDocument);
		this.countMessageModelsQuery = this.injector.get(CountMessageModelsDocument);
		this.findMessageModelsQuery = this.injector.get(FindMessageModelsDocument);
		this.searchMessageModelsQuery = this.injector.get(SearchMessageModelsDocument);
		this.exportSchemaMessageModelsQuery = this.injector.get(ExportSchemaMessageModelsDocument);
		this.exportDataMessageModelsQuery = this.injector.get(ExportDataMessageModelsDocument);
		this.customQueryMessageModelsQuery = this.injector.get(CustomQueryMessageModelsDocument);
		this.customQueryIdMessageModelsQuery = this.injector.get(CustomQueryIdMessageModelsDocument);
		this.findUnassociatedSecurityGroupsMessageModelsQuery = this.injector.get(FindUnassociatedSecurityGroupsMessageModelsDocument);
		this.findAssociatedOperatorTeamSecurityGroupsMessageModelsQuery = this.injector.get(FindAssociatedOperatorTeamSecurityGroupsMessageModelsDocument);
		this.findFilesMessageModelsQuery = this.injector.get(FindFilesMessageModelsDocument);
		this.findAttachmentFilesMessageModelsQuery = this.injector.get(FindAttachmentFilesMessageModelsDocument);
		this.findHelpDeskUserAttachmentFilesMessageModelsQuery = this.injector.get(FindHelpDeskUserAttachmentFilesMessageModelsDocument);
		this.usedMessageModelsQuery = this.injector.get(UsedMessageModelsDocument);
		this.existMessageModelsQuery = this.injector.get(ExistMessageModelsDocument);
		this.insertMessageModelsMutation = this.injector.get(InsertMessageModelsDocument);
		this.updateMessageModelsMutation = this.injector.get(UpdateMessageModelsDocument);
		this.importDataMessageModelsMutation = this.injector.get(ImportDataMessageModelsDocument);
		this.updateManyMessageModelsMutation = this.injector.get(UpdateManyMessageModelsDocument);
		this.deleteMessageModelsMutation = this.injector.get(DeleteMessageModelsDocument);
		this.addSecurityGroupMessageModelsMutation = this.injector.get(AddSecurityGroupMessageModelsDocument);
		this.removeSecurityGroupMessageModelsMutation = this.injector.get(RemoveSecurityGroupMessageModelsDocument);
		this.addOperatorTeamSecurityGroupMessageModelsMutation = this.injector.get(AddOperatorTeamSecurityGroupMessageModelsDocument);
		this.removeOperatorTeamSecurityGroupMessageModelsMutation = this.injector.get(RemoveOperatorTeamSecurityGroupMessageModelsDocument);
		this.addFilesMessageModelsMutation = this.injector.get(AddFilesMessageModelsDocument);
		this.removeFilesMessageModelsMutation = this.injector.get(RemoveFilesMessageModelsDocument);
		this.uploadChunkFileMessageModelsMutation = this.injector.get(UploadChunkFileMessageModelsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMessageModel> {
    
    		let variables: GetMessageModelsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getMessageModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.get));
            }
            else{
                let result:ServiceSingleResultOfMessageModel={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfMessageModel,
		@Args('filter?') filter?: FilterOfMessageModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMessageModel> {
    
    		let variables: FirstMessageModelsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstMessageModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfMessageModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountMessageModelsBaseVariables = {
    			filter: filter
    		}
    				return this.countMessageModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfMessageModel,
		@Args('filter?') filter?: FilterOfMessageModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMessageModel> {
    
    		let variables: FindMessageModelsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findMessageModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfMessageModel,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMessageModel> {
    
    		let variables: SearchMessageModelsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchMessageModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaMessageModelsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaMessageModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfMessageModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataMessageModelsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataMessageModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfMessageModel,
		@Args('filter?') filter?: FilterOfMessageModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMessageModel> {
    
    		let variables: CustomQueryMessageModelsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryMessageModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfMessageModel,
		@Args('filter?') filter?: FilterOfMessageModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMessageModel> {
    
    		let variables: CustomQueryIdMessageModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdMessageModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.customQueryId));
    	}

	@InjectArgs
	public findAssociatedSecurityGroups(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSecurityGroup> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('securityGroups', fields, null, [
			GqlSubFieldArg.create('filterOfSecurityGroups', 'filter'),
			GqlSubFieldArg.create('optionsOfSecurityGroups', 'options'),
		]),
		])
		extendedVariables['filterOfSecurityGroups'] = filter;
		extendedVariables['optionsOfSecurityGroups'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.securityGroups));
            }
            else{
                let result: ServiceListResultOfSecurityGroup = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/** Récupère les profils non liés de l'entité. */
    	@InjectArgs
    	public findUnassociatedSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroup> {
    
    		let variables: FindUnassociatedSecurityGroupsMessageModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSecurityGroupsMessageModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.findUnassociatedSecurityGroups));
    	}

    	/** Récupère les equipes d'operateur via les profils de l'entité. */
    	@InjectArgs
    	public findAssociatedOperatorTeamSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOperatorTeam> {
    
    		let variables: FindAssociatedOperatorTeamSecurityGroupsMessageModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAssociatedOperatorTeamSecurityGroupsMessageModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.findAssociatedOperatorTeamSecurityGroups));
    	}

    	/** Récupère les fichiers liés. */
    	@InjectArgs
    	public findFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindFilesMessageModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findFilesMessageModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.findFiles));
    	}

    	/** Récupère les fichiers attachés liés. */
    	@InjectArgs
    	public findAttachmentFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindAttachmentFilesMessageModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAttachmentFilesMessageModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.findAttachmentFiles));
    	}

    	/** Récupère les fichiers attachés liés visible pour les users help desk. */
    	@InjectArgs
    	public findHelpDeskUserAttachmentFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindHelpDeskUserAttachmentFilesMessageModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findHelpDeskUserAttachmentFilesMessageModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.findHelpDeskUserAttachmentFiles));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedMessageModelsBaseVariables = {
    			ids: ids
    		}
    				return this.usedMessageModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistMessageModelsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existMessageModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: MessageModelInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMessageModel> {
    
    		let variables: InsertMessageModelsBaseVariables = {
    			entity: entity
    		}
    				return this.insertMessageModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfMessageModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMessageModel> {
    
    		let variables: UpdateMessageModelsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateMessageModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataMessageModelsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataMessageModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfMessageModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyMessageModelsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyMessageModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteMessageModelsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteMessageModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSecurityGroupMessageModelsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds,
			isNew: isNew
    		}
    				return this.addSecurityGroupMessageModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.addSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSecurityGroupMessageModelsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds
    		}
    				return this.removeSecurityGroupMessageModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.removeSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddOperatorTeamSecurityGroupMessageModelsBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds,
			isNew: isNew
    		}
    				return this.addOperatorTeamSecurityGroupMessageModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.addOperatorTeamSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveOperatorTeamSecurityGroupMessageModelsBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds
    		}
    				return this.removeOperatorTeamSecurityGroupMessageModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.removeOperatorTeamSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileIds') fileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFilesMessageModelsBaseVariables = {
    			id: id,
			fileIds: fileIds
    		}
    				return this.addFilesMessageModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.addFiles));
    	}

    	/**  */
    	@InjectArgs
    	public removeFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileIds') fileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFilesMessageModelsBaseVariables = {
    			id: id,
			fileIds: fileIds
    		}
    				return this.removeFilesMessageModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.removeFiles));
    	}

    	/**  */
    	@InjectArgs
    	public uploadChunkFile(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('visibleByHelpDeskUser') visibleByHelpDeskUser: boolean,
		@Args('size') size: number,
		@Args('outgoingEmails') outgoingEmails: boolean,
		@Args('lastChunk') lastChunk: boolean,
		@Args('indexChunk') indexChunk: number,
		@Args('type?') type?: string,
		@Args('name?') name?: string,
		@Args('fileId?') fileId?: string,
		@Args('entityId?') entityId?: string,
		@Args('chunks?') chunks?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: UploadChunkFileMessageModelsBaseVariables = {
    			name: name,
			size: size,
			type: type,
			chunks: chunks,
			indexChunk: indexChunk,
			lastChunk: lastChunk,
			fileId: fileId,
			entityId: entityId,
			visibleByHelpDeskUser: visibleByHelpDeskUser,
			outgoingEmails: outgoingEmails
    		}
    				return this.uploadChunkFileMessageModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.messageModels.uploadChunkFile));
    	}
    
}