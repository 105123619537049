import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetScheduleTaskErrorsBaseVariables, FirstScheduleTaskErrorsBaseVariables, CountScheduleTaskErrorsBaseVariables, FindScheduleTaskErrorsBaseVariables, SearchScheduleTaskErrorsBaseVariables, ExportSchemaScheduleTaskErrorsBaseVariables, ExportDataScheduleTaskErrorsBaseVariables, CustomQueryScheduleTaskErrorsBaseVariables, CustomQueryIdScheduleTaskErrorsBaseVariables, UsedScheduleTaskErrorsBaseVariables, FindByStateScheduleTaskErrorsBaseVariables, ExistScheduleTaskErrorsBaseVariables, InsertScheduleTaskErrorsBaseVariables, UpdateScheduleTaskErrorsBaseVariables, ImportDataScheduleTaskErrorsBaseVariables, UpdateManyScheduleTaskErrorsBaseVariables, DeleteScheduleTaskErrorsBaseVariables, RetryTasksScheduleTaskErrorsBaseVariables, UnlockTasksScheduleTaskErrorsBaseVariables, DeleteTasksScheduleTaskErrorsBaseVariables, DeleteErrorTasksScheduleTaskErrorsBaseVariables, DownloadErrorTasksScheduleTaskErrorsBaseVariables, DownloadTasksScheduleTaskErrorsBaseVariables } from '../gqls'
import { GetScheduleTaskErrorsDocument, FirstScheduleTaskErrorsDocument, CountScheduleTaskErrorsDocument, FindScheduleTaskErrorsDocument, SearchScheduleTaskErrorsDocument, ExportSchemaScheduleTaskErrorsDocument, ExportDataScheduleTaskErrorsDocument, CustomQueryScheduleTaskErrorsDocument, CustomQueryIdScheduleTaskErrorsDocument, UsedScheduleTaskErrorsDocument, FindByStateScheduleTaskErrorsDocument, ExistScheduleTaskErrorsDocument, InsertScheduleTaskErrorsDocument, UpdateScheduleTaskErrorsDocument, ImportDataScheduleTaskErrorsDocument, UpdateManyScheduleTaskErrorsDocument, DeleteScheduleTaskErrorsDocument, RetryTasksScheduleTaskErrorsDocument, UnlockTasksScheduleTaskErrorsDocument, DeleteTasksScheduleTaskErrorsDocument, DeleteErrorTasksScheduleTaskErrorsDocument, DownloadErrorTasksScheduleTaskErrorsDocument, DownloadTasksScheduleTaskErrorsDocument } from '../gqls'
import { ServiceSingleResultOfScheduleTaskError, QueryContextOfScheduleTaskError, FilterOfScheduleTaskError, ServiceSingleResultOfInt64, ServiceListResultOfScheduleTaskError, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfScheduleTask, ScheduleTaskStatus, QueryContextOfScheduleTask, FilterOfScheduleTask, ScheduleTaskErrorInput, FieldUpdateOperatorOfScheduleTaskError, ServiceSingleResultOfFileDescriptor } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ScheduleTaskErrorBaseService {
    
public modelName = 'scheduleTaskError';
public modelPluralName = 'scheduleTaskErrors';

	private getScheduleTaskErrorsQuery: GetScheduleTaskErrorsDocument;
	private firstScheduleTaskErrorsQuery: FirstScheduleTaskErrorsDocument;
	private countScheduleTaskErrorsQuery: CountScheduleTaskErrorsDocument;
	private findScheduleTaskErrorsQuery: FindScheduleTaskErrorsDocument;
	private searchScheduleTaskErrorsQuery: SearchScheduleTaskErrorsDocument;
	private exportSchemaScheduleTaskErrorsQuery: ExportSchemaScheduleTaskErrorsDocument;
	private exportDataScheduleTaskErrorsQuery: ExportDataScheduleTaskErrorsDocument;
	private customQueryScheduleTaskErrorsQuery: CustomQueryScheduleTaskErrorsDocument;
	private customQueryIdScheduleTaskErrorsQuery: CustomQueryIdScheduleTaskErrorsDocument;
	private usedScheduleTaskErrorsQuery: UsedScheduleTaskErrorsDocument;
	private findByStateScheduleTaskErrorsQuery: FindByStateScheduleTaskErrorsDocument;
	private existScheduleTaskErrorsQuery: ExistScheduleTaskErrorsDocument;
	private insertScheduleTaskErrorsMutation: InsertScheduleTaskErrorsDocument;
	private updateScheduleTaskErrorsMutation: UpdateScheduleTaskErrorsDocument;
	private importDataScheduleTaskErrorsMutation: ImportDataScheduleTaskErrorsDocument;
	private updateManyScheduleTaskErrorsMutation: UpdateManyScheduleTaskErrorsDocument;
	private deleteScheduleTaskErrorsMutation: DeleteScheduleTaskErrorsDocument;
	private retryTasksScheduleTaskErrorsMutation: RetryTasksScheduleTaskErrorsDocument;
	private unlockTasksScheduleTaskErrorsMutation: UnlockTasksScheduleTaskErrorsDocument;
	private deleteTasksScheduleTaskErrorsMutation: DeleteTasksScheduleTaskErrorsDocument;
	private deleteErrorTasksScheduleTaskErrorsMutation: DeleteErrorTasksScheduleTaskErrorsDocument;
	private downloadErrorTasksScheduleTaskErrorsMutation: DownloadErrorTasksScheduleTaskErrorsDocument;
	private downloadTasksScheduleTaskErrorsMutation: DownloadTasksScheduleTaskErrorsDocument;

	constructor(private injector: Injector) {
		this.getScheduleTaskErrorsQuery = this.injector.get(GetScheduleTaskErrorsDocument);
		this.firstScheduleTaskErrorsQuery = this.injector.get(FirstScheduleTaskErrorsDocument);
		this.countScheduleTaskErrorsQuery = this.injector.get(CountScheduleTaskErrorsDocument);
		this.findScheduleTaskErrorsQuery = this.injector.get(FindScheduleTaskErrorsDocument);
		this.searchScheduleTaskErrorsQuery = this.injector.get(SearchScheduleTaskErrorsDocument);
		this.exportSchemaScheduleTaskErrorsQuery = this.injector.get(ExportSchemaScheduleTaskErrorsDocument);
		this.exportDataScheduleTaskErrorsQuery = this.injector.get(ExportDataScheduleTaskErrorsDocument);
		this.customQueryScheduleTaskErrorsQuery = this.injector.get(CustomQueryScheduleTaskErrorsDocument);
		this.customQueryIdScheduleTaskErrorsQuery = this.injector.get(CustomQueryIdScheduleTaskErrorsDocument);
		this.usedScheduleTaskErrorsQuery = this.injector.get(UsedScheduleTaskErrorsDocument);
		this.findByStateScheduleTaskErrorsQuery = this.injector.get(FindByStateScheduleTaskErrorsDocument);
		this.existScheduleTaskErrorsQuery = this.injector.get(ExistScheduleTaskErrorsDocument);
		this.insertScheduleTaskErrorsMutation = this.injector.get(InsertScheduleTaskErrorsDocument);
		this.updateScheduleTaskErrorsMutation = this.injector.get(UpdateScheduleTaskErrorsDocument);
		this.importDataScheduleTaskErrorsMutation = this.injector.get(ImportDataScheduleTaskErrorsDocument);
		this.updateManyScheduleTaskErrorsMutation = this.injector.get(UpdateManyScheduleTaskErrorsDocument);
		this.deleteScheduleTaskErrorsMutation = this.injector.get(DeleteScheduleTaskErrorsDocument);
		this.retryTasksScheduleTaskErrorsMutation = this.injector.get(RetryTasksScheduleTaskErrorsDocument);
		this.unlockTasksScheduleTaskErrorsMutation = this.injector.get(UnlockTasksScheduleTaskErrorsDocument);
		this.deleteTasksScheduleTaskErrorsMutation = this.injector.get(DeleteTasksScheduleTaskErrorsDocument);
		this.deleteErrorTasksScheduleTaskErrorsMutation = this.injector.get(DeleteErrorTasksScheduleTaskErrorsDocument);
		this.downloadErrorTasksScheduleTaskErrorsMutation = this.injector.get(DownloadErrorTasksScheduleTaskErrorsDocument);
		this.downloadTasksScheduleTaskErrorsMutation = this.injector.get(DownloadTasksScheduleTaskErrorsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScheduleTaskError> {
    
    		let variables: GetScheduleTaskErrorsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getScheduleTaskErrorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTaskErrors.get));
            }
            else{
                let result:ServiceSingleResultOfScheduleTaskError={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScheduleTaskError,
		@Args('filter?') filter?: FilterOfScheduleTaskError,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScheduleTaskError> {
    
    		let variables: FirstScheduleTaskErrorsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstScheduleTaskErrorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTaskErrors.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScheduleTaskError,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountScheduleTaskErrorsBaseVariables = {
    			filter: filter
    		}
    				return this.countScheduleTaskErrorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTaskErrors.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScheduleTaskError,
		@Args('filter?') filter?: FilterOfScheduleTaskError,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScheduleTaskError> {
    
    		let variables: FindScheduleTaskErrorsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findScheduleTaskErrorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTaskErrors.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfScheduleTaskError,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScheduleTaskError> {
    
    		let variables: SearchScheduleTaskErrorsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchScheduleTaskErrorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTaskErrors.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaScheduleTaskErrorsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaScheduleTaskErrorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTaskErrors.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScheduleTaskError,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataScheduleTaskErrorsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataScheduleTaskErrorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTaskErrors.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfScheduleTaskError,
		@Args('filter?') filter?: FilterOfScheduleTaskError,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScheduleTaskError> {
    
    		let variables: CustomQueryScheduleTaskErrorsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryScheduleTaskErrorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTaskErrors.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfScheduleTaskError,
		@Args('filter?') filter?: FilterOfScheduleTaskError,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScheduleTaskError> {
    
    		let variables: CustomQueryIdScheduleTaskErrorsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdScheduleTaskErrorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTaskErrors.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedScheduleTaskErrorsBaseVariables = {
    			ids: ids
    		}
    				return this.usedScheduleTaskErrorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTaskErrors.used));
    	}

    	/**  */
    	@InjectArgs
    	public findByState(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('state') state: ScheduleTaskStatus,
		@Args('options?') options?: QueryContextOfScheduleTask,
		@Args('filter?') filter?: FilterOfScheduleTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScheduleTask> {
    
    		let variables: FindByStateScheduleTaskErrorsBaseVariables = {
    			state: state,
			filter: filter,
			options: options
    		}
    				return this.findByStateScheduleTaskErrorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTaskErrors.findByState));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistScheduleTaskErrorsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existScheduleTaskErrorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTaskErrors.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ScheduleTaskErrorInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScheduleTaskError> {
    
    		let variables: InsertScheduleTaskErrorsBaseVariables = {
    			entity: entity
    		}
    				return this.insertScheduleTaskErrorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTaskErrors.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfScheduleTaskError,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScheduleTaskError> {
    
    		let variables: UpdateScheduleTaskErrorsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateScheduleTaskErrorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTaskErrors.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataScheduleTaskErrorsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataScheduleTaskErrorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTaskErrors.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfScheduleTaskError,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyScheduleTaskErrorsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyScheduleTaskErrorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTaskErrors.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteScheduleTaskErrorsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteScheduleTaskErrorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTaskErrors.delete));
    	}

    	/**  */
    	@InjectArgs
    	public retryTasks(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RetryTasksScheduleTaskErrorsBaseVariables = {
    			ids: ids
    		}
    				return this.retryTasksScheduleTaskErrorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTaskErrors.retryTasks));
    	}

    	/**  */
    	@InjectArgs
    	public unlockTasks(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UnlockTasksScheduleTaskErrorsBaseVariables = {
    			ids: ids
    		}
    				return this.unlockTasksScheduleTaskErrorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTaskErrors.unlockTasks));
    	}

    	/**  */
    	@InjectArgs
    	public deleteTasks(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTasksScheduleTaskErrorsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteTasksScheduleTaskErrorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTaskErrors.deleteTasks));
    	}

    	/**  */
    	@InjectArgs
    	public deleteErrorTasks(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteErrorTasksScheduleTaskErrorsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteErrorTasksScheduleTaskErrorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTaskErrors.deleteErrorTasks));
    	}

    	/**  */
    	@InjectArgs
    	public downloadErrorTasks(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFileDescriptor> {
    
    		let variables: DownloadErrorTasksScheduleTaskErrorsBaseVariables = {
    			ids: ids
    		}
    				return this.downloadErrorTasksScheduleTaskErrorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTaskErrors.downloadErrorTasks));
    	}

    	/**  */
    	@InjectArgs
    	public downloadTasks(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFileDescriptor> {
    
    		let variables: DownloadTasksScheduleTaskErrorsBaseVariables = {
    			ids: ids
    		}
    				return this.downloadTasksScheduleTaskErrorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTaskErrors.downloadTasks));
    	}
    
}