<clc-work-sub-form
  [heightNew]="heightNew"
  [rawModel]="subFormModel"
  [parentModel]="state"
  [(isPopupOpen)]="isPopupOpen"
  [rules]="rules"
  [service]="service"
  (onSaved)="saved($event)"
  [fieldName]="fieldName"
  [key]="key"
  [keySubId]="keySubId"
  [readOnly]="readOnly"
></clc-work-sub-form>
<cl-work-page>
  <cl-work-page-header>
    <cl-work-page-header-content style="border-left: 0px !important">
      <cl-work-page-header-content-body>
        <cl-toolbar class="action" *ngIf="canAdd || canEdit || canDelete">
          <cl-toolbar-items>
            <cl-toolbar-item-button
              *ngIf="newCommandMenu == undefined && canAdd"
              [text]="'new' | translate"
              icon="fas fa-plus"
              [visible]="!readOnly"
              (onClick)="openSubForm(true)"
            >
            </cl-toolbar-item-button>

            <cl-toolbar-item-dropdown-button
              *ngIf="newCommandMenu != undefined && canAdd"
              [text]="'new' | translate"
              icon="fas fa-plus"
              [source]="newCommandMenu.datasource"
              keyExpr="id"
              displayExpr="name"
              [splitButton]="false"
              [visible]="!readOnly"
              (onButtonClick)="onButtonClick('all')"
              (onItemClick)="onItemClick($event)"
            >
            </cl-toolbar-item-dropdown-button>

            <cl-toolbar-item-button
              *ngIf="canEdit"
              [text]="'edit' | translate"
              icon="fas fa-pencil-alt"
              [visible]="!readOnly"
              [disabled]="disableBtnEdit"
              (onClick)="edit()"
            >
            </cl-toolbar-item-button>
            <cl-toolbar-item-button
              *ngIf="canDelete"
              [text]="'remove' | translate"
              [visible]="!readOnly"
              [disabled]="selectedKeys.length === 0"
              (onClick)="remove()"
              icon="fas fa-trash-alt"
            >
            </cl-toolbar-item-button>
          </cl-toolbar-items>
        </cl-toolbar>
      </cl-work-page-header-content-body>
    </cl-work-page-header-content>
  </cl-work-page-header>
  <cl-work-page-left> </cl-work-page-left>
  <cl-work-page-content>
    <clc-list
      *ngIf="currentSource != undefined"
      [source]="currentSource"
      [multiple]="multiple"
      [canSelect]="!readOnly"
      [columns]="columns"
      [masterDetail]="masterDetail"
      [(selectedKeys)]="selectedKeys"
      [(selectedData)]="selectedData"
      (onRowClick)="onRowClick($event)"
      [modelState]="state"
      (onSelectionKeyChanged)="onSelectionChanged()"
      [allowExportPanel]="allowExportPanel"
      [allowFilterPanel]="!isMobile"
    >
    </clc-list>
  </cl-work-page-content>
</cl-work-page>
