<div class="chrono-container">
  <h1>{{ formatTime() }}</h1>

  <dx-button
    icon="fa-regular fa-play"
    (click)="startChrono()"
    [height]="80"
    [width]="80"
  ></dx-button>
  <dx-button
    icon="fa-regular fa-stop"
    (click)="stopChrono()"
    [height]="80"
    [width]="80"
  ></dx-button>
  <dx-button
    icon="fa-regular fa-arrows-rotate"
    (click)="resetChrono()"
    [height]="80"
    [width]="80"
  ></dx-button>
</div>
