import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetDashboardsBaseVariables, FirstDashboardsBaseVariables, CountDashboardsBaseVariables, FindDashboardsBaseVariables, SearchDashboardsBaseVariables, ExportSchemaDashboardsBaseVariables, ExportDataDashboardsBaseVariables, CustomQueryDashboardsBaseVariables, CustomQueryIdDashboardsBaseVariables, FindFilesDashboardsBaseVariables, UsedDashboardsBaseVariables, FindNotUsersDashboardsBaseVariables, ExistDashboardsBaseVariables, FindUnassociatedUsersDashboardsBaseVariables, InsertDashboardsBaseVariables, UpdateDashboardsBaseVariables, ImportDataDashboardsBaseVariables, UpdateManyDashboardsBaseVariables, DeleteDashboardsBaseVariables, OrderDashboardsBaseVariables, UploadChunkFileDashboardsBaseVariables, ExportDashboardsBaseVariables, ImportDashboardsBaseVariables, UpdateTimerAutoRefreshDashboardsBaseVariables, AddUsersDashboardsBaseVariables, RemoveUsersDashboardsBaseVariables } from '../gqls'
import { GetDashboardsDocument, FirstDashboardsDocument, CountDashboardsDocument, FindDashboardsDocument, SearchDashboardsDocument, ExportSchemaDashboardsDocument, ExportDataDashboardsDocument, CustomQueryDashboardsDocument, CustomQueryIdDashboardsDocument, FindFilesDashboardsDocument, UsedDashboardsDocument, FindNotUsersDashboardsDocument, ExistDashboardsDocument, FindUnassociatedUsersDashboardsDocument, InsertDashboardsDocument, UpdateDashboardsDocument, ImportDataDashboardsDocument, UpdateManyDashboardsDocument, DeleteDashboardsDocument, OrderDashboardsDocument, UploadChunkFileDashboardsDocument, ExportDashboardsDocument, ImportDashboardsDocument, UpdateTimerAutoRefreshDashboardsDocument, AddUsersDashboardsDocument, RemoveUsersDashboardsDocument } from '../gqls'
import { ServiceSingleResultOfDashboard, QueryContextOfDashboard, FilterOfDashboard, ServiceSingleResultOfInt64, ServiceListResultOfDashboard, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceListResultOfFileModel, QueryContextOfFileModel, FilterOfFileModel, ServiceSingleResultOfBoolean, ServiceListResultOfUser, QueryContextOfUser, FilterOfUser, DashboardInput, FieldUpdateOperatorOfDashboard, ServiceSingleResultOfGuid } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class DashboardBaseService {
    
public modelName = 'dashboard';
public modelPluralName = 'dashboards';

	private getDashboardsQuery: GetDashboardsDocument;
	private firstDashboardsQuery: FirstDashboardsDocument;
	private countDashboardsQuery: CountDashboardsDocument;
	private findDashboardsQuery: FindDashboardsDocument;
	private searchDashboardsQuery: SearchDashboardsDocument;
	private exportSchemaDashboardsQuery: ExportSchemaDashboardsDocument;
	private exportDataDashboardsQuery: ExportDataDashboardsDocument;
	private customQueryDashboardsQuery: CustomQueryDashboardsDocument;
	private customQueryIdDashboardsQuery: CustomQueryIdDashboardsDocument;
	private findFilesDashboardsQuery: FindFilesDashboardsDocument;
	private usedDashboardsQuery: UsedDashboardsDocument;
	private findNotUsersDashboardsQuery: FindNotUsersDashboardsDocument;
	private existDashboardsQuery: ExistDashboardsDocument;
	private findUnassociatedUsersDashboardsQuery: FindUnassociatedUsersDashboardsDocument;
	private insertDashboardsMutation: InsertDashboardsDocument;
	private updateDashboardsMutation: UpdateDashboardsDocument;
	private importDataDashboardsMutation: ImportDataDashboardsDocument;
	private updateManyDashboardsMutation: UpdateManyDashboardsDocument;
	private deleteDashboardsMutation: DeleteDashboardsDocument;
	private orderDashboardsMutation: OrderDashboardsDocument;
	private uploadChunkFileDashboardsMutation: UploadChunkFileDashboardsDocument;
	private exportDashboardsMutation: ExportDashboardsDocument;
	private importDashboardsMutation: ImportDashboardsDocument;
	private updateTimerAutoRefreshDashboardsMutation: UpdateTimerAutoRefreshDashboardsDocument;
	private addUsersDashboardsMutation: AddUsersDashboardsDocument;
	private removeUsersDashboardsMutation: RemoveUsersDashboardsDocument;

	constructor(private injector: Injector) {
		this.getDashboardsQuery = this.injector.get(GetDashboardsDocument);
		this.firstDashboardsQuery = this.injector.get(FirstDashboardsDocument);
		this.countDashboardsQuery = this.injector.get(CountDashboardsDocument);
		this.findDashboardsQuery = this.injector.get(FindDashboardsDocument);
		this.searchDashboardsQuery = this.injector.get(SearchDashboardsDocument);
		this.exportSchemaDashboardsQuery = this.injector.get(ExportSchemaDashboardsDocument);
		this.exportDataDashboardsQuery = this.injector.get(ExportDataDashboardsDocument);
		this.customQueryDashboardsQuery = this.injector.get(CustomQueryDashboardsDocument);
		this.customQueryIdDashboardsQuery = this.injector.get(CustomQueryIdDashboardsDocument);
		this.findFilesDashboardsQuery = this.injector.get(FindFilesDashboardsDocument);
		this.usedDashboardsQuery = this.injector.get(UsedDashboardsDocument);
		this.findNotUsersDashboardsQuery = this.injector.get(FindNotUsersDashboardsDocument);
		this.existDashboardsQuery = this.injector.get(ExistDashboardsDocument);
		this.findUnassociatedUsersDashboardsQuery = this.injector.get(FindUnassociatedUsersDashboardsDocument);
		this.insertDashboardsMutation = this.injector.get(InsertDashboardsDocument);
		this.updateDashboardsMutation = this.injector.get(UpdateDashboardsDocument);
		this.importDataDashboardsMutation = this.injector.get(ImportDataDashboardsDocument);
		this.updateManyDashboardsMutation = this.injector.get(UpdateManyDashboardsDocument);
		this.deleteDashboardsMutation = this.injector.get(DeleteDashboardsDocument);
		this.orderDashboardsMutation = this.injector.get(OrderDashboardsDocument);
		this.uploadChunkFileDashboardsMutation = this.injector.get(UploadChunkFileDashboardsDocument);
		this.exportDashboardsMutation = this.injector.get(ExportDashboardsDocument);
		this.importDashboardsMutation = this.injector.get(ImportDashboardsDocument);
		this.updateTimerAutoRefreshDashboardsMutation = this.injector.get(UpdateTimerAutoRefreshDashboardsDocument);
		this.addUsersDashboardsMutation = this.injector.get(AddUsersDashboardsDocument);
		this.removeUsersDashboardsMutation = this.injector.get(RemoveUsersDashboardsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDashboard> {
    
    		let variables: GetDashboardsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getDashboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.get));
            }
            else{
                let result:ServiceSingleResultOfDashboard={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDashboard,
		@Args('filter?') filter?: FilterOfDashboard,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDashboard> {
    
    		let variables: FirstDashboardsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstDashboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfDashboard,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountDashboardsBaseVariables = {
    			filter: filter
    		}
    				return this.countDashboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDashboard,
		@Args('filter?') filter?: FilterOfDashboard,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDashboard> {
    
    		let variables: FindDashboardsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findDashboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfDashboard,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDashboard> {
    
    		let variables: SearchDashboardsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchDashboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaDashboardsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaDashboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfDashboard,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataDashboardsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataDashboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfDashboard,
		@Args('filter?') filter?: FilterOfDashboard,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDashboard> {
    
    		let variables: CustomQueryDashboardsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryDashboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfDashboard,
		@Args('filter?') filter?: FilterOfDashboard,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDashboard> {
    
    		let variables: CustomQueryIdDashboardsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdDashboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.customQueryId));
    	}

    	/** Récupère les fichiers liés. */
    	@InjectArgs
    	public findFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindFilesDashboardsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findFilesDashboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.findFiles));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedDashboardsBaseVariables = {
    			ids: ids
    		}
    				return this.usedDashboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.used));
    	}

    	/**  */
    	@InjectArgs
    	public findNotUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindNotUsersDashboardsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findNotUsersDashboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.findNotUsers));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistDashboardsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existDashboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.exist));
    	}

	@InjectArgs
	public findAssociatedUsers(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfUser> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('users', fields, null, [
			GqlSubFieldArg.create('filterOfUsers', 'filter'),
			GqlSubFieldArg.create('optionsOfUsers', 'options'),
		]),
		])
		extendedVariables['filterOfUsers'] = filter;
		extendedVariables['optionsOfUsers'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.users));
            }
            else{
                let result: ServiceListResultOfUser = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindUnassociatedUsersDashboardsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedUsersDashboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.findUnassociatedUsers));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: DashboardInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDashboard> {
    
    		let variables: InsertDashboardsBaseVariables = {
    			entity: entity
    		}
    				return this.insertDashboardsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfDashboard,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDashboard> {
    
    		let variables: UpdateDashboardsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateDashboardsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataDashboardsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataDashboardsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfDashboard,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyDashboardsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyDashboardsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteDashboardsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteDashboardsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.delete));
    	}

    	/** Permet d'ordonner l'entité. */
    	@InjectArgs
    	public order(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fromId') fromId: string,
		@Args('toId?') toId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: OrderDashboardsBaseVariables = {
    			fromId: fromId,
			toId: toId
    		}
    				return this.orderDashboardsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.order));
    	}

    	/**  */
    	@InjectArgs
    	public uploadChunkFile(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('visibleByHelpDeskUser') visibleByHelpDeskUser: boolean,
		@Args('size') size: number,
		@Args('outgoingEmails') outgoingEmails: boolean,
		@Args('lastChunk') lastChunk: boolean,
		@Args('indexChunk') indexChunk: number,
		@Args('type?') type?: string,
		@Args('name?') name?: string,
		@Args('fileId?') fileId?: string,
		@Args('entityId?') entityId?: string,
		@Args('chunks?') chunks?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: UploadChunkFileDashboardsBaseVariables = {
    			name: name,
			size: size,
			type: type,
			chunks: chunks,
			indexChunk: indexChunk,
			lastChunk: lastChunk,
			fileId: fileId,
			entityId: entityId,
			visibleByHelpDeskUser: visibleByHelpDeskUser,
			outgoingEmails: outgoingEmails
    		}
    				return this.uploadChunkFileDashboardsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.uploadChunkFile));
    	}

    	/**  */
    	@InjectArgs
    	public export(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDashboardsBaseVariables = {
    			id: id
    		}
    				return this.exportDashboardsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.export));
    	}

    	/**  */
    	@InjectArgs
    	public import(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDashboardsBaseVariables = {
    			id: id,
			file: file
    		}
    				return this.importDashboardsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.import));
    	}

    	/**  */
    	@InjectArgs
    	public updateTimerAutoRefresh(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('timer') timer: number,
		@Args('id') id: string,
		@Args('autoRefresh') autoRefresh: boolean,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDashboard> {
    
    		let variables: UpdateTimerAutoRefreshDashboardsBaseVariables = {
    			id: id,
			autoRefresh: autoRefresh,
			timer: timer
    		}
    				return this.updateTimerAutoRefreshDashboardsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.updateTimerAutoRefresh));
    	}

    	/**  */
    	@InjectArgs
    	public addUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddUsersDashboardsBaseVariables = {
    			id: id,
			userIds: userIds
    		}
    				return this.addUsersDashboardsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.addUsers));
    	}

    	/**  */
    	@InjectArgs
    	public removeUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveUsersDashboardsBaseVariables = {
    			id: id,
			userIds: userIds
    		}
    				return this.removeUsersDashboardsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dashboards.removeUsers));
    	}
    
}