import {
  AfterViewInit,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GC, GCFactory } from '@clarilog/core/services/gc/gc';
import {
  CoreGraphQLSource,
  CorePolicyValidator,
  CriteriaHelpers,
} from '@clarilog/core/services2';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import {
  TaskSequenceCoreService,
  TicketCoreService,
  TicketTaskCoreService,
  UserCoreService,
} from '@clarilog/core/services2/graphql/generated-types/services';
import { FilterOfTaskSequence } from '@clarilog/core/services2/graphql/generated-types/types';
import { Column } from '@clarilog/shared2/models/schema';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import {
  ModelDataSourceContext,
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { FormGroupHelpers } from '../../form/work-form/form-group-helpers';
import { CoreWorkSubFormComponent } from '../../form/work-sub-form/work-sub-form.component';
import { alert, custom } from 'devextreme/ui/dialog';
import { TemplatesService } from '../../templates/templates.service';
import { dxButtonOptions } from 'devextreme/ui/button';
import { ServicePermission } from '@clarilog/core/services/graphql/graphql.service';
import { TranslatedFieldHelperService } from '../../translate-field';
import { CoreListComponent } from '../list';

@Component({
  selector: 'clc-task-tree-list',
  templateUrl: './task-tree-list.component.html',
  preserveWhitespaces: true,
})
export class TaskTreeListComponent
  implements OnInit, AfterViewInit, OnDestroy
{

  /** Obtient ou définit la source de données */
  @Input() source: ModelDataSourceContext;
  /**  Obtient ou définit le modelState */
  @Input() state: ModelState;
  /**  Obtient ou définit la clé */
  @Input() key: string;
  /** Obtient ou définit les clés sélectionnées */
  @Input() selectedKeys: Array<string> = [];
  /**  Obtient ou définit les données sélectionnées */
  @Input() selectedData: any[] = [];
  /** Obtient ou définit les colonnes */
  @Input() columns: Column[];
  /** Obtient ou définit si la sélection est multiple */
  @Input() multiple: boolean = false;
  /** Obtient ou définit le titre */
  @Input() title: string;
  /** Obtient ou définit si le composant est en lecture seule */
  @Input() readOnly: boolean = false;
  /** Obtient ou définit le fait d'ajouter un élément */
  @Input() canAdd: boolean = true;
  /** Obtient ou définit le fait d'éditer un élément */
  @Input() canEdit: boolean = true;
  /** Obtient ou définit le fait de supprimer un élément */
  @Input() canDelete: boolean = true;
  /** Obtient ou définit le fait d'ajouter une séquence de tâche */
  @Input() canAddTaskSequence: boolean = true;
  /** Obtient ou définit le service retreiver */
  @Input() serviceRetreiver: ModelFnContext;
  /** Obtient ou définit le nom du champ */
  @Input() fieldName: string;
  /** Obtient ou définit les rules pour le designer */
  @Input() rules: ModelFnContext;
  /** Obtient ou définit la clef pour les vues personnalisées */
  @Input() layoutKey: string;
  /** Obtient ou définit si les vues personnalisées doivent être affichées */
  @Input() isLayoutForTreeList: boolean = true;
  /** Obtient ou définit le nom du fichier pour l'export  */
  @Input() exportFileName: string;

  @ViewChild(CoreWorkSubFormComponent) subForm: CoreWorkSubFormComponent;
  @ViewChild(CoreListComponent) listComponent: CoreListComponent;
  
  rawModel: any;
  /** Obtient ou définit la source de données courante */
  currentSource: ModelDataSourceContext;
    /** Obtient ou définit la source de liste de séquence de tâche */
  taskSequenceSource: ModelDataSourceContext;
  /** Indique si on attend l'ouverture de la popup */
  waitSave: boolean = false;
  /** Indique si load panel peut-être visible */
  loadPanelShow: boolean = false;
  /** Indique le composant doit être ouvert en popup */
  isPopupOpen: boolean = false;
  /** Indique si la popup de séquence de tâche doit être visible */
  popupVisible: boolean = false;
  /** Indique si c'est une séquence de tâche */
  isTaskSequence: boolean = false;
  /** Permet de définir le service */
  service: any;
  /** Permet de définir l'url */
  url: boolean;
  /** Permet de refresh la liste des tâches */
  reloadtaskList: boolean = false;
  /** Permet de définir les éléments pour le bouton dropdown */
  buttonsSource = [
    {
      value: TranslateService.get('ganttNewTaskUp'),
      type: 'normal',
      name: 'addTaskUp1',
      onClick: (e) => {
        this.onCustomCommand(e, 'addTaskUp1');
      },
    },
    {
      value: TranslateService.get('ganttNewTaskDown'),
      type: 'normal',
      name: 'addTaskDown1',
      onClick: (e) => {
        this.onCustomCommand(e, 'addTaskDown1');
      },
    },
    {
      value: TranslateService.get('ganttNewSubTask'),
      type: 'normal',
      name: 'addSubTask1',
      onClick: (e) => {
        this.onCustomCommand(e, 'addSubTask1');
      },
    },
    {
      value: TranslateService.get('ganttNewSucessor'),
      type: 'normal',
      name: 'addSuccessor1',
      onClick: (e) => {
        this.onCustomCommand(e, 'addSuccessor1');
      },
    },
  ];
  /** Definit les colonnes des séquences de tâche */
  taskSequenceTypeColumns: Column[] = [
    {
        label: TranslateService.get('entities/taskSequence/name'),
        field:
          'name' + '.' + this.translateFieldHelperService.getTranslateKey(),
        translatable: true,
    }
  ];
  /** Garbage collector */
  private gc: GC;

  constructor(
    public templateService: TemplatesService,
    private route: ActivatedRoute,
    public router: Router,
    public taskSequenceService: TaskSequenceCoreService,
    public ticketTaskService: TicketTaskCoreService,
    public userService: UserCoreService,
    private servicePermission: ServicePermission,
    private policyValidator: CorePolicyValidator,
    private translateFieldHelperService: TranslatedFieldHelperService,
    public injector: Injector,
    private gcFactory: GCFactory
  ) {
    this.gc = this.gcFactory.create();
    this.onButtonClick = this.onButtonClick.bind(this);
    this.taskSequenceSource = this.getTaskSequenceSource();
  }

  /**
   * Permet d'initialiser le composant
   */
  async ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    if (id == undefined) {
      id = this.state.id;
    } else {
      this.setTaskTitle();
    }

    this.source.datasource.pageSize(5000);

    if (this.router.url.includes('task-sequence')) {
      this.url = false;
    }
    this.rawModel = this.state.sharedContext.params.get(this.key + '-subform');
    if (id != undefined && this.source != undefined) {
      this.currentSource = this.source;
      this.state.sharedContext.params.set(this.fieldName, () => id);
    } else {
      this.currentSource = new ModelDataSourceContext({
        datasource: new DataSource([]),
      });
      let sub = this.state.formComponent.onSaved.subscribe((res) => {
        sub.unsubscribe();
        this.currentSource = this.source;
        this.state.sharedContext.params.set(this.fieldName, () => res);
        this.state.id = res;
        if (!this.isTaskSequence) {
          this.isPopupOpen = true;
        }
      });
    }
    this.service = this.serviceRetreiver.fnCall();
    this.getPermissions();
  }
  
  /**
   * Permet d'initialiser le composant après la vue
   */
  ngAfterViewInit(): void {
    // Vérification si l'onglet doit etre sélectionné
    this.gc.forDispose(
      this.route.queryParams.subscribe((params) => {
        this.service = this.injector.get('TicketTaskCoreService');
        this.setTaskTitle();
        if (params.eventId != undefined && params.tabs == 'task') {
          this.rawModel = this.state.sharedContext.params.get('task-subform');
          this.subForm.editId = params.eventId;
          this.getPermissions();
          setTimeout(() => {
            this.isPopupOpen = true;
          }, 800);
        }
      }),
    );

    if (this.route?.snapshot?.queryParams?.taskId != undefined) {
      //this.openTaskForm();
    }

    if (this.state != undefined) {
      this.gc.forDispose(
        this.state.on.subscribe((event) => {
          if (event.eventName === 'tab-change') {
            if (event.eventData?.newTab?.itemKey === 'tasks') {
              this.reloadtaskList = false;
              this.refresh();
            }
          }
        }),
      );
    }
  }

  /**
   * Permet de définir la source de données des séquences de tâche
   */
  getTaskSequenceSource = (): ModelDataSourceContext =>  {
    return new ModelDataSourceContext({
        datasource: CoreGraphQLSource.create({
          query: (filter?: any, options?: any) => {
            let myFilter: FilterOfTaskSequence =
              filter != undefined
                ? CriteriaHelpers.convertDxFilter(
                    CriteriaHelpers.decompile(filter),
                  )
                : null;
            if (myFilter == undefined || myFilter == null) {
              myFilter = {};
            }
            let qualification =
              this.state.sharedContext.params.get('qualification');
            if (qualification != undefined) {
              myFilter.qualification = { elemMatch: { contains: qualification } };
            }
            return this.taskSequenceService.find(
              [
                GqlSubField.create('data', [
                  GqlField.create('id'),
                  GqlSubField.create(
                    'name',
                    this.translateFieldHelperService.translatedFields(),
                  ),
                ]),
                GqlField.create('totalCount'),
              ],
              options,
              myFilter,
            );
          },
        }),
    });
  }

  /**
   * Ouvre le formulaire d'une tâche par défaut
   */
  private openTaskForm() {
    this.subForm.editId = this.route?.snapshot?.queryParams?.taskId;
    this.isPopupOpen = true;
  }

  /**
   * Permet de déclencher une action lors du click sur un bouton
   * @param e event
   */
  onButtonClick(e) {
    this.onCustomCommand(e, 'addTask1');
  }

  /**
   * Permet de rafraîchir la source de données
   */
  refresh() {
    if (
      this.listComponent.component != undefined &&
      !this.reloadtaskList
    ) {
        let source = <DataSource>this.listComponent.internalSource;
        if(source != undefined && source.reload != undefined){
          source.reload();
        }
    }
    this.currentSource.datasource.reload();
  }

  /**
   * Permet d'ouvrir le formulaire
   * @param taskSequence 
   * @returns 
   */
  async openSubForm(taskSequence: boolean = false): Promise<boolean> {
    this.setTaskTitle();
    // Vérification si le modele est valide
    this.waitSave = false;
    let id = this.route.snapshot.paramMap.get('id') || this.state.id;
    if (id == undefined) {
      if (FormGroupHelpers.valid(this.state.formComponent.form) == false) {
        // Validation du formulaire
        alert(
          TranslateService.get('globals/formNotValid'),
          TranslateService.get('globals/warning'),
        );
        return false;
      } else {
        let confirm = await TaskTreeListComponent.confirmSaveDialog(
          TranslateService.get('confirm-title'),
          TranslateService.get('globals/confirm-refresh'),
          TranslateService.get('save'),
          TranslateService.get('cancel'),
        );
        if (confirm) {
          this.waitSave = true;
          this.state.formComponent.save({ close: false }).then((result) => {
            if (result != null) {
              if (!taskSequence) {
                this.isPopupOpen = true;
              }
              id = this.state.id;
              return true;
            }
          });
        }
      }
    } else if (!taskSequence) {
      this.isPopupOpen = true;
      return true;
    }
    return true;
  }

  /**
   * Permet d'afficher une boite de dialogue de confirmation
   * @param title 
   * @param message 
   * @param saveButtonMessage 
   * @param cancelButtonMessage 
   * @returns 
   */
  public static async confirmSaveDialog(
    title: string,
    message: string,
    saveButtonMessage: string,
    cancelButtonMessage: string,
  ): Promise<boolean> {
    let buttons: dxButtonOptions[] = [
      {
        type: 'default',
        text: saveButtonMessage,
        onClick: () => {
          return true;
        },
      },
      {
        text: cancelButtonMessage,
        onClick: () => {
          return false;
        },
      },
    ];

    return await custom({
      title: title,
      messageHtml: message,
      buttons: buttons,
    }).show();
  }

  /**
   * Permet de sélectionner une séquence de tâche
   * et de l'ajouter à la liste des tâches
   * @param e 
   */
  onSelectSequenceTask(e) {
    this.loadPanelShow = true;
    let id = this.route.snapshot.paramMap.get('id');
    if (id == undefined) {
      id = this.state.id;
    }
    let taskSequenceId = e[0].id;
    if (this.router.url.includes('incident-model')) {
      this.taskSequenceService
        .addLinkIncidentModels(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          taskSequenceId,
          id,
        )
        .subscribe((result) => {
          this.loadPanelShow = false;
          if (this.state != undefined) {
            this.state.on.emit({
              eventName: 'reload-tabs',
              eventData: undefined,
            });
          }

          if (result) {
            notify(
              TranslateService.get('globals/addTaskSequence'),
              'success',
              5000,
            );
            this.refresh();
          } else {
            notify(TranslateService.get('actionError'), 'error', 5000);
          }
        });
    } else {
      this.taskSequenceService
        .addLinkTickets(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          id,
          taskSequenceId,
        )
        .subscribe((result) => {
          if (this.state != undefined) {
            this.state.on.emit({
              eventName: 'reload-tabs',
              eventData: undefined,
            });
          }

          if (result) {
            notify(
              TranslateService.get('globals/addTaskSequence'),
              'success',
              5000,
            );
            this.refresh();
            this.loadPanelShow = false;
          } else {
            notify(TranslateService.get('actionError'), 'error', 5000);
          }
        });
    }
    this.isTaskSequence = true;
    this.popupVisible = false;
  }

  insertAt(array, index, ...elementsArray) {
    array.splice(index, 0, ...elementsArray);
  }

  /**
   * Permet de déclencher une action personnalisée
   * @param e 
   * @param origin 
   * @returns 
   */
  onCustomCommand(e, origin = null) {
    if ((e == undefined || e == null) &&
      (origin == undefined || origin == null)
    ) { return; }

    if ((e != null || e != undefined) &&
      (origin != undefined || origin != null)
    ) {
      e.name = origin;
    }

    if (
      e.name === 'addTask1' ||
      e.name === 'addSubTask1' ||
      e.name === 'addTaskUp1' ||
      e.name === 'addTaskDown1' ||
      e.name === 'addSuccessor1'
    ) {
      this.openSubForm(false);

      if (e.name === 'addTask1') {
        let unSub = this.state.subFormReady.subscribe(
          (data: { gc: GC; subModelState: ModelState; key: string }) => {
            unSub.unsubscribe();

            let orderControl = FormGroupHelpers.formControlByName(
              data.subModelState.form,
              'order',
            );
            if (orderControl != undefined) {
              orderControl.setValue(0);
              orderControl.markAsDirty();
              data.subModelState.sharedContext.entry.set('order', () => 0);
            }
          },
        );
      } else {
        let unSub = this.state.subFormReady.subscribe(
          (data: { gc: GC; subModelState: ModelState; key: string }) => {
            unSub.unsubscribe();
            if (data.key === this.key) {
              this.service
                .get(
                  [
                    GqlSubField.create('data', [
                      GqlField.create('id'),
                      GqlField.create('parentId'),
                      GqlField.create('order'),
                    ]),
                  ],
                  this.selectedKeys[0],
                )
                .subscribe((res) => {
                  let control = FormGroupHelpers.formControlByName(
                    data.subModelState.form,
                    'parentId',
                  );
                  let orderControl = FormGroupHelpers.formControlByName(
                    data.subModelState.form,
                    'order',
                  );
                  let predecessorIdControl = FormGroupHelpers.formControlByName(
                    data.subModelState.form,
                    'predecessorId',
                  );
                  if (control != undefined) {
                    if (e.name === 'addSuccessor1') {
                      predecessorIdControl.setValue(res.data.id);
                      predecessorIdControl.markAsDirty();
                    }
                  } else {
                    console.error('control undefined');
                  }
                  if (control != undefined) {
                    control.setValue(
                      e.name === 'addSubTask1'
                        ? res.data.id
                        : res.data.parentId,
                    );
                    control.markAsDirty();
                  } else {
                    console.error('control undefined');
                  }
                  if (orderControl != undefined) {
                    orderControl.setValue(
                      e.name === 'addSubTask1'
                        ? 1
                        : res.data.order +
                            (e.name === 'addTaskDown1' ||
                            e.name === 'addSuccessor1'
                              ? 1
                              : 0),
                    );
                    orderControl.markAsDirty();
                  } else {
                    console.error('orderControl undefined');
                  }
                });
            }
          },
        );
      }
    } else if (e.name === 'deleteTask1') {
      this.service
        .delete(ModelFieldCompilerService.createServiceSingleResultScalar(), [
          this.selectedKeys[0],
        ])
        .subscribe((res) => {
          this.refresh();
          if (this.state != undefined) {
            this.state.on.emit({
              eventName: 'refresh-tabs',
              eventData: 'gantt-delete',
            });
          }
        });
    } else if (e.name === 'editTask1') {
      if (this.selectedKeys != undefined) 
    {
        this.setTaskTitle();
        e.cancel = true;
        this.subForm.editId = this.selectedKeys[0];
        this.isPopupOpen = true;
      }
    } else if (e.name === 'addTaskSequence1') {
      this.openSubForm(true).then((result) => {
        if (result) {
          this.isTaskSequence = true;
          this.popupVisible = true;
        }
      });
    } else if (e.name === 'moveUp1') {
      this.ticketTaskService
        .moveUp(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          this.selectedKeys[0],
        )
        .subscribe((res) => {
          this.refresh();
        });
    } else if (e.name === 'moveDown1') {
      this.ticketTaskService
        .moveDown(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          this.selectedKeys[0],
        )
        .subscribe(() => {
          this.refresh();
        });
    }
  }

  saved(e) {
    this.currentSource.datasource.reload();
    this.refresh();
    this.subForm.editId = null;
    this.state.on.emit({
      eventName: 'reload-tabs',
      eventData: undefined,
    });
  }

  /**
    * Permet de définir le titre de la tâche
    */
  setTaskTitle() {
    let taskNumber = '';
    this.subForm;
    let unSub = this.state.subFormReady.subscribe(
      (data: { gc: GC; subModelState: ModelState; key: string }) => {
        if (
          this.state?.sharedContext?.params?.get('qualification') === 'problem'
        ) {
          let visibleForUser = FormGroupHelpers.formControlByName(
            data.subModelState.form,
            'visibleForUser',
          );
          if (visibleForUser != undefined) {
            visibleForUser.invisible();
          }
        }
        unSub.unsubscribe();
        if (this.selectedKeys != undefined) {
          this.service.get(
            [
              GqlSubField.create('data', [
                GqlField.create('id'),
                GqlField.create('taskNumber'),
              ]),
            ],
            this.selectedKeys[0],
          );
        }
        let taskNumberField = FormGroupHelpers.formControlByName(
          data.subModelState.form,
          'taskNumber',
        );

        taskNumber = taskNumberField?.value;
        this.taskTitleSave();
        if (taskNumber != undefined && taskNumber != '' && taskNumber != null) {
          this.title = this.title + ' n° ' + taskNumber;
        }
      },
    );
  }

  /**
   * Permet de sauvegarder le titre de la tâche
   */
  taskTitleSave() {
    if (this.title != TranslateService.get('entities/task/_title/singular')) {
      this.title = TranslateService.get('entities/task/_title/singular');
    }
  }
  /**
   * Permet de définir les permissions
   */
  getPermissions() {
    let policies = this.servicePermission.getAuthorizations(this.service);
    if (policies != undefined) {
      let operator = 'and';
      if (policies['operator'] != undefined) {
        operator = policies['operator'];
        policies = policies['policies'];
      } else if (policies['policies'] != undefined) {
        policies = policies['policies'];
      }
      let canAddTmp = false;
      let canDeleteTmp = false;
      let canEditTmp = false;
      for (let policy of policies) {
        if (operator == 'or') {
          if (canAddTmp == false) {
            canAddTmp =
              this.canAdd &&
              this.policyValidator.validate(
                policy.indexOf('.') > -1 ? `${policy}` : `${policy}.write`,
              );
          }
          if (canDeleteTmp == false) {
            canDeleteTmp =
              this.canDelete &&
              this.policyValidator.validate(
                policy.indexOf('.') > -1 ? `${policy}` : `${policy}.delete`,
              );
          }
          if (canEditTmp == false) {
            canEditTmp =
              this.canEdit &&
              this.policyValidator.validate(
                policy.indexOf('.') > -1 ? `${policy}` : `${policy}.update`,
              );
          }
        } else {
          this.canAdd =
            this.canAdd &&
            this.policyValidator.validate(
              policy.indexOf('.') > -1 ? `${policy}` : `${policy}.write`,
            );

          this.canDelete =
            this.canDelete &&
            this.policyValidator.validate(
              policy.indexOf('.') > -1 ? `${policy}` : `${policy}.delete`,
            );
          this.canEdit =
            this.canEdit &&
            this.policyValidator.validate(
              policy.indexOf('.') > -1 ? `${policy}` : `${policy}.update`,
            );
        }
      }

      if (operator == 'or') {
        this.canAdd = canAddTmp;
        this.canDelete = canDeleteTmp;
        this.canEdit = canEditTmp;
      }
    }
  }

  /**
   * Permet de configurer le clique droit
   * @param e event
   */
  onRightClick(e) {
    if (e.row && e.row.rowType === "data") {
        this.selectedKeys = [e.row.key];
        let contextMenuItems = [];

        if(this.canAdd){
          contextMenuItems.push({
            text: TranslateService.get("ganttNew"),
            items: [
                { text: TranslateService.get("ganttNewTaskUp"), onItemClick: () => this.onCustomCommand({name: 'addTaskUp1'}, 'addTaskUp1') },
                { text: TranslateService.get("ganttNewTaskDown"), onItemClick: () => this.onCustomCommand({name: 'addTaskDown1'}, 'addTaskDown1') },
                { text: TranslateService.get("ganttNewSubTask"), onItemClick: () => this.onCustomCommand({name: 'addSubTask1'}, 'addSubTask1') },
                { text: TranslateService.get("ganttNewSucessor"), onItemClick: () => this.onCustomCommand({name: 'addSuccessor1'}, 'addSuccessor1') }
            ]
          })
        }
        contextMenuItems.push({
          text: TranslateService.get("ganttAction"),
          items: [
              { text: TranslateService.get("ganttMoveUp"), onItemClick: () => this.onCustomCommand({name: 'moveUp1'}, 'moveUp1') },
              { text: TranslateService.get("ganttMoveDown"), onItemClick: () => this.onCustomCommand({name: 'moveDown1'}, 'moveDown1') },
          ]
        });
        if(this.canEdit){
          contextMenuItems.push({ text: TranslateService.get("ganttEditTask"), onItemClick: () => this.onCustomCommand({name: 'editTask1'}, 'editTask1') });
        }
        if (this.canDelete) {
          contextMenuItems.push({ text: TranslateService.get("ganttDeleteTask"), onItemClick: () => this.onCustomCommand({name: 'deleteTask1'}, 'deleteTask1') });
        }
        if (this.canAddTaskSequence) {
          contextMenuItems.push({ text: TranslateService.get("ganttAddTaskSequence"), onItemClick: () => this.onCustomCommand({name: 'addTaskSequence1'}, 'addTaskSequence1') });
        }

        e.items = contextMenuItems;
      }

  }

  onRowClick(e){

  }

  /**
   * Permet de détruire les ressources
   */
  ngOnDestroy(): void {
    this.gc.dispose();
  }
}
