import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { EmailConnectorSystemLogBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { GqlField, GqlSubField } from '../helpers';
import {
  FilterOfEmailConnectorSystemLog,
  QueryContextOfEmailConnectorSystemLog,
  ServiceListResultOfEmailConnectorSystemLog,
} from '../types';
import { FindEmailConnectorSystemLogsBaseVariables } from '../gqls';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EmailConnectorSystemLogCoreService extends EmailConnectorSystemLogBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  /** Récupère les entités selon le filtre. */
  @InjectArgs
  public findByConnector(
    @Args('id') id: string,
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfEmailConnectorSystemLog,
    @Args('filter?') filter?: FilterOfEmailConnectorSystemLog,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfEmailConnectorSystemLog> {
    var addFilter: FilterOfEmailConnectorSystemLog = {
      emailConnectorSystemId: { eq: id },
    };
    if (filter == undefined) {
      filter = addFilter;
    } else {
      filter = {
        and: [filter, addFilter],
      };
    }
    return this.find(fields, options, filter, extendedVariables);
  }
}
