<clc-work-items
  *ngIf="model != undefined && model.model != undefined"
  [canExport]="false"
  [allowChangeTitle]="false"
  [title]="
    label != undefined
      ? ('addNewElementsWith' | translate : { elementName: label })
      : title
  "
  [hint]="hint"
  [modelState]="model"
  [(selectedData)]="selectedData"
  [selectedKeys]="selectedKeys"
  [showBack]="false"
  [activeSelectLevelAction]="activeSelectLevelAction"
  [ngClass]="'fix-select-list'"
  [compactMode]="compactMode"
  (onItemSelected)="workItemSelected($event)"
  [deniedCumulateFilter]="true"
>
  <cl-toolbar-items [items]="toolbar?.itemsChildren.toArray()">
    <cl-toolbar-item-button
      [text]="'manage' | translate"
      [visible]="route !== undefined"
      (onClick)="onGoTo()"
      icon="fas fa-arrow-right"
      class="manage"
    ></cl-toolbar-item-button>
    <cl-toolbar-item-button
      [type]="'default'"
      [text]="buttonText != undefined ? buttonText : ('associate' | translate)"
      location="after"
      (onClick)="select()"
      [disabled]="canDisabled()"
    >
    </cl-toolbar-item-button>
  </cl-toolbar-items>
</clc-work-items>
<div class="close-btn" *ngIf="!compactMode">
  <dx-button icon="fal fa-times" (onClick)="close()"></dx-button>
</div>
