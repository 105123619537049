import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { GC, GCFactory } from '@clarilog/core/services';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { FormGroupHelpers } from '../form/work-form/form-group-helpers';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'clc-chrono',
  templateUrl: './chrono.component.html',
  styleUrls: ['./chrono.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreChronoComponent),
      multi: true,
    },
  ],
})
export class CoreChronoComponent {
  @Input() state: ModelState;
  /** Obtient ou définit le status du readonly */
  @Input() readOnly: boolean = false;
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};
  /** @inheritdoc */
  /** Obtient ou définit la valeur. */

  private _value: string;
  @Input()
  public get value(): string {
    return this._value;
  }
  public set value(value: string) {
    this._value = value;
  }

  /** Obtient ou définit l'état d'activation. */
  @Input() disabled: boolean = false;
  @Input() enableMinus: boolean = false;
  @Input() showDays: boolean = true;
  @Output() onValueChanged = new EventEmitter();

  hours: number = 0;
  minutes: number = 0;
  secondes: number = 0;
  days: number = 0;
  // Saved Value because in chrono mode the calul is saved + time elapsed since chrono start
  _secondes: number = 0;
  _minutes: number = 0;
  _hours: number = 0;
  _days: number = 0;
  _ms: number = 0;
  minus: boolean = false;

  @Input() modeChrono: boolean = false;
  @Input() modeInline: boolean = false;
  timeStartChrono: Date;
  timeEndChrono: Date;
  isChronoStart: boolean = false;
  orgiginalValue: string;

  chronoInterval: NodeJS.Timeout;
  _initializeValue: boolean = false;
  _initializeComponent: boolean = false;
  interval: any = null; // Variable pour stocker l'intervalle
  time: number = 0; // Temps écoulé en secondes
  gc: GC;

  constructor() {}
  /** @inheritdoc */
  public writeValue(value: any) {
    this.value = value;
  }
  /** @inheritdoc */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /** @inheritdoc */
  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  ngAfterViewInit(): void {}

  toTimespan(days: number, hours: number, minutes: number, seconds: number) {
    let timestamp = `${this.minus ? '-' : ''}P${days}DT${hours}H${minutes}M${
      seconds.toString().split('.')[0]
    }S`;
    return timestamp;
  }

  ngOnInit(): void {
    if (this.enableMinus == undefined) {
      this.enableMinus = false;
    }
    if (this.modeChrono == undefined) {
      this.modeChrono = false;
    }
    // throw new Error("Method not implemented.");
  }

  // Fonction pour démarrer le chrono
  startChrono() {
    if (!this.interval) {
      // Empêche le démarrage multiple
      this.interval = setInterval(() => {
        this.time++;
      }, 1000); // Augmente le temps chaque seconde
      this.hours = 0;
      this.minutes = 0;
    }
  }

  // Fonction pour arrêter le chrono
  stopChrono() {
    if (this.interval) {
      clearInterval(this.interval);
      this.value = this.time.toString();
      this.interval = null;
      this.hours = Math.floor(this.time / 3600);
      this.minutes = Math.floor((this.time % 3600) / 60);
      this.secondes = this.time % 60;
      this.valueChanged(
        this.toTimespan(this.days, this.hours, this.minutes, this.secondes),
      );
    }
  }

  // Fonction pour réinitialiser le chrono
  resetChrono() {
    this.stopChrono(); // Arrête le chrono avant de réinitialiser
    this.time = 0;
  }

  // Méthode pour formater le temps en HH:MM:SS
  formatTime(): string {
    const hours = Math.floor(this.time / 3600);
    const minutes = Math.floor((this.time % 3600) / 60);
    const seconds = this.time % 60;
    return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
  }

  // Méthode utilitaire pour ajouter un zéro aux nombres inférieurs à 10
  pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  valueChanged(value) {
    if (value != this.orgiginalValue) {
      this.onTouched();
      let elaspedTimeField = FormGroupHelpers.formControlByName(
        this.state.form,
        'elaspedTime',
      );

      this.onChange(value);
      this.onValueChanged.emit(value);
      elaspedTimeField.setValue(value);
      elaspedTimeField.markAsDirty();
    }
  }
}
