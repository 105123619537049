import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetTaskStatusWorkflowsBaseVariables, FirstTaskStatusWorkflowsBaseVariables, CountTaskStatusWorkflowsBaseVariables, FindTaskStatusWorkflowsBaseVariables, SearchTaskStatusWorkflowsBaseVariables, ExportSchemaTaskStatusWorkflowsBaseVariables, ExportDataTaskStatusWorkflowsBaseVariables, CustomQueryTaskStatusWorkflowsBaseVariables, CustomQueryIdTaskStatusWorkflowsBaseVariables, UsedTaskStatusWorkflowsBaseVariables, ExistTaskStatusWorkflowsBaseVariables, FindUnassociatedTicketTaskModelsTaskStatusWorkflowsBaseVariables, InsertTaskStatusWorkflowsBaseVariables, UpdateTaskStatusWorkflowsBaseVariables, ImportDataTaskStatusWorkflowsBaseVariables, UpdateManyTaskStatusWorkflowsBaseVariables, DeleteTaskStatusWorkflowsBaseVariables, AddTicketTaskModelsTaskStatusWorkflowsBaseVariables, RemoveTicketTaskModelsTaskStatusWorkflowsBaseVariables } from '../gqls'
import { GetTaskStatusWorkflowsDocument, FirstTaskStatusWorkflowsDocument, CountTaskStatusWorkflowsDocument, FindTaskStatusWorkflowsDocument, SearchTaskStatusWorkflowsDocument, ExportSchemaTaskStatusWorkflowsDocument, ExportDataTaskStatusWorkflowsDocument, CustomQueryTaskStatusWorkflowsDocument, CustomQueryIdTaskStatusWorkflowsDocument, UsedTaskStatusWorkflowsDocument, ExistTaskStatusWorkflowsDocument, FindUnassociatedTicketTaskModelsTaskStatusWorkflowsDocument, InsertTaskStatusWorkflowsDocument, UpdateTaskStatusWorkflowsDocument, ImportDataTaskStatusWorkflowsDocument, UpdateManyTaskStatusWorkflowsDocument, DeleteTaskStatusWorkflowsDocument, AddTicketTaskModelsTaskStatusWorkflowsDocument, RemoveTicketTaskModelsTaskStatusWorkflowsDocument } from '../gqls'
import { ServiceSingleResultOfTaskStatusWorkflow, QueryContextOfTaskStatusWorkflow, FilterOfTaskStatusWorkflow, ServiceSingleResultOfInt64, ServiceListResultOfTaskStatusWorkflow, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, QueryContextOfTicketTaskModel, FilterOfTicketTaskModel, ServiceListResultOfTicketTaskModel, TaskStatusWorkflowInput, FieldUpdateOperatorOfTaskStatusWorkflow } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class TaskStatusWorkflowBaseService {
    
public modelName = 'taskStatusWorkflow';
public modelPluralName = 'taskStatusWorkflows';

	private getTaskStatusWorkflowsQuery: GetTaskStatusWorkflowsDocument;
	private firstTaskStatusWorkflowsQuery: FirstTaskStatusWorkflowsDocument;
	private countTaskStatusWorkflowsQuery: CountTaskStatusWorkflowsDocument;
	private findTaskStatusWorkflowsQuery: FindTaskStatusWorkflowsDocument;
	private searchTaskStatusWorkflowsQuery: SearchTaskStatusWorkflowsDocument;
	private exportSchemaTaskStatusWorkflowsQuery: ExportSchemaTaskStatusWorkflowsDocument;
	private exportDataTaskStatusWorkflowsQuery: ExportDataTaskStatusWorkflowsDocument;
	private customQueryTaskStatusWorkflowsQuery: CustomQueryTaskStatusWorkflowsDocument;
	private customQueryIdTaskStatusWorkflowsQuery: CustomQueryIdTaskStatusWorkflowsDocument;
	private usedTaskStatusWorkflowsQuery: UsedTaskStatusWorkflowsDocument;
	private existTaskStatusWorkflowsQuery: ExistTaskStatusWorkflowsDocument;
	private findUnassociatedTicketTaskModelsTaskStatusWorkflowsQuery: FindUnassociatedTicketTaskModelsTaskStatusWorkflowsDocument;
	private insertTaskStatusWorkflowsMutation: InsertTaskStatusWorkflowsDocument;
	private updateTaskStatusWorkflowsMutation: UpdateTaskStatusWorkflowsDocument;
	private importDataTaskStatusWorkflowsMutation: ImportDataTaskStatusWorkflowsDocument;
	private updateManyTaskStatusWorkflowsMutation: UpdateManyTaskStatusWorkflowsDocument;
	private deleteTaskStatusWorkflowsMutation: DeleteTaskStatusWorkflowsDocument;
	private addTicketTaskModelsTaskStatusWorkflowsMutation: AddTicketTaskModelsTaskStatusWorkflowsDocument;
	private removeTicketTaskModelsTaskStatusWorkflowsMutation: RemoveTicketTaskModelsTaskStatusWorkflowsDocument;

	constructor(private injector: Injector) {
		this.getTaskStatusWorkflowsQuery = this.injector.get(GetTaskStatusWorkflowsDocument);
		this.firstTaskStatusWorkflowsQuery = this.injector.get(FirstTaskStatusWorkflowsDocument);
		this.countTaskStatusWorkflowsQuery = this.injector.get(CountTaskStatusWorkflowsDocument);
		this.findTaskStatusWorkflowsQuery = this.injector.get(FindTaskStatusWorkflowsDocument);
		this.searchTaskStatusWorkflowsQuery = this.injector.get(SearchTaskStatusWorkflowsDocument);
		this.exportSchemaTaskStatusWorkflowsQuery = this.injector.get(ExportSchemaTaskStatusWorkflowsDocument);
		this.exportDataTaskStatusWorkflowsQuery = this.injector.get(ExportDataTaskStatusWorkflowsDocument);
		this.customQueryTaskStatusWorkflowsQuery = this.injector.get(CustomQueryTaskStatusWorkflowsDocument);
		this.customQueryIdTaskStatusWorkflowsQuery = this.injector.get(CustomQueryIdTaskStatusWorkflowsDocument);
		this.usedTaskStatusWorkflowsQuery = this.injector.get(UsedTaskStatusWorkflowsDocument);
		this.existTaskStatusWorkflowsQuery = this.injector.get(ExistTaskStatusWorkflowsDocument);
		this.findUnassociatedTicketTaskModelsTaskStatusWorkflowsQuery = this.injector.get(FindUnassociatedTicketTaskModelsTaskStatusWorkflowsDocument);
		this.insertTaskStatusWorkflowsMutation = this.injector.get(InsertTaskStatusWorkflowsDocument);
		this.updateTaskStatusWorkflowsMutation = this.injector.get(UpdateTaskStatusWorkflowsDocument);
		this.importDataTaskStatusWorkflowsMutation = this.injector.get(ImportDataTaskStatusWorkflowsDocument);
		this.updateManyTaskStatusWorkflowsMutation = this.injector.get(UpdateManyTaskStatusWorkflowsDocument);
		this.deleteTaskStatusWorkflowsMutation = this.injector.get(DeleteTaskStatusWorkflowsDocument);
		this.addTicketTaskModelsTaskStatusWorkflowsMutation = this.injector.get(AddTicketTaskModelsTaskStatusWorkflowsDocument);
		this.removeTicketTaskModelsTaskStatusWorkflowsMutation = this.injector.get(RemoveTicketTaskModelsTaskStatusWorkflowsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTaskStatusWorkflow> {
    
    		let variables: GetTaskStatusWorkflowsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getTaskStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatusWorkflows.get));
            }
            else{
                let result:ServiceSingleResultOfTaskStatusWorkflow={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTaskStatusWorkflow,
		@Args('filter?') filter?: FilterOfTaskStatusWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTaskStatusWorkflow> {
    
    		let variables: FirstTaskStatusWorkflowsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstTaskStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatusWorkflows.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTaskStatusWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountTaskStatusWorkflowsBaseVariables = {
    			filter: filter
    		}
    				return this.countTaskStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatusWorkflows.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTaskStatusWorkflow,
		@Args('filter?') filter?: FilterOfTaskStatusWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTaskStatusWorkflow> {
    
    		let variables: FindTaskStatusWorkflowsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findTaskStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatusWorkflows.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfTaskStatusWorkflow,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTaskStatusWorkflow> {
    
    		let variables: SearchTaskStatusWorkflowsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchTaskStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatusWorkflows.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaTaskStatusWorkflowsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaTaskStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatusWorkflows.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTaskStatusWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataTaskStatusWorkflowsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataTaskStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatusWorkflows.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfTaskStatusWorkflow,
		@Args('filter?') filter?: FilterOfTaskStatusWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTaskStatusWorkflow> {
    
    		let variables: CustomQueryTaskStatusWorkflowsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryTaskStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatusWorkflows.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfTaskStatusWorkflow,
		@Args('filter?') filter?: FilterOfTaskStatusWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTaskStatusWorkflow> {
    
    		let variables: CustomQueryIdTaskStatusWorkflowsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdTaskStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatusWorkflows.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedTaskStatusWorkflowsBaseVariables = {
    			ids: ids
    		}
    				return this.usedTaskStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatusWorkflows.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistTaskStatusWorkflowsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existTaskStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatusWorkflows.exist));
    	}

	@InjectArgs
	public findAssociatedTicketTaskModels(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfTicketTaskModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicketTaskModel,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfTicketTaskModel> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('ticketTaskModels', fields, null, [
			GqlSubFieldArg.create('filterOfTicketTaskModels', 'filter'),
			GqlSubFieldArg.create('optionsOfTicketTaskModels', 'options'),
		]),
		])
		extendedVariables['filterOfTicketTaskModels'] = filter;
		extendedVariables['optionsOfTicketTaskModels'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.ticketTaskModels));
            }
            else{
                let result: ServiceListResultOfTicketTaskModel = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedTicketTaskModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketTaskModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicketTaskModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketTaskModel> {
    
    		let variables: FindUnassociatedTicketTaskModelsTaskStatusWorkflowsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedTicketTaskModelsTaskStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatusWorkflows.findUnassociatedTicketTaskModels));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: TaskStatusWorkflowInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTaskStatusWorkflow> {
    
    		let variables: InsertTaskStatusWorkflowsBaseVariables = {
    			entity: entity
    		}
    				return this.insertTaskStatusWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatusWorkflows.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTaskStatusWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTaskStatusWorkflow> {
    
    		let variables: UpdateTaskStatusWorkflowsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateTaskStatusWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatusWorkflows.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataTaskStatusWorkflowsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataTaskStatusWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatusWorkflows.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfTaskStatusWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyTaskStatusWorkflowsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyTaskStatusWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatusWorkflows.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTaskStatusWorkflowsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteTaskStatusWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatusWorkflows.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addTicketTaskModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketTaskModelIds') ticketTaskModelIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddTicketTaskModelsTaskStatusWorkflowsBaseVariables = {
    			id: id,
			ticketTaskModelIds: ticketTaskModelIds
    		}
    				return this.addTicketTaskModelsTaskStatusWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatusWorkflows.addTicketTaskModels));
    	}

    	/**  */
    	@InjectArgs
    	public removeTicketTaskModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketTaskModelIds') ticketTaskModelIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveTicketTaskModelsTaskStatusWorkflowsBaseVariables = {
    			ticketTaskModelIds: ticketTaskModelIds
    		}
    				return this.removeTicketTaskModelsTaskStatusWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatusWorkflows.removeTicketTaskModels));
    	}
    
}