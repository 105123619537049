import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetInStockMovementsBaseVariables, FirstInStockMovementsBaseVariables, CountInStockMovementsBaseVariables, FindInStockMovementsBaseVariables, SearchInStockMovementsBaseVariables, ExportSchemaInStockMovementsBaseVariables, ExportDataInStockMovementsBaseVariables, CustomQueryInStockMovementsBaseVariables, CustomQueryIdInStockMovementsBaseVariables, FindUnassociatedSecurityGroupsInStockMovementsBaseVariables, FindFilesInStockMovementsBaseVariables, FindAttachmentFilesInStockMovementsBaseVariables, FindHelpDeskUserAttachmentFilesInStockMovementsBaseVariables, UsedInStockMovementsBaseVariables, HasAssetEntriesInStockMovementsBaseVariables, FindCreatedAssetsInStockMovementsBaseVariables, FindCreatedRecycleAssetsInStockMovementsBaseVariables, ExportCreatedAssetDataInStockMovementsBaseVariables, ExistInStockMovementsBaseVariables, FindRecyclesInStockMovementsBaseVariables, CountRecyclesInStockMovementsBaseVariables, ReadOnlyInStockMovementsBaseVariables, FindArchivedInStockMovementsBaseVariables, CountAllInStockMovementsBaseVariables, FindDynamicPropertyFieldsInStockMovementsBaseVariables, InsertInStockMovementsBaseVariables, UpdateInStockMovementsBaseVariables, ImportDataInStockMovementsBaseVariables, UpdateManyInStockMovementsBaseVariables, DeleteInStockMovementsBaseVariables, AddSecurityGroupInStockMovementsBaseVariables, RemoveSecurityGroupInStockMovementsBaseVariables, AddFilesInStockMovementsBaseVariables, RemoveFilesInStockMovementsBaseVariables, UploadChunkFileInStockMovementsBaseVariables, DeleteAssetsInStockMovementsBaseVariables, PurgeAssetsInStockMovementsBaseVariables, RestoreAssetsInStockMovementsBaseVariables, RestoreInStockMovementsBaseVariables, RecycleInStockMovementsBaseVariables, RestoreArchivedInStockMovementsBaseVariables, ArchivedInStockMovementsBaseVariables, AddFileDynamicFieldInStockMovementsBaseVariables, RemoveFileDynamicFieldInStockMovementsBaseVariables } from '../gqls'
import { GetInStockMovementsDocument, FirstInStockMovementsDocument, CountInStockMovementsDocument, FindInStockMovementsDocument, SearchInStockMovementsDocument, ExportSchemaInStockMovementsDocument, ExportDataInStockMovementsDocument, CustomQueryInStockMovementsDocument, CustomQueryIdInStockMovementsDocument, FindUnassociatedSecurityGroupsInStockMovementsDocument, FindFilesInStockMovementsDocument, FindAttachmentFilesInStockMovementsDocument, FindHelpDeskUserAttachmentFilesInStockMovementsDocument, UsedInStockMovementsDocument, HasAssetEntriesInStockMovementsDocument, FindCreatedAssetsInStockMovementsDocument, FindCreatedRecycleAssetsInStockMovementsDocument, ExportCreatedAssetDataInStockMovementsDocument, ExistInStockMovementsDocument, FindRecyclesInStockMovementsDocument, CountRecyclesInStockMovementsDocument, ReadOnlyInStockMovementsDocument, FindArchivedInStockMovementsDocument, CountAllInStockMovementsDocument, FindDynamicPropertyFieldsInStockMovementsDocument, InsertInStockMovementsDocument, UpdateInStockMovementsDocument, ImportDataInStockMovementsDocument, UpdateManyInStockMovementsDocument, DeleteInStockMovementsDocument, AddSecurityGroupInStockMovementsDocument, RemoveSecurityGroupInStockMovementsDocument, AddFilesInStockMovementsDocument, RemoveFilesInStockMovementsDocument, UploadChunkFileInStockMovementsDocument, DeleteAssetsInStockMovementsDocument, PurgeAssetsInStockMovementsDocument, RestoreAssetsInStockMovementsDocument, RestoreInStockMovementsDocument, RecycleInStockMovementsDocument, RestoreArchivedInStockMovementsDocument, ArchivedInStockMovementsDocument, AddFileDynamicFieldInStockMovementsDocument, RemoveFileDynamicFieldInStockMovementsDocument } from '../gqls'
import { ServiceSingleResultOfInStockMovement, QueryContextOfInStockMovement, FilterOfInStockMovement, ServiceSingleResultOfInt64, ServiceListResultOfInStockMovement, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, QueryContextOfSecurityGroup, FilterOfSecurityGroup, ServiceListResultOfSecurityGroup, ServiceListResultOfFileModel, QueryContextOfFileModel, FilterOfFileModel, ServiceSingleResultOfBoolean, ServiceListResultOfAsset, QueryContextOfAsset, FilterOfAsset, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfInStockMovement, InStockMovementInput, ServiceSingleResultOfGuid } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class InStockMovementBaseService {
    
public modelName = 'inStockMovement';
public modelPluralName = 'inStockMovements';

	private getInStockMovementsQuery: GetInStockMovementsDocument;
	private firstInStockMovementsQuery: FirstInStockMovementsDocument;
	private countInStockMovementsQuery: CountInStockMovementsDocument;
	private findInStockMovementsQuery: FindInStockMovementsDocument;
	private searchInStockMovementsQuery: SearchInStockMovementsDocument;
	private exportSchemaInStockMovementsQuery: ExportSchemaInStockMovementsDocument;
	private exportDataInStockMovementsQuery: ExportDataInStockMovementsDocument;
	private customQueryInStockMovementsQuery: CustomQueryInStockMovementsDocument;
	private customQueryIdInStockMovementsQuery: CustomQueryIdInStockMovementsDocument;
	private findUnassociatedSecurityGroupsInStockMovementsQuery: FindUnassociatedSecurityGroupsInStockMovementsDocument;
	private findFilesInStockMovementsQuery: FindFilesInStockMovementsDocument;
	private findAttachmentFilesInStockMovementsQuery: FindAttachmentFilesInStockMovementsDocument;
	private findHelpDeskUserAttachmentFilesInStockMovementsQuery: FindHelpDeskUserAttachmentFilesInStockMovementsDocument;
	private usedInStockMovementsQuery: UsedInStockMovementsDocument;
	private hasAssetEntriesInStockMovementsQuery: HasAssetEntriesInStockMovementsDocument;
	private findCreatedAssetsInStockMovementsQuery: FindCreatedAssetsInStockMovementsDocument;
	private findCreatedRecycleAssetsInStockMovementsQuery: FindCreatedRecycleAssetsInStockMovementsDocument;
	private exportCreatedAssetDataInStockMovementsQuery: ExportCreatedAssetDataInStockMovementsDocument;
	private existInStockMovementsQuery: ExistInStockMovementsDocument;
	private findRecyclesInStockMovementsQuery: FindRecyclesInStockMovementsDocument;
	private countRecyclesInStockMovementsQuery: CountRecyclesInStockMovementsDocument;
	private readOnlyInStockMovementsQuery: ReadOnlyInStockMovementsDocument;
	private findArchivedInStockMovementsQuery: FindArchivedInStockMovementsDocument;
	private countAllInStockMovementsQuery: CountAllInStockMovementsDocument;
	private findDynamicPropertyFieldsInStockMovementsQuery: FindDynamicPropertyFieldsInStockMovementsDocument;
	private insertInStockMovementsMutation: InsertInStockMovementsDocument;
	private updateInStockMovementsMutation: UpdateInStockMovementsDocument;
	private importDataInStockMovementsMutation: ImportDataInStockMovementsDocument;
	private updateManyInStockMovementsMutation: UpdateManyInStockMovementsDocument;
	private deleteInStockMovementsMutation: DeleteInStockMovementsDocument;
	private addSecurityGroupInStockMovementsMutation: AddSecurityGroupInStockMovementsDocument;
	private removeSecurityGroupInStockMovementsMutation: RemoveSecurityGroupInStockMovementsDocument;
	private addFilesInStockMovementsMutation: AddFilesInStockMovementsDocument;
	private removeFilesInStockMovementsMutation: RemoveFilesInStockMovementsDocument;
	private uploadChunkFileInStockMovementsMutation: UploadChunkFileInStockMovementsDocument;
	private deleteAssetsInStockMovementsMutation: DeleteAssetsInStockMovementsDocument;
	private purgeAssetsInStockMovementsMutation: PurgeAssetsInStockMovementsDocument;
	private restoreAssetsInStockMovementsMutation: RestoreAssetsInStockMovementsDocument;
	private restoreInStockMovementsMutation: RestoreInStockMovementsDocument;
	private recycleInStockMovementsMutation: RecycleInStockMovementsDocument;
	private restoreArchivedInStockMovementsMutation: RestoreArchivedInStockMovementsDocument;
	private archivedInStockMovementsMutation: ArchivedInStockMovementsDocument;
	private addFileDynamicFieldInStockMovementsMutation: AddFileDynamicFieldInStockMovementsDocument;
	private removeFileDynamicFieldInStockMovementsMutation: RemoveFileDynamicFieldInStockMovementsDocument;

	constructor(private injector: Injector) {
		this.getInStockMovementsQuery = this.injector.get(GetInStockMovementsDocument);
		this.firstInStockMovementsQuery = this.injector.get(FirstInStockMovementsDocument);
		this.countInStockMovementsQuery = this.injector.get(CountInStockMovementsDocument);
		this.findInStockMovementsQuery = this.injector.get(FindInStockMovementsDocument);
		this.searchInStockMovementsQuery = this.injector.get(SearchInStockMovementsDocument);
		this.exportSchemaInStockMovementsQuery = this.injector.get(ExportSchemaInStockMovementsDocument);
		this.exportDataInStockMovementsQuery = this.injector.get(ExportDataInStockMovementsDocument);
		this.customQueryInStockMovementsQuery = this.injector.get(CustomQueryInStockMovementsDocument);
		this.customQueryIdInStockMovementsQuery = this.injector.get(CustomQueryIdInStockMovementsDocument);
		this.findUnassociatedSecurityGroupsInStockMovementsQuery = this.injector.get(FindUnassociatedSecurityGroupsInStockMovementsDocument);
		this.findFilesInStockMovementsQuery = this.injector.get(FindFilesInStockMovementsDocument);
		this.findAttachmentFilesInStockMovementsQuery = this.injector.get(FindAttachmentFilesInStockMovementsDocument);
		this.findHelpDeskUserAttachmentFilesInStockMovementsQuery = this.injector.get(FindHelpDeskUserAttachmentFilesInStockMovementsDocument);
		this.usedInStockMovementsQuery = this.injector.get(UsedInStockMovementsDocument);
		this.hasAssetEntriesInStockMovementsQuery = this.injector.get(HasAssetEntriesInStockMovementsDocument);
		this.findCreatedAssetsInStockMovementsQuery = this.injector.get(FindCreatedAssetsInStockMovementsDocument);
		this.findCreatedRecycleAssetsInStockMovementsQuery = this.injector.get(FindCreatedRecycleAssetsInStockMovementsDocument);
		this.exportCreatedAssetDataInStockMovementsQuery = this.injector.get(ExportCreatedAssetDataInStockMovementsDocument);
		this.existInStockMovementsQuery = this.injector.get(ExistInStockMovementsDocument);
		this.findRecyclesInStockMovementsQuery = this.injector.get(FindRecyclesInStockMovementsDocument);
		this.countRecyclesInStockMovementsQuery = this.injector.get(CountRecyclesInStockMovementsDocument);
		this.readOnlyInStockMovementsQuery = this.injector.get(ReadOnlyInStockMovementsDocument);
		this.findArchivedInStockMovementsQuery = this.injector.get(FindArchivedInStockMovementsDocument);
		this.countAllInStockMovementsQuery = this.injector.get(CountAllInStockMovementsDocument);
		this.findDynamicPropertyFieldsInStockMovementsQuery = this.injector.get(FindDynamicPropertyFieldsInStockMovementsDocument);
		this.insertInStockMovementsMutation = this.injector.get(InsertInStockMovementsDocument);
		this.updateInStockMovementsMutation = this.injector.get(UpdateInStockMovementsDocument);
		this.importDataInStockMovementsMutation = this.injector.get(ImportDataInStockMovementsDocument);
		this.updateManyInStockMovementsMutation = this.injector.get(UpdateManyInStockMovementsDocument);
		this.deleteInStockMovementsMutation = this.injector.get(DeleteInStockMovementsDocument);
		this.addSecurityGroupInStockMovementsMutation = this.injector.get(AddSecurityGroupInStockMovementsDocument);
		this.removeSecurityGroupInStockMovementsMutation = this.injector.get(RemoveSecurityGroupInStockMovementsDocument);
		this.addFilesInStockMovementsMutation = this.injector.get(AddFilesInStockMovementsDocument);
		this.removeFilesInStockMovementsMutation = this.injector.get(RemoveFilesInStockMovementsDocument);
		this.uploadChunkFileInStockMovementsMutation = this.injector.get(UploadChunkFileInStockMovementsDocument);
		this.deleteAssetsInStockMovementsMutation = this.injector.get(DeleteAssetsInStockMovementsDocument);
		this.purgeAssetsInStockMovementsMutation = this.injector.get(PurgeAssetsInStockMovementsDocument);
		this.restoreAssetsInStockMovementsMutation = this.injector.get(RestoreAssetsInStockMovementsDocument);
		this.restoreInStockMovementsMutation = this.injector.get(RestoreInStockMovementsDocument);
		this.recycleInStockMovementsMutation = this.injector.get(RecycleInStockMovementsDocument);
		this.restoreArchivedInStockMovementsMutation = this.injector.get(RestoreArchivedInStockMovementsDocument);
		this.archivedInStockMovementsMutation = this.injector.get(ArchivedInStockMovementsDocument);
		this.addFileDynamicFieldInStockMovementsMutation = this.injector.get(AddFileDynamicFieldInStockMovementsDocument);
		this.removeFileDynamicFieldInStockMovementsMutation = this.injector.get(RemoveFileDynamicFieldInStockMovementsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInStockMovement> {
    
    		let variables: GetInStockMovementsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.get));
            }
            else{
                let result:ServiceSingleResultOfInStockMovement={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInStockMovement,
		@Args('filter?') filter?: FilterOfInStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInStockMovement> {
    
    		let variables: FirstInStockMovementsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfInStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountInStockMovementsBaseVariables = {
    			filter: filter
    		}
    				return this.countInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInStockMovement,
		@Args('filter?') filter?: FilterOfInStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInStockMovement> {
    
    		let variables: FindInStockMovementsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfInStockMovement,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInStockMovement> {
    
    		let variables: SearchInStockMovementsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaInStockMovementsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfInStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataInStockMovementsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfInStockMovement,
		@Args('filter?') filter?: FilterOfInStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInStockMovement> {
    
    		let variables: CustomQueryInStockMovementsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfInStockMovement,
		@Args('filter?') filter?: FilterOfInStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInStockMovement> {
    
    		let variables: CustomQueryIdInStockMovementsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.customQueryId));
    	}

	@InjectArgs
	public findAssociatedSecurityGroups(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSecurityGroup> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('securityGroups', fields, null, [
			GqlSubFieldArg.create('filterOfSecurityGroups', 'filter'),
			GqlSubFieldArg.create('optionsOfSecurityGroups', 'options'),
		]),
		])
		extendedVariables['filterOfSecurityGroups'] = filter;
		extendedVariables['optionsOfSecurityGroups'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.securityGroups));
            }
            else{
                let result: ServiceListResultOfSecurityGroup = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/** Récupère les profils non liés de l'entité. */
    	@InjectArgs
    	public findUnassociatedSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroup> {
    
    		let variables: FindUnassociatedSecurityGroupsInStockMovementsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSecurityGroupsInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.findUnassociatedSecurityGroups));
    	}

    	/** Récupère les fichiers liés. */
    	@InjectArgs
    	public findFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindFilesInStockMovementsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findFilesInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.findFiles));
    	}

    	/** Récupère les fichiers attachés liés. */
    	@InjectArgs
    	public findAttachmentFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindAttachmentFilesInStockMovementsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAttachmentFilesInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.findAttachmentFiles));
    	}

    	/** Récupère les fichiers attachés liés visible pour les users help desk. */
    	@InjectArgs
    	public findHelpDeskUserAttachmentFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindHelpDeskUserAttachmentFilesInStockMovementsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findHelpDeskUserAttachmentFilesInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.findHelpDeskUserAttachmentFiles));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedInStockMovementsBaseVariables = {
    			ids: ids
    		}
    				return this.usedInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.used));
    	}

    	/**  */
    	@InjectArgs
    	public hasAssetEntries(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: HasAssetEntriesInStockMovementsBaseVariables = {
    			id: id
    		}
    				return this.hasAssetEntriesInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.hasAssetEntries));
    	}

    	/**  */
    	@InjectArgs
    	public findCreatedAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindCreatedAssetsInStockMovementsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findCreatedAssetsInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.findCreatedAssets));
    	}

    	/**  */
    	@InjectArgs
    	public findCreatedRecycleAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindCreatedRecycleAssetsInStockMovementsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findCreatedRecycleAssetsInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.findCreatedRecycleAssets));
    	}

    	/**  */
    	@InjectArgs
    	public exportCreatedAssetData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportCreatedAssetDataInStockMovementsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.exportCreatedAssetDataInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.exportCreatedAssetData));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistInStockMovementsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInStockMovement,
		@Args('filter?') filter?: FilterOfInStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInStockMovement> {
    
    		let variables: FindRecyclesInStockMovementsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfInStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesInStockMovementsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyInStockMovementsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInStockMovement,
		@Args('filter?') filter?: FilterOfInStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInStockMovement> {
    
    		let variables: FindArchivedInStockMovementsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfInStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllInStockMovementsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfInStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsInStockMovementsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsInStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: InStockMovementInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInStockMovement> {
    
    		let variables: InsertInStockMovementsBaseVariables = {
    			entity: entity
    		}
    				return this.insertInStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfInStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInStockMovement> {
    
    		let variables: UpdateInStockMovementsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateInStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataInStockMovementsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataInStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfInStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyInStockMovementsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyInStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteInStockMovementsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteInStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSecurityGroupInStockMovementsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds,
			isNew: isNew
    		}
    				return this.addSecurityGroupInStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.addSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSecurityGroupInStockMovementsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds
    		}
    				return this.removeSecurityGroupInStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.removeSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileIds') fileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFilesInStockMovementsBaseVariables = {
    			id: id,
			fileIds: fileIds
    		}
    				return this.addFilesInStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.addFiles));
    	}

    	/**  */
    	@InjectArgs
    	public removeFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileIds') fileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFilesInStockMovementsBaseVariables = {
    			id: id,
			fileIds: fileIds
    		}
    				return this.removeFilesInStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.removeFiles));
    	}

    	/**  */
    	@InjectArgs
    	public uploadChunkFile(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('visibleByHelpDeskUser') visibleByHelpDeskUser: boolean,
		@Args('size') size: number,
		@Args('outgoingEmails') outgoingEmails: boolean,
		@Args('lastChunk') lastChunk: boolean,
		@Args('indexChunk') indexChunk: number,
		@Args('type?') type?: string,
		@Args('name?') name?: string,
		@Args('fileId?') fileId?: string,
		@Args('entityId?') entityId?: string,
		@Args('chunks?') chunks?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: UploadChunkFileInStockMovementsBaseVariables = {
    			name: name,
			size: size,
			type: type,
			chunks: chunks,
			indexChunk: indexChunk,
			lastChunk: lastChunk,
			fileId: fileId,
			entityId: entityId,
			visibleByHelpDeskUser: visibleByHelpDeskUser,
			outgoingEmails: outgoingEmails
    		}
    				return this.uploadChunkFileInStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.uploadChunkFile));
    	}

    	/**  */
    	@InjectArgs
    	public deleteAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAssetsInStockMovementsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAssetsInStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.deleteAssets));
    	}

    	/**  */
    	@InjectArgs
    	public purgeAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: PurgeAssetsInStockMovementsBaseVariables = {
    			ids: ids
    		}
    				return this.purgeAssetsInStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.purgeAssets));
    	}

    	/**  */
    	@InjectArgs
    	public restoreAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreAssetsInStockMovementsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreAssetsInStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.restoreAssets));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreInStockMovementsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreInStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleInStockMovementsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleInStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedInStockMovementsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedInStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedInStockMovementsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedInStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldInStockMovementsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldInStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldInStockMovementsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldInStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inStockMovements.removeFileDynamicField));
    	}
    
}