import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfTicket, FilterOfTicket, QueryContextOfTicket, ServiceSingleResultOfInt64, ServiceListResultOfTicket, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfAsset, FilterOfAsset, QueryContextOfAsset, ServiceListResultOfUser, FilterOfUser, QueryContextOfUser, ServiceListResultOfNote, FilterOfNote, QueryContextOfNote, ServiceSingleResultOfStatusWorkflow, ServiceListResultOfTicketAppointment, FilterOfTicketAppointment, QueryContextOfTicketAppointment, ServiceListResultOfGuid, ServiceListResultOfBadge, ServiceListResultOfResolutionModel, FilterOfResolutionModel, QueryContextOfResolutionModel, ServiceListResultOfTicketCategory, FilterOfTicketCategory, QueryContextOfTicketCategory, ServiceListResultOfLocation, FilterOfLocation, QueryContextOfLocation, ServiceListResultOfOrganizationalUnit, FilterOfOrganizationalUnit, QueryContextOfOrganizationalUnit, ServiceListResultOfImpact, FilterOfImpact, QueryContextOfImpact, ServiceListResultOfPriority, FilterOfPriority, QueryContextOfPriority, ServiceListResultOfUrgency, FilterOfUrgency, QueryContextOfUrgency, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfTicket, ServiceListResultOfMessage, FilterOfMessage, QueryContextOfMessage, ServiceListResultOfAlert, FilterOfAlert, QueryContextOfAlert, ServiceListResultOfTicketTask, FilterOfTicketTask, QueryContextOfTicketTask, ServiceListResultOfIntervention, FilterOfIntervention, QueryContextOfIntervention, ServiceListResultOfEmailPrototypeLog, FilterOfEmailPrototypeLog, QueryContextOfEmailPrototypeLog, ServiceListResultOfTicketEmail, FilterOfTicketEmail, QueryContextOfTicketEmail, ServiceSingleResultOfGuid, ServiceListResultOfString, FieldUpdateOperatorOfMessage } from '../types'

export type GetTicketsResultType = {
    tickets: {
        get?: Maybe<ServiceSingleResultOfTicket>
    }
}

export type GetTicketsBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getTickets ${args} {
    tickets {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfTicket", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstTicketsResultType = {
    tickets: {
        first?: Maybe<ServiceSingleResultOfTicket>
    }
}

export type FirstTicketsBaseVariables = {
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicket>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfTicket = null, $options: QueryContextOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstTickets ${args} {
    tickets {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfTicket", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountTicketsResultType = {
    tickets: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountTicketsBaseVariables = {
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countTickets ${args} {
    tickets {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindTicketsResultType = {
    tickets: {
        find?: Maybe<ServiceListResultOfTicket>
    }
}

export type FindTicketsBaseVariables = {
	options: Maybe<QueryContextOfTicket>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfTicket = null, $filter: FilterOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findTickets ${args} {
    tickets {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicket", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchTicketsResultType = {
    tickets: {
        search?: Maybe<ServiceListResultOfTicket>
    }
}

export type SearchTicketsBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfTicket>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchTickets ${args} {
    tickets {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicket", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaTicketsResultType = {
    tickets: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaTicketsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaTickets ${args} {
    tickets {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataTicketsResultType = {
    tickets: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataTicketsBaseVariables = {
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataTickets ${args} {
    tickets {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryTicketsResultType = {
    tickets: {
        customQuery?: Maybe<ServiceListResultOfTicket>
    }
}

export type CustomQueryTicketsBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicket>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfTicket = null, $options: QueryContextOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryTickets ${args} {
    tickets {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicket", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdTicketsResultType = {
    tickets: {
        customQueryId?: Maybe<ServiceListResultOfTicket>
    }
}

export type CustomQueryIdTicketsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicket>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfTicket = null, $options: QueryContextOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdTickets ${args} {
    tickets {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicket", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedTicketsResultType = {
    tickets: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedTicketsBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedTickets ${args} {
    tickets {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAssetsTicketsResultType = {
    tickets: {
        findAssets?: Maybe<ServiceListResultOfAsset>
    }
}

export type FindAssetsTicketsBaseVariables = {
	filter: Maybe<FilterOfAsset>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAsset>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindAssetsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfAsset = null, $options: QueryContextOfAsset = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAssetsTickets ${args} {
    tickets {
        findAssets(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAssetsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAssetsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAsset", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindOperatorReferentTicketsResultType = {
    tickets: {
        findOperatorReferent?: Maybe<ServiceListResultOfUser>
    }
}

export type FindOperatorReferentTicketsBaseVariables = {
	operatorTeamReferentId: Maybe<string>, /**  */
	filter: Maybe<FilterOfUser>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfUser>, /** Les options de requêtage à appliquer. */
}

/** Permet de récuperer la liste d'opérateurs référents. */
@Injectable({providedIn: 'root'})
export class FindOperatorReferentTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$operatorTeamReferentId: Uuid = null, $filter: FilterOfUser = null, $options: QueryContextOfUser = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findOperatorReferentTickets ${args} {
    tickets {
        findOperatorReferent(operatorTeamReferentId: $operatorTeamReferentId, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindOperatorReferentTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindOperatorReferentTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfUser", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindOperatorAffectedTicketsResultType = {
    tickets: {
        findOperatorAffected?: Maybe<ServiceListResultOfUser>
    }
}

export type FindOperatorAffectedTicketsBaseVariables = {
	operatorTeamAffectedId: Maybe<string>, /**  */
	levelSupport: Maybe<number>, /**  */
	filter: Maybe<FilterOfUser>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfUser>, /** Les options de requêtage à appliquer. */
}

/** Permet de récuperer la liste d'opérateurs affectés. */
@Injectable({providedIn: 'root'})
export class FindOperatorAffectedTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$operatorTeamAffectedId: Uuid = null, $levelSupport: Int = null, $filter: FilterOfUser = null, $options: QueryContextOfUser = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findOperatorAffectedTickets ${args} {
    tickets {
        findOperatorAffected(operatorTeamAffectedId: $operatorTeamAffectedId, levelSupport: $levelSupport, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindOperatorAffectedTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindOperatorAffectedTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfUser", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAssociatedNotesWithoutEmptyTicketsResultType = {
    tickets: {
        findAssociatedNotesWithoutEmpty?: Maybe<ServiceListResultOfNote>
    }
}

export type FindAssociatedNotesWithoutEmptyTicketsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfNote>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfNote>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindAssociatedNotesWithoutEmptyTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfNote = null, $options: QueryContextOfNote = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAssociatedNotesWithoutEmptyTickets ${args} {
    tickets {
        findAssociatedNotesWithoutEmpty(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAssociatedNotesWithoutEmptyTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAssociatedNotesWithoutEmptyTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfNote", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAssociatedEscaladeTicketsResultType = {
    tickets: {
        findAssociatedEscalade?: Maybe<ServiceListResultOfNote>
    }
}

export type FindAssociatedEscaladeTicketsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfNote>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfNote>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindAssociatedEscaladeTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfNote = null, $options: QueryContextOfNote = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAssociatedEscaladeTickets ${args} {
    tickets {
        findAssociatedEscalade(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAssociatedEscaladeTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAssociatedEscaladeTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfNote", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindStatusTrackingTicketsResultType = {
    tickets: {
        findStatusTracking?: Maybe<ServiceListResultOfNote>
    }
}

export type FindStatusTrackingTicketsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfNote>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfNote>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindStatusTrackingTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfNote = null, $options: QueryContextOfNote = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findStatusTrackingTickets ${args} {
    tickets {
        findStatusTracking(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindStatusTrackingTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindStatusTrackingTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfNote", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindMyFavoriteTicketTicketsResultType = {
    tickets: {
        findMyFavoriteTicket?: Maybe<ServiceListResultOfTicket>
    }
}

export type FindMyFavoriteTicketTicketsBaseVariables = {
	textSearch: Maybe<string>, /**  */
	lists: Array<Array<Maybe<string>>>, /**  */
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicket>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindMyFavoriteTicketTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$textSearch: String = null, $lists: [[String]] = null, $filter: FilterOfTicket = null, $options: QueryContextOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findMyFavoriteTicketTickets ${args} {
    tickets {
        findMyFavoriteTicket(textSearch: $textSearch, lists: $lists, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindMyFavoriteTicketTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindMyFavoriteTicketTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicket", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindRecyclesFavoriteTicketsResultType = {
    tickets: {
        findRecyclesFavorite?: Maybe<ServiceListResultOfTicket>
    }
}

export type FindRecyclesFavoriteTicketsBaseVariables = {
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicket>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindRecyclesFavoriteTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfTicket = null, $options: QueryContextOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findRecyclesFavoriteTickets ${args} {
    tickets {
        findRecyclesFavorite(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindRecyclesFavoriteTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindRecyclesFavoriteTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicket", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountRecyclesFavoriteTicketsResultType = {
    tickets: {
        countRecyclesFavorite?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountRecyclesFavoriteTicketsBaseVariables = {

}

/**  */
@Injectable({providedIn: 'root'})
export class CountRecyclesFavoriteTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countRecyclesFavoriteTickets ${args} {
    tickets {
        countRecyclesFavorite {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountRecyclesFavoriteTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountRecyclesFavoriteTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindMyTeamTicketTicketsResultType = {
    tickets: {
        findMyTeamTicket?: Maybe<ServiceListResultOfTicket>
    }
}

export type FindMyTeamTicketTicketsBaseVariables = {
	type: Maybe<string>, /**  */
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicket>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindMyTeamTicketTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: String = null, $filter: FilterOfTicket = null, $options: QueryContextOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findMyTeamTicketTickets ${args} {
    tickets {
        findMyTeamTicket(type: $type, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindMyTeamTicketTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindMyTeamTicketTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicket", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindMyTicketTicketsResultType = {
    tickets: {
        findMyTicket?: Maybe<ServiceListResultOfTicket>
    }
}

export type FindMyTicketTicketsBaseVariables = {
	type: Maybe<string>, /**  */
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicket>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindMyTicketTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: String = null, $filter: FilterOfTicket = null, $options: QueryContextOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findMyTicketTickets ${args} {
    tickets {
        findMyTicket(type: $type, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindMyTicketTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindMyTicketTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicket", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SchemasTicketLinkTypeTicketsResultType = {
    tickets: {
        schemasTicketLinkType?: Maybe<ServiceSingleResultOfString>
    }
}

export type SchemasTicketLinkTypeTicketsBaseVariables = {
	type: Maybe<string>, /**  */
	format: ImportFileFormat, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class SchemasTicketLinkTypeTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: String = null, $format: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query schemasTicketLinkTypeTickets ${args} {
    tickets {
        schemasTicketLinkType(type: $type, format: $format) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SchemasTicketLinkTypeTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SchemasTicketLinkTypeTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindNotUsersTicketsResultType = {
    tickets: {
        findNotUsers?: Maybe<ServiceListResultOfUser>
    }
}

export type FindNotUsersTicketsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfUser>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfUser>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindNotUsersTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfUser = null, $options: QueryContextOfUser = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findNotUsersTickets ${args} {
    tickets {
        findNotUsers(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindNotUsersTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindNotUsersTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfUser", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindNotOtherUsersTicketsResultType = {
    tickets: {
        findNotOtherUsers?: Maybe<ServiceListResultOfUser>
    }
}

export type FindNotOtherUsersTicketsBaseVariables = {
	id: Maybe<string>, /**  */
	locationId: Maybe<string>, /**  */
	organizationalUnitId: Maybe<string>, /**  */
	filter: Maybe<FilterOfUser>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfUser>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindNotOtherUsersTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $locationId: Uuid = null, $organizationalUnitId: Uuid = null, $filter: FilterOfUser = null, $options: QueryContextOfUser = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findNotOtherUsersTickets ${args} {
    tickets {
        findNotOtherUsers(id: $id, locationId: $locationId, organizationalUnitId: $organizationalUnitId, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindNotOtherUsersTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindNotOtherUsersTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfUser", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindTicketResearchTicketsResultType = {
    tickets: {
        findTicketResearch?: Maybe<ServiceListResultOfTicket>
    }
}

export type FindTicketResearchTicketsBaseVariables = {
	textSearch: Maybe<string>, /**  */
	lists: Array<Array<Maybe<string>>>, /**  */
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicket>, /** Les options de requêtage à appliquer. */
}

/** Effectue une recherche sur les tickets */
@Injectable({providedIn: 'root'})
export class FindTicketResearchTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$textSearch: String = null, $lists: [[String]] = null, $filter: FilterOfTicket = null, $options: QueryContextOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findTicketResearchTickets ${args} {
    tickets {
        findTicketResearch(textSearch: $textSearch, lists: $lists, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindTicketResearchTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindTicketResearchTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicket", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type StatusWorkflowByTicketIdTicketsResultType = {
    tickets: {
        statusWorkflowByTicketId?: Maybe<ServiceSingleResultOfStatusWorkflow>
    }
}

export type StatusWorkflowByTicketIdTicketsBaseVariables = {
	ticketId: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class StatusWorkflowByTicketIdTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ticketId: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query statusWorkflowByTicketIdTickets ${args} {
    tickets {
        statusWorkflowByTicketId(ticketId: $ticketId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: StatusWorkflowByTicketIdTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<StatusWorkflowByTicketIdTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfStatusWorkflow", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindPlanificationsTicketsResultType = {
    tickets: {
        findPlanifications?: Maybe<ServiceListResultOfTicketAppointment>
    }
}

export type FindPlanificationsTicketsBaseVariables = {
	filter: Maybe<FilterOfTicketAppointment>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicketAppointment>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindPlanificationsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfTicketAppointment = null, $options: QueryContextOfTicketAppointment = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findPlanificationsTickets ${args} {
    tickets {
        findPlanifications(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindPlanificationsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindPlanificationsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicketAppointment", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAllTicketsResultType = {
    tickets: {
        findAll?: Maybe<ServiceListResultOfTicket>
    }
}

export type FindAllTicketsBaseVariables = {
	type: Maybe<string>, /**  */
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicket>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindAllTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: String = null, $filter: FilterOfTicket = null, $options: QueryContextOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAllTickets ${args} {
    tickets {
        findAll(type: $type, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAllTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAllTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicket", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindTicketsRecyclesTicketsResultType = {
    tickets: {
        findTicketsRecycles?: Maybe<ServiceListResultOfTicket>
    }
}

export type FindTicketsRecyclesTicketsBaseVariables = {
	type: Maybe<string>, /**  */
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicket>, /** Les options de requêtage à appliquer. */
}

/** Permet de recuperer la liste des tickets supprimes */
@Injectable({providedIn: 'root'})
export class FindTicketsRecyclesTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: String = null, $filter: FilterOfTicket = null, $options: QueryContextOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findTicketsRecyclesTickets ${args} {
    tickets {
        findTicketsRecycles(type: $type, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindTicketsRecyclesTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindTicketsRecyclesTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicket", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountTicketsRecyclesTicketsResultType = {
    tickets: {
        countTicketsRecycles?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountTicketsRecyclesTicketsBaseVariables = {
	type: Maybe<string>, /**  */
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
}

/** Le nombre des tickets supprimes */
@Injectable({providedIn: 'root'})
export class CountTicketsRecyclesTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: String = null, $filter: FilterOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countTicketsRecyclesTickets ${args} {
    tickets {
        countTicketsRecycles(type: $type, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountTicketsRecyclesTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountTicketsRecyclesTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type IsEndingTicketTicketsResultType = {
    tickets: {
        isEndingTicket?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type IsEndingTicketTicketsBaseVariables = {
	ticketId: string, /**  */
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicket>, /** Les options de requêtage à appliquer. */
}

/** Permet de savoir si le status du ticket est un point de sortie */
@Injectable({providedIn: 'root'})
export class IsEndingTicketTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ticketId: Uuid!, $filter: FilterOfTicket = null, $options: QueryContextOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query isEndingTicketTickets ${args} {
    tickets {
        isEndingTicket(ticketId: $ticketId, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: IsEndingTicketTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<IsEndingTicketTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CheckLockTicketsTicketsResultType = {
    tickets: {
        checkLockTickets?: Maybe<ServiceListResultOfGuid>
    }
}

export type CheckLockTicketsTicketsBaseVariables = {
	ids: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class CheckLockTicketsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query checkLockTicketsTickets ${args} {
    tickets {
        checkLockTickets(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CheckLockTicketsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CheckLockTicketsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfGuid", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveLockTicketsTicketsResultType = {
    tickets: {
        removeLockTickets?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveLockTicketsTicketsBaseVariables = {
	ids: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveLockTicketsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query removeLockTicketsTickets ${args} {
    tickets {
        removeLockTickets(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: RemoveLockTicketsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<RemoveLockTicketsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindTicketInformationTicketsResultType = {
    tickets: {
        findTicketInformation?: Maybe<ServiceListResultOfTicket>
    }
}

export type FindTicketInformationTicketsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicket>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindTicketInformationTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfTicket = null, $options: QueryContextOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findTicketInformationTickets ${args} {
    tickets {
        findTicketInformation(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindTicketInformationTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindTicketInformationTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicket", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type BadgesTicketsResultType = {
    tickets: {
        badges?: Maybe<ServiceListResultOfBadge>
    }
}

export type BadgesTicketsBaseVariables = {
	id: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class BadgesTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query badgesTickets ${args} {
    tickets {
        badges(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: BadgesTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<BadgesTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfBadge", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CounterByStatusesTicketsResultType = {
    tickets: {
        counterByStatuses?: Maybe<any>
    }
}

export type CounterByStatusesTicketsBaseVariables = {

}

/**  */
@Injectable({providedIn: 'root'})
export class CounterByStatusesTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query counterByStatusesTickets ${args} {
    tickets {
        counterByStatuses 
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CounterByStatusesTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CounterByStatusesTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "any", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UserPrivilegePropertyTicketsResultType = {
    tickets: {
        userPrivilegeProperty?: Maybe<ServiceSingleResultOfString>
    }
}

export type UserPrivilegePropertyTicketsBaseVariables = {
	ticketId: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class UserPrivilegePropertyTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ticketId: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query userPrivilegePropertyTickets ${args} {
    tickets {
        userPrivilegeProperty(ticketId: $ticketId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UserPrivilegePropertyTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UserPrivilegePropertyTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindArchivedAllArchivedTicketsResultType = {
    tickets: {
        findArchivedAllArchived?: Maybe<ServiceListResultOfTicket>
    }
}

export type FindArchivedAllArchivedTicketsBaseVariables = {
	textSearch: Maybe<string>, /**  */
	lists: Array<Array<Maybe<string>>>, /**  */
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicket>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindArchivedAllArchivedTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$textSearch: String = null, $lists: [[String]] = null, $filter: FilterOfTicket = null, $options: QueryContextOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findArchivedAllArchivedTickets ${args} {
    tickets {
        findArchivedAllArchived(textSearch: $textSearch, lists: $lists, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindArchivedAllArchivedTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindArchivedAllArchivedTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicket", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindResolutionModelTicketsResultType = {
    tickets: {
        findResolutionModel?: Maybe<ServiceListResultOfResolutionModel>
    }
}

export type FindResolutionModelTicketsBaseVariables = {
	type: Maybe<string>, /**  */
	ticketCategoryId: Maybe<string>, /**  */
	resolutionCategoryId: Maybe<string>, /**  */
	qualification: Maybe<string>, /**  */
	filter: Maybe<FilterOfResolutionModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfResolutionModel>, /** Les options de requêtage à appliquer. */
}

/** Obtient les modèles de résolutions pour les modèles de ticket */
@Injectable({providedIn: 'root'})
export class FindResolutionModelTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: String = null, $ticketCategoryId: Uuid = null, $resolutionCategoryId: Uuid = null, $qualification: String = null, $filter: FilterOfResolutionModel = null, $options: QueryContextOfResolutionModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findResolutionModelTickets ${args} {
    tickets {
        findResolutionModel(type: $type, ticketCategoryId: $ticketCategoryId, resolutionCategoryId: $resolutionCategoryId, qualification: $qualification, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindResolutionModelTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindResolutionModelTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfResolutionModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindTicketCategoryByPopulationTicketsResultType = {
    tickets: {
        findTicketCategoryByPopulation?: Maybe<ServiceListResultOfTicketCategory>
    }
}

export type FindTicketCategoryByPopulationTicketsBaseVariables = {
	qualification: Maybe<string>, /**  */
	filter: Maybe<FilterOfTicketCategory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicketCategory>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindTicketCategoryByPopulationTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$qualification: String = null, $filter: FilterOfTicketCategory = null, $options: QueryContextOfTicketCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findTicketCategoryByPopulationTickets ${args} {
    tickets {
        findTicketCategoryByPopulation(qualification: $qualification, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindTicketCategoryByPopulationTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindTicketCategoryByPopulationTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicketCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindLocationByPopulationTicketsResultType = {
    tickets: {
        findLocationByPopulation?: Maybe<ServiceListResultOfLocation>
    }
}

export type FindLocationByPopulationTicketsBaseVariables = {
	filter: Maybe<FilterOfLocation>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfLocation>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindLocationByPopulationTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfLocation = null, $options: QueryContextOfLocation = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findLocationByPopulationTickets ${args} {
    tickets {
        findLocationByPopulation(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindLocationByPopulationTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindLocationByPopulationTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfLocation", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindOrganizationalUnitByPopulationTicketsResultType = {
    tickets: {
        findOrganizationalUnitByPopulation?: Maybe<ServiceListResultOfOrganizationalUnit>
    }
}

export type FindOrganizationalUnitByPopulationTicketsBaseVariables = {
	filter: Maybe<FilterOfOrganizationalUnit>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOrganizationalUnit>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindOrganizationalUnitByPopulationTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfOrganizationalUnit = null, $options: QueryContextOfOrganizationalUnit = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findOrganizationalUnitByPopulationTickets ${args} {
    tickets {
        findOrganizationalUnitByPopulation(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindOrganizationalUnitByPopulationTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindOrganizationalUnitByPopulationTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOrganizationalUnit", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindImpactTicketsResultType = {
    tickets: {
        findImpact?: Maybe<ServiceListResultOfImpact>
    }
}

export type FindImpactTicketsBaseVariables = {
	qualification: Maybe<string>, /**  */
	filter: Maybe<FilterOfImpact>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfImpact>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindImpactTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$qualification: String = null, $filter: FilterOfImpact = null, $options: QueryContextOfImpact = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findImpactTickets ${args} {
    tickets {
        findImpact(qualification: $qualification, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindImpactTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindImpactTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfImpact", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindPriorityTicketsResultType = {
    tickets: {
        findPriority?: Maybe<ServiceListResultOfPriority>
    }
}

export type FindPriorityTicketsBaseVariables = {
	qualification: Maybe<string>, /**  */
	filter: Maybe<FilterOfPriority>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfPriority>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindPriorityTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$qualification: String = null, $filter: FilterOfPriority = null, $options: QueryContextOfPriority = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findPriorityTickets ${args} {
    tickets {
        findPriority(qualification: $qualification, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindPriorityTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindPriorityTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfPriority", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUrgencyTicketsResultType = {
    tickets: {
        findUrgency?: Maybe<ServiceListResultOfUrgency>
    }
}

export type FindUrgencyTicketsBaseVariables = {
	qualification: Maybe<string>, /**  */
	filter: Maybe<FilterOfUrgency>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfUrgency>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUrgencyTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$qualification: String = null, $filter: FilterOfUrgency = null, $options: QueryContextOfUrgency = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUrgencyTickets ${args} {
    tickets {
        findUrgency(qualification: $qualification, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUrgencyTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUrgencyTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfUrgency", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistTicketsResultType = {
    tickets: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistTicketsBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existTickets ${args} {
    tickets {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindRecyclesTicketsResultType = {
    tickets: {
        findRecycles?: Maybe<ServiceListResultOfTicket>
    }
}

export type FindRecyclesTicketsBaseVariables = {
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicket>, /** Les options de requêtage à appliquer. */
}

/** Récupère les entités mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindRecyclesTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfTicket = null, $options: QueryContextOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findRecyclesTickets ${args} {
    tickets {
        findRecycles(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindRecyclesTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindRecyclesTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicket", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountRecyclesTicketsResultType = {
    tickets: {
        countRecycles?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountRecyclesTicketsBaseVariables = {
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountRecyclesTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countRecyclesTickets ${args} {
    tickets {
        countRecycles(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountRecyclesTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountRecyclesTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ReadOnlyTicketsResultType = {
    tickets: {
        readOnly?: Maybe<ServiceSingleResultOfEntityAttribute>
    }
}

export type ReadOnlyTicketsBaseVariables = {
	id: string, /** L'Id de l'élément. */
}

/** Vérifie si l'entité est en lecture seule. */
@Injectable({providedIn: 'root'})
export class ReadOnlyTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query readOnlyTickets ${args} {
    tickets {
        readOnly(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ReadOnlyTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ReadOnlyTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfEntityAttribute", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindArchivedTicketsResultType = {
    tickets: {
        findArchived?: Maybe<ServiceListResultOfTicket>
    }
}

export type FindArchivedTicketsBaseVariables = {
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicket>, /** Les options de requêtage à appliquer. */
}

/** Récupère les entités archivées selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindArchivedTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfTicket = null, $options: QueryContextOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findArchivedTickets ${args} {
    tickets {
        findArchived(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindArchivedTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindArchivedTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicket", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountAllTicketsResultType = {
    tickets: {
        countAll?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountAllTicketsBaseVariables = {
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountAllTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countAllTickets ${args} {
    tickets {
        countAll(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountAllTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountAllTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindDynamicPropertyFieldsTicketsResultType = {
    tickets: {
        findDynamicPropertyFields?: Maybe<ServiceListResultOfDynamicPropertyField>
    }
}

export type FindDynamicPropertyFieldsTicketsBaseVariables = {
	id: Maybe<string>, /**  */
	entry: Maybe<FieldUpdateOperatorOfTicket>, /** Les actions de mise à jour à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindDynamicPropertyFieldsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $entry: FieldUpdateOperatorOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findDynamicPropertyFieldsTickets ${args} {
    tickets {
        findDynamicPropertyFields(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindDynamicPropertyFieldsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindDynamicPropertyFieldsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfDynamicPropertyField", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedChildrenTicketsResultType = {
    tickets: {
        findUnassociatedChildren?: Maybe<ServiceListResultOfTicket>
    }
}

export type FindUnassociatedChildrenTicketsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicket>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedChildrenTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfTicket = null, $options: QueryContextOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedChildrenTickets ${args} {
    tickets {
        findUnassociatedChildren(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedChildrenTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedChildrenTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicket", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedUsersTicketsResultType = {
    tickets: {
        findUnassociatedUsers?: Maybe<ServiceListResultOfUser>
    }
}

export type FindUnassociatedUsersTicketsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfUser>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfUser>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedUsersTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfUser = null, $options: QueryContextOfUser = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedUsersTickets ${args} {
    tickets {
        findUnassociatedUsers(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedUsersTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedUsersTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfUser", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedAssetsTicketsResultType = {
    tickets: {
        findUnassociatedAssets?: Maybe<ServiceListResultOfAsset>
    }
}

export type FindUnassociatedAssetsTicketsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfAsset>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAsset>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedAssetsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfAsset = null, $options: QueryContextOfAsset = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedAssetsTickets ${args} {
    tickets {
        findUnassociatedAssets(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedAssetsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedAssetsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAsset", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedMessagesTicketsResultType = {
    tickets: {
        findUnassociatedMessages?: Maybe<ServiceListResultOfMessage>
    }
}

export type FindUnassociatedMessagesTicketsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfMessage>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfMessage>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedMessagesTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfMessage = null, $options: QueryContextOfMessage = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedMessagesTickets ${args} {
    tickets {
        findUnassociatedMessages(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedMessagesTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedMessagesTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfMessage", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedAlertsTicketsResultType = {
    tickets: {
        findUnassociatedAlerts?: Maybe<ServiceListResultOfAlert>
    }
}

export type FindUnassociatedAlertsTicketsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfAlert>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAlert>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedAlertsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfAlert = null, $options: QueryContextOfAlert = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedAlertsTickets ${args} {
    tickets {
        findUnassociatedAlerts(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedAlertsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedAlertsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAlert", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedTasksTicketsResultType = {
    tickets: {
        findUnassociatedTasks?: Maybe<ServiceListResultOfTicketTask>
    }
}

export type FindUnassociatedTasksTicketsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfTicketTask>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicketTask>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedTasksTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfTicketTask = null, $options: QueryContextOfTicketTask = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedTasksTickets ${args} {
    tickets {
        findUnassociatedTasks(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedTasksTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedTasksTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicketTask", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedInterventionsTicketsResultType = {
    tickets: {
        findUnassociatedInterventions?: Maybe<ServiceListResultOfIntervention>
    }
}

export type FindUnassociatedInterventionsTicketsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfIntervention>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfIntervention>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedInterventionsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfIntervention = null, $options: QueryContextOfIntervention = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedInterventionsTickets ${args} {
    tickets {
        findUnassociatedInterventions(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedInterventionsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedInterventionsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfIntervention", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedEmailPrototypeLogsTicketsResultType = {
    tickets: {
        findUnassociatedEmailPrototypeLogs?: Maybe<ServiceListResultOfEmailPrototypeLog>
    }
}

export type FindUnassociatedEmailPrototypeLogsTicketsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfEmailPrototypeLog>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfEmailPrototypeLog>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedEmailPrototypeLogsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfEmailPrototypeLog = null, $options: QueryContextOfEmailPrototypeLog = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedEmailPrototypeLogsTickets ${args} {
    tickets {
        findUnassociatedEmailPrototypeLogs(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedEmailPrototypeLogsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedEmailPrototypeLogsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfEmailPrototypeLog", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedNotesTicketsResultType = {
    tickets: {
        findUnassociatedNotes?: Maybe<ServiceListResultOfNote>
    }
}

export type FindUnassociatedNotesTicketsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfNote>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfNote>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedNotesTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfNote = null, $options: QueryContextOfNote = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedNotesTickets ${args} {
    tickets {
        findUnassociatedNotes(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedNotesTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedNotesTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfNote", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedTicketEmailsTicketsResultType = {
    tickets: {
        findUnassociatedTicketEmails?: Maybe<ServiceListResultOfTicketEmail>
    }
}

export type FindUnassociatedTicketEmailsTicketsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfTicketEmail>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicketEmail>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedTicketEmailsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfTicketEmail = null, $options: QueryContextOfTicketEmail = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedTicketEmailsTickets ${args} {
    tickets {
        findUnassociatedTicketEmails(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedTicketEmailsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedTicketEmailsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicketEmail", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyTicketsResultType = {
    tickets: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyTicketsBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfTicket>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyTickets ${args} {
    tickets {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteTicketsResultType = {
    tickets: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteTicketsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteTickets ${args} {
    tickets {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddFilesTicketsResultType = {
    tickets: {
        addFiles?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddFilesTicketsBaseVariables = {
	id: string, /**  */
	fileIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddFilesTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $fileIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addFilesTickets ${args} {
    tickets {
        addFiles(id: $id, fileIds: $fileIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddFilesTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddFilesTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveFilesTicketsResultType = {
    tickets: {
        removeFiles?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveFilesTicketsBaseVariables = {
	id: string, /**  */
	fileIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveFilesTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $fileIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeFilesTickets ${args} {
    tickets {
        removeFiles(id: $id, fileIds: $fileIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveFilesTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveFilesTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UploadChunkFileTicketsResultType = {
    tickets: {
        uploadChunkFile?: Maybe<ServiceSingleResultOfGuid>
    }
}

export type UploadChunkFileTicketsBaseVariables = {
	name: Maybe<string>, /**  */
	size: number, /**  */
	type: Maybe<string>, /**  */
	chunks: Maybe<string>, /**  */
	indexChunk: number, /**  */
	lastChunk: boolean, /**  */
	fileId: Maybe<string>, /**  */
	entityId: Maybe<string>, /**  */
	visibleByHelpDeskUser: boolean, /**  */
	outgoingEmails: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class UploadChunkFileTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$name: String = null, $size: Int!, $type: String = null, $chunks: String = null, $indexChunk: Int!, $lastChunk: Boolean!, $fileId: Uuid = null, $entityId: Uuid = null, $visibleByHelpDeskUser: Boolean!, $outgoingEmails: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation uploadChunkFileTickets ${args} {
    tickets {
        uploadChunkFile(name: $name, size: $size, type: $type, chunks: $chunks, indexChunk: $indexChunk, lastChunk: $lastChunk, fileId: $fileId, entityId: $entityId, visibleByHelpDeskUser: $visibleByHelpDeskUser, outgoingEmails: $outgoingEmails) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UploadChunkFileTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UploadChunkFileTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfGuid", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddFavoriteTicketsTicketsResultType = {
    tickets: {
        addFavoriteTickets?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddFavoriteTicketsTicketsBaseVariables = {
	id: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddFavoriteTicketsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addFavoriteTicketsTickets ${args} {
    tickets {
        addFavoriteTickets(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddFavoriteTicketsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddFavoriteTicketsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveFavoriteTicketsTicketsResultType = {
    tickets: {
        removeFavoriteTickets?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveFavoriteTicketsTicketsBaseVariables = {
	id: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveFavoriteTicketsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeFavoriteTicketsTickets ${args} {
    tickets {
        removeFavoriteTickets(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveFavoriteTicketsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveFavoriteTicketsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type TransformOnTicketsResultType = {
    tickets: {
        transformOn?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type TransformOnTicketsBaseVariables = {
	ids: Array<string>, /**  */
	to: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class TransformOnTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $to: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation transformOnTickets ${args} {
    tickets {
        transformOn(ids: $ids, to: $to) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: TransformOnTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<TransformOnTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportLinkByTypeTicketsResultType = {
    tickets: {
        importLinkByType?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportLinkByTypeTicketsBaseVariables = {
	file: Maybe<string>, /**  */
	type: Maybe<string>, /**  */
	format: ImportFileFormat, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class ImportLinkByTypeTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$file: String = null, $type: String = null, $format: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importLinkByTypeTickets ${args} {
    tickets {
        importLinkByType(file: $file, type: $type, format: $format) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportLinkByTypeTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportLinkByTypeTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ChangeAffectedByTicketIdsTicketsResultType = {
    tickets: {
        changeAffectedByTicketIds?: Maybe<ServiceListResultOfString>
    }
}

export type ChangeAffectedByTicketIdsTicketsBaseVariables = {
	incidentIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class ChangeAffectedByTicketIdsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$incidentIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation changeAffectedByTicketIdsTickets ${args} {
    tickets {
        changeAffectedByTicketIds(incidentIds: $incidentIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ChangeAffectedByTicketIdsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ChangeAffectedByTicketIdsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateReminderTicketsResultType = {
    tickets: {
        updateReminder?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateReminderTicketsBaseVariables = {
	id: string, /**  */
	entry: Maybe<FieldUpdateOperatorOfMessage>, /** Les actions de mise à jour à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class UpdateReminderTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfMessage = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateReminderTickets ${args} {
    tickets {
        updateReminder(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateReminderTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateReminderTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateTagsTicketsResultType = {
    tickets: {
        updateTags?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateTagsTicketsBaseVariables = {
	ticketIds: Array<string>, /**  */
	addTag: Array<Maybe<string>>, /**  */
	removeTag: Array<Maybe<string>>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class UpdateTagsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ticketIds: [Uuid!], $addTag: [String] = null, $removeTag: [String] = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateTagsTickets ${args} {
    tickets {
        updateTags(ticketIds: $ticketIds, addTag: $addTag, removeTag: $removeTag) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateTagsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateTagsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type GroupTicketTicketsResultType = {
    tickets: {
        groupTicket?: Maybe<ServiceSingleResultOfGuid>
    }
}

export type GroupTicketTicketsBaseVariables = {
	sourceId: string, /**  */
	ids: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class GroupTicketTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$sourceId: Uuid!, $ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation groupTicketTickets ${args} {
    tickets {
        groupTicket(sourceId: $sourceId, ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: GroupTicketTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<GroupTicketTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfGuid", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AssociateChildrenTicketsResultType = {
    tickets: {
        associateChildren?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AssociateChildrenTicketsBaseVariables = {
	id: string, /**  */
	ticketIds: Array<string>, /**  */
}

/** Permet d'associer des tickets à un ticket pére. */
@Injectable({providedIn: 'root'})
export class AssociateChildrenTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $ticketIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation associateChildrenTickets ${args} {
    tickets {
        associateChildren(id: $id, ticketIds: $ticketIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AssociateChildrenTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AssociateChildrenTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DissociateChildrenTicketsResultType = {
    tickets: {
        dissociateChildren?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DissociateChildrenTicketsBaseVariables = {
	id: string, /**  */
	ticketIds: Array<string>, /**  */
}

/** Permet de dissocier des tickets à un ticket pére. */
@Injectable({providedIn: 'root'})
export class DissociateChildrenTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $ticketIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation dissociateChildrenTickets ${args} {
    tickets {
        dissociateChildren(id: $id, ticketIds: $ticketIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DissociateChildrenTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DissociateChildrenTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type PrintTicketEventsTicketsResultType = {
    tickets: {
        printTicketEvents?: Maybe<ServiceSingleResultOfString>
    }
}

export type PrintTicketEventsTicketsBaseVariables = {
	ids: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class PrintTicketEventsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation printTicketEventsTickets ${args} {
    tickets {
        printTicketEvents(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: PrintTicketEventsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<PrintTicketEventsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RestoreTicketsResultType = {
    tickets: {
        restore?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RestoreTicketsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
@Injectable({providedIn: 'root'})
export class RestoreTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation restoreTickets ${args} {
    tickets {
        restore(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RestoreTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RestoreTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RecycleTicketsResultType = {
    tickets: {
        recycle?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RecycleTicketsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
@Injectable({providedIn: 'root'})
export class RecycleTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation recycleTickets ${args} {
    tickets {
        recycle(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RecycleTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RecycleTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RestoreArchivedTicketsResultType = {
    tickets: {
        restoreArchived?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RestoreArchivedTicketsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de restauré une ou plusieurs entités mises en archive. */
@Injectable({providedIn: 'root'})
export class RestoreArchivedTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation restoreArchivedTickets ${args} {
    tickets {
        restoreArchived(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RestoreArchivedTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RestoreArchivedTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ArchivedTicketsResultType = {
    tickets: {
        archived?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ArchivedTicketsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à archiver. */
}

/** Permet d'archiver une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class ArchivedTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation archivedTickets ${args} {
    tickets {
        archived(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ArchivedTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ArchivedTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddFileDynamicFieldTicketsResultType = {
    tickets: {
        addFileDynamicField?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddFileDynamicFieldTicketsBaseVariables = {
	id: Maybe<string>, /**  */
	fileId: Maybe<string>, /**  */
	propertyName: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddFileDynamicFieldTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $fileId: Uuid = null, $propertyName: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addFileDynamicFieldTickets ${args} {
    tickets {
        addFileDynamicField(id: $id, fileId: $fileId, propertyName: $propertyName) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddFileDynamicFieldTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddFileDynamicFieldTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveFileDynamicFieldTicketsResultType = {
    tickets: {
        removeFileDynamicField?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveFileDynamicFieldTicketsBaseVariables = {
	id: Maybe<string>, /**  */
	fileId: Maybe<string>, /**  */
	propertyName: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveFileDynamicFieldTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $fileId: Uuid = null, $propertyName: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeFileDynamicFieldTickets ${args} {
    tickets {
        removeFileDynamicField(id: $id, fileId: $fileId, propertyName: $propertyName) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveFileDynamicFieldTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveFileDynamicFieldTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddAssetsTicketsResultType = {
    tickets: {
        addAssets?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddAssetsTicketsBaseVariables = {
	id: string, /**  */
	assetIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddAssetsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $assetIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addAssetsTickets ${args} {
    tickets {
        addAssets(id: $id, assetIds: $assetIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddAssetsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddAssetsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveAssetsTicketsResultType = {
    tickets: {
        removeAssets?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveAssetsTicketsBaseVariables = {
	id: string, /**  */
	assetIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveAssetsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $assetIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeAssetsTickets ${args} {
    tickets {
        removeAssets(id: $id, assetIds: $assetIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveAssetsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveAssetsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddUsersTicketsResultType = {
    tickets: {
        addUsers?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddUsersTicketsBaseVariables = {
	id: string, /**  */
	userIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddUsersTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $userIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addUsersTickets ${args} {
    tickets {
        addUsers(id: $id, userIds: $userIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddUsersTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddUsersTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveUsersTicketsResultType = {
    tickets: {
        removeUsers?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveUsersTicketsBaseVariables = {
	id: string, /**  */
	userIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveUsersTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $userIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeUsersTickets ${args} {
    tickets {
        removeUsers(id: $id, userIds: $userIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveUsersTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveUsersTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddAlertsTicketsResultType = {
    tickets: {
        addAlerts?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddAlertsTicketsBaseVariables = {
	id: string, /**  */
	alertIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddAlertsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $alertIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addAlertsTickets ${args} {
    tickets {
        addAlerts(id: $id, alertIds: $alertIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddAlertsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddAlertsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveAlertsTicketsResultType = {
    tickets: {
        removeAlerts?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveAlertsTicketsBaseVariables = {
	alertIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveAlertsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$alertIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeAlertsTickets ${args} {
    tickets {
        removeAlerts(alertIds: $alertIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveAlertsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveAlertsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddEmailPrototypeLogsTicketsResultType = {
    tickets: {
        addEmailPrototypeLogs?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddEmailPrototypeLogsTicketsBaseVariables = {
	id: string, /**  */
	emailPrototypeLogIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddEmailPrototypeLogsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $emailPrototypeLogIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addEmailPrototypeLogsTickets ${args} {
    tickets {
        addEmailPrototypeLogs(id: $id, emailPrototypeLogIds: $emailPrototypeLogIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddEmailPrototypeLogsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddEmailPrototypeLogsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveEmailPrototypeLogsTicketsResultType = {
    tickets: {
        removeEmailPrototypeLogs?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveEmailPrototypeLogsTicketsBaseVariables = {
	emailPrototypeLogIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveEmailPrototypeLogsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$emailPrototypeLogIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeEmailPrototypeLogsTickets ${args} {
    tickets {
        removeEmailPrototypeLogs(emailPrototypeLogIds: $emailPrototypeLogIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveEmailPrototypeLogsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveEmailPrototypeLogsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddInterventionsTicketsResultType = {
    tickets: {
        addInterventions?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddInterventionsTicketsBaseVariables = {
	id: string, /**  */
	interventionIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddInterventionsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $interventionIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addInterventionsTickets ${args} {
    tickets {
        addInterventions(id: $id, interventionIds: $interventionIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddInterventionsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddInterventionsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveInterventionsTicketsResultType = {
    tickets: {
        removeInterventions?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveInterventionsTicketsBaseVariables = {
	interventionIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveInterventionsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$interventionIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeInterventionsTickets ${args} {
    tickets {
        removeInterventions(interventionIds: $interventionIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveInterventionsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveInterventionsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddMessagesTicketsResultType = {
    tickets: {
        addMessages?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddMessagesTicketsBaseVariables = {
	id: string, /**  */
	messageIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddMessagesTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $messageIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addMessagesTickets ${args} {
    tickets {
        addMessages(id: $id, messageIds: $messageIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddMessagesTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddMessagesTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveMessagesTicketsResultType = {
    tickets: {
        removeMessages?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveMessagesTicketsBaseVariables = {
	messageIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveMessagesTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$messageIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeMessagesTickets ${args} {
    tickets {
        removeMessages(messageIds: $messageIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveMessagesTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveMessagesTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddNotesTicketsResultType = {
    tickets: {
        addNotes?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddNotesTicketsBaseVariables = {
	id: string, /**  */
	noteIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddNotesTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $noteIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addNotesTickets ${args} {
    tickets {
        addNotes(id: $id, noteIds: $noteIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddNotesTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddNotesTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveNotesTicketsResultType = {
    tickets: {
        removeNotes?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveNotesTicketsBaseVariables = {
	noteIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveNotesTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$noteIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeNotesTickets ${args} {
    tickets {
        removeNotes(noteIds: $noteIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveNotesTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveNotesTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddTasksTicketsResultType = {
    tickets: {
        addTasks?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddTasksTicketsBaseVariables = {
	id: string, /**  */
	ticketTaskIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddTasksTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $ticketTaskIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addTasksTickets ${args} {
    tickets {
        addTasks(id: $id, ticketTaskIds: $ticketTaskIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddTasksTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddTasksTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveTasksTicketsResultType = {
    tickets: {
        removeTasks?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveTasksTicketsBaseVariables = {
	ticketTaskIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveTasksTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ticketTaskIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeTasksTickets ${args} {
    tickets {
        removeTasks(ticketTaskIds: $ticketTaskIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveTasksTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveTasksTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddChildrenTicketsResultType = {
    tickets: {
        addChildren?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddChildrenTicketsBaseVariables = {
	id: string, /**  */
	ticketIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddChildrenTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $ticketIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addChildrenTickets ${args} {
    tickets {
        addChildren(id: $id, ticketIds: $ticketIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddChildrenTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddChildrenTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveChildrenTicketsResultType = {
    tickets: {
        removeChildren?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveChildrenTicketsBaseVariables = {
	ticketIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveChildrenTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ticketIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeChildrenTickets ${args} {
    tickets {
        removeChildren(ticketIds: $ticketIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveChildrenTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveChildrenTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddTicketEmailsTicketsResultType = {
    tickets: {
        addTicketEmails?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddTicketEmailsTicketsBaseVariables = {
	id: string, /**  */
	ticketEmailIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddTicketEmailsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $ticketEmailIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addTicketEmailsTickets ${args} {
    tickets {
        addTicketEmails(id: $id, ticketEmailIds: $ticketEmailIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddTicketEmailsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddTicketEmailsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveTicketEmailsTicketsResultType = {
    tickets: {
        removeTicketEmails?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveTicketEmailsTicketsBaseVariables = {
	ticketEmailIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveTicketEmailsTicketsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ticketEmailIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeTicketEmailsTickets ${args} {
    tickets {
        removeTicketEmails(ticketEmailIds: $ticketEmailIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveTicketEmailsTicketsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveTicketEmailsTicketsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
