import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfResolutionModel, FilterOfResolutionModel, QueryContextOfResolutionModel, ServiceSingleResultOfInt64, ServiceListResultOfResolutionModel, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceListResultOfSecurityGroup, FilterOfSecurityGroup, QueryContextOfSecurityGroup, ServiceListResultOfOperatorTeam, ServiceListResultOfFileModel, FilterOfFileModel, QueryContextOfFileModel, ServiceSingleResultOfBoolean, ServiceListResultOfResolutionCategory, FilterOfResolutionCategory, QueryContextOfResolutionCategory, ResolutionModelInput, FieldUpdateOperatorOfResolutionModel, ServiceSingleResultOfGuid } from '../types'

export type GetResolutionModelsResultType = {
    resolutionModels: {
        get?: Maybe<ServiceSingleResultOfResolutionModel>
    }
}

export type GetResolutionModelsBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getResolutionModels ${args} {
    resolutionModels {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfResolutionModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstResolutionModelsResultType = {
    resolutionModels: {
        first?: Maybe<ServiceSingleResultOfResolutionModel>
    }
}

export type FirstResolutionModelsBaseVariables = {
	filter: Maybe<FilterOfResolutionModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfResolutionModel>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfResolutionModel = null, $options: QueryContextOfResolutionModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstResolutionModels ${args} {
    resolutionModels {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfResolutionModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountResolutionModelsResultType = {
    resolutionModels: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountResolutionModelsBaseVariables = {
	filter: Maybe<FilterOfResolutionModel>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfResolutionModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countResolutionModels ${args} {
    resolutionModels {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindResolutionModelsResultType = {
    resolutionModels: {
        find?: Maybe<ServiceListResultOfResolutionModel>
    }
}

export type FindResolutionModelsBaseVariables = {
	options: Maybe<QueryContextOfResolutionModel>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfResolutionModel>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfResolutionModel = null, $filter: FilterOfResolutionModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findResolutionModels ${args} {
    resolutionModels {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfResolutionModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchResolutionModelsResultType = {
    resolutionModels: {
        search?: Maybe<ServiceListResultOfResolutionModel>
    }
}

export type SearchResolutionModelsBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfResolutionModel>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfResolutionModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchResolutionModels ${args} {
    resolutionModels {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfResolutionModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaResolutionModelsResultType = {
    resolutionModels: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaResolutionModelsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaResolutionModels ${args} {
    resolutionModels {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataResolutionModelsResultType = {
    resolutionModels: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataResolutionModelsBaseVariables = {
	filter: Maybe<FilterOfResolutionModel>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfResolutionModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataResolutionModels ${args} {
    resolutionModels {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryResolutionModelsResultType = {
    resolutionModels: {
        customQuery?: Maybe<ServiceListResultOfResolutionModel>
    }
}

export type CustomQueryResolutionModelsBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfResolutionModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfResolutionModel>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfResolutionModel = null, $options: QueryContextOfResolutionModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryResolutionModels ${args} {
    resolutionModels {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfResolutionModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdResolutionModelsResultType = {
    resolutionModels: {
        customQueryId?: Maybe<ServiceListResultOfResolutionModel>
    }
}

export type CustomQueryIdResolutionModelsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfResolutionModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfResolutionModel>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfResolutionModel = null, $options: QueryContextOfResolutionModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdResolutionModels ${args} {
    resolutionModels {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfResolutionModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedSecurityGroupsResolutionModelsResultType = {
    resolutionModels: {
        findUnassociatedSecurityGroups?: Maybe<ServiceListResultOfSecurityGroup>
    }
}

export type FindUnassociatedSecurityGroupsResolutionModelsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfSecurityGroup>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSecurityGroup>, /** Les options de requêtage à appliquer. */
}

/** Récupère les profils non liés de l'entité. */
@Injectable({providedIn: 'root'})
export class FindUnassociatedSecurityGroupsResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfSecurityGroup = null, $options: QueryContextOfSecurityGroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedSecurityGroupsResolutionModels ${args} {
    resolutionModels {
        findUnassociatedSecurityGroups(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedSecurityGroupsResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedSecurityGroupsResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSecurityGroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAssociatedOperatorTeamSecurityGroupsResolutionModelsResultType = {
    resolutionModels: {
        findAssociatedOperatorTeamSecurityGroups?: Maybe<ServiceListResultOfOperatorTeam>
    }
}

export type FindAssociatedOperatorTeamSecurityGroupsResolutionModelsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfSecurityGroup>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSecurityGroup>, /** Les options de requêtage à appliquer. */
}

/** Récupère les equipes d'operateur via les profils de l'entité. */
@Injectable({providedIn: 'root'})
export class FindAssociatedOperatorTeamSecurityGroupsResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfSecurityGroup = null, $options: QueryContextOfSecurityGroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAssociatedOperatorTeamSecurityGroupsResolutionModels ${args} {
    resolutionModels {
        findAssociatedOperatorTeamSecurityGroups(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAssociatedOperatorTeamSecurityGroupsResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAssociatedOperatorTeamSecurityGroupsResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOperatorTeam", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindFilesResolutionModelsResultType = {
    resolutionModels: {
        findFiles?: Maybe<ServiceListResultOfFileModel>
    }
}

export type FindFilesResolutionModelsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfFileModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfFileModel>, /** Les options de requêtage à appliquer. */
}

/** Récupère les fichiers liés. */
@Injectable({providedIn: 'root'})
export class FindFilesResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfFileModel = null, $options: QueryContextOfFileModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findFilesResolutionModels ${args} {
    resolutionModels {
        findFiles(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindFilesResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindFilesResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfFileModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAttachmentFilesResolutionModelsResultType = {
    resolutionModels: {
        findAttachmentFiles?: Maybe<ServiceListResultOfFileModel>
    }
}

export type FindAttachmentFilesResolutionModelsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfFileModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfFileModel>, /** Les options de requêtage à appliquer. */
}

/** Récupère les fichiers attachés liés. */
@Injectable({providedIn: 'root'})
export class FindAttachmentFilesResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfFileModel = null, $options: QueryContextOfFileModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAttachmentFilesResolutionModels ${args} {
    resolutionModels {
        findAttachmentFiles(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAttachmentFilesResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAttachmentFilesResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfFileModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindHelpDeskUserAttachmentFilesResolutionModelsResultType = {
    resolutionModels: {
        findHelpDeskUserAttachmentFiles?: Maybe<ServiceListResultOfFileModel>
    }
}

export type FindHelpDeskUserAttachmentFilesResolutionModelsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfFileModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfFileModel>, /** Les options de requêtage à appliquer. */
}

/** Récupère les fichiers attachés liés visible pour les users help desk. */
@Injectable({providedIn: 'root'})
export class FindHelpDeskUserAttachmentFilesResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfFileModel = null, $options: QueryContextOfFileModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findHelpDeskUserAttachmentFilesResolutionModels ${args} {
    resolutionModels {
        findHelpDeskUserAttachmentFiles(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindHelpDeskUserAttachmentFilesResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindHelpDeskUserAttachmentFilesResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfFileModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedResolutionModelsResultType = {
    resolutionModels: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedResolutionModelsBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedResolutionModels ${args} {
    resolutionModels {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindByTeamOperatorLinkedResolutionModelsResultType = {
    resolutionModels: {
        findByTeamOperatorLinked?: Maybe<ServiceListResultOfResolutionModel>
    }
}

export type FindByTeamOperatorLinkedResolutionModelsBaseVariables = {
	ticketCategoriesIds: Array<Maybe<string>>, /**  */
	filter: Maybe<FilterOfResolutionModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfResolutionModel>, /** Les options de requêtage à appliquer. */
}

/** Permet de recuperer les modèles d'intervention associé a certaines équipes opérateurs. */
@Injectable({providedIn: 'root'})
export class FindByTeamOperatorLinkedResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ticketCategoriesIds: [Uuid] = null, $filter: FilterOfResolutionModel = null, $options: QueryContextOfResolutionModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findByTeamOperatorLinkedResolutionModels ${args} {
    resolutionModels {
        findByTeamOperatorLinked(ticketCategoriesIds: $ticketCategoriesIds, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindByTeamOperatorLinkedResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindByTeamOperatorLinkedResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfResolutionModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistResolutionModelsResultType = {
    resolutionModels: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistResolutionModelsBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existResolutionModels ${args} {
    resolutionModels {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedResolutionCategoriesResolutionModelsResultType = {
    resolutionModels: {
        findUnassociatedResolutionCategories?: Maybe<ServiceListResultOfResolutionCategory>
    }
}

export type FindUnassociatedResolutionCategoriesResolutionModelsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfResolutionCategory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfResolutionCategory>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedResolutionCategoriesResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfResolutionCategory = null, $options: QueryContextOfResolutionCategory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedResolutionCategoriesResolutionModels ${args} {
    resolutionModels {
        findUnassociatedResolutionCategories(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedResolutionCategoriesResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedResolutionCategoriesResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfResolutionCategory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertResolutionModelsResultType = {
    resolutionModels: {
        insert?: Maybe<ServiceSingleResultOfResolutionModel>
    }
}

export type InsertResolutionModelsBaseVariables = {
	entity: ResolutionModelInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: ResolutionModelInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertResolutionModels ${args} {
    resolutionModels {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfResolutionModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateResolutionModelsResultType = {
    resolutionModels: {
        update?: Maybe<ServiceSingleResultOfResolutionModel>
    }
}

export type UpdateResolutionModelsBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfResolutionModel>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfResolutionModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateResolutionModels ${args} {
    resolutionModels {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfResolutionModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataResolutionModelsResultType = {
    resolutionModels: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataResolutionModelsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataResolutionModels ${args} {
    resolutionModels {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyResolutionModelsResultType = {
    resolutionModels: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyResolutionModelsBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfResolutionModel>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfResolutionModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyResolutionModels ${args} {
    resolutionModels {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteResolutionModelsResultType = {
    resolutionModels: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteResolutionModelsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteResolutionModels ${args} {
    resolutionModels {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddSecurityGroupResolutionModelsResultType = {
    resolutionModels: {
        addSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddSecurityGroupResolutionModelsBaseVariables = {
	id: string, /**  */
	securityGroupIds: Array<string>, /**  */
	isNew: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddSecurityGroupResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $securityGroupIds: [Uuid!], $isNew: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addSecurityGroupResolutionModels ${args} {
    resolutionModels {
        addSecurityGroup(id: $id, securityGroupIds: $securityGroupIds, isNew: $isNew) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddSecurityGroupResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddSecurityGroupResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveSecurityGroupResolutionModelsResultType = {
    resolutionModels: {
        removeSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveSecurityGroupResolutionModelsBaseVariables = {
	id: string, /**  */
	securityGroupIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveSecurityGroupResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $securityGroupIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeSecurityGroupResolutionModels ${args} {
    resolutionModels {
        removeSecurityGroup(id: $id, securityGroupIds: $securityGroupIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveSecurityGroupResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveSecurityGroupResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddOperatorTeamSecurityGroupResolutionModelsResultType = {
    resolutionModels: {
        addOperatorTeamSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddOperatorTeamSecurityGroupResolutionModelsBaseVariables = {
	id: string, /**  */
	operatorTeamIds: Array<string>, /**  */
	isNew: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddOperatorTeamSecurityGroupResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $operatorTeamIds: [Uuid!], $isNew: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addOperatorTeamSecurityGroupResolutionModels ${args} {
    resolutionModels {
        addOperatorTeamSecurityGroup(id: $id, operatorTeamIds: $operatorTeamIds, isNew: $isNew) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddOperatorTeamSecurityGroupResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddOperatorTeamSecurityGroupResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveOperatorTeamSecurityGroupResolutionModelsResultType = {
    resolutionModels: {
        removeOperatorTeamSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveOperatorTeamSecurityGroupResolutionModelsBaseVariables = {
	id: string, /**  */
	operatorTeamIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveOperatorTeamSecurityGroupResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $operatorTeamIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeOperatorTeamSecurityGroupResolutionModels ${args} {
    resolutionModels {
        removeOperatorTeamSecurityGroup(id: $id, operatorTeamIds: $operatorTeamIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveOperatorTeamSecurityGroupResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveOperatorTeamSecurityGroupResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddFilesResolutionModelsResultType = {
    resolutionModels: {
        addFiles?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddFilesResolutionModelsBaseVariables = {
	id: string, /**  */
	fileIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddFilesResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $fileIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addFilesResolutionModels ${args} {
    resolutionModels {
        addFiles(id: $id, fileIds: $fileIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddFilesResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddFilesResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveFilesResolutionModelsResultType = {
    resolutionModels: {
        removeFiles?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveFilesResolutionModelsBaseVariables = {
	id: string, /**  */
	fileIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveFilesResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $fileIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeFilesResolutionModels ${args} {
    resolutionModels {
        removeFiles(id: $id, fileIds: $fileIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveFilesResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveFilesResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UploadChunkFileResolutionModelsResultType = {
    resolutionModels: {
        uploadChunkFile?: Maybe<ServiceSingleResultOfGuid>
    }
}

export type UploadChunkFileResolutionModelsBaseVariables = {
	name: Maybe<string>, /**  */
	size: number, /**  */
	type: Maybe<string>, /**  */
	chunks: Maybe<string>, /**  */
	indexChunk: number, /**  */
	lastChunk: boolean, /**  */
	fileId: Maybe<string>, /**  */
	entityId: Maybe<string>, /**  */
	visibleByHelpDeskUser: boolean, /**  */
	outgoingEmails: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class UploadChunkFileResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$name: String = null, $size: Int!, $type: String = null, $chunks: String = null, $indexChunk: Int!, $lastChunk: Boolean!, $fileId: Uuid = null, $entityId: Uuid = null, $visibleByHelpDeskUser: Boolean!, $outgoingEmails: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation uploadChunkFileResolutionModels ${args} {
    resolutionModels {
        uploadChunkFile(name: $name, size: $size, type: $type, chunks: $chunks, indexChunk: $indexChunk, lastChunk: $lastChunk, fileId: $fileId, entityId: $entityId, visibleByHelpDeskUser: $visibleByHelpDeskUser, outgoingEmails: $outgoingEmails) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UploadChunkFileResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UploadChunkFileResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfGuid", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddResolutionCategoriesResolutionModelsResultType = {
    resolutionModels: {
        addResolutionCategories?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddResolutionCategoriesResolutionModelsBaseVariables = {
	id: string, /**  */
	resolutionCategoryIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddResolutionCategoriesResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $resolutionCategoryIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addResolutionCategoriesResolutionModels ${args} {
    resolutionModels {
        addResolutionCategories(id: $id, resolutionCategoryIds: $resolutionCategoryIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddResolutionCategoriesResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddResolutionCategoriesResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveResolutionCategoriesResolutionModelsResultType = {
    resolutionModels: {
        removeResolutionCategories?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveResolutionCategoriesResolutionModelsBaseVariables = {
	id: string, /**  */
	resolutionCategoryIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveResolutionCategoriesResolutionModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $resolutionCategoryIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeResolutionCategoriesResolutionModels ${args} {
    resolutionModels {
        removeResolutionCategories(id: $id, resolutionCategoryIds: $resolutionCategoryIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveResolutionCategoriesResolutionModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveResolutionCategoriesResolutionModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
