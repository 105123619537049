import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetLoansBaseVariables, FirstLoansBaseVariables, CountLoansBaseVariables, FindLoansBaseVariables, SearchLoansBaseVariables, ExportSchemaLoansBaseVariables, ExportDataLoansBaseVariables, CustomQueryLoansBaseVariables, CustomQueryIdLoansBaseVariables, FindFilesLoansBaseVariables, FindAttachmentFilesLoansBaseVariables, FindHelpDeskUserAttachmentFilesLoansBaseVariables, UsedLoansBaseVariables, FindLoanPlanificationsLoansBaseVariables, FindMyLoansLoansBaseVariables, FindAllAssociatedAssetsLoansBaseVariables, ExistLoansBaseVariables, FindUnassociatedAssetsLoansBaseVariables, FindUnassociatedNextAssetLoansLoansBaseVariables, FindUnassociatedAlertsLoansBaseVariables, FindUnassociatedLocationsLoansBaseVariables, FindUnassociatedNextLocationsLoansBaseVariables, InsertLoansBaseVariables, UpdateLoansBaseVariables, ImportDataLoansBaseVariables, UpdateManyLoansBaseVariables, DeleteLoansBaseVariables, AddFilesLoansBaseVariables, RemoveFilesLoansBaseVariables, UploadChunkFileLoansBaseVariables, AddCheckedAssetsLoansBaseVariables, AddCheckedLocationsLoansBaseVariables, PrintLoansLoansBaseVariables, AddAssetsLoansBaseVariables, RemoveAssetsLoansBaseVariables, AddLocationsLoansBaseVariables, RemoveLocationsLoansBaseVariables, AddAlertsLoansBaseVariables, RemoveAlertsLoansBaseVariables, AddNextAssetLoansLoansBaseVariables, RemoveNextAssetLoansLoansBaseVariables, AddNextLocationsLoansBaseVariables, RemoveNextLocationsLoansBaseVariables } from '../gqls'
import { GetLoansDocument, FirstLoansDocument, CountLoansDocument, FindLoansDocument, SearchLoansDocument, ExportSchemaLoansDocument, ExportDataLoansDocument, CustomQueryLoansDocument, CustomQueryIdLoansDocument, FindFilesLoansDocument, FindAttachmentFilesLoansDocument, FindHelpDeskUserAttachmentFilesLoansDocument, UsedLoansDocument, FindLoanPlanificationsLoansDocument, FindMyLoansLoansDocument, FindAllAssociatedAssetsLoansDocument, ExistLoansDocument, FindUnassociatedAssetsLoansDocument, FindUnassociatedNextAssetLoansLoansDocument, FindUnassociatedAlertsLoansDocument, FindUnassociatedLocationsLoansDocument, FindUnassociatedNextLocationsLoansDocument, InsertLoansDocument, UpdateLoansDocument, ImportDataLoansDocument, UpdateManyLoansDocument, DeleteLoansDocument, AddFilesLoansDocument, RemoveFilesLoansDocument, UploadChunkFileLoansDocument, AddCheckedAssetsLoansDocument, AddCheckedLocationsLoansDocument, PrintLoansLoansDocument, AddAssetsLoansDocument, RemoveAssetsLoansDocument, AddLocationsLoansDocument, RemoveLocationsLoansDocument, AddAlertsLoansDocument, RemoveAlertsLoansDocument, AddNextAssetLoansLoansDocument, RemoveNextAssetLoansLoansDocument, AddNextLocationsLoansDocument, RemoveNextLocationsLoansDocument } from '../gqls'
import { ServiceSingleResultOfLoan, QueryContextOfLoan, FilterOfLoan, ServiceSingleResultOfInt64, ServiceListResultOfLoan, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceListResultOfFileModel, QueryContextOfFileModel, FilterOfFileModel, ServiceSingleResultOfBoolean, ServiceListResultOfLoanAppointment, QueryContextOfLoanAppointment, FilterOfLoanAppointment, ServiceListResultOfAsset, QueryContextOfAsset, FilterOfAsset, QueryContextOfAlert, FilterOfAlert, ServiceListResultOfAlert, QueryContextOfLocation, FilterOfLocation, ServiceListResultOfLocation, LoanInput, FieldUpdateOperatorOfLoan, ServiceSingleResultOfGuid } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class LoanBaseService {
    
public modelName = 'loan';
public modelPluralName = 'loans';

	private getLoansQuery: GetLoansDocument;
	private firstLoansQuery: FirstLoansDocument;
	private countLoansQuery: CountLoansDocument;
	private findLoansQuery: FindLoansDocument;
	private searchLoansQuery: SearchLoansDocument;
	private exportSchemaLoansQuery: ExportSchemaLoansDocument;
	private exportDataLoansQuery: ExportDataLoansDocument;
	private customQueryLoansQuery: CustomQueryLoansDocument;
	private customQueryIdLoansQuery: CustomQueryIdLoansDocument;
	private findFilesLoansQuery: FindFilesLoansDocument;
	private findAttachmentFilesLoansQuery: FindAttachmentFilesLoansDocument;
	private findHelpDeskUserAttachmentFilesLoansQuery: FindHelpDeskUserAttachmentFilesLoansDocument;
	private usedLoansQuery: UsedLoansDocument;
	private findLoanPlanificationsLoansQuery: FindLoanPlanificationsLoansDocument;
	private findMyLoansLoansQuery: FindMyLoansLoansDocument;
	private findAllAssociatedAssetsLoansQuery: FindAllAssociatedAssetsLoansDocument;
	private existLoansQuery: ExistLoansDocument;
	private findUnassociatedAssetsLoansQuery: FindUnassociatedAssetsLoansDocument;
	private findUnassociatedNextAssetLoansLoansQuery: FindUnassociatedNextAssetLoansLoansDocument;
	private findUnassociatedAlertsLoansQuery: FindUnassociatedAlertsLoansDocument;
	private findUnassociatedLocationsLoansQuery: FindUnassociatedLocationsLoansDocument;
	private findUnassociatedNextLocationsLoansQuery: FindUnassociatedNextLocationsLoansDocument;
	private insertLoansMutation: InsertLoansDocument;
	private updateLoansMutation: UpdateLoansDocument;
	private importDataLoansMutation: ImportDataLoansDocument;
	private updateManyLoansMutation: UpdateManyLoansDocument;
	private deleteLoansMutation: DeleteLoansDocument;
	private addFilesLoansMutation: AddFilesLoansDocument;
	private removeFilesLoansMutation: RemoveFilesLoansDocument;
	private uploadChunkFileLoansMutation: UploadChunkFileLoansDocument;
	private addCheckedAssetsLoansMutation: AddCheckedAssetsLoansDocument;
	private addCheckedLocationsLoansMutation: AddCheckedLocationsLoansDocument;
	private printLoansLoansMutation: PrintLoansLoansDocument;
	private addAssetsLoansMutation: AddAssetsLoansDocument;
	private removeAssetsLoansMutation: RemoveAssetsLoansDocument;
	private addLocationsLoansMutation: AddLocationsLoansDocument;
	private removeLocationsLoansMutation: RemoveLocationsLoansDocument;
	private addAlertsLoansMutation: AddAlertsLoansDocument;
	private removeAlertsLoansMutation: RemoveAlertsLoansDocument;
	private addNextAssetLoansLoansMutation: AddNextAssetLoansLoansDocument;
	private removeNextAssetLoansLoansMutation: RemoveNextAssetLoansLoansDocument;
	private addNextLocationsLoansMutation: AddNextLocationsLoansDocument;
	private removeNextLocationsLoansMutation: RemoveNextLocationsLoansDocument;

	constructor(private injector: Injector) {
		this.getLoansQuery = this.injector.get(GetLoansDocument);
		this.firstLoansQuery = this.injector.get(FirstLoansDocument);
		this.countLoansQuery = this.injector.get(CountLoansDocument);
		this.findLoansQuery = this.injector.get(FindLoansDocument);
		this.searchLoansQuery = this.injector.get(SearchLoansDocument);
		this.exportSchemaLoansQuery = this.injector.get(ExportSchemaLoansDocument);
		this.exportDataLoansQuery = this.injector.get(ExportDataLoansDocument);
		this.customQueryLoansQuery = this.injector.get(CustomQueryLoansDocument);
		this.customQueryIdLoansQuery = this.injector.get(CustomQueryIdLoansDocument);
		this.findFilesLoansQuery = this.injector.get(FindFilesLoansDocument);
		this.findAttachmentFilesLoansQuery = this.injector.get(FindAttachmentFilesLoansDocument);
		this.findHelpDeskUserAttachmentFilesLoansQuery = this.injector.get(FindHelpDeskUserAttachmentFilesLoansDocument);
		this.usedLoansQuery = this.injector.get(UsedLoansDocument);
		this.findLoanPlanificationsLoansQuery = this.injector.get(FindLoanPlanificationsLoansDocument);
		this.findMyLoansLoansQuery = this.injector.get(FindMyLoansLoansDocument);
		this.findAllAssociatedAssetsLoansQuery = this.injector.get(FindAllAssociatedAssetsLoansDocument);
		this.existLoansQuery = this.injector.get(ExistLoansDocument);
		this.findUnassociatedAssetsLoansQuery = this.injector.get(FindUnassociatedAssetsLoansDocument);
		this.findUnassociatedNextAssetLoansLoansQuery = this.injector.get(FindUnassociatedNextAssetLoansLoansDocument);
		this.findUnassociatedAlertsLoansQuery = this.injector.get(FindUnassociatedAlertsLoansDocument);
		this.findUnassociatedLocationsLoansQuery = this.injector.get(FindUnassociatedLocationsLoansDocument);
		this.findUnassociatedNextLocationsLoansQuery = this.injector.get(FindUnassociatedNextLocationsLoansDocument);
		this.insertLoansMutation = this.injector.get(InsertLoansDocument);
		this.updateLoansMutation = this.injector.get(UpdateLoansDocument);
		this.importDataLoansMutation = this.injector.get(ImportDataLoansDocument);
		this.updateManyLoansMutation = this.injector.get(UpdateManyLoansDocument);
		this.deleteLoansMutation = this.injector.get(DeleteLoansDocument);
		this.addFilesLoansMutation = this.injector.get(AddFilesLoansDocument);
		this.removeFilesLoansMutation = this.injector.get(RemoveFilesLoansDocument);
		this.uploadChunkFileLoansMutation = this.injector.get(UploadChunkFileLoansDocument);
		this.addCheckedAssetsLoansMutation = this.injector.get(AddCheckedAssetsLoansDocument);
		this.addCheckedLocationsLoansMutation = this.injector.get(AddCheckedLocationsLoansDocument);
		this.printLoansLoansMutation = this.injector.get(PrintLoansLoansDocument);
		this.addAssetsLoansMutation = this.injector.get(AddAssetsLoansDocument);
		this.removeAssetsLoansMutation = this.injector.get(RemoveAssetsLoansDocument);
		this.addLocationsLoansMutation = this.injector.get(AddLocationsLoansDocument);
		this.removeLocationsLoansMutation = this.injector.get(RemoveLocationsLoansDocument);
		this.addAlertsLoansMutation = this.injector.get(AddAlertsLoansDocument);
		this.removeAlertsLoansMutation = this.injector.get(RemoveAlertsLoansDocument);
		this.addNextAssetLoansLoansMutation = this.injector.get(AddNextAssetLoansLoansDocument);
		this.removeNextAssetLoansLoansMutation = this.injector.get(RemoveNextAssetLoansLoansDocument);
		this.addNextLocationsLoansMutation = this.injector.get(AddNextLocationsLoansDocument);
		this.removeNextLocationsLoansMutation = this.injector.get(RemoveNextLocationsLoansDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLoan> {
    
    		let variables: GetLoansBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loans.get));
            }
            else{
                let result:ServiceSingleResultOfLoan={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLoan,
		@Args('filter?') filter?: FilterOfLoan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLoan> {
    
    		let variables: FirstLoansBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loans.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLoan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountLoansBaseVariables = {
    			filter: filter
    		}
    				return this.countLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loans.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLoan,
		@Args('filter?') filter?: FilterOfLoan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLoan> {
    
    		let variables: FindLoansBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loans.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfLoan,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLoan> {
    
    		let variables: SearchLoansBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loans.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaLoansBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loans.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLoan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataLoansBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loans.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfLoan,
		@Args('filter?') filter?: FilterOfLoan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLoan> {
    
    		let variables: CustomQueryLoansBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loans.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfLoan,
		@Args('filter?') filter?: FilterOfLoan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLoan> {
    
    		let variables: CustomQueryIdLoansBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loans.customQueryId));
    	}

    	/** Récupère les fichiers liés. */
    	@InjectArgs
    	public findFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindFilesLoansBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findFilesLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loans.findFiles));
    	}

    	/** Récupère les fichiers attachés liés. */
    	@InjectArgs
    	public findAttachmentFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindAttachmentFilesLoansBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAttachmentFilesLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loans.findAttachmentFiles));
    	}

    	/** Récupère les fichiers attachés liés visible pour les users help desk. */
    	@InjectArgs
    	public findHelpDeskUserAttachmentFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindHelpDeskUserAttachmentFilesLoansBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findHelpDeskUserAttachmentFilesLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loans.findHelpDeskUserAttachmentFiles));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedLoansBaseVariables = {
    			ids: ids
    		}
    				return this.usedLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loans.used));
    	}

    	/**  */
    	@InjectArgs
    	public findLoanPlanifications(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLoanAppointment,
		@Args('filter?') filter?: FilterOfLoanAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLoanAppointment> {
    
    		let variables: FindLoanPlanificationsLoansBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findLoanPlanificationsLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loans.findLoanPlanifications));
    	}

    	/**  */
    	@InjectArgs
    	public findMyLoans(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLoan,
		@Args('filter?') filter?: FilterOfLoan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLoan> {
    
    		let variables: FindMyLoansLoansBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findMyLoansLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loans.findMyLoans));
    	}

    	/**  */
    	@InjectArgs
    	public findAllAssociatedAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindAllAssociatedAssetsLoansBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAllAssociatedAssetsLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loans.findAllAssociatedAssets));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistLoansBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loans.exist));
    	}

	@InjectArgs
	public findAssociatedAssets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAsset> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('assets', fields, null, [
			GqlSubFieldArg.create('filterOfAssets', 'filter'),
			GqlSubFieldArg.create('optionsOfAssets', 'options'),
		]),
		])
		extendedVariables['filterOfAssets'] = filter;
		extendedVariables['optionsOfAssets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.assets));
            }
            else{
                let result: ServiceListResultOfAsset = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindUnassociatedAssetsLoansBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAssetsLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loans.findUnassociatedAssets));
    	}

	@InjectArgs
	public findAssociatedNextAssetLoans(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAsset> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('nextAssetLoans', fields, null, [
			GqlSubFieldArg.create('filterOfNextAssetLoans', 'filter'),
			GqlSubFieldArg.create('optionsOfNextAssetLoans', 'options'),
		]),
		])
		extendedVariables['filterOfNextAssetLoans'] = filter;
		extendedVariables['optionsOfNextAssetLoans'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.nextAssetLoans));
            }
            else{
                let result: ServiceListResultOfAsset = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedNextAssetLoans(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindUnassociatedNextAssetLoansLoansBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedNextAssetLoansLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loans.findUnassociatedNextAssetLoans));
    	}

	@InjectArgs
	public findAssociatedAlerts(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAlert> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('alerts', fields, null, [
			GqlSubFieldArg.create('filterOfAlerts', 'filter'),
			GqlSubFieldArg.create('optionsOfAlerts', 'options'),
		]),
		])
		extendedVariables['filterOfAlerts'] = filter;
		extendedVariables['optionsOfAlerts'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.alerts));
            }
            else{
                let result: ServiceListResultOfAlert = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlert> {
    
    		let variables: FindUnassociatedAlertsLoansBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAlertsLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loans.findUnassociatedAlerts));
    	}

	@InjectArgs
	public findAssociatedLocations(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfLocation,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfLocation,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfLocation> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('locations', fields, null, [
			GqlSubFieldArg.create('filterOfLocations', 'filter'),
			GqlSubFieldArg.create('optionsOfLocations', 'options'),
		]),
		])
		extendedVariables['filterOfLocations'] = filter;
		extendedVariables['optionsOfLocations'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.locations));
            }
            else{
                let result: ServiceListResultOfLocation = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedLocations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLocation,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfLocation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLocation> {
    
    		let variables: FindUnassociatedLocationsLoansBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedLocationsLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loans.findUnassociatedLocations));
    	}

	@InjectArgs
	public findAssociatedNextLocations(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfLocation,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfLocation,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfLocation> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('nextLocations', fields, null, [
			GqlSubFieldArg.create('filterOfNextLocations', 'filter'),
			GqlSubFieldArg.create('optionsOfNextLocations', 'options'),
		]),
		])
		extendedVariables['filterOfNextLocations'] = filter;
		extendedVariables['optionsOfNextLocations'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.nextLocations));
            }
            else{
                let result: ServiceListResultOfLocation = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedNextLocations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLocation,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfLocation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLocation> {
    
    		let variables: FindUnassociatedNextLocationsLoansBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedNextLocationsLoansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loans.findUnassociatedNextLocations));
    	}
    
    	/**  */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('force?') force?: boolean,
		@Args('entity?') entity?: LoanInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLoan> {
    
    		let variables: InsertLoansBaseVariables = {
    			entity: entity,
			force: force
    		}
    				return this.insertLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loans.insert));
    	}

    	/**  */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('force?') force?: boolean,
		@Args('entry?') entry?: FieldUpdateOperatorOfLoan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLoan> {
    
    		let variables: UpdateLoansBaseVariables = {
    			id: id,
			force: force,
			entry: entry
    		}
    				return this.updateLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loans.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataLoansBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loans.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfLoan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyLoansBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loans.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteLoansBaseVariables = {
    			ids: ids
    		}
    				return this.deleteLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loans.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileIds') fileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFilesLoansBaseVariables = {
    			id: id,
			fileIds: fileIds
    		}
    				return this.addFilesLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loans.addFiles));
    	}

    	/**  */
    	@InjectArgs
    	public removeFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileIds') fileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFilesLoansBaseVariables = {
    			id: id,
			fileIds: fileIds
    		}
    				return this.removeFilesLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loans.removeFiles));
    	}

    	/**  */
    	@InjectArgs
    	public uploadChunkFile(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('visibleByHelpDeskUser') visibleByHelpDeskUser: boolean,
		@Args('size') size: number,
		@Args('outgoingEmails') outgoingEmails: boolean,
		@Args('lastChunk') lastChunk: boolean,
		@Args('indexChunk') indexChunk: number,
		@Args('type?') type?: string,
		@Args('name?') name?: string,
		@Args('fileId?') fileId?: string,
		@Args('entityId?') entityId?: string,
		@Args('chunks?') chunks?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: UploadChunkFileLoansBaseVariables = {
    			name: name,
			size: size,
			type: type,
			chunks: chunks,
			indexChunk: indexChunk,
			lastChunk: lastChunk,
			fileId: fileId,
			entityId: entityId,
			visibleByHelpDeskUser: visibleByHelpDeskUser,
			outgoingEmails: outgoingEmails
    		}
    				return this.uploadChunkFileLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loans.uploadChunkFile));
    	}

    	/**  */
    	@InjectArgs
    	public addCheckedAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('force?') force?: boolean,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddCheckedAssetsLoansBaseVariables = {
    			id: id,
			force: force,
			assetIds: assetIds
    		}
    				return this.addCheckedAssetsLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loans.addCheckedAssets));
    	}

    	/**  */
    	@InjectArgs
    	public addCheckedLocations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('locationIds') locationIds: Array<string>,
		@Args('id') id: string,
		@Args('force?') force?: boolean,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddCheckedLocationsLoansBaseVariables = {
    			id: id,
			force: force,
			locationIds: locationIds
    		}
    				return this.addCheckedLocationsLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loans.addCheckedLocations));
    	}

    	/**  */
    	@InjectArgs
    	public printLoans(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('loanVoucherId?') loanVoucherId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: PrintLoansLoansBaseVariables = {
    			ids: ids,
			loanVoucherId: loanVoucherId
    		}
    				return this.printLoansLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loans.printLoans));
    	}

    	/**  */
    	@InjectArgs
    	public addAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAssetsLoansBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.addAssetsLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loans.addAssets));
    	}

    	/**  */
    	@InjectArgs
    	public removeAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveAssetsLoansBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.removeAssetsLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loans.removeAssets));
    	}

    	/**  */
    	@InjectArgs
    	public addLocations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('locationIds') locationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddLocationsLoansBaseVariables = {
    			id: id,
			locationIds: locationIds
    		}
    				return this.addLocationsLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loans.addLocations));
    	}

    	/**  */
    	@InjectArgs
    	public removeLocations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('locationIds') locationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveLocationsLoansBaseVariables = {
    			id: id,
			locationIds: locationIds
    		}
    				return this.removeLocationsLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loans.removeLocations));
    	}

    	/**  */
    	@InjectArgs
    	public addAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('alertIds') alertIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAlertsLoansBaseVariables = {
    			id: id,
			alertIds: alertIds
    		}
    				return this.addAlertsLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loans.addAlerts));
    	}

    	/**  */
    	@InjectArgs
    	public removeAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('alertIds') alertIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveAlertsLoansBaseVariables = {
    			alertIds: alertIds
    		}
    				return this.removeAlertsLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loans.removeAlerts));
    	}

    	/**  */
    	@InjectArgs
    	public addNextAssetLoans(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddNextAssetLoansLoansBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.addNextAssetLoansLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loans.addNextAssetLoans));
    	}

    	/**  */
    	@InjectArgs
    	public removeNextAssetLoans(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveNextAssetLoansLoansBaseVariables = {
    			assetIds: assetIds
    		}
    				return this.removeNextAssetLoansLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loans.removeNextAssetLoans));
    	}

    	/**  */
    	@InjectArgs
    	public addNextLocations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('locationIds') locationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddNextLocationsLoansBaseVariables = {
    			id: id,
			locationIds: locationIds
    		}
    				return this.addNextLocationsLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loans.addNextLocations));
    	}

    	/**  */
    	@InjectArgs
    	public removeNextLocations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('locationIds') locationIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveNextLocationsLoansBaseVariables = {
    			locationIds: locationIds
    		}
    				return this.removeNextLocationsLoansMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loans.removeNextLocations));
    	}
    
}