import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  DefaultUrlSerializer,
  Router,
  UrlTree,
} from '@angular/router';
import { AuthentificationService } from '@clarilog/core/services/authentification/authentification.service';
import { EnvironmentService } from '@clarilog/core/services/environment/environment.service';
import { Observable, ReplaySubject } from 'rxjs';
import { Locale, LocalizeService } from '../../modules/globalize/services';
import { CoreAuthorizationPolicyConfiguration } from '../authorization/authorization-policy-configuration.service';
import { AuthorizationCoreService } from '../authorization/authorization.service';
import { GqlField, GqlSubField } from '../graphql/generated-types/helpers';
import { MyOrganizationCoreService } from '../graphql/generated-types/services/my-organization.service';
import { OrganizationCoreService } from '../graphql/generated-types/services/organization.service';
import { UserCoreService } from '../graphql/generated-types/services/user.service';
import { CoreLicenseReader } from '../license-reader/license-reader';
import Globalize from 'globalize/dist/globalize';
import { GlobalizationService } from '@code-art-eg/angular-globalize';
import { DeviceDetectorService } from 'ngx-device-detector';
/**
 * Représente la classe d'initialisation de l'application.
 */
@Injectable({
  providedIn: 'root',
})
export class InitializationCoreService {
  private isLoadedSubject$ = new ReplaySubject<boolean>();
  private _startUrl: string;
  /** Obtient l'observable qui averti sur l'état du chargement */
  isLoaded$ = this.isLoadedSubject$.asObservable();
  isInitialize = false;
  isMobile = false;

  constructor(
    private authentificationService: AuthentificationService,
    private authorizationService: AuthorizationCoreService,
    private localizeService: LocalizeService,
    private userService: UserCoreService,
    private authorizationPolicyConfiguration: CoreAuthorizationPolicyConfiguration,
    private router: Router,
    private route: ActivatedRoute,
    private envService: EnvironmentService,
    private licenseReader: CoreLicenseReader,
    private myOrganizationService: MyOrganizationCoreService,
    private deviceService: DeviceDetectorService,
  ) {}
  setStartUrl(url: string) {
    this._startUrl = url;
  }
  /** Permet d'initialiser l'application.
   *  Ne doit être appelé qu'une seule fois. */
  initialize(): void {
    if (this.isInitialize === false) {
      this.isInitialize = true;
      this.initializeAuthentification().subscribe(() => {
        this.authorizationPolicyConfiguration.initialize();

        this.initializeRouter().subscribe((result) => {
          this.initializeGlobalization(result).subscribe((result) => {
            this.isLoadedSubject$.next(result);
            this.isLoadedSubject$.complete();
          });
        });
      });
    } else {
      this.isLoadedSubject$.next(this.isInitialize);
      this.isLoadedSubject$.complete();
    }
  }

  private mainUri(changeURL = null) {
    const port = window.location.port;
    const hostName = window.location.hostname;
    let host = hostName.split('.');
    host[0] = port === '4200' ? 'main' : 'www';
    let mainUrl = window.location.protocol + '//' + host.join('.') + ':' + port;
    if (changeURL != null && changeURL.split('?invalidToken=').length > 0) {
      mainUrl = mainUrl + changeURL;
    } else if (changeURL != null) {
      mainUrl = mainUrl + changeURL;
    }
    return mainUrl;
  }

  /** Permet de vérifie si l'url est correct
   * exemple : https://clarilog.clarilog.one-clarilog.com
   */
  private checkUrl() {
    let env = this.envService.baseURL;
    // if (
    //   this.envService.isProduction &&
    //   window.location.origin.toLowerCase().indexOf('.poc.') > 0
    // ) {
    //   // Cas particulier du poc sur l'env production
    //   env = 'poc.' + env;
    // }

    // Regex de validation de l'url
    let regex = '([http]+[https])://([a-zA-Z-0-9]+(-[a-zA-Z0-9]+)*).' + env;

    let found = window.location.origin.match(regex);

    if (found == undefined) {
      // redirection vers main (organisation)
      let host = window.location.port === '4200' ? 'main' : 'www';

      location.href =
        window.location.protocol +
        '//' +
        host +
        '.' +
        this.envService.baseURL +
        ':' +
        window.location.port +
        '/?force=true';
    }
  }

  private initializeRouter(): Observable<boolean> {
    return new Observable((observer) => {
      const port = window.location.port;
      const hostName = window.location.hostname;

      this.checkUrl();

      const params = window.location.search;

      let localForce = localStorage.getItem('force');

      const hosts = hostName.split('.');

      /**
       * Récupération du route ciblé par l'Url
       */
      let host = decodeURIComponent(this._startUrl);
      if (host === undefined || host === null || host === '/') {
        // Revoir le redirect
        this.isMobile = this.deviceService.isMobile();
        if (this.isMobile === true) {
          host = 'organizations/my-organizations/mobile';
        } else {
          host = 'organizations';
        }
      }
      let redirectHost = host;
      let url = hosts[0];
      let invalidToken = window.location.search.includes('invalidToken');

      // Delete the persist token if inviteToken is include
      if (localStorage.invalid == 'true') {
        localStorage.removeItem('persist');
        localStorage.setItem('invalid', 'false');
      }
      // console.log(localForce);

      if ((hosts[0] == 'main' || hosts[0] == 'www') && localForce != 'true') {
        localStorage.removeItem('viewHelpMe');
        this.myOrganizationService
          .find([
            GqlSubField.create('data', [
              GqlField.create('name'),
              GqlField.create('url'),
              GqlField.create('id'),
              GqlField.create('blocked'),
              GqlSubField.create('licenseModel', [
                GqlSubField.create('data', [GqlField.create('expireAt')]),
              ]),
            ]),
            GqlField.create('totalCount'),
          ])
          .subscribe((x) => {
            if (x.data != null && x.data.length == 1) {
              let isExpired = 1;

              if (x.data[0].licenseModel.data.expireAt != undefined) {
                isExpired =
                  new Date(x.data[0].licenseModel.data.expireAt).getTime() -
                  new Date().getTime();
              }
              // Vérification si blocked
              if (
                x.data[0].blocked != undefined &&
                x.data[0].blocked === true
              ) {
                isExpired = 0;
              }

              if (
                x.data.length == 1 &&
                isExpired > 0 &&
                invalidToken == false
              ) {
                let url = x.data[0].url;

                this._startUrl = undefined;
                hosts[0] = url;

                let urlredirect =
                  window.location.protocol +
                  '//' +
                  hosts.join('.') +
                  ':' +
                  port;

                window.location.href = urlredirect;
              }
            }
          });
      }
      let getInviteToken = window.location.search;
      if (getInviteToken.trim() != null && getInviteToken.trim().length > 0) {
        if (getInviteToken.includes('inviteToken=')) {
          let inviteToken = getInviteToken.split('inviteToken=')[1].trim();

          localStorage.setItem('persist', inviteToken);
        }
      }

      let removeForce = localStorage.getItem('persist');
      if (removeForce != undefined && removeForce != null) {
        if (removeForce.includes('force=')) {
          let inviteToken = removeForce.split('/?')[0].trim();
          localStorage.setItem('persist', inviteToken);
        }
      }

      if (hosts[0] !== 'www' && hosts[0] !== 'main' && hosts.length >= 3) {
        if (
          host === undefined ||
          host === null ||
          host === '/' ||
          host === 'organizations'
        ) {
          // TODO A changer lorsque l'on pourra modifier les préférences de l'utilisateur ou rediriger sur Dashboard.
          redirectHost = '';
        }

        // Initialise les autorisations de l'utilisateur en fonction de l'organisation.

        // Attention, erreur sur le organizationByUrl car il n'est pas autorisé a y acceder
        //console.log(url, localStorage);
        this.authorizationService.initialize(url).subscribe(
          async (re) => {
            await this.licenseReader.initialize();
            if (re === false) {
              window.location.href = this.mainUri();
            } else {
              if (
                localStorage.persist != null &&
                localStorage.persist != undefined
              ) {
                await this.test();
              }
              this.authorizationPolicyConfiguration.initialize();
              let urlTree: UrlTree = new DefaultUrlSerializer().parse(
                redirectHost,
              );
              let url = redirectHost;
              if (redirectHost.indexOf('?') !== -1) {
                url = redirectHost.slice(0, redirectHost.indexOf('?'));
              }

              if (urlTree.queryParams.inviteToken != undefined) {
                urlTree.queryParams.inviteToken = undefined;
              }

              // Login
              /* if (localStorage.persist != undefined) {
                urlTree.queryParams.inviteToken = localStorage.persist;
              }*/

              this._startUrl = undefined;
              this.router.navigate([url], {
                queryParams: urlTree.queryParams,
              });

              observer.next(true);
            }

            observer.complete();
          },
          async (err) => {
            // redirection si l'utilisateur utilise un token ou n'as pas acces a l'orga
            if (
              localStorage.persist != null &&
              localStorage.persist != undefined
            ) {
              // Système pour forcer la vérification avec le tocken
              await this.test();
            }
            // err.name = 'OrganizationError';
            window.location.href = this.mainUri(
              window.location.search + '/?force=true',
            );
          },
        );
      } else {
        // Si domaine racine, alors la seule route accéssible est organisation.
        if (redirectHost.indexOf('organizations') === -1) {
          redirectHost = 'organizations';
        }

        let urlTree: UrlTree = new DefaultUrlSerializer().parse(redirectHost);
        let url = redirectHost;

        // if (localStorage.persist != undefined) {
        //   urlTree.queryParams.inviteToken = localStorage.persist;
        // }
        if (redirectHost.indexOf('?') !== -1) {
          url = redirectHost.slice(0, redirectHost.indexOf('?'));
        }
        this.router.navigate([url], { queryParams: urlTree.queryParams });
        this._startUrl = undefined;
        observer.next(false);
        observer.complete();
      }
    });
  }

  public async test() {
    if (localStorage.persist != null && localStorage.persist != undefined) {
      let token_beta = localStorage.persist;
      // try {
      await this.myOrganizationService
        .checkUrlInvitation([GqlField.create('data')], token_beta, null)
        .subscribe(
          (result) => {
            if (result.data !== undefined) {
              if (result.data == false) {
                throw new Error('invalidToken');
              } else {
                window.location.href = window.location.origin;
              }
            }
          },
          (err) => {
            let newParam = `/organizations/my-organizations/?invalidToken=true`;
            localStorage.setItem('invalid', 'true');
            localStorage.clear();
            window.location.href = this.mainUri(newParam);
          },
        );
    }
  }

  private initializeAuthentification(): Observable<boolean> {
    return this.authentificationService.initialize();
  }
  private initializeGlobalization(
    loadUserLanguage: boolean,
  ): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      // Charge les fichiers de traductions et de localisation.
      document.domain = this.envService.baseURL;
      this.localizeService.initialize();
      let license = this.licenseReader?.currentLicense;
      // PAr dfefaut pas de validation de licence
      let licenseExpire = true;
      if (license != undefined) {
        // Licence
        licenseExpire = false;
        if (license.expireAt != undefined) {
          // Vérification si pas d'expriration
          licenseExpire = new Date(license.expireAt) < new Date();
        }
      }

      // Recherche la langue par défaut
      if (loadUserLanguage === true && licenseExpire == false) {
        document.domain = this.envService.baseURL;
        let customHelpMe = this.authorizationService.user.getCustomization();

        this.userService.getDisplayLanguage().subscribe((lang) => {
          let json = JSON.parse(lang?.data);
          let userLocale = Object.keys(json)[0];

          if (userLocale == undefined) {
            //Prend la langue de l'authentification
            userLocale = this.authentificationService.userInfo.locale;
          }

          const locale = this.localizeService
            .getLocales()
            .filter(
              (l) => l.value.split('-')[0].toLowerCase() === userLocale,
            )[0];

          if (customHelpMe.helpMe?.accessTicketsObserverLabel != null) {
            let accessTicketsObserverLabel =
              customHelpMe.helpMe?.accessTicketsObserverLabel[userLocale];
            let translateCustomHelpMe = {};
            translateCustomHelpMe[userLocale] = {
              contentCustomHelpMe: {
                accessTicketsObserverLabel: accessTicketsObserverLabel,
              },
            };
            Globalize.loadMessages(translateCustomHelpMe);
          }

          //Si autre lang que FR ou EN alors on charge aussi la langue EN pour les traduction manquante des autre langues
          if (
            !(
              locale.value == Locale.Fr().value ||
              locale.value == Locale.En().value
            )
          ) {
            this.myOrganizationService
              .modelLanguage([GqlField.create('data')], Locale.En().value)
              .subscribe((modelLang) => {
                Globalize.loadMessages(JSON.parse(modelLang?.data));
                this.localizeService.setLocal(locale, json);
                observer.next(true);
                observer.complete();
              });
          } else {
            this.localizeService.setLocal(locale, json);
            observer.next(true);
            observer.complete();
          }
        });

        // // Requête pour rechercher la langue que l'utilisateur à sélectionné dans ses préférences
        // const userLocaleQuery = this.userService.getUserLocalize().subscribe(resultUserLocal => {
        //   let userLocale = resultUserLocal.data.lang;
        //   if (userLocale == undefined) {
        //     //Prend la langue de l'authentification
        //     userLocale = this.authentificationService.userInfo.locale;
        //   }
        //   const locale = this.localizeService
        //     .getLocales()
        //     .filter(l => l.value === userLocale)[0];
        //   this.localizeService.setLocal(locale);
        //   observer.next(true);
        //   observer.complete();
        // });

        // Requête pour rechercher la langue applicative de l'organisation
        // this.organizationService.getOrganisationLocalize().subscribe(resultOrganizationLocale => {
        //   let organizationLocale = resultOrganizationLocale && resultOrganizationLocale.data
        //   if (organizationLocale.lang == undefined) {

        //     //Prend la langue de l'authentification
        //     //organizationLocale = this.authentificationService.userInfo.locale;
        //   } else {
        //     const locale = this.localizeService
        //       .getLocales()
        //       .filter(l => l.value === organizationLocale.lang)[0];
        //     this.localizeService.setLocal(locale);
        //     observer.next(true);
        //     observer.complete();
        //   }
        //   // Force la langue en fr
        //   // TODO : A prendre en compte une fois traduit
        //   //this.localizeService.setLocal(Locale.Fr());

        // });
      } else {
        // Charge la langue par défaut de l'authentification
        let userLocale = this.authentificationService.userInfo.locale;
        const locale = this.localizeService
          .getLocales()
          .filter((l) => l.value === userLocale)[0];
        this.myOrganizationService
          .modelLanguage([GqlField.create('data')], locale?.value)
          .subscribe((modelLang) => {
            this.localizeService.setLocal(locale, JSON.parse(modelLang?.data));
            observer.next(true);
            observer.complete();
          });
        // Force la langue en fr
        // TODO : A prendre en compte une fois traduit
        //this.localizeService.setLocal(Locale.Fr());
        //observer.next(true);
        //observer.complete();
      }
    });
  }
}
