import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetContactsBaseVariables, FirstContactsBaseVariables, CountContactsBaseVariables, FindContactsBaseVariables, SearchContactsBaseVariables, ExportSchemaContactsBaseVariables, ExportDataContactsBaseVariables, CustomQueryContactsBaseVariables, CustomQueryIdContactsBaseVariables, FindUnassociatedSecurityGroupsContactsBaseVariables, FindFilesContactsBaseVariables, FindAttachmentFilesContactsBaseVariables, FindHelpDeskUserAttachmentFilesContactsBaseVariables, UsedContactsBaseVariables, FindContactByServiceOfferIdsContactsBaseVariables, FindTitleContactsBaseVariables, ExistContactsBaseVariables, FindRecyclesContactsBaseVariables, CountRecyclesContactsBaseVariables, ReadOnlyContactsBaseVariables, FindArchivedContactsBaseVariables, CountAllContactsBaseVariables, FindDynamicPropertyFieldsContactsBaseVariables, InsertContactsBaseVariables, UpdateContactsBaseVariables, ImportDataContactsBaseVariables, UpdateManyContactsBaseVariables, DeleteContactsBaseVariables, AddSecurityGroupContactsBaseVariables, RemoveSecurityGroupContactsBaseVariables, AddFilesContactsBaseVariables, RemoveFilesContactsBaseVariables, UploadChunkFileContactsBaseVariables, RestoreContactsBaseVariables, RecycleContactsBaseVariables, RestoreArchivedContactsBaseVariables, ArchivedContactsBaseVariables, AddFileDynamicFieldContactsBaseVariables, RemoveFileDynamicFieldContactsBaseVariables } from '../gqls'
import { GetContactsDocument, FirstContactsDocument, CountContactsDocument, FindContactsDocument, SearchContactsDocument, ExportSchemaContactsDocument, ExportDataContactsDocument, CustomQueryContactsDocument, CustomQueryIdContactsDocument, FindUnassociatedSecurityGroupsContactsDocument, FindFilesContactsDocument, FindAttachmentFilesContactsDocument, FindHelpDeskUserAttachmentFilesContactsDocument, UsedContactsDocument, FindContactByServiceOfferIdsContactsDocument, FindTitleContactsDocument, ExistContactsDocument, FindRecyclesContactsDocument, CountRecyclesContactsDocument, ReadOnlyContactsDocument, FindArchivedContactsDocument, CountAllContactsDocument, FindDynamicPropertyFieldsContactsDocument, InsertContactsDocument, UpdateContactsDocument, ImportDataContactsDocument, UpdateManyContactsDocument, DeleteContactsDocument, AddSecurityGroupContactsDocument, RemoveSecurityGroupContactsDocument, AddFilesContactsDocument, RemoveFilesContactsDocument, UploadChunkFileContactsDocument, RestoreContactsDocument, RecycleContactsDocument, RestoreArchivedContactsDocument, ArchivedContactsDocument, AddFileDynamicFieldContactsDocument, RemoveFileDynamicFieldContactsDocument } from '../gqls'
import { ServiceSingleResultOfUser, QueryContextOfUser, FilterOfUser, ServiceSingleResultOfInt64, ServiceListResultOfUser, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, QueryContextOfSecurityGroup, FilterOfSecurityGroup, ServiceListResultOfSecurityGroup, ServiceListResultOfFileModel, QueryContextOfFileModel, FilterOfFileModel, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfUser, UserInput, ServiceSingleResultOfGuid } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ContactBaseService {
    
public modelName = 'contact';
public modelPluralName = 'contacts';

	private getContactsQuery: GetContactsDocument;
	private firstContactsQuery: FirstContactsDocument;
	private countContactsQuery: CountContactsDocument;
	private findContactsQuery: FindContactsDocument;
	private searchContactsQuery: SearchContactsDocument;
	private exportSchemaContactsQuery: ExportSchemaContactsDocument;
	private exportDataContactsQuery: ExportDataContactsDocument;
	private customQueryContactsQuery: CustomQueryContactsDocument;
	private customQueryIdContactsQuery: CustomQueryIdContactsDocument;
	private findUnassociatedSecurityGroupsContactsQuery: FindUnassociatedSecurityGroupsContactsDocument;
	private findFilesContactsQuery: FindFilesContactsDocument;
	private findAttachmentFilesContactsQuery: FindAttachmentFilesContactsDocument;
	private findHelpDeskUserAttachmentFilesContactsQuery: FindHelpDeskUserAttachmentFilesContactsDocument;
	private usedContactsQuery: UsedContactsDocument;
	private findContactByServiceOfferIdsContactsQuery: FindContactByServiceOfferIdsContactsDocument;
	private findTitleContactsQuery: FindTitleContactsDocument;
	private existContactsQuery: ExistContactsDocument;
	private findRecyclesContactsQuery: FindRecyclesContactsDocument;
	private countRecyclesContactsQuery: CountRecyclesContactsDocument;
	private readOnlyContactsQuery: ReadOnlyContactsDocument;
	private findArchivedContactsQuery: FindArchivedContactsDocument;
	private countAllContactsQuery: CountAllContactsDocument;
	private findDynamicPropertyFieldsContactsQuery: FindDynamicPropertyFieldsContactsDocument;
	private insertContactsMutation: InsertContactsDocument;
	private updateContactsMutation: UpdateContactsDocument;
	private importDataContactsMutation: ImportDataContactsDocument;
	private updateManyContactsMutation: UpdateManyContactsDocument;
	private deleteContactsMutation: DeleteContactsDocument;
	private addSecurityGroupContactsMutation: AddSecurityGroupContactsDocument;
	private removeSecurityGroupContactsMutation: RemoveSecurityGroupContactsDocument;
	private addFilesContactsMutation: AddFilesContactsDocument;
	private removeFilesContactsMutation: RemoveFilesContactsDocument;
	private uploadChunkFileContactsMutation: UploadChunkFileContactsDocument;
	private restoreContactsMutation: RestoreContactsDocument;
	private recycleContactsMutation: RecycleContactsDocument;
	private restoreArchivedContactsMutation: RestoreArchivedContactsDocument;
	private archivedContactsMutation: ArchivedContactsDocument;
	private addFileDynamicFieldContactsMutation: AddFileDynamicFieldContactsDocument;
	private removeFileDynamicFieldContactsMutation: RemoveFileDynamicFieldContactsDocument;

	constructor(private injector: Injector) {
		this.getContactsQuery = this.injector.get(GetContactsDocument);
		this.firstContactsQuery = this.injector.get(FirstContactsDocument);
		this.countContactsQuery = this.injector.get(CountContactsDocument);
		this.findContactsQuery = this.injector.get(FindContactsDocument);
		this.searchContactsQuery = this.injector.get(SearchContactsDocument);
		this.exportSchemaContactsQuery = this.injector.get(ExportSchemaContactsDocument);
		this.exportDataContactsQuery = this.injector.get(ExportDataContactsDocument);
		this.customQueryContactsQuery = this.injector.get(CustomQueryContactsDocument);
		this.customQueryIdContactsQuery = this.injector.get(CustomQueryIdContactsDocument);
		this.findUnassociatedSecurityGroupsContactsQuery = this.injector.get(FindUnassociatedSecurityGroupsContactsDocument);
		this.findFilesContactsQuery = this.injector.get(FindFilesContactsDocument);
		this.findAttachmentFilesContactsQuery = this.injector.get(FindAttachmentFilesContactsDocument);
		this.findHelpDeskUserAttachmentFilesContactsQuery = this.injector.get(FindHelpDeskUserAttachmentFilesContactsDocument);
		this.usedContactsQuery = this.injector.get(UsedContactsDocument);
		this.findContactByServiceOfferIdsContactsQuery = this.injector.get(FindContactByServiceOfferIdsContactsDocument);
		this.findTitleContactsQuery = this.injector.get(FindTitleContactsDocument);
		this.existContactsQuery = this.injector.get(ExistContactsDocument);
		this.findRecyclesContactsQuery = this.injector.get(FindRecyclesContactsDocument);
		this.countRecyclesContactsQuery = this.injector.get(CountRecyclesContactsDocument);
		this.readOnlyContactsQuery = this.injector.get(ReadOnlyContactsDocument);
		this.findArchivedContactsQuery = this.injector.get(FindArchivedContactsDocument);
		this.countAllContactsQuery = this.injector.get(CountAllContactsDocument);
		this.findDynamicPropertyFieldsContactsQuery = this.injector.get(FindDynamicPropertyFieldsContactsDocument);
		this.insertContactsMutation = this.injector.get(InsertContactsDocument);
		this.updateContactsMutation = this.injector.get(UpdateContactsDocument);
		this.importDataContactsMutation = this.injector.get(ImportDataContactsDocument);
		this.updateManyContactsMutation = this.injector.get(UpdateManyContactsDocument);
		this.deleteContactsMutation = this.injector.get(DeleteContactsDocument);
		this.addSecurityGroupContactsMutation = this.injector.get(AddSecurityGroupContactsDocument);
		this.removeSecurityGroupContactsMutation = this.injector.get(RemoveSecurityGroupContactsDocument);
		this.addFilesContactsMutation = this.injector.get(AddFilesContactsDocument);
		this.removeFilesContactsMutation = this.injector.get(RemoveFilesContactsDocument);
		this.uploadChunkFileContactsMutation = this.injector.get(UploadChunkFileContactsDocument);
		this.restoreContactsMutation = this.injector.get(RestoreContactsDocument);
		this.recycleContactsMutation = this.injector.get(RecycleContactsDocument);
		this.restoreArchivedContactsMutation = this.injector.get(RestoreArchivedContactsDocument);
		this.archivedContactsMutation = this.injector.get(ArchivedContactsDocument);
		this.addFileDynamicFieldContactsMutation = this.injector.get(AddFileDynamicFieldContactsDocument);
		this.removeFileDynamicFieldContactsMutation = this.injector.get(RemoveFileDynamicFieldContactsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUser> {
    
    		let variables: GetContactsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getContactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.get));
            }
            else{
                let result:ServiceSingleResultOfUser={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUser> {
    
    		let variables: FirstContactsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstContactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountContactsBaseVariables = {
    			filter: filter
    		}
    				return this.countContactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindContactsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findContactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfUser,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: SearchContactsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchContactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaContactsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaContactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataContactsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataContactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfUser,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: CustomQueryContactsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryContactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfUser,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: CustomQueryIdContactsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdContactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.customQueryId));
    	}

	@InjectArgs
	public findAssociatedSecurityGroups(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSecurityGroup> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('securityGroups', fields, null, [
			GqlSubFieldArg.create('filterOfSecurityGroups', 'filter'),
			GqlSubFieldArg.create('optionsOfSecurityGroups', 'options'),
		]),
		])
		extendedVariables['filterOfSecurityGroups'] = filter;
		extendedVariables['optionsOfSecurityGroups'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.securityGroups));
            }
            else{
                let result: ServiceListResultOfSecurityGroup = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/** Récupère les profils non liés de l'entité. */
    	@InjectArgs
    	public findUnassociatedSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroup> {
    
    		let variables: FindUnassociatedSecurityGroupsContactsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSecurityGroupsContactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.findUnassociatedSecurityGroups));
    	}

    	/** Récupère les fichiers liés. */
    	@InjectArgs
    	public findFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindFilesContactsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findFilesContactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.findFiles));
    	}

    	/** Récupère les fichiers attachés liés. */
    	@InjectArgs
    	public findAttachmentFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindAttachmentFilesContactsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAttachmentFilesContactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.findAttachmentFiles));
    	}

    	/** Récupère les fichiers attachés liés visible pour les users help desk. */
    	@InjectArgs
    	public findHelpDeskUserAttachmentFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindHelpDeskUserAttachmentFilesContactsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findHelpDeskUserAttachmentFilesContactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.findHelpDeskUserAttachmentFiles));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedContactsBaseVariables = {
    			ids: ids
    		}
    				return this.usedContactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.used));
    	}

    	/**  */
    	@InjectArgs
    	public findContactByServiceOfferIds(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('serviceOfferIds') serviceOfferIds: Array<string>,
		@Args('options?') options?: QueryContextOfUser,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindContactByServiceOfferIdsContactsBaseVariables = {
    			serviceOfferIds: serviceOfferIds,
			filter: filter,
			options: options
    		}
    				return this.findContactByServiceOfferIdsContactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.findContactByServiceOfferIds));
    	}

    	/**  */
    	@InjectArgs
    	public findTitle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindTitleContactsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findTitleContactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.findTitle));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistContactsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existContactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindRecyclesContactsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesContactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesContactsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesContactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyContactsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyContactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindArchivedContactsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedContactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllContactsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllContactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsContactsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsContactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: UserInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUser> {
    
    		let variables: InsertContactsBaseVariables = {
    			entity: entity
    		}
    				return this.insertContactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUser> {
    
    		let variables: UpdateContactsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateContactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataContactsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataContactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyContactsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyContactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteContactsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteContactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSecurityGroupContactsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds,
			isNew: isNew
    		}
    				return this.addSecurityGroupContactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.addSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSecurityGroupContactsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds
    		}
    				return this.removeSecurityGroupContactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.removeSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileIds') fileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFilesContactsBaseVariables = {
    			id: id,
			fileIds: fileIds
    		}
    				return this.addFilesContactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.addFiles));
    	}

    	/**  */
    	@InjectArgs
    	public removeFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileIds') fileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFilesContactsBaseVariables = {
    			id: id,
			fileIds: fileIds
    		}
    				return this.removeFilesContactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.removeFiles));
    	}

    	/**  */
    	@InjectArgs
    	public uploadChunkFile(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('visibleByHelpDeskUser') visibleByHelpDeskUser: boolean,
		@Args('size') size: number,
		@Args('outgoingEmails') outgoingEmails: boolean,
		@Args('lastChunk') lastChunk: boolean,
		@Args('indexChunk') indexChunk: number,
		@Args('type?') type?: string,
		@Args('name?') name?: string,
		@Args('fileId?') fileId?: string,
		@Args('entityId?') entityId?: string,
		@Args('chunks?') chunks?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: UploadChunkFileContactsBaseVariables = {
    			name: name,
			size: size,
			type: type,
			chunks: chunks,
			indexChunk: indexChunk,
			lastChunk: lastChunk,
			fileId: fileId,
			entityId: entityId,
			visibleByHelpDeskUser: visibleByHelpDeskUser,
			outgoingEmails: outgoingEmails
    		}
    				return this.uploadChunkFileContactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.uploadChunkFile));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreContactsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreContactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleContactsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleContactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedContactsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedContactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedContactsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedContactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldContactsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldContactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldContactsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldContactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contacts.removeFileDynamicField));
    	}
    
}