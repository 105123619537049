import { AlarmBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { Args, InjectArgs } from '@clarilog/core/modules';
import { GqlField, GqlSubField } from '../helpers';
import { Maybe } from 'graphql/jsutils/Maybe';
import {
  FilterOfAlarm,
  FilterOfEmailPrototype,
  QueryContextOfAlarm,
  QueryContextOfEmailPrototype,
  ServiceListResultOfAlarm,
  ServiceListResultOfEmailPrototype,
} from '../types';
import { Observable } from 'rxjs/internal/Observable';

export interface AlarmTypeParam {
  name: string;
  listModelPath: any;
  route: string;
  routeFieldId: string;
  translatedName: string;
}

@Injectable({ providedIn: 'root' })
@Authorize('administration.alarm')
export class AlarmCoreService extends AlarmBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  /** En attendant les modeljson coté serveur */
  public async alarmTypes(): Promise<AlarmTypeParam[]> {
    let listAlarmType = [
      {
        name: 'asset',
        listModelPath: await import(
          '@clarilog/modules2/assets/pages/asset-list/model.json'
        ),
        route: 'assets',
        routeFieldId: 'assetId',
        translatedName: TranslateService.get(`entities/asset/_title/singular`),
      },
      {
        name: 'softwarePackage',
        listModelPath: await import(
          '@clarilog/modules2/software/modules/software-packages/pages/software-package-list/model.json'
        ),
        route: 'softwares/softwarePackages',
        routeFieldId: 'softwarePackageId',
        translatedName: TranslateService.get(`entities/alarm/package`),
      },
      {
        name: 'software',
        listModelPath: await import(
          '@clarilog/modules2/software/pages/software-list/model.json'
        ),
        route: 'softwares',
        routeFieldId: 'softwareId',
        translatedName: TranslateService.get(
          `entities/software/_title/singular`,
        ),
      },
      {
        name: 'scanConfiguration',
        listModelPath: await import(
          '@clarilog/modules2/inventory/modules/scan-configurations/pages/scan-configuration-list/model.json'
        ),
        route: 'inventory/scan-configurations',
        routeFieldId: 'scanConfigurationId',
        translatedName: TranslateService.get(
          `entities/scanConfiguration/_title/singular`,
        ),
      },
      {
        name: 'budget',
        listModelPath: await import(
          '@clarilog/modules2/financials/modules/budgets/budget-list/model.json'
        ),
        route: 'financials/budgets',
        routeFieldId: 'budgetId',
        translatedName: TranslateService.get(`entities/budget/_title/singular`),
      },
      {
        name: 'contract',
        listModelPath: await import(
          '@clarilog/modules2/contracts/pages/contract-list/model.json'
        ),
        route: 'contracts',
        routeFieldId: 'contractId',
        translatedName: TranslateService.get(
          `entities/contract/_title/singular`,
        ),
      },
      {
        name: 'licenseKey',
        listModelPath: await import(
          '@clarilog/modules2/license-keys/pages/license-key-list/model.json'
        ),
        route: 'contracts/license-keys',
        routeFieldId: 'licenseKeyId',
        translatedName: TranslateService.get(
          `entities/licenseKey/_title/singular`,
        ),
      },
      {
        name: 'loan',
        listModelPath: await import(
          '@clarilog/modules2/loans/pages/loan-list/model.json'
        ),
        route: 'loans',
        routeFieldId: 'loanId',
        translatedName: TranslateService.get(`entities/loan/_title/singular`),
      },
      {
        name: 'clarilogServer',
        listModelPath: await import(
          '@clarilog/modules2/inventory/modules/clarilog-servers/pages/clarilog-server-list/model.json'
        ),
        route: 'inventory/clarilog-servers',
        routeFieldId: 'clarilogServerId',
        translatedName: TranslateService.get(
          `entities/clarilogServer/_title/singular`,
        ),
      },
      {
        name: 'assetModel',
        listModelPath: await import(
          '@clarilog/modules2/stocks/modules/asset-models/pages/asset-model-list/model.json'
        ),
        route: 'stocks/asset-models',
        routeFieldId: 'assetModelId',
        translatedName: TranslateService.get(
          `entities/assetModel/_title/singular`,
        ),
      },
      {
        name: 'logicalDisk',
        listModelPath: await import(
          '@clarilog/modules2/assets/pages/asset-list/model.json'
        ),
        route: 'assets',
        routeFieldId: 'assetId',
        translatedName: TranslateService.get(
          `entities/logicalDisk/_title/singular`,
        ),
      },
      {
        name: 'printerConsumable',
        listModelPath: await import(
          '@clarilog/modules2/assets/pages/asset-list/model.json'
        ),
        route: 'assets',
        routeFieldId: 'assetId',
        translatedName: TranslateService.get(
          `entities/printerConsumable/_title/singular`,
        ),
      },
      {
        name: 'message',
        route: 'incidents',
        listModelPath: await import(
          '@clarilog/modules2/incident/pages/incident-list/model.json'
        ),
        routeFieldId: 'ticketId',
        translatedName: TranslateService.get(
          `entities/message/_title/singular`,
        ),
      },
      {
        name: 'incident',
        route: 'incidents',
        listModelPath: await import(
          '@clarilog/modules2/incident/pages/incident-list/model.json'
        ),
        routeFieldId: 'incidentId',
        translatedName: TranslateService.get(
          `entities/incident/_title/singular`,
        ),
      },
      {
        name: 'ticket',
        route: 'tickets',
        listModelPath: await import(
          '@clarilog/modules2/ticket/pages/ticket-list/model.json'
        ),
        routeFieldId: 'ticketId',
        translatedName: TranslateService.get(`entities/ticket/_title/singular`),
      },
      {
        name: 'request',
        route: 'requests',
        listModelPath: await import(
          '@clarilog/modules2/request/pages/request-list/model.json'
        ),
        routeFieldId: 'requestId',
        translatedName: TranslateService.get(
          `entities/request/_title/singular`,
        ),
      },
      {
        name: 'problem',
        route: 'problems',
        listModelPath: await import(
          '@clarilog/modules2/problem/pages/problem-list/model.json'
        ),
        routeFieldId: 'problemId',
        translatedName: TranslateService.get(
          `entities/problem/_title/singular`,
        ),
      },
      {
        name: 'ticketTask',
        route: 'tasks',
        listModelPath: await import(
          '@clarilog/modules2/task/pages/task-list/model.json'
        ),
        routeFieldId: 'ticketTaskId',
        translatedName: TranslateService.get(`entities/task/_title/singular`),
      },
      {
        name: 'intervention',
        route: 'incidents',
        listModelPath: await import(
          '@clarilog/modules2/incident/pages/incident-list/model.json'
        ),
        routeFieldId: 'interventionId',
        translatedName: TranslateService.get(
          `entities/intervention/_title/singular`,
        ),
      },
      {
        name: 'user',
        route: 'users',
        listModelPath: await import(
          '@clarilog/modules2/users/pages/user-list/model.json'
        ),
        routeFieldId: 'userId',
        translatedName: TranslateService.get(`entities/user/_title/singular`),
      },
    ];
    listAlarmType.sort(function (a, b) {
      if (a.translatedName < b.translatedName) {
        return -1;
      }
      if (a.translatedName > b.translatedName) {
        return 1;
      }
      return 0;
    });
    return listAlarmType;
  }

  /** En attendant les modeljson coté serveur */
  alarmEnumType(): any[] {
    return [
      {
        id: 'asset',
        value: TranslateService.get(`entities/asset/_title/singular`),
      },
      {
        id: 'softwarePackage',
        value: TranslateService.get(`entities/softwarePackage/_title/singular`),
      },
      {
        id: 'software',
        value: TranslateService.get(`entities/software/_title/singular`),
      },
      {
        id: 'budget',
        value: TranslateService.get(`entities/budget/_title/singular`),
      },
      {
        id: 'contract',
        value: TranslateService.get(`entities/contract/_title/singular`),
      },
      {
        id: 'scanConfiguration',
        value: TranslateService.get(
          `entities/scanConfiguration/_title/singular`,
        ),
      },
      {
        id: 'loan',
        value: TranslateService.get(`entities/loan/_title/singular`),
      },
      {
        id: 'logicalDisk',
        value: TranslateService.get(`entities/logicalDisk/_title/singular`),
      },
      {
        id: 'printerConsumable',
        value: TranslateService.get(
          `entities/printerConsumable/_title/singular`,
        ),
      },
      {
        id: 'assetModel',
        value: TranslateService.get(`entities/assetModel/_title/singular`),
      },
      {
        id: 'message',
        value: TranslateService.get(`entities/message/_title/singular`),
      },
      {
        id: 'ticket',
        value: TranslateService.get(`entities/ticket/_title/singular`),
      },
      {
        id: 'incident',
        value: TranslateService.get(`entities/incident/_title/singular`),
      },
      {
        id: 'request',
        value: TranslateService.get(`entities/request/_title/singular`),
      },
      {
        id: 'problem',
        value: TranslateService.get(`entities/problem/_title/singular`),
      },
      {
        id: 'ticketTask',
        value: TranslateService.get(`entities/task/_title/singular`),
      },
      {
        id: 'intervention',
        value: TranslateService.get(`entities/intervention/_title/singular`),
      },
      {
        id: 'clarilogServer',
        value: TranslateService.get(
          'entities/scanConfiguration/clarilogServer',
        ),
      },
      {
        id: 'user',
        value: TranslateService.get('entities/user/_title/singular'),
      },
      {
        id: 'licenseKey',
        value: TranslateService.get('entities/licenseKey/_title/singular'),
      },
    ];
  }

  recipientProperties(): any[] {
    return [
      {
        id: 'operatorTeamReferentId',
        value: TranslateService.get(`entities/incident/operatorTeamReferentId`),
      },
      {
        id: 'operatorReferentId',
        value: TranslateService.get(`entities/incident/operatorReferentId`),
      },
      {
        id: 'operatorTeamAffectedId',
        value: TranslateService.get(`entities/incident/operatorTeamAffected`),
      },
      {
        id: 'operatorAffectedId',
        value: TranslateService.get(`entities/incident/operatorAffectedId`),
      },
    ];
  }

  @InjectArgs
  public find(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfAlarm,
    @Args('filter?') filter?: FilterOfAlarm,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfAlarm> {
    fields.push(GqlField.create('totalCount'));
    return super.find(fields, options, filter, extendedVariables);
  }

  @InjectArgs
  public findEmailPrototypesUnassociatedCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfEmailPrototype,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfEmailPrototype,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfEmailPrototype> {
    //let typeAlarm: string = null;

    let typeAlarm: Array<Maybe<string>> = [];
    let createFilter: FilterOfEmailPrototype = {};

    if (filter != null) {
      if (filter?.and?.length > 1) {
        let andTableFilter = [];
        filter?.and?.forEach((element) => {
          if (element?.type == undefined && element?.or == undefined) {
            //
            if (element?.or?.length > 1) {
              let orTableFilter = [];
              element?.or?.forEach((value) => {
                if (value?.type == undefined) {
                  orTableFilter.push(element);
                } else {
                  typeAlarm.push(element?.type?.eq);
                }
              });
            }

            andTableFilter.push(element);
          } else {
            typeAlarm.push(element?.type?.eq);
          }
        });

        createFilter = { and: andTableFilter };
      } else if (filter?.or?.length > 1) {
        let orTableFilter = [];
        filter?.or?.forEach((element) => {
          if (element?.type == undefined) {
            orTableFilter.push(element);
          } else {
            typeAlarm.push(element?.type?.eq);
          }
        });
      } else {
        if (filter?.type == undefined) {
          createFilter = filter;
        } else {
          typeAlarm = [filter?.type?.eq];
        }
      }
    }
    return super.findEmailPrototypesUnassociated(
      fields,
      typeAlarm,
      options,
      id,
      createFilter,
      extendedVariables,
    );
  }
}
