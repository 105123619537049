import { OperatorTeamBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { GqlField, GqlSubField } from '../helpers';
import {
  FilterOfEmailPrototype,
  FilterOfOperatorTeam,
  FilterOfUser,
  FilterOfWarningMessage,
  QueryContextOfEmailPrototype,
  QueryContextOfOperatorTeam,
  QueryContextOfUser,
  QueryContextOfWarningMessage,
  ServiceListResultOfEmailPrototype,
  ServiceListResultOfOperatorTeam,
  ServiceListResultOfUser,
  ServiceListResultOfWarningMessage,
  UserCategory,
  WarningMessageCategory,
} from '../types';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-operator-team')
export class OperatorTeamCoreService extends OperatorTeamBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  /**  */
  @InjectArgs
  public findOperatorTeamAffected(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('levelSupport') levelSupport?: number,
    @Args('options?') options?: QueryContextOfOperatorTeam,
    @Args('operatorAffectedId?') operatorAffectedId?: string,
    @Args('filter?') filter?: FilterOfOperatorTeam,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfOperatorTeam> {
    return this.findWithOperatorByLevelSupport(
      fields,
      options,
      operatorAffectedId,
      levelSupport,
      filter,
      extendedVariables,
    );
  }

  /**
   * Permet de chercher les teams operator par rapport au niveau de support ou par rapport a l'operator
   */
  @InjectArgs
  public findOperatorTeamAffectedTicketAffectation(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('supportLevel') supportLevel?: number,
    @Args('options?') options?: QueryContextOfOperatorTeam,
    @Args('affectedOperatorId?') affectedOperatorId?: string,
    @Args('filter?') filter?: FilterOfOperatorTeam,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfOperatorTeam> {
    return this.findOperatorTeamAffected(
      fields,
      supportLevel,
      options,
      affectedOperatorId,
      filter,
      extendedVariables,
    );
  }

  /**  */
  @InjectArgs
  public findOperatorTeamReferent(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfOperatorTeam,
    @Args('operatorReferentId?') operatorReferentId?: string,
    @Args('filter?') filter?: FilterOfOperatorTeam,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfOperatorTeam> {
    return this.findWithOperatorByOperator(
      fields,
      options,
      operatorReferentId,
      filter,
      extendedVariables,
    );
  }

  /**  */
  @InjectArgs
  public findOperatorTeamReferentBySupportLevel(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('supportLevel') supportLevel?: number,
    @Args('options?') options?: QueryContextOfOperatorTeam,
    @Args('operatorReferentId?') operatorReferentId?: string,
    @Args('filter?') filter?: FilterOfOperatorTeam,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfOperatorTeam> {
    return this.findWithOperatorByLevelSupport(
      fields,
      options,
      operatorReferentId,
      supportLevel,
      filter,
      extendedVariables,
    );
  }

  @InjectArgs
  public findAssociatedUsersContact(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfUser,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfUser,

    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfUser> {
    let newFilter: FilterOfUser = {
      category: {
        eq: UserCategory.Contact,
      },
    };
    return this.findAssociatedUsers(fields, options, id, newFilter);
  }

  /**  */
  @InjectArgs
  public findUnassociatedUsersContact(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfUser,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfUser> {
    let newFilter: FilterOfUser = {
      category: {
        eq: UserCategory.Contact,
      },
    };

    return this.findUnassociatedUsers(fields, options, id, filter);
  }

  //Methode a retravailler plus tard
  @InjectArgs
  public findNotUsersSupplierLink(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('serviceOfferIds') serviceOfferIds: Array<string>,
    @Args('options?') options?: QueryContextOfUser,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfUser> {
    if (filter != null) {
      if (filter?.and != undefined) {
        filter.and.map((value, index) => {
          if (value?.or != undefined) {
            console.error('and et or');
          } else {
            serviceOfferIds = value['serviceOfferIds']?.elemMatch.in;
            if (value['serviceOfferIds'] != undefined) {
              filter.and.splice(1, index);
            }
          }
        });
      } else if (filter?.or != undefined) {
        console.error('or');
      } else {
        serviceOfferIds = filter['serviceOfferIds']?.elemMatch.in;
      }
    }
    return super.findNotUsersSupplierLink(
      fields,
      serviceOfferIds,
      options,
      id,
      filter,
      extendedVariables,
    );
  }

  @InjectArgs
  public findWarningMessages(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfWarningMessage,
    @Args('filter?') filter?: FilterOfWarningMessage,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfWarningMessage> {
    let defaultFitler: FilterOfWarningMessage = {
      warningMessageType: { eq: WarningMessageCategory.Operator },
    };
    if (filter != null) {
      filter = { and: [filter, defaultFitler] };
    } else {
      filter = defaultFitler;
    }
    return super.findWarningMessages(
      fields,
      options,
      filter,
      extendedVariables,
    );
  }

  @InjectArgs
  public findSecurityGroupAssociateEmailPrototypesCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfEmailPrototype,
    @Args('securityGroupId?') securityGroupId?: string,
    @Args('filter?') filter?: FilterOfEmailPrototype,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfEmailPrototype> {
    filter = {
      type: { nin: ['queryBuilder', 'alarm'] },
    };

    return this.findSecurityGroupAssociateEmailPrototypes(
      fields,
      securityGroupId,
      options,
      filter,
      extendedVariables,
    );
  }
  @InjectArgs
  public findEmailPrototypesCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfEmailPrototype,
    @Args('filter?') filter?: FilterOfEmailPrototype,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfEmailPrototype> {
    filter = {
      type: { nin: ['queryBuilder', 'alarm'] },
    };

    return this.findEmailPrototypes(fields, options, filter, extendedVariables);
  }

  @InjectArgs
  public findOtherOperatorTeams(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('id?') id?: string,
    @Args('options?') options?: QueryContextOfOperatorTeam,
    @Args('filter?') filter?: FilterOfOperatorTeam,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfOperatorTeam> {
    let removeThisTeamFilter: FilterOfOperatorTeam = filter ?? {};

    if (id != null) {
      removeThisTeamFilter.and = [{ id: { ne: id } }];
    }

    return this.find(fields, options, removeThisTeamFilter, extendedVariables);
  }

  /** Méthode uniquement utilisée pour les formulaires de tickets et tâches de tickets (incidents & demandes) */
  @InjectArgs
  public findFilteredOperatorTeamAffected(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('levelSupport?') levelSupport?: number,
    @Args('options?') options?: QueryContextOfOperatorTeam,
    @Args('operatorAffectedId?') operatorAffectedId?: string,
    @Args('filter?') filter?: FilterOfOperatorTeam,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfOperatorTeam> {
    return this.findFilteredAssignedOperatorTeams(
      fields,
      options,
      operatorAffectedId,
      levelSupport,
      null,
      filter,
      extendedVariables,
    );
  }

  /** Méthode uniquement utilisée pour les formulaires de tickets et tâches de tickets (incidents & demandes) */
  @InjectArgs
  public findFilteredOperatorTeamReferent(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfOperatorTeam,
    @Args('operatorReferentId?') operatorReferentId?: string,
    @Args('filter?') filter?: FilterOfOperatorTeam,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfOperatorTeam> {
    return this.findFilteredAssignedOperatorTeams(
      fields,
      options,
      operatorReferentId,
      null,
      null,
      filter,
      extendedVariables,
    );
  }

  @InjectArgs
  public findTasksOperatorTeams(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfOperatorTeam,
    @Args('filter?') filter?: FilterOfOperatorTeam,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfOperatorTeam> {
    return this.findFilteredAssignedOperatorTeams(
      fields,
      options,
      null,
      null,
      true,
      filter,
      extendedVariables,
    );
  }

  /** Find tous les opérateur avec l'id en cours exclu */
  @InjectArgs
  findNotMe(
    @Args('id') id: string,
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfOperatorTeam,
    @Args('filter?') filter?: FilterOfOperatorTeam,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfOperatorTeam> {
    if (id != undefined) {
      let excludeId: FilterOfOperatorTeam = {
        id: {
          ne: id,
        },
      };
      if (filter != undefined) {
        filter = {
          and: [filter, excludeId],
        };
      } else {
        filter = excludeId;
      }
    }
    return this.find(fields, options, filter, extendedVariables);
  }
}
