<cl-work-page (keydown)="onKeyDown($event)" tabindex="0">
  <cl-work-page-header>
    <cl-work-page-header-content>
      <cl-work-page-header-content-title
        [attributesInfo]="attributesInfo"
        [showBack]="showBack"
        [backMode]="backMode"
        [mobileMode]="enableMobileMode"
        [menuNavMobileVisibility]="_menuNavMobileVisibility"
        (onMobileMenuClick)="onMobileMenuClick($event)"
        (onBackClick)="close($event)"
      >
        <span>
          {{ title
          }}{{
            titlePlus != undefined &&
            titlePlus.trim != undefined &&
            titlePlus.trim() !== ''
              ? ' : ' + titlePlus
              : ''
          }}
          <span *ngIf="displayReadOnlyMsg == true">
            {{ readOnly ? ' [' + ('readOnly' | translate) + ']' : '' }}
            {{
              userName != undefined
                ? '[' + ('lockedBy' | translate) + userName + ']'
                : ''
            }}
          </span>
          <span *ngIf="readOnlyAttribute != undefined">
            <a
              class="cl-no-click"
              [routerLink]="readOnlyAttribute.url"
              target="_blank"
            >
              {{ readOnlyAttribute.display }}
            </a>
          </span>
        </span>
      </cl-work-page-header-content-title>
      <cl-work-page-header-content-body>
        <cl-toolbar *ngIf="enableMobileMode === false">
          <cl-toolbar-items [items]="toolbar?.itemsChildren.toArray()">
            <!-- <cl-toolbar-item-button
              icon="fal fa-sync"
              [location]="'after'"
              (onClick)="refresh()"
            >
            </cl-toolbar-item-button> -->
            <cl-toolbar-item-button
              *clcHasPolicies="savePolicies"
              [text]="
                (saveSplitButton === true ? 'HIDDEN' : '') +
                (saveTilte != undefined
                  ? (saveTilte | translate)
                  : ('saveAndClose' | translate))
              "
              keyExpr="id"
              displayExpr="name"
              [location]="'after'"
              [disabled]="!(formPending === false && canSave$ | async)"
              (onClick)="submitAndClose($event)"
              [visible]="
                showSave &&
                saveSplitButton === false &&
                onlySaveButton === false
              "
              type="default"
            >
            </cl-toolbar-item-button>
            <cl-toolbar-item-button
              *clcHasPolicies="savePolicies"
              [text]="
                (onlySaveButton === false ? 'HIDDEN' : '') +
                (saveTilte !== undefined
                  ? (saveTilte | translate)
                  : ('save' | translate))
              "
              keyExpr="id"
              displayExpr="name"
              [location]="'after'"
              [disabled]="!(formPending === false && canSave$ | async)"
              (onClick)="submit($event)"
              [visible]="showSave && onlySaveButton"
              type="default"
            >
            </cl-toolbar-item-button>
            <cl-toolbar-item-button
              *clcHasPolicies="savePolicies"
              [visible]="displaySaveIcon && !readOnly"
              [elementAttr]="{ class: 'save-button' }"
              [location]="'after'"
              [hint]="'save' | translate"
              [locateInMenu]="'never'"
              [icon]="'save'"
              (onClick)="submit()"
              [disabled]="!(formPending === false && canSave$ | async)"
            ></cl-toolbar-item-button>
            <cl-toolbar-item-dropdown-button
              *clcHasPolicies="savePolicies"
              [text]="
                saveTilte !== undefined
                  ? (saveTilte | translate)
                  : ('saveAndClose' | translate)
              "
              keyExpr="id"
              displayExpr="name"
              [location]="'after'"
              [items]="[{ id: 'save', name: 'save' | translate }]"
              [disabled]="!(formPending === false && canSave$ | async)"
              [splitButton]="saveSplitButton"
              (onButtonClick)="submitAndClose($event)"
              (onItemClick)="submit($event)"
              [locateInMenu]="'auto'"
              [visible]="showSave && saveSplitButton === true"
              type="default"
            >
            </cl-toolbar-item-dropdown-button>
          </cl-toolbar-items>
        </cl-toolbar>
      </cl-work-page-header-content-body>
    </cl-work-page-header-content>
  </cl-work-page-header>
  <cl-work-page-errors [errors]="errors"></cl-work-page-errors>
  <cl-work-page-system>
    <ng-content select="cl-work-item-form-system"></ng-content>
    <ng-content></ng-content>
  </cl-work-page-system>
  <cl-work-page-left [hide]="items == undefined || items.length === 1">
    <clc-nav-menu *ngIf="enableMobileMode === false">
      <clc-nav-group *ngFor="let item of items" [text]="item.title">
        <clc-nav-menu-item
          *ngFor="let child of item.items"
          [text]="child.title"
          [icon]="child.icon"
          [active]="child === currentItem"
          [visibled]="isVisible(item, child)"
          [isValid]="isValid(item, child)"
          (onClick)="selectionChanged(child)"
          [isBadge]="isBadge(item)"
        ></clc-nav-menu-item>
      </clc-nav-group>
    </clc-nav-menu>
  </cl-work-page-left>
  <cl-work-page-content>
    <div class="work-item-container" [formGroup]="formGroup">
      <cl-mobile-nav-menu
        *ngIf="enableMobileMode === true"
        [(isMenuOpen)]="isMobileMenuOpen"
        [navItems]="items"
        [state]="state"
        (menuNavMobileVisibility)="handleResultMobile($event)"
      ></cl-mobile-nav-menu>
      <cl-mobile-actions
        *ngIf="enableMobileMode === true"
        [title]="'save' | translate"
        icon="fa-solid fa-floppy-disk-circle-xmark"
        [visible]="formPending === false && canSave$ | async"
        (onClick)="submit()"
        [index]="1"
      ></cl-mobile-actions>
      <ng-content select="cl-mobile-actions"></ng-content>

      <!-- Template pour afficher 1 host -->
      <ng-template #hostTemplate let-item let-fg="fg" let-parent="parent">
        <div
          *ngIf="item.items == undefined"
          [ngClass]="{ 'work-item-content-hide': item !== activeItem?.item }"
          class="work-item-content"
        >
          <div
            *ngIf="fg.get(item.name) != undefined"
            [formGroup]="fg.get(item.name)"
          >
            <ng-template clc-work-item-host></ng-template>
          </div>
        </div>
        <div
          *ngIf="
            item.title != undefined && item.items != undefined as value;
            else noTitle
          "
          [formGroup]="fg.get(item.name)"
        >
          <ng-container
            *ngTemplateOutlet="
              hostsTemplate;
              context: {
                $implicit: item.items,
                fg: fg.get(item.name),
                parent: item
              }
            "
          >
          </ng-container>
        </div>
        <ng-template #noTitle>
          <ng-container
            *ngTemplateOutlet="
              hostsTemplate;
              context: { $implicit: item.items, fg: fg }
            "
          >
          </ng-container>
        </ng-template>
      </ng-template>
      <!-- Template pour afficher les hosts -->
      <ng-template #hostsTemplate let-items let-fg="fg" let-parent="parent">
        <ng-container
          *ngFor="let item of items"
          [ngTemplateOutlet]="hostTemplate"
          [ngTemplateOutletContext]="{
            $implicit: item,
            fg: fg,
            parent: parent
          }"
        ></ng-container>
      </ng-template>
      <!-- Applique un template pour les items -->
      <ng-container
        [ngIf]="formGroup != undefined"
        *ngTemplateOutlet="
          hostsTemplate;
          context: { $implicit: items, fg: formGroup }
        "
      ></ng-container>
    </div>
  </cl-work-page-content>
</cl-work-page>
<!-- <dx-load-panel
  shadingColor="rgba(0,0,0,0.1)"
  [visible]="!formGroup.root.loaded"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
></dx-load-panel> -->
