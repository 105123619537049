import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSmtpConnectorSystemsBaseVariables, FirstSmtpConnectorSystemsBaseVariables, CountSmtpConnectorSystemsBaseVariables, FindSmtpConnectorSystemsBaseVariables, SearchSmtpConnectorSystemsBaseVariables, ExportSchemaSmtpConnectorSystemsBaseVariables, ExportDataSmtpConnectorSystemsBaseVariables, CustomQuerySmtpConnectorSystemsBaseVariables, CustomQueryIdSmtpConnectorSystemsBaseVariables, UsedSmtpConnectorSystemsBaseVariables, ExistSmtpConnectorSystemsBaseVariables, InsertSmtpConnectorSystemsBaseVariables, UpdateSmtpConnectorSystemsBaseVariables, ImportDataSmtpConnectorSystemsBaseVariables, UpdateManySmtpConnectorSystemsBaseVariables, DeleteSmtpConnectorSystemsBaseVariables, OrderSmtpConnectorSystemsBaseVariables, CheckParamSmtpConnectorSystemsBaseVariables, ResetPasswordSmtpConnectorSystemsBaseVariables } from '../gqls'
import { GetSmtpConnectorSystemsDocument, FirstSmtpConnectorSystemsDocument, CountSmtpConnectorSystemsDocument, FindSmtpConnectorSystemsDocument, SearchSmtpConnectorSystemsDocument, ExportSchemaSmtpConnectorSystemsDocument, ExportDataSmtpConnectorSystemsDocument, CustomQuerySmtpConnectorSystemsDocument, CustomQueryIdSmtpConnectorSystemsDocument, UsedSmtpConnectorSystemsDocument, ExistSmtpConnectorSystemsDocument, InsertSmtpConnectorSystemsDocument, UpdateSmtpConnectorSystemsDocument, ImportDataSmtpConnectorSystemsDocument, UpdateManySmtpConnectorSystemsDocument, DeleteSmtpConnectorSystemsDocument, OrderSmtpConnectorSystemsDocument, CheckParamSmtpConnectorSystemsDocument, ResetPasswordSmtpConnectorSystemsDocument } from '../gqls'
import { ServiceSingleResultOfSmtpConnectorSystem, QueryContextOfSmtpConnectorSystem, FilterOfSmtpConnectorSystem, ServiceSingleResultOfInt64, ServiceListResultOfSmtpConnectorSystem, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, SmtpConnectorSystemInput, FieldUpdateOperatorOfSmtpConnectorSystem } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SmtpConnectorSystemBaseService {
    
public modelName = 'smtpConnectorSystem';
public modelPluralName = 'smtpConnectorSystems';

	private getSmtpConnectorSystemsQuery: GetSmtpConnectorSystemsDocument;
	private firstSmtpConnectorSystemsQuery: FirstSmtpConnectorSystemsDocument;
	private countSmtpConnectorSystemsQuery: CountSmtpConnectorSystemsDocument;
	private findSmtpConnectorSystemsQuery: FindSmtpConnectorSystemsDocument;
	private searchSmtpConnectorSystemsQuery: SearchSmtpConnectorSystemsDocument;
	private exportSchemaSmtpConnectorSystemsQuery: ExportSchemaSmtpConnectorSystemsDocument;
	private exportDataSmtpConnectorSystemsQuery: ExportDataSmtpConnectorSystemsDocument;
	private customQuerySmtpConnectorSystemsQuery: CustomQuerySmtpConnectorSystemsDocument;
	private customQueryIdSmtpConnectorSystemsQuery: CustomQueryIdSmtpConnectorSystemsDocument;
	private usedSmtpConnectorSystemsQuery: UsedSmtpConnectorSystemsDocument;
	private existSmtpConnectorSystemsQuery: ExistSmtpConnectorSystemsDocument;
	private insertSmtpConnectorSystemsMutation: InsertSmtpConnectorSystemsDocument;
	private updateSmtpConnectorSystemsMutation: UpdateSmtpConnectorSystemsDocument;
	private importDataSmtpConnectorSystemsMutation: ImportDataSmtpConnectorSystemsDocument;
	private updateManySmtpConnectorSystemsMutation: UpdateManySmtpConnectorSystemsDocument;
	private deleteSmtpConnectorSystemsMutation: DeleteSmtpConnectorSystemsDocument;
	private orderSmtpConnectorSystemsMutation: OrderSmtpConnectorSystemsDocument;
	private checkParamSmtpConnectorSystemsMutation: CheckParamSmtpConnectorSystemsDocument;
	private resetPasswordSmtpConnectorSystemsMutation: ResetPasswordSmtpConnectorSystemsDocument;

	constructor(private injector: Injector) {
		this.getSmtpConnectorSystemsQuery = this.injector.get(GetSmtpConnectorSystemsDocument);
		this.firstSmtpConnectorSystemsQuery = this.injector.get(FirstSmtpConnectorSystemsDocument);
		this.countSmtpConnectorSystemsQuery = this.injector.get(CountSmtpConnectorSystemsDocument);
		this.findSmtpConnectorSystemsQuery = this.injector.get(FindSmtpConnectorSystemsDocument);
		this.searchSmtpConnectorSystemsQuery = this.injector.get(SearchSmtpConnectorSystemsDocument);
		this.exportSchemaSmtpConnectorSystemsQuery = this.injector.get(ExportSchemaSmtpConnectorSystemsDocument);
		this.exportDataSmtpConnectorSystemsQuery = this.injector.get(ExportDataSmtpConnectorSystemsDocument);
		this.customQuerySmtpConnectorSystemsQuery = this.injector.get(CustomQuerySmtpConnectorSystemsDocument);
		this.customQueryIdSmtpConnectorSystemsQuery = this.injector.get(CustomQueryIdSmtpConnectorSystemsDocument);
		this.usedSmtpConnectorSystemsQuery = this.injector.get(UsedSmtpConnectorSystemsDocument);
		this.existSmtpConnectorSystemsQuery = this.injector.get(ExistSmtpConnectorSystemsDocument);
		this.insertSmtpConnectorSystemsMutation = this.injector.get(InsertSmtpConnectorSystemsDocument);
		this.updateSmtpConnectorSystemsMutation = this.injector.get(UpdateSmtpConnectorSystemsDocument);
		this.importDataSmtpConnectorSystemsMutation = this.injector.get(ImportDataSmtpConnectorSystemsDocument);
		this.updateManySmtpConnectorSystemsMutation = this.injector.get(UpdateManySmtpConnectorSystemsDocument);
		this.deleteSmtpConnectorSystemsMutation = this.injector.get(DeleteSmtpConnectorSystemsDocument);
		this.orderSmtpConnectorSystemsMutation = this.injector.get(OrderSmtpConnectorSystemsDocument);
		this.checkParamSmtpConnectorSystemsMutation = this.injector.get(CheckParamSmtpConnectorSystemsDocument);
		this.resetPasswordSmtpConnectorSystemsMutation = this.injector.get(ResetPasswordSmtpConnectorSystemsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSmtpConnectorSystem> {
    
    		let variables: GetSmtpConnectorSystemsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSmtpConnectorSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.smtpConnectorSystems.get));
            }
            else{
                let result:ServiceSingleResultOfSmtpConnectorSystem={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSmtpConnectorSystem,
		@Args('filter?') filter?: FilterOfSmtpConnectorSystem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSmtpConnectorSystem> {
    
    		let variables: FirstSmtpConnectorSystemsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSmtpConnectorSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.smtpConnectorSystems.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSmtpConnectorSystem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSmtpConnectorSystemsBaseVariables = {
    			filter: filter
    		}
    				return this.countSmtpConnectorSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.smtpConnectorSystems.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSmtpConnectorSystem,
		@Args('filter?') filter?: FilterOfSmtpConnectorSystem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSmtpConnectorSystem> {
    
    		let variables: FindSmtpConnectorSystemsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSmtpConnectorSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.smtpConnectorSystems.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSmtpConnectorSystem,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSmtpConnectorSystem> {
    
    		let variables: SearchSmtpConnectorSystemsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSmtpConnectorSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.smtpConnectorSystems.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSmtpConnectorSystemsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSmtpConnectorSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.smtpConnectorSystems.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSmtpConnectorSystem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSmtpConnectorSystemsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSmtpConnectorSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.smtpConnectorSystems.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSmtpConnectorSystem,
		@Args('filter?') filter?: FilterOfSmtpConnectorSystem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSmtpConnectorSystem> {
    
    		let variables: CustomQuerySmtpConnectorSystemsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySmtpConnectorSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.smtpConnectorSystems.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSmtpConnectorSystem,
		@Args('filter?') filter?: FilterOfSmtpConnectorSystem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSmtpConnectorSystem> {
    
    		let variables: CustomQueryIdSmtpConnectorSystemsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSmtpConnectorSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.smtpConnectorSystems.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSmtpConnectorSystemsBaseVariables = {
    			ids: ids
    		}
    				return this.usedSmtpConnectorSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.smtpConnectorSystems.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSmtpConnectorSystemsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSmtpConnectorSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.smtpConnectorSystems.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: SmtpConnectorSystemInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSmtpConnectorSystem> {
    
    		let variables: InsertSmtpConnectorSystemsBaseVariables = {
    			entity: entity
    		}
    				return this.insertSmtpConnectorSystemsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.smtpConnectorSystems.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfSmtpConnectorSystem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSmtpConnectorSystem> {
    
    		let variables: UpdateSmtpConnectorSystemsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateSmtpConnectorSystemsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.smtpConnectorSystems.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataSmtpConnectorSystemsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataSmtpConnectorSystemsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.smtpConnectorSystems.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfSmtpConnectorSystem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManySmtpConnectorSystemsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManySmtpConnectorSystemsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.smtpConnectorSystems.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteSmtpConnectorSystemsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteSmtpConnectorSystemsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.smtpConnectorSystems.delete));
    	}

    	/** Permet d'ordonner l'entité. */
    	@InjectArgs
    	public order(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fromId') fromId: string,
		@Args('toId?') toId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: OrderSmtpConnectorSystemsBaseVariables = {
    			fromId: fromId,
			toId: toId
    		}
    				return this.orderSmtpConnectorSystemsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.smtpConnectorSystems.order));
    	}

    	/**  */
    	@InjectArgs
    	public checkParam(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: CheckParamSmtpConnectorSystemsBaseVariables = {
    			id: id
    		}
    				return this.checkParamSmtpConnectorSystemsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.smtpConnectorSystems.checkParam));
    	}

    	/**  */
    	@InjectArgs
    	public resetPassword(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('type?') type?: string,
		@Args('newPassword?') newPassword?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ResetPasswordSmtpConnectorSystemsBaseVariables = {
    			id: id,
			newPassword: newPassword,
			type: type
    		}
    				return this.resetPasswordSmtpConnectorSystemsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.smtpConnectorSystems.resetPassword));
    	}
    
}