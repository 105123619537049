import {
  Component,
  forwardRef,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EnvironmentService, FileModelType } from '@clarilog/core';
import { GqlField } from '@clarilog/core/services2/graphql/generated-types/helpers';
import { TicketCoreService } from '@clarilog/core/services2/graphql/generated-types/services';
import { FileManagerCoreService } from '@clarilog/core/services2/graphql/generated-types/services/file-manager.service';
import { OrganizationStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/organization-storage-service';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import {
  ModelDataSourceContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import DataSource from 'devextreme/data/data_source';
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider';
import { mimeTypes } from 'mime-wrapper';
import { CoreDynamicPopUpComponent } from '../dynamic-popup/dynamic-popup.component';
import { FileViewerType } from '../file-manager/viewer/file-manager-viewer.component';
import { FormGroupHelpers } from '../form/work-form/form-group-helpers';
import { ItemsValue } from '../form/work-form/form-link-array';
import commentaryModel from './commentary.model.json';
/** Représente la classe du composent cl-file-manager. */
@Component({
  selector: 'clc-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss'],
  viewProviders: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreFileManagerComponent),
      multi: true,
    },
  ],
})
export class CoreFileManagerComponent implements ControlValueAccessor, OnInit {
  /** Représente la valeur. */
  _value: ItemsValue;
  _filesItem: FileModelType[] = [];
  _tempFileId: any;
  fileProvider: CustomFileSystemProvider;
  allowedFileExtensions: any[];
  forbidenFileExtensions: any[];
  instanceFileComponent: any;
  dataSource: DataSource;
  /** Représente les valeur pour le viewer. */
  fileViewerInfo: FileViewerType;
  message: string;

  /** Obtient ou définit la source de données. */
  @Input() source: ModelDataSourceContext;
  // /** Obtient ou définit les éléments ajoutés. */
  @Input() itemsAdded: any[] = [];
  // /** Obtient ou définit les éléments supprimés. */
  @Input() itemsRemoved: any[] = [];
  @Input() canRemove: boolean = true;
  @Input() canCommentary;
  @Input() canVisibleHelpDesk;
  @Input() canOutgoingEmails;
  @Input() readOnly: boolean = false;
  @Input() alert: boolean = false;
  @Input() modelState: ModelState;
  _filesItem_Added: FileModelType[] = [];
  _filesItem_Removed: FileModelType[] = [];

  isCommentaryVisible: boolean = false;
  commentaryModel: any;
  lastfileId: any;
  titleCommentaryPopUp: string;
  commentaryBtnOptions: any;
  visibleHelpDeskUserBtnOptions: any;
  outgoingEmailsBtnOptions: any;
  isViewHelpMe: boolean = false;
  entityId: string = undefined;
  disabledEmailfunction: boolean = false;

  @ViewChild(CoreDynamicPopUpComponent, { static: true })
  popupDynamic: CoreDynamicPopUpComponent;
  control: AbstractControl;
  uploadFiles: number;
  maxSize: number;
  get value(): ItemsValue {
    return this._value;
  }
  set value(value: ItemsValue) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
  }
  /** Définit l'injector */
  injector: Injector;
  constructor(
    private route: ActivatedRoute,
    public fileManagerService: FileManagerCoreService,
    private ticketService: TicketCoreService,
    injector: Injector,
    private organizationStorageService: OrganizationStorageService,
    private _envService: EnvironmentService,
  ) {
    this.maxSize = this.organizationStorageService.getMaxFileSize();
    this.allowedFileExtensions =
      this.organizationStorageService.getAllowedFileExtension();
    this.forbidenFileExtensions =
      this.organizationStorageService.getForbiddenFileExtension();
    this.injector = injector;
    this.fileProvider = this.getFileProvider();
    this.commentaryModel = commentaryModel;
    this.commentaryBtnOptions = {
      items: [
        {
          text: TranslateService.get('entities/files/commentary'),
          icon: 'edit',
        },
      ],
      onItemClick: this.openCommentary.bind(this),
    };
    this.visibleHelpDeskUserBtnOptions = {
      items: [
        {
          text: TranslateService.get('entities/files/visibleHelpDesk'),
          icon: 'user',
        },
      ],
      onItemClick: this.updateVisibilityHelpDesk.bind(this),
    };
    this.outgoingEmailsBtnOptions = {
      items: [
        {
          text: TranslateService.get('entities/files/outgoingEmails'),
          icon: 'exportselected',
        },
      ],
      onItemClick: this.updateOutgoingEmails.bind(this),
    };
    this.isViewHelpMe =
      localStorage.getItem('hasHelpDeskUser') == 'true' ? true : false;
  }

  async onInitialized(e) {
    this.instanceFileComponent = e.component;
    this.dataSource = <DataSource>(<unknown>this.source.datasource);
  }

  contentReady(e) {
    var elements = document.getElementsByClassName(
      'dx-filemanager-details-item-thumbnail',
    );

    var grid = e.component._itemView._filesView.instance();
    grid.option('onKeyDown', (e1) => {
      if (e1.event.keyCode == 39 || e1.event.keyCode == 37) {
        e1.event.preventDefault();
      }
    });
  }
  convertKBToKO(e: string, forceNull: boolean = false) {
    if (e != undefined && e.trim() !== '') {
      const parts = e.split(' ');
      const value = parseFloat(parts[0]);
      const unit = parts[1].trim().toLowerCase();
      switch (unit) {
        case 'b':
          return value + ' o';
        case 'kb':
          return value + ' Ko';
        case 'mb':
          return value + ' Mo';
        case 'gb':
          return value + ' Go';
        default:
          return e;
      }
    }

    if (forceNull) {
      return ' 0 o';
    }
    return ' ';
  }

  customizeDetailColumns(columns) {
    columns[1].cellTemplate = 'fileOpenCellTemplate';
    columns[3].cellTemplate = 'sizeTemplate';
    if (
      columns.findIndex(
        (d) => d.dataField == 'metaData.visibleByHelpDeskUser',
      ) != -1
    ) {
      columns[
        columns.findIndex(
          (d) => d.dataField == 'metaData.visibleByHelpDeskUser',
        )
      ].cellTemplate = 'fileCheckTemplate';
    }
    if (
      columns.findIndex((d) => d.dataField == 'metaData.outgoingEmails') != -1
    ) {
      columns[
        columns.findIndex((d) => d.dataField == 'metaData.outgoingEmails')
      ].cellTemplate = 'fileCheckTemplate';
    }
    return columns;
  }

  async ngOnInit() {
    if (this.modelState.isSubForm == false) {
      this.entityId = this.route.snapshot.paramMap.get('id');
    } else {
      this.entityId = this.modelState.id;
    }
    if (this.entityId != null) {
      await this.ticketService
        .isEndingTicket([GqlField.create('data')], this.entityId)
        .subscribe((response) => {
          if (response.data) {
            this.disabledEmailfunction = true;
          }
        });
    }
    this.control = FormGroupHelpers.formControlByName(
      this.modelState.form,
      'fileIds',
    );
    this.control.setValidators([this.isValid.bind(this)]);
    this.uploadFiles = 0;
  }

  isValid(control: AbstractControl): ValidationErrors | null {
    if (this.uploadFiles > 0) {
      return { invalidMsg: TranslateService.get(`errors/uploadInProgress`) };
    }
    return null;
  }

  writeValue(value: any): void {
    if (value != undefined) {
      this.value = value;
    } else {
      value = {
        itemsAdded: [],
        itemsRemoved: [],
        itemsUpdated: [],
      };
      this.value = value;
      this.itemsRemoved.clear();
      this.itemsAdded.clear();
      this._filesItem_Removed.clear();
      this._filesItem_Added.clear();
    }
    if (value?.itemsRemoved?.length === 0) {
      this.itemsRemoved.clear();
      this._filesItem_Removed.clear();
    }
    if (value?.itemsAdded?.length === 0) {
      this.itemsAdded.clear();
      this._filesItem_Added.clear();
    }
    if (value?.itemsFilesAdded != undefined) {
      this._filesItem_Added = this._filesItem_Added.concat(
        value['itemsFilesAdded'],
      );
      let filesItem_AddedUnique = [
        ...new Map(
          this._filesItem_Added.map((item) => [item['_tempFileId'], item]),
        ).values(),
      ];
      this._filesItem_Added = filesItem_AddedUnique;
      this.refresh();
    } else {
      this._filesItem_Added.clear();
    }
  }
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  onTouched: any = () => {};
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /** Permet de modifier les items de add. */
  private setItemsAdded(items: any, data: any) {
    this._value.itemsAdded.push(items);
    if (this._value['itemsFilesAdded'] == undefined) {
      this._value['itemsFilesAdded'] = [];
    }
    this._value['itemsFilesAdded'].push(data);
    this.onChange(this._value);
    this.onTouched();
  }
  /** Permet de modifier les items de remove. */
  private setItemsRemoved(items: any[]) {
    this._value.itemsRemoved.push(items);
    this.onChange(this._value);
    this.onTouched();
  }

  getFileProvider() {
    let cp = new CustomFileSystemProvider({
      getItems: async (pathInfo) => {
        this._filesItem = await this.dataSource.load();
        this._filesItem = this._filesItem.concat(this._filesItem_Added);
        this._filesItem_Removed.forEach(function (f) {
          let index = this._filesItem.indexOf(f);
          if (index == -1) {
            index = this._filesItem.findIndex((e) => e.fileId == f.fileId);
          }
          if (index > -1) {
            this._filesItem.splice(index, 1);
          }
        }, this);
        return this._filesItem;
      },

      uploadFileChunk: async (fileData, chunksInfo, destinationDir) => {
        let type = mimeTypes.getExtension(fileData.type);
        if (type != undefined && type != '') {
          type = '.' + mimeTypes.getExtension(fileData.type);
        } else {
          type = fileData.name.substring(fileData.name.lastIndexOf('.'));
        }

        if (this.forbidenFileExtensions?.includes(type)) {
          this.instanceFileComponent._showNotification(
            TranslateService.get('entities/files/forbiddenFiles'),
            false,
          );
          return;
        }
        if (chunksInfo.bytesUploaded == 0) {
          fileData['_tempFileId'] = null;
          this.uploadFiles += 1;
          this.onChange(this._value);
        }
        let chunks = await this.readFileAsync(chunksInfo);
        let lastChunk = chunksInfo.chunkIndex == chunksInfo.chunkCount - 1;
        let fields = [GqlField.create('data')];

        // TODO check
        fileData['_tempFileId'] = await this.injector
          .get(this.source.serviceName)
          ['uploadChunkFile'](
            fields,
            true,
            fileData.size,
            true,
            lastChunk,
            chunksInfo.chunkIndex,
            fileData.type,
            fileData.name,
            fileData['_tempFileId']?.data,
            this.entityId,
            chunks,
          )
          .toPromise();
        if (chunksInfo.chunkCount == chunksInfo.chunkIndex + 1) {
          (<any>fileData).fileId = fileData['_tempFileId']?.data;
          fileData['metaData'] = {
            visibleByHelpDeskUser: true,
            outgoingEmails: true,
          };
          this._filesItem_Added.push(fileData);
          this.uploadFiles -= 1;
          this.setItemsAdded(fileData['_tempFileId'].data, fileData);
        }
      },
      deleteItem: (item) => {
        var fileElement = this._filesItem.find(
          (e) => e.fileId == item.dataItem.fileId,
        );

        var index = this._filesItem.indexOf(fileElement);
        if (index > -1) {
          this._filesItem.splice(index, 1);
        }
        var indexAdded = this._value.itemsAdded.indexOf(item.dataItem.fileId);
        if (indexAdded > -1) {
          this._value.itemsAdded.splice(indexAdded, 1);
        }
        this._filesItem_Removed.push(fileElement);
        this.setItemsRemoved(item.dataItem.fileId);
      },
      downloadItems: async (items) => {
        items.forEach(async (item) => {
          this.downloadFile(item.dataItem);
        });
      },
    });
    return cp;
  }

  /** Permet de lire le fichier pour l'envoyer. */
  async readFileAsync(chunksInfo): Promise<string> {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = (file) => {
        let result = btoa(reader.result.toString());
        resolve(result);
      };
      reader.onerror = reject;
      reader.readAsBinaryString(chunksInfo.chunkBlob);
    });
  }

  /** Permet de reconstituer le fichier. */
  downloadFile(fileInfo) {
    let uri = `${this._envService.apiURL}/file/download/${fileInfo.fileId}`;
    var link = document.createElement('a');
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', fileInfo.name);
    link.href = uri;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  /** Permet de visualiser le fichier. */
  onSelectedFileOpened = (e) => {
    this.fileViewerInfo = {
      display: true,
      file: e.data.fileItem.dataItem,
      files: this._filesItem,
    };
  };

  refresh() {
    if (this.instanceFileComponent != undefined) {
      this.instanceFileComponent.refresh();
    }
  }

  ngDoCheck() {
    if (this._filesItem.length > 0) {
      this.message = null;
    }
    if (this._filesItem.length == 0 && this.alert == true) {
      this.message = TranslateService.get(`errors/requiredFile`);
    }
  }
  onFormLoaded(e) {
    let selected = this.instanceFileComponent.getSelectedItems();
    if (selected.length == 1) {
      let commentaryField = FormGroupHelpers.formControlByName(
        this.popupDynamic.model.form,
        'commentary',
      );

      let comment =
        selected[0].dataItem.metaData !== undefined
          ? selected[0].dataItem.metaData.commentary
          : selected[0].dataItem.commentary;

      commentaryField.setValue(comment, {
        emitEvent: false,
      });
    }
  }
  openCommentary() {
    this.isCommentaryVisible = true;
  }

  savedCommentary(e) {
    let files = this.instanceFileComponent.getSelectedItems();
    let fileIds = files.map((m) => m.dataItem.fileId);

    files.forEach((f) => {
      if (f.dataItem.uploaded === undefined || f.dataItem.uploaded === null) {
        if (f.dataItem.metaData === undefined || f.dataItem.metaData === null) {
          f.dataItem.metaData = {
            commentary: e.commentary,
          };
        } else {
          f.dataItem.metaData.commentary = e.commentary;
        }
      }
    });

    this.fileManagerService
      .addCommentary(
        ModelFieldCompilerService.createServiceSingleResultScalar(),
        fileIds,
        e.commentary,
      )
      .subscribe((result) => {
        this.isCommentaryVisible = false;
        let files = this._filesItem_Added.filter((x) =>
          fileIds.includes(x.fileId),
        );

        files.forEach((f) => (f.commentary = e.commentary));

        this.refresh();
      });
  }

  updateVisibilityHelpDesk(e) {
    let files = this.instanceFileComponent.getSelectedItems();
    let fileIds = files.map((m) => m.dataItem.fileId);

    this.fileManagerService
      .updateVisibilityHelpDesk(
        ModelFieldCompilerService.createServiceSingleResultScalar(),
        fileIds,
      )
      .subscribe((result) => {
        let files = this._filesItem_Added.filter((x) =>
          fileIds.includes(x.fileId),
        );
        files.forEach(
          (f) =>
            (f.metaData.visibleByHelpDeskUser =
              !f.metaData.visibleByHelpDeskUser),
        );
        if (this.instanceFileComponent != undefined) {
          this.instanceFileComponent.refresh();
        }
      });
  }

  updateOutgoingEmails(e) {
    let files = this.instanceFileComponent.getSelectedItems();
    let fileIds = files.map((m) => m.dataItem.fileId);

    this.fileManagerService
      .updateOutgoingEmails(
        ModelFieldCompilerService.createServiceSingleResultScalar(),
        fileIds,
      )
      .subscribe((result) => {
        let files = this._filesItem_Added.filter((x) =>
          fileIds.includes(x.fileId),
        );
        files.forEach(
          (f) => (f.metaData.outgoingEmails = !f.metaData.outgoingEmails),
        );
        this.refresh();
      });
  }
}
