import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetScheduleTasksBaseVariables, FirstScheduleTasksBaseVariables, CountScheduleTasksBaseVariables, FindScheduleTasksBaseVariables, SearchScheduleTasksBaseVariables, ExportSchemaScheduleTasksBaseVariables, ExportDataScheduleTasksBaseVariables, CustomQueryScheduleTasksBaseVariables, CustomQueryIdScheduleTasksBaseVariables, UsedScheduleTasksBaseVariables, ExistScheduleTasksBaseVariables, UpdateManyScheduleTasksBaseVariables, DeleteScheduleTasksBaseVariables, ExecuteTaskByIdScheduleTasksBaseVariables } from '../gqls'
import { GetScheduleTasksDocument, FirstScheduleTasksDocument, CountScheduleTasksDocument, FindScheduleTasksDocument, SearchScheduleTasksDocument, ExportSchemaScheduleTasksDocument, ExportDataScheduleTasksDocument, CustomQueryScheduleTasksDocument, CustomQueryIdScheduleTasksDocument, UsedScheduleTasksDocument, ExistScheduleTasksDocument, UpdateManyScheduleTasksDocument, DeleteScheduleTasksDocument, ExecuteTaskByIdScheduleTasksDocument } from '../gqls'
import { ServiceSingleResultOfScheduleTask, QueryContextOfScheduleTask, FilterOfScheduleTask, ServiceSingleResultOfInt64, ServiceListResultOfScheduleTask, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, FieldUpdateOperatorOfScheduleTask } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ScheduleTaskBaseService {
    
public modelName = 'scheduleTask';
public modelPluralName = 'scheduleTasks';

	private getScheduleTasksQuery: GetScheduleTasksDocument;
	private firstScheduleTasksQuery: FirstScheduleTasksDocument;
	private countScheduleTasksQuery: CountScheduleTasksDocument;
	private findScheduleTasksQuery: FindScheduleTasksDocument;
	private searchScheduleTasksQuery: SearchScheduleTasksDocument;
	private exportSchemaScheduleTasksQuery: ExportSchemaScheduleTasksDocument;
	private exportDataScheduleTasksQuery: ExportDataScheduleTasksDocument;
	private customQueryScheduleTasksQuery: CustomQueryScheduleTasksDocument;
	private customQueryIdScheduleTasksQuery: CustomQueryIdScheduleTasksDocument;
	private usedScheduleTasksQuery: UsedScheduleTasksDocument;
	private existScheduleTasksQuery: ExistScheduleTasksDocument;
	private updateManyScheduleTasksMutation: UpdateManyScheduleTasksDocument;
	private deleteScheduleTasksMutation: DeleteScheduleTasksDocument;
	private executeTaskByIdScheduleTasksMutation: ExecuteTaskByIdScheduleTasksDocument;

	constructor(private injector: Injector) {
		this.getScheduleTasksQuery = this.injector.get(GetScheduleTasksDocument);
		this.firstScheduleTasksQuery = this.injector.get(FirstScheduleTasksDocument);
		this.countScheduleTasksQuery = this.injector.get(CountScheduleTasksDocument);
		this.findScheduleTasksQuery = this.injector.get(FindScheduleTasksDocument);
		this.searchScheduleTasksQuery = this.injector.get(SearchScheduleTasksDocument);
		this.exportSchemaScheduleTasksQuery = this.injector.get(ExportSchemaScheduleTasksDocument);
		this.exportDataScheduleTasksQuery = this.injector.get(ExportDataScheduleTasksDocument);
		this.customQueryScheduleTasksQuery = this.injector.get(CustomQueryScheduleTasksDocument);
		this.customQueryIdScheduleTasksQuery = this.injector.get(CustomQueryIdScheduleTasksDocument);
		this.usedScheduleTasksQuery = this.injector.get(UsedScheduleTasksDocument);
		this.existScheduleTasksQuery = this.injector.get(ExistScheduleTasksDocument);
		this.updateManyScheduleTasksMutation = this.injector.get(UpdateManyScheduleTasksDocument);
		this.deleteScheduleTasksMutation = this.injector.get(DeleteScheduleTasksDocument);
		this.executeTaskByIdScheduleTasksMutation = this.injector.get(ExecuteTaskByIdScheduleTasksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScheduleTask> {
    
    		let variables: GetScheduleTasksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getScheduleTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTasks.get));
            }
            else{
                let result:ServiceSingleResultOfScheduleTask={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScheduleTask,
		@Args('filter?') filter?: FilterOfScheduleTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScheduleTask> {
    
    		let variables: FirstScheduleTasksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstScheduleTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTasks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScheduleTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountScheduleTasksBaseVariables = {
    			filter: filter
    		}
    				return this.countScheduleTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTasks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScheduleTask,
		@Args('filter?') filter?: FilterOfScheduleTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScheduleTask> {
    
    		let variables: FindScheduleTasksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findScheduleTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTasks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfScheduleTask,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScheduleTask> {
    
    		let variables: SearchScheduleTasksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchScheduleTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTasks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaScheduleTasksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaScheduleTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTasks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScheduleTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataScheduleTasksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataScheduleTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTasks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfScheduleTask,
		@Args('filter?') filter?: FilterOfScheduleTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScheduleTask> {
    
    		let variables: CustomQueryScheduleTasksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryScheduleTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTasks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfScheduleTask,
		@Args('filter?') filter?: FilterOfScheduleTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScheduleTask> {
    
    		let variables: CustomQueryIdScheduleTasksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdScheduleTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTasks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedScheduleTasksBaseVariables = {
    			ids: ids
    		}
    				return this.usedScheduleTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTasks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistScheduleTasksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existScheduleTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTasks.exist));
    	}
    
    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfScheduleTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyScheduleTasksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyScheduleTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTasks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteScheduleTasksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteScheduleTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTasks.delete));
    	}

    	/**  */
    	@InjectArgs
    	public executeTaskById(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExecuteTaskByIdScheduleTasksBaseVariables = {
    			id: id
    		}
    				return this.executeTaskByIdScheduleTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scheduleTasks.executeTaskById));
    	}
    
}