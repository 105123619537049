import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWorkflowLinkItemsBaseVariables, FirstWorkflowLinkItemsBaseVariables, CountWorkflowLinkItemsBaseVariables, FindWorkflowLinkItemsBaseVariables, SearchWorkflowLinkItemsBaseVariables, ExportSchemaWorkflowLinkItemsBaseVariables, ExportDataWorkflowLinkItemsBaseVariables, CustomQueryWorkflowLinkItemsBaseVariables, CustomQueryIdWorkflowLinkItemsBaseVariables, UsedWorkflowLinkItemsBaseVariables, FindNotUsersWorkflowLinkItemsBaseVariables, ExistWorkflowLinkItemsBaseVariables, FindUnassociatedUsersWorkflowLinkItemsBaseVariables, InsertWorkflowLinkItemsBaseVariables, UpdateWorkflowLinkItemsBaseVariables, ImportDataWorkflowLinkItemsBaseVariables, UpdateManyWorkflowLinkItemsBaseVariables, DeleteWorkflowLinkItemsBaseVariables, AddUsersWorkflowLinkItemsBaseVariables, RemoveUsersWorkflowLinkItemsBaseVariables } from '../gqls'
import { GetWorkflowLinkItemsDocument, FirstWorkflowLinkItemsDocument, CountWorkflowLinkItemsDocument, FindWorkflowLinkItemsDocument, SearchWorkflowLinkItemsDocument, ExportSchemaWorkflowLinkItemsDocument, ExportDataWorkflowLinkItemsDocument, CustomQueryWorkflowLinkItemsDocument, CustomQueryIdWorkflowLinkItemsDocument, UsedWorkflowLinkItemsDocument, FindNotUsersWorkflowLinkItemsDocument, ExistWorkflowLinkItemsDocument, FindUnassociatedUsersWorkflowLinkItemsDocument, InsertWorkflowLinkItemsDocument, UpdateWorkflowLinkItemsDocument, ImportDataWorkflowLinkItemsDocument, UpdateManyWorkflowLinkItemsDocument, DeleteWorkflowLinkItemsDocument, AddUsersWorkflowLinkItemsDocument, RemoveUsersWorkflowLinkItemsDocument } from '../gqls'
import { ServiceSingleResultOfWorkflowLinkItem, QueryContextOfWorkflowLinkItem, FilterOfWorkflowLinkItem, ServiceSingleResultOfInt64, ServiceListResultOfWorkflowLinkItem, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfUser, QueryContextOfUser, FilterOfUser, WorkflowLinkItemInput, FieldUpdateOperatorOfWorkflowLinkItem } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WorkflowLinkItemBaseService {
    
public modelName = 'workflowLinkItem';
public modelPluralName = 'workflowLinkItems';

	private getWorkflowLinkItemsQuery: GetWorkflowLinkItemsDocument;
	private firstWorkflowLinkItemsQuery: FirstWorkflowLinkItemsDocument;
	private countWorkflowLinkItemsQuery: CountWorkflowLinkItemsDocument;
	private findWorkflowLinkItemsQuery: FindWorkflowLinkItemsDocument;
	private searchWorkflowLinkItemsQuery: SearchWorkflowLinkItemsDocument;
	private exportSchemaWorkflowLinkItemsQuery: ExportSchemaWorkflowLinkItemsDocument;
	private exportDataWorkflowLinkItemsQuery: ExportDataWorkflowLinkItemsDocument;
	private customQueryWorkflowLinkItemsQuery: CustomQueryWorkflowLinkItemsDocument;
	private customQueryIdWorkflowLinkItemsQuery: CustomQueryIdWorkflowLinkItemsDocument;
	private usedWorkflowLinkItemsQuery: UsedWorkflowLinkItemsDocument;
	private findNotUsersWorkflowLinkItemsQuery: FindNotUsersWorkflowLinkItemsDocument;
	private existWorkflowLinkItemsQuery: ExistWorkflowLinkItemsDocument;
	private findUnassociatedUsersWorkflowLinkItemsQuery: FindUnassociatedUsersWorkflowLinkItemsDocument;
	private insertWorkflowLinkItemsMutation: InsertWorkflowLinkItemsDocument;
	private updateWorkflowLinkItemsMutation: UpdateWorkflowLinkItemsDocument;
	private importDataWorkflowLinkItemsMutation: ImportDataWorkflowLinkItemsDocument;
	private updateManyWorkflowLinkItemsMutation: UpdateManyWorkflowLinkItemsDocument;
	private deleteWorkflowLinkItemsMutation: DeleteWorkflowLinkItemsDocument;
	private addUsersWorkflowLinkItemsMutation: AddUsersWorkflowLinkItemsDocument;
	private removeUsersWorkflowLinkItemsMutation: RemoveUsersWorkflowLinkItemsDocument;

	constructor(private injector: Injector) {
		this.getWorkflowLinkItemsQuery = this.injector.get(GetWorkflowLinkItemsDocument);
		this.firstWorkflowLinkItemsQuery = this.injector.get(FirstWorkflowLinkItemsDocument);
		this.countWorkflowLinkItemsQuery = this.injector.get(CountWorkflowLinkItemsDocument);
		this.findWorkflowLinkItemsQuery = this.injector.get(FindWorkflowLinkItemsDocument);
		this.searchWorkflowLinkItemsQuery = this.injector.get(SearchWorkflowLinkItemsDocument);
		this.exportSchemaWorkflowLinkItemsQuery = this.injector.get(ExportSchemaWorkflowLinkItemsDocument);
		this.exportDataWorkflowLinkItemsQuery = this.injector.get(ExportDataWorkflowLinkItemsDocument);
		this.customQueryWorkflowLinkItemsQuery = this.injector.get(CustomQueryWorkflowLinkItemsDocument);
		this.customQueryIdWorkflowLinkItemsQuery = this.injector.get(CustomQueryIdWorkflowLinkItemsDocument);
		this.usedWorkflowLinkItemsQuery = this.injector.get(UsedWorkflowLinkItemsDocument);
		this.findNotUsersWorkflowLinkItemsQuery = this.injector.get(FindNotUsersWorkflowLinkItemsDocument);
		this.existWorkflowLinkItemsQuery = this.injector.get(ExistWorkflowLinkItemsDocument);
		this.findUnassociatedUsersWorkflowLinkItemsQuery = this.injector.get(FindUnassociatedUsersWorkflowLinkItemsDocument);
		this.insertWorkflowLinkItemsMutation = this.injector.get(InsertWorkflowLinkItemsDocument);
		this.updateWorkflowLinkItemsMutation = this.injector.get(UpdateWorkflowLinkItemsDocument);
		this.importDataWorkflowLinkItemsMutation = this.injector.get(ImportDataWorkflowLinkItemsDocument);
		this.updateManyWorkflowLinkItemsMutation = this.injector.get(UpdateManyWorkflowLinkItemsDocument);
		this.deleteWorkflowLinkItemsMutation = this.injector.get(DeleteWorkflowLinkItemsDocument);
		this.addUsersWorkflowLinkItemsMutation = this.injector.get(AddUsersWorkflowLinkItemsDocument);
		this.removeUsersWorkflowLinkItemsMutation = this.injector.get(RemoveUsersWorkflowLinkItemsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowLinkItem> {
    
    		let variables: GetWorkflowLinkItemsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWorkflowLinkItemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowLinkItems.get));
            }
            else{
                let result:ServiceSingleResultOfWorkflowLinkItem={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowLinkItem,
		@Args('filter?') filter?: FilterOfWorkflowLinkItem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowLinkItem> {
    
    		let variables: FirstWorkflowLinkItemsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWorkflowLinkItemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowLinkItems.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowLinkItem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWorkflowLinkItemsBaseVariables = {
    			filter: filter
    		}
    				return this.countWorkflowLinkItemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowLinkItems.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowLinkItem,
		@Args('filter?') filter?: FilterOfWorkflowLinkItem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowLinkItem> {
    
    		let variables: FindWorkflowLinkItemsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWorkflowLinkItemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowLinkItems.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWorkflowLinkItem,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowLinkItem> {
    
    		let variables: SearchWorkflowLinkItemsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWorkflowLinkItemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowLinkItems.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWorkflowLinkItemsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWorkflowLinkItemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowLinkItems.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowLinkItem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWorkflowLinkItemsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWorkflowLinkItemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowLinkItems.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWorkflowLinkItem,
		@Args('filter?') filter?: FilterOfWorkflowLinkItem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowLinkItem> {
    
    		let variables: CustomQueryWorkflowLinkItemsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWorkflowLinkItemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowLinkItems.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWorkflowLinkItem,
		@Args('filter?') filter?: FilterOfWorkflowLinkItem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowLinkItem> {
    
    		let variables: CustomQueryIdWorkflowLinkItemsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWorkflowLinkItemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowLinkItems.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWorkflowLinkItemsBaseVariables = {
    			ids: ids
    		}
    				return this.usedWorkflowLinkItemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowLinkItems.used));
    	}

    	/**  */
    	@InjectArgs
    	public findNotUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindNotUsersWorkflowLinkItemsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findNotUsersWorkflowLinkItemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowLinkItems.findNotUsers));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWorkflowLinkItemsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWorkflowLinkItemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowLinkItems.exist));
    	}

	@InjectArgs
	public findAssociatedUsers(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfUser> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('users', fields, null, [
			GqlSubFieldArg.create('filterOfUsers', 'filter'),
			GqlSubFieldArg.create('optionsOfUsers', 'options'),
		]),
		])
		extendedVariables['filterOfUsers'] = filter;
		extendedVariables['optionsOfUsers'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.users));
            }
            else{
                let result: ServiceListResultOfUser = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindUnassociatedUsersWorkflowLinkItemsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedUsersWorkflowLinkItemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowLinkItems.findUnassociatedUsers));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: WorkflowLinkItemInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowLinkItem> {
    
    		let variables: InsertWorkflowLinkItemsBaseVariables = {
    			entity: entity
    		}
    				return this.insertWorkflowLinkItemsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowLinkItems.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowLinkItem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowLinkItem> {
    
    		let variables: UpdateWorkflowLinkItemsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateWorkflowLinkItemsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowLinkItems.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataWorkflowLinkItemsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataWorkflowLinkItemsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowLinkItems.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowLinkItem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyWorkflowLinkItemsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyWorkflowLinkItemsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowLinkItems.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteWorkflowLinkItemsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteWorkflowLinkItemsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowLinkItems.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddUsersWorkflowLinkItemsBaseVariables = {
    			id: id,
			userIds: userIds
    		}
    				return this.addUsersWorkflowLinkItemsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowLinkItems.addUsers));
    	}

    	/**  */
    	@InjectArgs
    	public removeUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveUsersWorkflowLinkItemsBaseVariables = {
    			id: id,
			userIds: userIds
    		}
    				return this.removeUsersWorkflowLinkItemsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowLinkItems.removeUsers));
    	}
    
}