import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetContractLicenseTypesBaseVariables, FirstContractLicenseTypesBaseVariables, CountContractLicenseTypesBaseVariables, FindContractLicenseTypesBaseVariables, SearchContractLicenseTypesBaseVariables, ExportSchemaContractLicenseTypesBaseVariables, ExportDataContractLicenseTypesBaseVariables, CustomQueryContractLicenseTypesBaseVariables, CustomQueryIdContractLicenseTypesBaseVariables, UsedContractLicenseTypesBaseVariables, ExistContractLicenseTypesBaseVariables, FindUnassociatedLicenseKeysContractLicenseTypesBaseVariables, InsertContractLicenseTypesBaseVariables, UpdateContractLicenseTypesBaseVariables, ImportDataContractLicenseTypesBaseVariables, UpdateManyContractLicenseTypesBaseVariables, DeleteContractLicenseTypesBaseVariables, AddLicenseKeysContractLicenseTypesBaseVariables, RemoveLicenseKeysContractLicenseTypesBaseVariables } from '../gqls'
import { GetContractLicenseTypesDocument, FirstContractLicenseTypesDocument, CountContractLicenseTypesDocument, FindContractLicenseTypesDocument, SearchContractLicenseTypesDocument, ExportSchemaContractLicenseTypesDocument, ExportDataContractLicenseTypesDocument, CustomQueryContractLicenseTypesDocument, CustomQueryIdContractLicenseTypesDocument, UsedContractLicenseTypesDocument, ExistContractLicenseTypesDocument, FindUnassociatedLicenseKeysContractLicenseTypesDocument, InsertContractLicenseTypesDocument, UpdateContractLicenseTypesDocument, ImportDataContractLicenseTypesDocument, UpdateManyContractLicenseTypesDocument, DeleteContractLicenseTypesDocument, AddLicenseKeysContractLicenseTypesDocument, RemoveLicenseKeysContractLicenseTypesDocument } from '../gqls'
import { ServiceSingleResultOfContractLicenseType, QueryContextOfContractLicenseType, FilterOfContractLicenseType, ServiceSingleResultOfInt64, ServiceListResultOfContractLicenseType, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, QueryContextOfLicenseKey, FilterOfLicenseKey, ServiceListResultOfLicenseKey, ContractLicenseTypeInput, FieldUpdateOperatorOfContractLicenseType } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ContractLicenseTypeBaseService {
    
public modelName = 'contractLicenseType';
public modelPluralName = 'contractLicenseTypes';

	private getContractLicenseTypesQuery: GetContractLicenseTypesDocument;
	private firstContractLicenseTypesQuery: FirstContractLicenseTypesDocument;
	private countContractLicenseTypesQuery: CountContractLicenseTypesDocument;
	private findContractLicenseTypesQuery: FindContractLicenseTypesDocument;
	private searchContractLicenseTypesQuery: SearchContractLicenseTypesDocument;
	private exportSchemaContractLicenseTypesQuery: ExportSchemaContractLicenseTypesDocument;
	private exportDataContractLicenseTypesQuery: ExportDataContractLicenseTypesDocument;
	private customQueryContractLicenseTypesQuery: CustomQueryContractLicenseTypesDocument;
	private customQueryIdContractLicenseTypesQuery: CustomQueryIdContractLicenseTypesDocument;
	private usedContractLicenseTypesQuery: UsedContractLicenseTypesDocument;
	private existContractLicenseTypesQuery: ExistContractLicenseTypesDocument;
	private findUnassociatedLicenseKeysContractLicenseTypesQuery: FindUnassociatedLicenseKeysContractLicenseTypesDocument;
	private insertContractLicenseTypesMutation: InsertContractLicenseTypesDocument;
	private updateContractLicenseTypesMutation: UpdateContractLicenseTypesDocument;
	private importDataContractLicenseTypesMutation: ImportDataContractLicenseTypesDocument;
	private updateManyContractLicenseTypesMutation: UpdateManyContractLicenseTypesDocument;
	private deleteContractLicenseTypesMutation: DeleteContractLicenseTypesDocument;
	private addLicenseKeysContractLicenseTypesMutation: AddLicenseKeysContractLicenseTypesDocument;
	private removeLicenseKeysContractLicenseTypesMutation: RemoveLicenseKeysContractLicenseTypesDocument;

	constructor(private injector: Injector) {
		this.getContractLicenseTypesQuery = this.injector.get(GetContractLicenseTypesDocument);
		this.firstContractLicenseTypesQuery = this.injector.get(FirstContractLicenseTypesDocument);
		this.countContractLicenseTypesQuery = this.injector.get(CountContractLicenseTypesDocument);
		this.findContractLicenseTypesQuery = this.injector.get(FindContractLicenseTypesDocument);
		this.searchContractLicenseTypesQuery = this.injector.get(SearchContractLicenseTypesDocument);
		this.exportSchemaContractLicenseTypesQuery = this.injector.get(ExportSchemaContractLicenseTypesDocument);
		this.exportDataContractLicenseTypesQuery = this.injector.get(ExportDataContractLicenseTypesDocument);
		this.customQueryContractLicenseTypesQuery = this.injector.get(CustomQueryContractLicenseTypesDocument);
		this.customQueryIdContractLicenseTypesQuery = this.injector.get(CustomQueryIdContractLicenseTypesDocument);
		this.usedContractLicenseTypesQuery = this.injector.get(UsedContractLicenseTypesDocument);
		this.existContractLicenseTypesQuery = this.injector.get(ExistContractLicenseTypesDocument);
		this.findUnassociatedLicenseKeysContractLicenseTypesQuery = this.injector.get(FindUnassociatedLicenseKeysContractLicenseTypesDocument);
		this.insertContractLicenseTypesMutation = this.injector.get(InsertContractLicenseTypesDocument);
		this.updateContractLicenseTypesMutation = this.injector.get(UpdateContractLicenseTypesDocument);
		this.importDataContractLicenseTypesMutation = this.injector.get(ImportDataContractLicenseTypesDocument);
		this.updateManyContractLicenseTypesMutation = this.injector.get(UpdateManyContractLicenseTypesDocument);
		this.deleteContractLicenseTypesMutation = this.injector.get(DeleteContractLicenseTypesDocument);
		this.addLicenseKeysContractLicenseTypesMutation = this.injector.get(AddLicenseKeysContractLicenseTypesDocument);
		this.removeLicenseKeysContractLicenseTypesMutation = this.injector.get(RemoveLicenseKeysContractLicenseTypesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfContractLicenseType> {
    
    		let variables: GetContractLicenseTypesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getContractLicenseTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractLicenseTypes.get));
            }
            else{
                let result:ServiceSingleResultOfContractLicenseType={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfContractLicenseType,
		@Args('filter?') filter?: FilterOfContractLicenseType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfContractLicenseType> {
    
    		let variables: FirstContractLicenseTypesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstContractLicenseTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractLicenseTypes.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfContractLicenseType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountContractLicenseTypesBaseVariables = {
    			filter: filter
    		}
    				return this.countContractLicenseTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractLicenseTypes.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfContractLicenseType,
		@Args('filter?') filter?: FilterOfContractLicenseType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfContractLicenseType> {
    
    		let variables: FindContractLicenseTypesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findContractLicenseTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractLicenseTypes.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfContractLicenseType,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfContractLicenseType> {
    
    		let variables: SearchContractLicenseTypesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchContractLicenseTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractLicenseTypes.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaContractLicenseTypesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaContractLicenseTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractLicenseTypes.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfContractLicenseType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataContractLicenseTypesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataContractLicenseTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractLicenseTypes.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfContractLicenseType,
		@Args('filter?') filter?: FilterOfContractLicenseType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfContractLicenseType> {
    
    		let variables: CustomQueryContractLicenseTypesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryContractLicenseTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractLicenseTypes.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfContractLicenseType,
		@Args('filter?') filter?: FilterOfContractLicenseType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfContractLicenseType> {
    
    		let variables: CustomQueryIdContractLicenseTypesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdContractLicenseTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractLicenseTypes.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedContractLicenseTypesBaseVariables = {
    			ids: ids
    		}
    				return this.usedContractLicenseTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractLicenseTypes.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistContractLicenseTypesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existContractLicenseTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractLicenseTypes.exist));
    	}

	@InjectArgs
	public findAssociatedLicenseKeys(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfLicenseKey,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfLicenseKey,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfLicenseKey> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('licenseKeys', fields, null, [
			GqlSubFieldArg.create('filterOfLicenseKeys', 'filter'),
			GqlSubFieldArg.create('optionsOfLicenseKeys', 'options'),
		]),
		])
		extendedVariables['filterOfLicenseKeys'] = filter;
		extendedVariables['optionsOfLicenseKeys'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.licenseKeys));
            }
            else{
                let result: ServiceListResultOfLicenseKey = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedLicenseKeys(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLicenseKey,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfLicenseKey,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLicenseKey> {
    
    		let variables: FindUnassociatedLicenseKeysContractLicenseTypesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedLicenseKeysContractLicenseTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractLicenseTypes.findUnassociatedLicenseKeys));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ContractLicenseTypeInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfContractLicenseType> {
    
    		let variables: InsertContractLicenseTypesBaseVariables = {
    			entity: entity
    		}
    				return this.insertContractLicenseTypesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contractLicenseTypes.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfContractLicenseType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfContractLicenseType> {
    
    		let variables: UpdateContractLicenseTypesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateContractLicenseTypesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contractLicenseTypes.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataContractLicenseTypesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataContractLicenseTypesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contractLicenseTypes.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfContractLicenseType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyContractLicenseTypesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyContractLicenseTypesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contractLicenseTypes.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteContractLicenseTypesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteContractLicenseTypesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contractLicenseTypes.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addLicenseKeys(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('licenseKeyIds') licenseKeyIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddLicenseKeysContractLicenseTypesBaseVariables = {
    			id: id,
			licenseKeyIds: licenseKeyIds
    		}
    				return this.addLicenseKeysContractLicenseTypesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contractLicenseTypes.addLicenseKeys));
    	}

    	/**  */
    	@InjectArgs
    	public removeLicenseKeys(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('licenseKeyIds') licenseKeyIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveLicenseKeysContractLicenseTypesBaseVariables = {
    			licenseKeyIds: licenseKeyIds
    		}
    				return this.removeLicenseKeysContractLicenseTypesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contractLicenseTypes.removeLicenseKeys));
    	}
    
}