<div *ngIf="modelState != undefined">
  <cl-mobile-list
    [columns]="modelState?.model?.grid?.layout?.columns"
    [canSelect]="false"
    [allowFilterPanel]="false"
    [allowSearchPanel]="false"
    [dataRowTemplateName]="dataRowTemplateName"
    [allowExportPanel]="false"
    [allowSelectColsPanel]="false"
    [source]="source"
    [modelState]="modelState"
    [layoutState]="layoutState"
    [canExport]="false"
    (onRowClick)="rowClick($event)"
  ></cl-mobile-list>
  <!--  <clc-list [columns]='modelState?.model?.grid?.layout?.columns' [canSelect]='false' [allowFilterPanel]='false' [allowSearchPanel]='false'-->
  <!--            [allowExportPanel]='false' [allowSelectColsPanel]='false'-->
  <!--            [source]='source'-->
  <!--  ></clc-list>-->
</div>
