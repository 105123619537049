import { NamingConventionBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { GqlField, GqlSubField } from '../helpers';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { EnumCoreService } from '@clarilog/core/services2/graphql/generated-types/enum-service';
import { Args } from '@clarilog/core';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
@Authorize('administration.naming-convention')
export class NamingConventionCoreService extends NamingConventionBaseService {
  constructor(injector: Injector, private enumCoreService: EnumCoreService) {
    super(injector);
  }

  static namingConventionSummaryFields(): Array<GqlSubField | GqlField> {
    return [
      GqlField.create('value'),
      GqlField.create('id'),
      GqlField.create('lock'),
    ];
  }

  public namingConventionSubstituteValue(@Args('namingConventionCategory')namingConventionCategory): Observable<any[]>{
    return of(this.enumCoreService.namingConventionAction().filter(el => (namingConventionCategory != undefined) && el.id != namingConventionCategory))
  }

  static byAssetFields(): Array<GqlSubField | GqlField> {
    return [
      GqlSubField.create('data', [
        GqlSubField.create('onName', this.namingConventionSummaryFields()),
        GqlSubField.create('onNetBios', this.namingConventionSummaryFields()),
        GqlSubField.create(
          'onInventoryNumber',
          this.namingConventionSummaryFields(),
        ),
        GqlSubField.create('onBarCode', this.namingConventionSummaryFields()),
      ]),
      GqlSubField.create('errors', [GqlField.create('messageError'), GqlField.create('property')])
    ];
  }
}
