import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetEmailConnectorSystemLogsBaseVariables, FirstEmailConnectorSystemLogsBaseVariables, CountEmailConnectorSystemLogsBaseVariables, FindEmailConnectorSystemLogsBaseVariables, SearchEmailConnectorSystemLogsBaseVariables, ExportSchemaEmailConnectorSystemLogsBaseVariables, ExportDataEmailConnectorSystemLogsBaseVariables, CustomQueryEmailConnectorSystemLogsBaseVariables, CustomQueryIdEmailConnectorSystemLogsBaseVariables, UsedEmailConnectorSystemLogsBaseVariables, ExistEmailConnectorSystemLogsBaseVariables, InsertEmailConnectorSystemLogsBaseVariables, UpdateEmailConnectorSystemLogsBaseVariables, ImportDataEmailConnectorSystemLogsBaseVariables, UpdateManyEmailConnectorSystemLogsBaseVariables, DeleteEmailConnectorSystemLogsBaseVariables } from '../gqls'
import { GetEmailConnectorSystemLogsDocument, FirstEmailConnectorSystemLogsDocument, CountEmailConnectorSystemLogsDocument, FindEmailConnectorSystemLogsDocument, SearchEmailConnectorSystemLogsDocument, ExportSchemaEmailConnectorSystemLogsDocument, ExportDataEmailConnectorSystemLogsDocument, CustomQueryEmailConnectorSystemLogsDocument, CustomQueryIdEmailConnectorSystemLogsDocument, UsedEmailConnectorSystemLogsDocument, ExistEmailConnectorSystemLogsDocument, InsertEmailConnectorSystemLogsDocument, UpdateEmailConnectorSystemLogsDocument, ImportDataEmailConnectorSystemLogsDocument, UpdateManyEmailConnectorSystemLogsDocument, DeleteEmailConnectorSystemLogsDocument } from '../gqls'
import { ServiceSingleResultOfEmailConnectorSystemLog, QueryContextOfEmailConnectorSystemLog, FilterOfEmailConnectorSystemLog, ServiceSingleResultOfInt64, ServiceListResultOfEmailConnectorSystemLog, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, EmailConnectorSystemLogInput, FieldUpdateOperatorOfEmailConnectorSystemLog } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class EmailConnectorSystemLogBaseService {
    
public modelName = 'emailConnectorSystemLog';
public modelPluralName = 'emailConnectorSystemLogs';

	private getEmailConnectorSystemLogsQuery: GetEmailConnectorSystemLogsDocument;
	private firstEmailConnectorSystemLogsQuery: FirstEmailConnectorSystemLogsDocument;
	private countEmailConnectorSystemLogsQuery: CountEmailConnectorSystemLogsDocument;
	private findEmailConnectorSystemLogsQuery: FindEmailConnectorSystemLogsDocument;
	private searchEmailConnectorSystemLogsQuery: SearchEmailConnectorSystemLogsDocument;
	private exportSchemaEmailConnectorSystemLogsQuery: ExportSchemaEmailConnectorSystemLogsDocument;
	private exportDataEmailConnectorSystemLogsQuery: ExportDataEmailConnectorSystemLogsDocument;
	private customQueryEmailConnectorSystemLogsQuery: CustomQueryEmailConnectorSystemLogsDocument;
	private customQueryIdEmailConnectorSystemLogsQuery: CustomQueryIdEmailConnectorSystemLogsDocument;
	private usedEmailConnectorSystemLogsQuery: UsedEmailConnectorSystemLogsDocument;
	private existEmailConnectorSystemLogsQuery: ExistEmailConnectorSystemLogsDocument;
	private insertEmailConnectorSystemLogsMutation: InsertEmailConnectorSystemLogsDocument;
	private updateEmailConnectorSystemLogsMutation: UpdateEmailConnectorSystemLogsDocument;
	private importDataEmailConnectorSystemLogsMutation: ImportDataEmailConnectorSystemLogsDocument;
	private updateManyEmailConnectorSystemLogsMutation: UpdateManyEmailConnectorSystemLogsDocument;
	private deleteEmailConnectorSystemLogsMutation: DeleteEmailConnectorSystemLogsDocument;

	constructor(private injector: Injector) {
		this.getEmailConnectorSystemLogsQuery = this.injector.get(GetEmailConnectorSystemLogsDocument);
		this.firstEmailConnectorSystemLogsQuery = this.injector.get(FirstEmailConnectorSystemLogsDocument);
		this.countEmailConnectorSystemLogsQuery = this.injector.get(CountEmailConnectorSystemLogsDocument);
		this.findEmailConnectorSystemLogsQuery = this.injector.get(FindEmailConnectorSystemLogsDocument);
		this.searchEmailConnectorSystemLogsQuery = this.injector.get(SearchEmailConnectorSystemLogsDocument);
		this.exportSchemaEmailConnectorSystemLogsQuery = this.injector.get(ExportSchemaEmailConnectorSystemLogsDocument);
		this.exportDataEmailConnectorSystemLogsQuery = this.injector.get(ExportDataEmailConnectorSystemLogsDocument);
		this.customQueryEmailConnectorSystemLogsQuery = this.injector.get(CustomQueryEmailConnectorSystemLogsDocument);
		this.customQueryIdEmailConnectorSystemLogsQuery = this.injector.get(CustomQueryIdEmailConnectorSystemLogsDocument);
		this.usedEmailConnectorSystemLogsQuery = this.injector.get(UsedEmailConnectorSystemLogsDocument);
		this.existEmailConnectorSystemLogsQuery = this.injector.get(ExistEmailConnectorSystemLogsDocument);
		this.insertEmailConnectorSystemLogsMutation = this.injector.get(InsertEmailConnectorSystemLogsDocument);
		this.updateEmailConnectorSystemLogsMutation = this.injector.get(UpdateEmailConnectorSystemLogsDocument);
		this.importDataEmailConnectorSystemLogsMutation = this.injector.get(ImportDataEmailConnectorSystemLogsDocument);
		this.updateManyEmailConnectorSystemLogsMutation = this.injector.get(UpdateManyEmailConnectorSystemLogsDocument);
		this.deleteEmailConnectorSystemLogsMutation = this.injector.get(DeleteEmailConnectorSystemLogsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEmailConnectorSystemLog> {
    
    		let variables: GetEmailConnectorSystemLogsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getEmailConnectorSystemLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystemLogs.get));
            }
            else{
                let result:ServiceSingleResultOfEmailConnectorSystemLog={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEmailConnectorSystemLog,
		@Args('filter?') filter?: FilterOfEmailConnectorSystemLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEmailConnectorSystemLog> {
    
    		let variables: FirstEmailConnectorSystemLogsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstEmailConnectorSystemLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystemLogs.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfEmailConnectorSystemLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountEmailConnectorSystemLogsBaseVariables = {
    			filter: filter
    		}
    				return this.countEmailConnectorSystemLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystemLogs.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEmailConnectorSystemLog,
		@Args('filter?') filter?: FilterOfEmailConnectorSystemLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailConnectorSystemLog> {
    
    		let variables: FindEmailConnectorSystemLogsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findEmailConnectorSystemLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystemLogs.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfEmailConnectorSystemLog,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailConnectorSystemLog> {
    
    		let variables: SearchEmailConnectorSystemLogsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchEmailConnectorSystemLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystemLogs.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaEmailConnectorSystemLogsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaEmailConnectorSystemLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystemLogs.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfEmailConnectorSystemLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataEmailConnectorSystemLogsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataEmailConnectorSystemLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystemLogs.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfEmailConnectorSystemLog,
		@Args('filter?') filter?: FilterOfEmailConnectorSystemLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailConnectorSystemLog> {
    
    		let variables: CustomQueryEmailConnectorSystemLogsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryEmailConnectorSystemLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystemLogs.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfEmailConnectorSystemLog,
		@Args('filter?') filter?: FilterOfEmailConnectorSystemLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailConnectorSystemLog> {
    
    		let variables: CustomQueryIdEmailConnectorSystemLogsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdEmailConnectorSystemLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystemLogs.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedEmailConnectorSystemLogsBaseVariables = {
    			ids: ids
    		}
    				return this.usedEmailConnectorSystemLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystemLogs.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistEmailConnectorSystemLogsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existEmailConnectorSystemLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystemLogs.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: EmailConnectorSystemLogInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEmailConnectorSystemLog> {
    
    		let variables: InsertEmailConnectorSystemLogsBaseVariables = {
    			entity: entity
    		}
    				return this.insertEmailConnectorSystemLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystemLogs.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfEmailConnectorSystemLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEmailConnectorSystemLog> {
    
    		let variables: UpdateEmailConnectorSystemLogsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateEmailConnectorSystemLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystemLogs.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataEmailConnectorSystemLogsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataEmailConnectorSystemLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystemLogs.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfEmailConnectorSystemLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyEmailConnectorSystemLogsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyEmailConnectorSystemLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystemLogs.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteEmailConnectorSystemLogsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteEmailConnectorSystemLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailConnectorSystemLogs.delete));
    	}
    
}