import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetMessagesBaseVariables, FirstMessagesBaseVariables, CountMessagesBaseVariables, FindMessagesBaseVariables, SearchMessagesBaseVariables, ExportSchemaMessagesBaseVariables, ExportDataMessagesBaseVariables, CustomQueryMessagesBaseVariables, CustomQueryIdMessagesBaseVariables, FindFilesMessagesBaseVariables, FindAttachmentFilesMessagesBaseVariables, FindHelpDeskUserAttachmentFilesMessagesBaseVariables, UsedMessagesBaseVariables, FindNotReadMessagesBaseVariables, ExistMessagesBaseVariables, FindUnassociatedAlertsMessagesBaseVariables, InsertMessagesBaseVariables, UpdateMessagesBaseVariables, ImportDataMessagesBaseVariables, UpdateManyMessagesBaseVariables, DeleteMessagesBaseVariables, AddFilesMessagesBaseVariables, RemoveFilesMessagesBaseVariables, UploadChunkFileMessagesBaseVariables, ReadMessageByTicketIdMessagesBaseVariables, AddAlertsMessagesBaseVariables, RemoveAlertsMessagesBaseVariables } from '../gqls'
import { GetMessagesDocument, FirstMessagesDocument, CountMessagesDocument, FindMessagesDocument, SearchMessagesDocument, ExportSchemaMessagesDocument, ExportDataMessagesDocument, CustomQueryMessagesDocument, CustomQueryIdMessagesDocument, FindFilesMessagesDocument, FindAttachmentFilesMessagesDocument, FindHelpDeskUserAttachmentFilesMessagesDocument, UsedMessagesDocument, FindNotReadMessagesDocument, ExistMessagesDocument, FindUnassociatedAlertsMessagesDocument, InsertMessagesDocument, UpdateMessagesDocument, ImportDataMessagesDocument, UpdateManyMessagesDocument, DeleteMessagesDocument, AddFilesMessagesDocument, RemoveFilesMessagesDocument, UploadChunkFileMessagesDocument, ReadMessageByTicketIdMessagesDocument, AddAlertsMessagesDocument, RemoveAlertsMessagesDocument } from '../gqls'
import { ServiceSingleResultOfMessage, QueryContextOfMessage, FilterOfMessage, ServiceSingleResultOfInt64, ServiceListResultOfMessage, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceListResultOfFileModel, QueryContextOfFileModel, FilterOfFileModel, ServiceSingleResultOfBoolean, QueryContextOfAlert, FilterOfAlert, ServiceListResultOfAlert, MessageInput, FieldUpdateOperatorOfMessage, ServiceSingleResultOfGuid } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class MessageBaseService {
    
public modelName = 'message';
public modelPluralName = 'messages';

	private getMessagesQuery: GetMessagesDocument;
	private firstMessagesQuery: FirstMessagesDocument;
	private countMessagesQuery: CountMessagesDocument;
	private findMessagesQuery: FindMessagesDocument;
	private searchMessagesQuery: SearchMessagesDocument;
	private exportSchemaMessagesQuery: ExportSchemaMessagesDocument;
	private exportDataMessagesQuery: ExportDataMessagesDocument;
	private customQueryMessagesQuery: CustomQueryMessagesDocument;
	private customQueryIdMessagesQuery: CustomQueryIdMessagesDocument;
	private findFilesMessagesQuery: FindFilesMessagesDocument;
	private findAttachmentFilesMessagesQuery: FindAttachmentFilesMessagesDocument;
	private findHelpDeskUserAttachmentFilesMessagesQuery: FindHelpDeskUserAttachmentFilesMessagesDocument;
	private usedMessagesQuery: UsedMessagesDocument;
	private findNotReadMessagesQuery: FindNotReadMessagesDocument;
	private existMessagesQuery: ExistMessagesDocument;
	private findUnassociatedAlertsMessagesQuery: FindUnassociatedAlertsMessagesDocument;
	private insertMessagesMutation: InsertMessagesDocument;
	private updateMessagesMutation: UpdateMessagesDocument;
	private importDataMessagesMutation: ImportDataMessagesDocument;
	private updateManyMessagesMutation: UpdateManyMessagesDocument;
	private deleteMessagesMutation: DeleteMessagesDocument;
	private addFilesMessagesMutation: AddFilesMessagesDocument;
	private removeFilesMessagesMutation: RemoveFilesMessagesDocument;
	private uploadChunkFileMessagesMutation: UploadChunkFileMessagesDocument;
	private readMessageByTicketIdMessagesMutation: ReadMessageByTicketIdMessagesDocument;
	private addAlertsMessagesMutation: AddAlertsMessagesDocument;
	private removeAlertsMessagesMutation: RemoveAlertsMessagesDocument;

	constructor(private injector: Injector) {
		this.getMessagesQuery = this.injector.get(GetMessagesDocument);
		this.firstMessagesQuery = this.injector.get(FirstMessagesDocument);
		this.countMessagesQuery = this.injector.get(CountMessagesDocument);
		this.findMessagesQuery = this.injector.get(FindMessagesDocument);
		this.searchMessagesQuery = this.injector.get(SearchMessagesDocument);
		this.exportSchemaMessagesQuery = this.injector.get(ExportSchemaMessagesDocument);
		this.exportDataMessagesQuery = this.injector.get(ExportDataMessagesDocument);
		this.customQueryMessagesQuery = this.injector.get(CustomQueryMessagesDocument);
		this.customQueryIdMessagesQuery = this.injector.get(CustomQueryIdMessagesDocument);
		this.findFilesMessagesQuery = this.injector.get(FindFilesMessagesDocument);
		this.findAttachmentFilesMessagesQuery = this.injector.get(FindAttachmentFilesMessagesDocument);
		this.findHelpDeskUserAttachmentFilesMessagesQuery = this.injector.get(FindHelpDeskUserAttachmentFilesMessagesDocument);
		this.usedMessagesQuery = this.injector.get(UsedMessagesDocument);
		this.findNotReadMessagesQuery = this.injector.get(FindNotReadMessagesDocument);
		this.existMessagesQuery = this.injector.get(ExistMessagesDocument);
		this.findUnassociatedAlertsMessagesQuery = this.injector.get(FindUnassociatedAlertsMessagesDocument);
		this.insertMessagesMutation = this.injector.get(InsertMessagesDocument);
		this.updateMessagesMutation = this.injector.get(UpdateMessagesDocument);
		this.importDataMessagesMutation = this.injector.get(ImportDataMessagesDocument);
		this.updateManyMessagesMutation = this.injector.get(UpdateManyMessagesDocument);
		this.deleteMessagesMutation = this.injector.get(DeleteMessagesDocument);
		this.addFilesMessagesMutation = this.injector.get(AddFilesMessagesDocument);
		this.removeFilesMessagesMutation = this.injector.get(RemoveFilesMessagesDocument);
		this.uploadChunkFileMessagesMutation = this.injector.get(UploadChunkFileMessagesDocument);
		this.readMessageByTicketIdMessagesMutation = this.injector.get(ReadMessageByTicketIdMessagesDocument);
		this.addAlertsMessagesMutation = this.injector.get(AddAlertsMessagesDocument);
		this.removeAlertsMessagesMutation = this.injector.get(RemoveAlertsMessagesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMessage> {
    
    		let variables: GetMessagesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messages.get));
            }
            else{
                let result:ServiceSingleResultOfMessage={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfMessage,
		@Args('filter?') filter?: FilterOfMessage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMessage> {
    
    		let variables: FirstMessagesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messages.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfMessage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountMessagesBaseVariables = {
    			filter: filter
    		}
    				return this.countMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messages.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfMessage,
		@Args('filter?') filter?: FilterOfMessage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMessage> {
    
    		let variables: FindMessagesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messages.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfMessage,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMessage> {
    
    		let variables: SearchMessagesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messages.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaMessagesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messages.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfMessage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataMessagesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messages.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfMessage,
		@Args('filter?') filter?: FilterOfMessage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMessage> {
    
    		let variables: CustomQueryMessagesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messages.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfMessage,
		@Args('filter?') filter?: FilterOfMessage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMessage> {
    
    		let variables: CustomQueryIdMessagesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messages.customQueryId));
    	}

    	/** Récupère les fichiers liés. */
    	@InjectArgs
    	public findFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindFilesMessagesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findFilesMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messages.findFiles));
    	}

    	/** Récupère les fichiers attachés liés. */
    	@InjectArgs
    	public findAttachmentFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindAttachmentFilesMessagesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAttachmentFilesMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messages.findAttachmentFiles));
    	}

    	/** Récupère les fichiers attachés liés visible pour les users help desk. */
    	@InjectArgs
    	public findHelpDeskUserAttachmentFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindHelpDeskUserAttachmentFilesMessagesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findHelpDeskUserAttachmentFilesMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messages.findHelpDeskUserAttachmentFiles));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedMessagesBaseVariables = {
    			ids: ids
    		}
    				return this.usedMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messages.used));
    	}

    	/**  */
    	@InjectArgs
    	public findNotRead(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfMessage,
		@Args('filter?') filter?: FilterOfMessage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMessage> {
    
    		let variables: FindNotReadMessagesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findNotReadMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messages.findNotRead));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistMessagesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messages.exist));
    	}

	@InjectArgs
	public findAssociatedAlerts(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAlert> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('alerts', fields, null, [
			GqlSubFieldArg.create('filterOfAlerts', 'filter'),
			GqlSubFieldArg.create('optionsOfAlerts', 'options'),
		]),
		])
		extendedVariables['filterOfAlerts'] = filter;
		extendedVariables['optionsOfAlerts'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.alerts));
            }
            else{
                let result: ServiceListResultOfAlert = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlert> {
    
    		let variables: FindUnassociatedAlertsMessagesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAlertsMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.messages.findUnassociatedAlerts));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: MessageInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMessage> {
    
    		let variables: InsertMessagesBaseVariables = {
    			entity: entity
    		}
    				return this.insertMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.messages.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfMessage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMessage> {
    
    		let variables: UpdateMessagesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.messages.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataMessagesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.messages.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfMessage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyMessagesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.messages.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteMessagesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.messages.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileIds') fileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFilesMessagesBaseVariables = {
    			id: id,
			fileIds: fileIds
    		}
    				return this.addFilesMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.messages.addFiles));
    	}

    	/**  */
    	@InjectArgs
    	public removeFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileIds') fileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFilesMessagesBaseVariables = {
    			id: id,
			fileIds: fileIds
    		}
    				return this.removeFilesMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.messages.removeFiles));
    	}

    	/**  */
    	@InjectArgs
    	public uploadChunkFile(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('visibleByHelpDeskUser') visibleByHelpDeskUser: boolean,
		@Args('size') size: number,
		@Args('outgoingEmails') outgoingEmails: boolean,
		@Args('lastChunk') lastChunk: boolean,
		@Args('indexChunk') indexChunk: number,
		@Args('type?') type?: string,
		@Args('name?') name?: string,
		@Args('fileId?') fileId?: string,
		@Args('entityId?') entityId?: string,
		@Args('chunks?') chunks?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: UploadChunkFileMessagesBaseVariables = {
    			name: name,
			size: size,
			type: type,
			chunks: chunks,
			indexChunk: indexChunk,
			lastChunk: lastChunk,
			fileId: fileId,
			entityId: entityId,
			visibleByHelpDeskUser: visibleByHelpDeskUser,
			outgoingEmails: outgoingEmails
    		}
    				return this.uploadChunkFileMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.messages.uploadChunkFile));
    	}

    	/**  */
    	@InjectArgs
    	public readMessageByTicketId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketId') ticketId: string,
		@Args('isOperator') isOperator: boolean,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ReadMessageByTicketIdMessagesBaseVariables = {
    			ticketId: ticketId,
			isOperator: isOperator
    		}
    				return this.readMessageByTicketIdMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.messages.readMessageByTicketId));
    	}

    	/**  */
    	@InjectArgs
    	public addAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('alertIds') alertIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAlertsMessagesBaseVariables = {
    			id: id,
			alertIds: alertIds
    		}
    				return this.addAlertsMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.messages.addAlerts));
    	}

    	/**  */
    	@InjectArgs
    	public removeAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('alertIds') alertIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveAlertsMessagesBaseVariables = {
    			alertIds: alertIds
    		}
    				return this.removeAlertsMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.messages.removeAlerts));
    	}
    
}