import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonCoreService } from '@clarilog/core/services2/graphql/generated-types/services/common.service';
import { QueryBuilderCoreService } from '@clarilog/core/services2/graphql/generated-types/services/query-builder.service';
import {
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import dxDataGrid from 'devextreme/ui/data_grid';
import { FormGroupHelpers } from '../../form/work-form/form-group-helpers';
import { TemplatesService } from '../../templates/templates.service';
import { TranslatedFieldHelperService } from '../../translate-field/translate-field-helper-service';
import { Column } from '@clarilog/shared2/models/schema';

/** Représente la classe du composent cl-list. */
@Component({
  selector: 'clc-column-chooser',
  templateUrl: './column-chooser.component.html',
  styleUrls: ['./column-chooser.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreColumnChooserComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CoreColumnChooserComponent),
      multi: true,
    },
  ],
})
// ATTENTION il y a une erreur sur ce composant
/// ERROR TypeError: Cannot read property 'forEach' of undefined
/// c'est connu et ça vient de devextreme
/// https://supportcenter.devexpress.com/ticket/details/t959818/error-editing-devextreme-data-grid-forked-codesandbox
///
///
///
export class CoreColumnChooserComponent
  implements ControlValueAccessor, OnInit
{
  /** Sauvegarde les valeurs. */
  _values: string;

  /** Obtient ou définit les options du composant */
  @Input() options: any;
  /** Represente le modelState du formulaire */
  @Input() modelState: ModelState;
  /** Obtient ou définit les colonnes à inclure */
  @Input() includeColunms: ModelFnContext | any[];

  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};
  /** Obtient ou définit la source de données. */
  source: any;
  /** Récupère le composant en cours. */
  component: dxDataGrid;
  /** Obtient ou définit toutes les column */
  columns: any[];
  columnsChooser: any[];
  /** Obtient le type du model */
  type: string;
  /** Tableau des index sélectionnées */
  initializeValue: boolean = false;
  /** Affiche la colonne chooseur */
  showColumnChooser: boolean = false;
  /** Définit le liste des colonne affiché */
  columnVisible: string[] = [];
  /** Obtient les colonne de la grille */
  gridColumns: any[] = [];

  /** Choix des colonne */
  selectedColumns: any[] = [];

  /** Définit les templates de base */
  defaultTemplates: string[] = [
    'timeSpanTemplate',
    'estimatedTimeSpanTemplate',
    'estimatedTimeMinutesTemplate',
    'displayWithoutHTMLTemplate',
    'userDistinguishNameTemplate',
  ];

  /** Obtient l'erreur  */
  error: string = '*';

  /** Affiche le load */
  loadPanel: boolean = false;
  constructor(
    public templateService: TemplatesService,
    public queryBuilderService: QueryBuilderCoreService,
    private commonService: CommonCoreService,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private translatedFieldHelperService: TranslatedFieldHelperService,
  ) {}

  /** Obtient ou définit les valeurs. */
  get values(): string {
    return this._values;
  }
  set values(values: string) {
    this._values = values;
    if (this.initializeValue === false) {
      this.onChange(this._values);
      this.onTouched();
    }
  }

  /** @inheritdoc */
  writeValue(values: string): void {
    // ne gere pas les changement de sélection
    this.initializeValue = true;
    if (this.values != values) {
      this.loadColumns();
    }
    this.values = values;
    this.initializeValue = false;
  }
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /** @inheritdoc */
  setDisabledState?(isDisabled: boolean): void {}
  /** @inheritdoc */
  async ngOnInit() {
    const typeByUrl = this.route.snapshot.queryParamMap.get('type');

    if (this.type == '' || this.type == undefined) {
      this.type = this.modelState.sharedContext.params.get('type');
    }

    if ((this.type == '' || this.type == undefined) && typeByUrl != undefined) {
      this.type = typeByUrl;
    }

    this.type = this.commonService.getQueryBuilderType(this.type);

    this.loadColumns();
  }

  loadColumns() {
    let includes: Column[];
    if (this.includeColunms != undefined) {
      if (this.includeColunms as ModelFnContext) {
        includes = (<any>(
          (this.includeColunms as ModelFnContext).fnCall()
        )) as Column[];
      } else if (Array.isArray(this.includeColunms as Column[])) {
        includes = this.includeColunms as Column[];
      }
    }

    if (this.columns == undefined) {
      this.commonService
        .allFields(
          this.commonService.gqlField(),
          this.type.charAt(0).toUpperCase() + this.type.substring(1),
        )
        .subscribe(async (res) => {
          let loadingColumns = await this.commonService.createFields(
            this.type,
            res,
            false,
            true,
            undefined,
            0,
            'authorizeReturnTableGuid',
          );
          loadingColumns.forEach((element) => {
            if (element.translatable == true) {
              element.dataField =
                this.translatedFieldHelperService.setColumnTranslateField(
                  element.dataField,
                );
            }
          });

          // N'affiche pas les champs de type GUID
          // Si besoin de la donnée, il faut utilisé est vérifier la configuration de la dénormalisation
          let excludeColumns = loadingColumns.filter(
            (f) =>
              (f.dataType == 'Guid' || f.dataType == 'ID') && !f.hasChildren,
          );
          loadingColumns = loadingColumns.filter(
            (x) => excludeColumns.filter((f) => f.id == x.id).length == 0,
          );

          // Conserve que les colonnes demandé
          let arrayIncludes = [];
          if (includes != undefined && includes.length > 0) {
            // Conserve que les colonnes incluses
            let includeFieldNames = includes.map((d) => d.field);
            arrayIncludes = loadingColumns.filter((s) => {
              let dataFieldName = s.dataField;
              if (dataFieldName.includes('dynamicProperties')) return true;

              if (s.translatable === true) {
                dataFieldName = dataFieldName.substring(
                  0,
                  dataFieldName.length - 3,
                );
              }
              return includeFieldNames.includes(dataFieldName);
            });
            // Vérifie si toutes les colonne incluse sont présente
            // Cas de champs graphql calculé (exe Is favorite sur ticket)
            includes.forEach((f) => {
              let fieldName = f.field;
              if (f.translatable === true) {
                fieldName =
                  this.translatedFieldHelperService.setColumnTranslateField(
                    fieldName,
                  );
              }
              let find = arrayIncludes.find((s) => s.dataField == fieldName);
              if (find == undefined) {
                let caption = f.label;
                if (caption != undefined) {
                  caption = caption.replace("[resource('", '');
                  caption = caption.replace("')]", '');
                  caption = TranslateService.get(caption);
                }
                // ajoute les colonnes pouvant etre manquante
                arrayIncludes.push({
                  caption: caption,
                  dataField: f.field,
                  dataType: f.dataType,
                  hasChildren: false,
                  id: f.label,
                  translatable: f.translatable,
                });
              }
            });

            // Recherche des parents
            let addParents = [];
            arrayIncludes.forEach((g) => {
              let searchParent = g;
              while (searchParent?.parentId != undefined) {
                let searchParents = loadingColumns.filter(
                  (s) => s.id == searchParent?.parentId,
                );
                if (searchParents != undefined && searchParents.length > 0) {
                  searchParent = searchParents[0];
                  if (
                    addParents.filter((f) => f.id == searchParent.id).length ==
                      0 &&
                    arrayIncludes.filter((f) => f.id == searchParent.id)
                      .length == 0
                  ) {
                    addParents.push(searchParent);
                  }
                } else {
                  searchParent = undefined;
                }
              }
            });
            arrayIncludes = arrayIncludes.concat(addParents);
            loadingColumns = arrayIncludes;
          }

          this.columnsChooser = JSON.parse(JSON.stringify(loadingColumns));
          this.columns = JSON.parse(JSON.stringify(loadingColumns));

          this.aplyTemplate();
        });
    } else {
      this.aplyTemplate();
    }
  }

  aplyTemplate() {
    // Apply template de la grille
    if (this.values != undefined) {
      let columns = JSON.parse(this.values);
      // Order
      columns.sort(function (a, b) {
        if (a.visibleIndex < b.visibleIndex) {
          return -1;
        }
        if (a.visibleIndex > b.visibleIndex) {
          return 1;
        }
        return 0;
      });

      // Traduction
      this.initializeValue = true;
      this.component.beginUpdate();
      this.gridColumns = [];
      columns.forEach((element) => {
        this.addColumns(element);
      });
      this.component.endUpdate();
      // this.component.state({
      //   columns: columns,
      // });

      //reforce la value
      this.values = JSON.stringify(columns);

      this.changeDetector.detectChanges();

      this.initializeValue = false;
    }
  }

  /** Vaidation */
  validate({ value }: UntypedFormControl) {
    let isNotValid = false;
    if (this.values == undefined) {
      isNotValid = true;
    } else {
      let col = JSON.parse(this.values);
      if (col == undefined || col.length == 0) {
        isNotValid = true;
      }
    }

    if (isNotValid) {
      this.error = TranslateService.get('globals/oneColumnOnChooser');
    } else {
      this.error = '';
    }
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  addColumns(column) {
    if (!column.hasChildren) {
      let c = this.columns.filter((d) => d.dataField == column.dataField);
      if (c != undefined && c.length > 0) {
        column.caption = this.commonService.validParentCaption(
          c[0],
          this.columns,
        );
      }
      var exist = this.columns.find((s) => s.dataField == column['dataField']);
      if (exist != undefined) {
        if (exist.translatable === true) {
          column['dataField'] =
            this.translatedFieldHelperService.setColumnTranslateField(
              column['dataField'],
            );

          column['translatable'] = exist.translatable;
        }
      }
      column['visible'] = true;

      // Attention voir pour les cas de valeur non denormliser
      column['allowSorting'] = true;
      column['allowGrouping'] = true;
      column['allowReordering'] = true;
      column['allowResizing'] = true;

      column['name'] = column['dataField'];
      switch (column['dataField']) {
        case 'actualLevel':
          column['template'] = 'percentTemplate';
          break;
        case 'elaspedTime':
        case 'duration':
        case 'predecessor.data.duration':
          column['template'] = 'timeSpanTemplate';
          column['allowFiltering'] = false;
          column['allowSorting'] = false;
          break;
        case 'datetime':
          column['format'] = 'shortDateShortTime';
          break;
        case 'estimate':
          column['template'] = 'estimatedTimeSpanTemplate';
          column['allowSorting'] = true;
          break;
        case 'estimateMinutes':
        case 'durationMinutes':
        case 'predecessor.data.durationMinutes':
          column['template'] = 'estimatedTimeMinutesTemplate';
          column['allowSorting'] = true;
          break;
        case 'satisfaction.commentary':
          column['template'] = 'displayWithoutHTMLTemplate';
          column['allowSorting'] = false;
          break;
        case 'manager':
        case 'organizationalUnit.data.manager':
          column['template'] = 'userDistinguishNameTemplate';
          break;
      }

      switch (column['dataType']) {
        case 'boolean':
          column['template'] = 'booleanTemplate';
          break;
        case 'datetime':
          column['format'] = 'shortDateShortTime';
          break;
      }

      var cols = this.component.option('columns');
      if (
        cols == undefined ||
        (cols != undefined &&
          cols.filter((f) => (f as any).dataField == column['dataField'])
            .length == 0)
      ) {
        this.component.addColumn(column);
      }

      this.gridColumns.push(column);
    }
  }

  /** Initialisation de la grille */
  onInitialized(e) {
    this.component = e.component;
  }

  /** Préparation du toolbar */
  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        widget: 'dxButton',
        location: 'after',
        options: {
          icon: 'columnchooser',
          hint: TranslateService.get('globals/columnChooser'),
          onClick: () => {
            let visibleColumn = this.component.getVisibleColumns();
            let ids = this.columns
              .filter(
                (f) =>
                  visibleColumn.filter((col) => col.dataField == f.dataField)
                    .length > 0,
              )
              .map((s) => s.id);
            this.columnVisible = ids;
            this.showColumnChooser = true;
          },
        },
      },
      {
        widget: 'dxButton',
        location: 'after',
        options: {
          icon: 'refresh',
          hint: TranslateService.get('globals/test'),
          onClick: () => {
            this.run();
          },
        },
      },
    );
  }

  /* Changement de sélection */
  onSelectionChanged(e) {
    this.selectedColumns = e.component.getSelectedRowsData('all');
  }

  /** Se produit des que composant change */
  onOptionChanged(e) {
    if (!this.initializeValue && e.name == 'columns') {
      setTimeout(() => {
        this.values = JSON.stringify(this.component.state().columns);
      }, 300);
    }
  }

  /** Excution et test de la requête */
  run() {
    let filterControl = FormGroupHelpers.formControlByName(
      this.modelState.form,
      'filter',
    );
    let filter = undefined;
    if (filterControl != undefined) {
      filter = filterControl.value;
    }

    this.queryBuilderService
      .run(
        this.type,
        this.values,
        {
          limit: 5,
          skip: 0,
        },
        filter,
      )
      .subscribe((result) => {
        result.data.map((element) => {
          //- Interpretation des balises HTML (description)
          let description = element.description;
          if (description != undefined) {
            let contentHTML = new DOMParser().parseFromString(
              description,
              'text/html',
            ).documentElement.textContent;
            element.description = contentHTML;
          }
        });
        this.source = result.data;
        this.component.repaint();
      });
  }

  onHidden(e) {
    if (this.loadPanel) {
      this.component.beginUpdate();
      // Remet à jour toutes les colonne
      var removeCols = [];

      // Supprime tte les colonnes de la grid
      this.gridColumns.forEach((f) => {
        // colonne choise
        var choose = this.selectedColumns.filter(
          (s) => s.dataField == f.dataField,
        );
        if (choose.length == 0) {
          this.component.deleteColumn(f.dataField);
          removeCols.push(f);
        }
      });

      //
      this.selectedColumns.forEach((f) => {
        var add = this.gridColumns.filter((s) => s.dataField == f.dataField);
        if (add.length == 0) {
          var col = this.columns.filter((col) => col.id == f.id);
          if (col != undefined && col.length > 0) {
            this.addColumns(col[0]);
          }
        }
      });

      // Remet a jour
      this.gridColumns = this.gridColumns.filter(
        (f) =>
          removeCols.filter((rc) => rc.dataField == f.dataField).length == 0,
      );
      this.component.endUpdate();
      this.component.repaint();
      this.values = JSON.stringify(this.component.state().columns);
    }
    this.loadPanel = false;
  }

  /** Valide le choix des colonne */
  ok(e) {
    this.showColumnChooser = false;
    this.loadPanel = true;
  }

  /** Annule le choix des colonne */
  cancel(e) {
    this.showColumnChooser = false;
  }
}
