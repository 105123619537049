<div *ngIf="field !== undefined && this.selectData.length !== 0">
  <div *ngIf="showFilterContidion != undefined && showFilterContidion == true">
    <clc-work-item-field
      [label]="'entities/workflow/conditions' | translate"
      [visibled]="true"
    >
      <clc-filter-builder
        [type]="type"
        [fieldName]="'expression'"
        [state]="state"
        [useChangeValue]="false"
        [(customValue)]="expression"
        (onFilterValueChanged)="onFilterValueChanged($event)"
      >
      </clc-filter-builder>
    </clc-work-item-field>
  </div>

  <clc-work-item-field
    [label]="'entities/updateTypeTitle' | translate"
    [visibled]="true"
  >
    <dx-select-box
      [valueExpr]="'key'"
      [displayExpr]="'displayName'"
      [(value)]="editType"
      [dataSource]="selectDataSourceContext.datasource"
      (onValueChanged)="editTypeSelectionChanged($event)"
    >
    </dx-select-box>
  </clc-work-item-field>

  <div
    *ngIf="
      editType !== undefined &&
      editType === 'value' &&
      field?.hasChildren === false
    "
  >
    <clc-work-item-field
      [label]="'entities/specificValue' | translate"
      [visibled]="true"
    >
      <dx-date-box
        *ngIf="field?.type == 'DateTime'"
        type="datetime"
        style="padding-top: 6px; padding-bottom: 6px"
        (onValueChanged)="valueDateSelectionChanged($event)"
        [(value)]="valueDate"
      >
      </dx-date-box>
      <dx-number-box
        *ngIf="field?.type == 'Double' || field?.type == 'Int32'"
        [(value)]="value"
        (onValueChanged)="valueNumberSelectionChanged($event)"
        [min]="0"
        [showSpinButtons]="true"
        [showClearButton]="true"
      ></dx-number-box>
      <dx-text-box
        *ngIf="field?.type == 'String' || field?.type == 'String[]'"
        style="padding-top: 6px; padding-bottom: 6px"
        [(value)]="value"
        (onValueChanged)="valueSelectionChanged($event)"
      >
      </dx-text-box>
    </clc-work-item-field>
  </div>
  <div
    *ngIf="
      editType !== undefined &&
      (editType === 'value' || editType === 'substitute-value') &&
      field?.hasChildren === true
    "
  >
    <clc-work-item-field
      [label]="
        editType === 'value'
          ? ('entities/specificValue' | translate)
          : ('entities/subValue' | translate)
      "
      [visibled]="true"
    >
      <clc-link
        [source]="selectValueDataSourceContext.datasource"
        [columns]="[
          {
            label: '',
            field:
              editType === 'value' ? getDisplayExpr(field) : 'translatedName'
          }
        ]"
        [type]="GridOrTreeFormat"
        [valueExpr]="'id'"
        [clearFilterOnClick]="false"
        [(_value)]="value"
        [displayExpr]="
          editType === 'value' ? getDisplayExpr(field) : 'translatedName'
        "
        (onValueChanged)="valueSelectionChanged($event)"
      >
      </clc-link>
    </clc-work-item-field>
  </div>
  <div *ngIf="editType !== undefined && editType === 'substitute-value-add'">
    <clc-work-item-field
      [label]="'entities/specificValueAdd' | translate"
      [visibled]="true"
    >
      <dx-select-box
        [valueExpr]="'key'"
        [displayExpr]="'displayName'"
        [dataSource]="subValueAddDatasource"
        [(value)]="value"
        (onValueChanged)="valueSelectionChanged($event)"
      >
      </dx-select-box>
    </clc-work-item-field>
  </div>
</div>
