import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { SubFormNewMenuItem } from '@clarilog/shared2';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { map, Observable, of } from 'rxjs';
import { EnumCoreService } from '../enum-service/enum.service';
import { WorkflowBaseService } from '../service-bases';
import { WorkflowInvitationCoreService } from './workflow-invitation.service';
import { WorkflowMailCoreService } from './workflow-mail.service';
import { WorkflowPropertyCoreService } from './workflow-property.service';
import { WorkflowRevocationCoreService } from './workflow-revocation.service';
import { TicketCoreService } from './ticket.service';
import { CustomFilterOperation } from '../custom-types';
import { WorkflowNotificationTeamCoreService } from './workflow-notification-team.service';
import { WorkflowHttpRequestCoreService } from '@clarilog/core/services2/graphql/generated-types/services/workflow-http-request.service';
import { WorkflowRevocationWithoutEmailCoreService } from './workflow-revocation-without-email.service';
import { GqlField, GqlSubField } from '../helpers';
import {
  FilterOfWorkflowAction,
  QueryContextOfWorkflowAction,
  ServiceListResultOfWorkflowAction,
} from '../types';
import {
  WorkflowLinkItemCoreService
} from '@clarilog/core/services2/graphql/generated-types/services/workflow-link-item.service';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-workflow')
export class WorkflowCoreService extends WorkflowBaseService {
  constructor(
    injector: Injector,
    public enumCoreService: EnumCoreService,
    private workflowPropertyCoreService: WorkflowPropertyCoreService,
    private workflowRevocationCoreService: WorkflowRevocationCoreService,
    private workflowMailCoreService: WorkflowMailCoreService,
    private workflowHttpRequestCoreService: WorkflowHttpRequestCoreService,
    private workflowInvitationCoreService: WorkflowInvitationCoreService,
    private workflowRevocationWithoutEmailCoreService: WorkflowRevocationWithoutEmailCoreService,
    private workflowNotificationTeamsCoreService: WorkflowNotificationTeamCoreService,
    private workflowLinkItemCoreService: WorkflowLinkItemCoreService,
    private ticketService: TicketCoreService,
  ) {
    super(injector);
  }

  /** Obtient la liste des actions */
  @InjectArgs
  findTranslateActions(
    @Args('type') type: string,
    @Args('workflowFrom') workflowFrom: string = null,
  ): Observable<SubFormNewMenuItem[]> {
    let items: SubFormNewMenuItem[] = [
      {
        id: 'workflowMail',
        name: TranslateService.get('entities/workflowMail/_title/singular'),
        service: this.workflowMailCoreService,
        unique: false,
        withoutForm: false,
      },
    ];

    if (type === "problem" || type === "incident" || type === "request") {
      items.push({
        id: 'workflowLinkItem',
        name: TranslateService.get(
          'entities/workflowLinkItem/_title/plural',
        ),
        service: this.workflowLinkItemCoreService,
        unique: false,
        withoutForm: false,
      });
    }

    if (
      (window.location.toString().includes('diot') ||
        window.location.toString().includes('massiot')) &&
      workflowFrom == 'service-desk'
    ) {
      items.push({
        id: 'workflowHttpRequest',
        name: TranslateService.get(
          'entities/workflowHttpRequest/_title/singular',
        ),
        service: this.workflowHttpRequestCoreService,
        unique: false,
        withoutForm: false,
      });
    }

    if (type != 'loan') {
      items.push({
        id: 'workflowProperty',
        name: TranslateService.get('entities/workflowProperty/_title/singular'),
        service: this.workflowPropertyCoreService,
        unique: false,
        withoutForm: false,
      });
    }

    if (type == 'message') {
      items.push({
        id: 'workflowNotificationTeams',
        name: TranslateService.get(
          'entities/workflowNotificationTeams/_title/singular',
        ),
        service: this.workflowNotificationTeamsCoreService,
        unique: false,
        withoutForm: false,
      });
    }

    items.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    if (type == 'user') {
      items = [
        {
          id: 'workflowProperty',
          name: TranslateService.get('entities/workflowProperty/_title/singular'),
          service: this.workflowPropertyCoreService,
          unique: false,
          withoutForm: false,
        },
        {
          id: 'workflowInvitation',
          name: TranslateService.get(
            'entities/workflowInvitation/_title/singular',
          ),
          service: this.workflowInvitationCoreService,
          unique: false,
          withoutForm: false,
        },
        {
          id: 'workflowRevocation',
          name: TranslateService.get(
            'entities/workflowRevocation/_title/singular',
          ),
          service: this.workflowRevocationCoreService,
          unique: true,
          withoutForm: true,
        },
        {
          id: 'WorkflowRevocationWithoutEmail',
          name: TranslateService.get(
            'entities/workflowRevocationWithoutEmail/_title/singular',
          ),
          service: this.workflowRevocationWithoutEmailCoreService,
          unique: true,
          withoutForm: true,
        },
      ];
    }

    if (type == 'ticket' || type == 'incident' || type == 'request') {
      items.push({
        id: 'ticketTaskAction',

        name: TranslateService.get(
          'entities/workflowProperty/ticketTaskAction',
        ),
        service: this.workflowPropertyCoreService,
        unique: false,
        withoutForm: false,
      });
    }

    items.sort((a, b) => a.name.localeCompare(b.name));

    return of(items);
  }

  /** Obtient la liste des actions */
  @InjectArgs
  findTranslateActionsRule(
    @Args('type') type: string,
  ): Observable<SubFormNewMenuItem[]> {
    let items: SubFormNewMenuItem[] = [
      {
        id: 'workflowMail',
        name: TranslateService.get('entities/workflowMail/_title/singular'),
        service: this.workflowMailCoreService,
        unique: false,
        withoutForm: false,
      },
    ];

    items.sort((a, b) => a.name.localeCompare(b.name));

    return of(items);
  }

  @InjectArgs
  public useMethod(@Args('type') type: string): string {
    switch (type) {
      case 'message':
      case 'ticketEmail':
      case 'incident':
      case 'request':
        return 'allFields';
      default:
        return 'fields';
    }
  }

  @InjectArgs
  public customFilterOperations(
    @Args('type') type: string,
  ): CustomFilterOperation[] {
    let result = [];
    switch (type) {
      case 'incident':
      case 'request':
        this.ticketService
          .customFilterOperations()
          .forEach((d) => result.push(d));
    }

    return result;
  }

  workFlowNewCommand(): Observable<{ id: string; name: string }[]> {
    let items = [
      {
        id: 'incident',
        name: TranslateService.get('entities/incident/_title/singular'),
      },
      {
        id: 'request',
        name: TranslateService.get('entities/request/_title/singular'),
      },
    ];
    items.sort((a, b) => a.name.localeCompare(b.name));
    return of(items);
  }

  findByKey(
    @Args('workflowFrom') workflowFrom: string,
  ): Observable<{ id: string; name: string }[]> {
    let items = [
      {
        id: 'incident',
        name: TranslateService.get('entities/incident/_title/singular'),
      },
      {
        id: 'request',
        name: TranslateService.get('entities/request/_title/singular'),
      },
      {
        id: 'problem',
        name: TranslateService.get('entities/problem/_title/singular'),
      },
      {
        id: 'message',
        name: TranslateService.get('entities/incident/messages'),
      },
      {
        id: 'user',
        name: TranslateService.get('entities/user/_title/singular'),
      },
      {
        id: 'ticketEmail',
        name: TranslateService.get('entities/ticketEmail/_title/plural'),
      },
      {
        id: 'ticketTask',
        name: TranslateService.get('entities/task/_title/singular'),
      },
    ];

    if (workflowFrom === 'rule') {
      items = [
        {
          id: 'loan',
          name: TranslateService.get('entities/loan/_title/singular'),
        },
      ];
    }

    items.sort((a, b) => a.name.localeCompare(b.name));
    return of(items);
  }

  getAllWorkflowType(): { id: string; name: string }[] {
    let items = [
      {
        id: 'incident',
        name: TranslateService.get('entities/incident/_title/singular'),
      },
      {
        id: 'request',
        name: TranslateService.get('entities/request/_title/singular'),
      },
      {
        id: 'problem',
        name: TranslateService.get('entities/problem/_title/singular'),
      },
      {
        id: 'message',
        name: TranslateService.get('entities/message/_title/singular'),
      },
      {
        id: 'user',
        name: TranslateService.get('entities/user/_title/singular'),
      },
      {
        id: 'ticketemail',
        name: TranslateService.get('entities/ticketEmail/_title/singular'),
      },
      {
        id: 'tickettask',
        name: TranslateService.get('entities/task/_title/singular'),
      },
      {
        id: 'loan',
        name: TranslateService.get('entities/loan/_title/singular'),
      },
    ];

    return items;
  }

  @InjectArgs
  public findTranslateAssociatedWorkflowActions(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfWorkflowAction,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfWorkflowAction,

    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfWorkflowAction> {
    return this.findAssociatedWorkflowActions(fields, options, id, filter).pipe(
      map((s) => {
        if (s?.data != undefined) {
          s.data.forEach((f) => {
            let translate =
              'entities/' +
              (
                f['__typename'].charAt(0).toLowerCase() +
                f['__typename'].slice(1)
              ).replace('Type', '') +
              '/_title/singular';
            if (
              f['workflowLinkProperty'] != undefined &&
              f['workflowLinkProperty']['actionName'] != undefined &&
              f['workflowLinkProperty']['actionName'] != ''
            ) {
              translate = f['workflowLinkProperty']['actionName'];
            }

            f['tranlatedTypename'] = TranslateService.get(translate);

            if (f['propertyName'] != undefined && f['propertyName'] != '') {
              let typeProperty = f.workflow.data.entityType;

              if (
                f['workflowLinkProperty'] != undefined &&
                f['workflowLinkProperty']['entityType'] != undefined &&
                f['workflowLinkProperty']['entityType'] != ''
              ) {
                typeProperty = f['workflowLinkProperty']['entityType'];
              }

              let translate =
                'entities/' +
                (
                  typeProperty.charAt(0).toLowerCase() + typeProperty.slice(1)
                ).replace('Type', '') +
                '/' +
                f['propertyName'];

              let displayName = TranslateService.get(translate);
              if (!displayName.startsWith('[')) {
                f['propertyName'] = displayName;
                f['alreadyTranslatedPropertyName'] = true;
              } else {
                if (translate.endsWith('Id')) {
                  displayName = TranslateService.get(
                    translate.substring(0, translate.length - 2),
                  );
                  if (!displayName.startsWith('[')) {
                    f['propertyName'] = displayName;
                    f['alreadyTranslatedPropertyName'] = true;
                  }
                }
              }
            }
          });
        }
        return s;
      }),
    );
  }
}
