import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetInterventionsBaseVariables, FirstInterventionsBaseVariables, CountInterventionsBaseVariables, FindInterventionsBaseVariables, SearchInterventionsBaseVariables, ExportSchemaInterventionsBaseVariables, ExportDataInterventionsBaseVariables, CustomQueryInterventionsBaseVariables, CustomQueryIdInterventionsBaseVariables, FindFilesInterventionsBaseVariables, FindAttachmentFilesInterventionsBaseVariables, FindHelpDeskUserAttachmentFilesInterventionsBaseVariables, UsedInterventionsBaseVariables, ReadOnlyInterventionsBaseVariables, BadgesInterventionsBaseVariables, ExistInterventionsBaseVariables, FindRecyclesInterventionsBaseVariables, CountRecyclesInterventionsBaseVariables, FindArchivedInterventionsBaseVariables, CountAllInterventionsBaseVariables, FindDynamicPropertyFieldsInterventionsBaseVariables, FindUnassociatedAlertsInterventionsBaseVariables, InsertInterventionsBaseVariables, UpdateInterventionsBaseVariables, ImportDataInterventionsBaseVariables, UpdateManyInterventionsBaseVariables, DeleteInterventionsBaseVariables, AddFilesInterventionsBaseVariables, RemoveFilesInterventionsBaseVariables, UploadChunkFileInterventionsBaseVariables, RestoreInterventionsBaseVariables, RecycleInterventionsBaseVariables, RestoreArchivedInterventionsBaseVariables, ArchivedInterventionsBaseVariables, AddFileDynamicFieldInterventionsBaseVariables, RemoveFileDynamicFieldInterventionsBaseVariables, AddAlertsInterventionsBaseVariables, RemoveAlertsInterventionsBaseVariables } from '../gqls'
import { GetInterventionsDocument, FirstInterventionsDocument, CountInterventionsDocument, FindInterventionsDocument, SearchInterventionsDocument, ExportSchemaInterventionsDocument, ExportDataInterventionsDocument, CustomQueryInterventionsDocument, CustomQueryIdInterventionsDocument, FindFilesInterventionsDocument, FindAttachmentFilesInterventionsDocument, FindHelpDeskUserAttachmentFilesInterventionsDocument, UsedInterventionsDocument, ReadOnlyInterventionsDocument, BadgesInterventionsDocument, ExistInterventionsDocument, FindRecyclesInterventionsDocument, CountRecyclesInterventionsDocument, FindArchivedInterventionsDocument, CountAllInterventionsDocument, FindDynamicPropertyFieldsInterventionsDocument, FindUnassociatedAlertsInterventionsDocument, InsertInterventionsDocument, UpdateInterventionsDocument, ImportDataInterventionsDocument, UpdateManyInterventionsDocument, DeleteInterventionsDocument, AddFilesInterventionsDocument, RemoveFilesInterventionsDocument, UploadChunkFileInterventionsDocument, RestoreInterventionsDocument, RecycleInterventionsDocument, RestoreArchivedInterventionsDocument, ArchivedInterventionsDocument, AddFileDynamicFieldInterventionsDocument, RemoveFileDynamicFieldInterventionsDocument, AddAlertsInterventionsDocument, RemoveAlertsInterventionsDocument } from '../gqls'
import { ServiceSingleResultOfIntervention, QueryContextOfIntervention, FilterOfIntervention, ServiceSingleResultOfInt64, ServiceListResultOfIntervention, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceListResultOfFileModel, QueryContextOfFileModel, FilterOfFileModel, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfBadge, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfIntervention, QueryContextOfAlert, FilterOfAlert, ServiceListResultOfAlert, InterventionInput, ServiceSingleResultOfGuid } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class InterventionBaseService {
    
public modelName = 'intervention';
public modelPluralName = 'interventions';

	private getInterventionsQuery: GetInterventionsDocument;
	private firstInterventionsQuery: FirstInterventionsDocument;
	private countInterventionsQuery: CountInterventionsDocument;
	private findInterventionsQuery: FindInterventionsDocument;
	private searchInterventionsQuery: SearchInterventionsDocument;
	private exportSchemaInterventionsQuery: ExportSchemaInterventionsDocument;
	private exportDataInterventionsQuery: ExportDataInterventionsDocument;
	private customQueryInterventionsQuery: CustomQueryInterventionsDocument;
	private customQueryIdInterventionsQuery: CustomQueryIdInterventionsDocument;
	private findFilesInterventionsQuery: FindFilesInterventionsDocument;
	private findAttachmentFilesInterventionsQuery: FindAttachmentFilesInterventionsDocument;
	private findHelpDeskUserAttachmentFilesInterventionsQuery: FindHelpDeskUserAttachmentFilesInterventionsDocument;
	private usedInterventionsQuery: UsedInterventionsDocument;
	private readOnlyInterventionsQuery: ReadOnlyInterventionsDocument;
	private badgesInterventionsQuery: BadgesInterventionsDocument;
	private existInterventionsQuery: ExistInterventionsDocument;
	private findRecyclesInterventionsQuery: FindRecyclesInterventionsDocument;
	private countRecyclesInterventionsQuery: CountRecyclesInterventionsDocument;
	private findArchivedInterventionsQuery: FindArchivedInterventionsDocument;
	private countAllInterventionsQuery: CountAllInterventionsDocument;
	private findDynamicPropertyFieldsInterventionsQuery: FindDynamicPropertyFieldsInterventionsDocument;
	private findUnassociatedAlertsInterventionsQuery: FindUnassociatedAlertsInterventionsDocument;
	private insertInterventionsMutation: InsertInterventionsDocument;
	private updateInterventionsMutation: UpdateInterventionsDocument;
	private importDataInterventionsMutation: ImportDataInterventionsDocument;
	private updateManyInterventionsMutation: UpdateManyInterventionsDocument;
	private deleteInterventionsMutation: DeleteInterventionsDocument;
	private addFilesInterventionsMutation: AddFilesInterventionsDocument;
	private removeFilesInterventionsMutation: RemoveFilesInterventionsDocument;
	private uploadChunkFileInterventionsMutation: UploadChunkFileInterventionsDocument;
	private restoreInterventionsMutation: RestoreInterventionsDocument;
	private recycleInterventionsMutation: RecycleInterventionsDocument;
	private restoreArchivedInterventionsMutation: RestoreArchivedInterventionsDocument;
	private archivedInterventionsMutation: ArchivedInterventionsDocument;
	private addFileDynamicFieldInterventionsMutation: AddFileDynamicFieldInterventionsDocument;
	private removeFileDynamicFieldInterventionsMutation: RemoveFileDynamicFieldInterventionsDocument;
	private addAlertsInterventionsMutation: AddAlertsInterventionsDocument;
	private removeAlertsInterventionsMutation: RemoveAlertsInterventionsDocument;

	constructor(private injector: Injector) {
		this.getInterventionsQuery = this.injector.get(GetInterventionsDocument);
		this.firstInterventionsQuery = this.injector.get(FirstInterventionsDocument);
		this.countInterventionsQuery = this.injector.get(CountInterventionsDocument);
		this.findInterventionsQuery = this.injector.get(FindInterventionsDocument);
		this.searchInterventionsQuery = this.injector.get(SearchInterventionsDocument);
		this.exportSchemaInterventionsQuery = this.injector.get(ExportSchemaInterventionsDocument);
		this.exportDataInterventionsQuery = this.injector.get(ExportDataInterventionsDocument);
		this.customQueryInterventionsQuery = this.injector.get(CustomQueryInterventionsDocument);
		this.customQueryIdInterventionsQuery = this.injector.get(CustomQueryIdInterventionsDocument);
		this.findFilesInterventionsQuery = this.injector.get(FindFilesInterventionsDocument);
		this.findAttachmentFilesInterventionsQuery = this.injector.get(FindAttachmentFilesInterventionsDocument);
		this.findHelpDeskUserAttachmentFilesInterventionsQuery = this.injector.get(FindHelpDeskUserAttachmentFilesInterventionsDocument);
		this.usedInterventionsQuery = this.injector.get(UsedInterventionsDocument);
		this.readOnlyInterventionsQuery = this.injector.get(ReadOnlyInterventionsDocument);
		this.badgesInterventionsQuery = this.injector.get(BadgesInterventionsDocument);
		this.existInterventionsQuery = this.injector.get(ExistInterventionsDocument);
		this.findRecyclesInterventionsQuery = this.injector.get(FindRecyclesInterventionsDocument);
		this.countRecyclesInterventionsQuery = this.injector.get(CountRecyclesInterventionsDocument);
		this.findArchivedInterventionsQuery = this.injector.get(FindArchivedInterventionsDocument);
		this.countAllInterventionsQuery = this.injector.get(CountAllInterventionsDocument);
		this.findDynamicPropertyFieldsInterventionsQuery = this.injector.get(FindDynamicPropertyFieldsInterventionsDocument);
		this.findUnassociatedAlertsInterventionsQuery = this.injector.get(FindUnassociatedAlertsInterventionsDocument);
		this.insertInterventionsMutation = this.injector.get(InsertInterventionsDocument);
		this.updateInterventionsMutation = this.injector.get(UpdateInterventionsDocument);
		this.importDataInterventionsMutation = this.injector.get(ImportDataInterventionsDocument);
		this.updateManyInterventionsMutation = this.injector.get(UpdateManyInterventionsDocument);
		this.deleteInterventionsMutation = this.injector.get(DeleteInterventionsDocument);
		this.addFilesInterventionsMutation = this.injector.get(AddFilesInterventionsDocument);
		this.removeFilesInterventionsMutation = this.injector.get(RemoveFilesInterventionsDocument);
		this.uploadChunkFileInterventionsMutation = this.injector.get(UploadChunkFileInterventionsDocument);
		this.restoreInterventionsMutation = this.injector.get(RestoreInterventionsDocument);
		this.recycleInterventionsMutation = this.injector.get(RecycleInterventionsDocument);
		this.restoreArchivedInterventionsMutation = this.injector.get(RestoreArchivedInterventionsDocument);
		this.archivedInterventionsMutation = this.injector.get(ArchivedInterventionsDocument);
		this.addFileDynamicFieldInterventionsMutation = this.injector.get(AddFileDynamicFieldInterventionsDocument);
		this.removeFileDynamicFieldInterventionsMutation = this.injector.get(RemoveFileDynamicFieldInterventionsDocument);
		this.addAlertsInterventionsMutation = this.injector.get(AddAlertsInterventionsDocument);
		this.removeAlertsInterventionsMutation = this.injector.get(RemoveAlertsInterventionsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfIntervention> {
    
    		let variables: GetInterventionsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.get));
            }
            else{
                let result:ServiceSingleResultOfIntervention={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfIntervention,
		@Args('filter?') filter?: FilterOfIntervention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfIntervention> {
    
    		let variables: FirstInterventionsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfIntervention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountInterventionsBaseVariables = {
    			filter: filter
    		}
    				return this.countInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfIntervention,
		@Args('filter?') filter?: FilterOfIntervention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIntervention> {
    
    		let variables: FindInterventionsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfIntervention,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIntervention> {
    
    		let variables: SearchInterventionsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaInterventionsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfIntervention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataInterventionsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfIntervention,
		@Args('filter?') filter?: FilterOfIntervention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIntervention> {
    
    		let variables: CustomQueryInterventionsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfIntervention,
		@Args('filter?') filter?: FilterOfIntervention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIntervention> {
    
    		let variables: CustomQueryIdInterventionsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.customQueryId));
    	}

    	/** Récupère les fichiers liés. */
    	@InjectArgs
    	public findFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindFilesInterventionsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findFilesInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.findFiles));
    	}

    	/** Récupère les fichiers attachés liés. */
    	@InjectArgs
    	public findAttachmentFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindAttachmentFilesInterventionsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAttachmentFilesInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.findAttachmentFiles));
    	}

    	/** Récupère les fichiers attachés liés visible pour les users help desk. */
    	@InjectArgs
    	public findHelpDeskUserAttachmentFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindHelpDeskUserAttachmentFilesInterventionsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findHelpDeskUserAttachmentFilesInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.findHelpDeskUserAttachmentFiles));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedInterventionsBaseVariables = {
    			ids: ids
    		}
    				return this.usedInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.used));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyInterventionsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.readOnly));
    	}

    	/**  */
    	@InjectArgs
    	public badges(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBadge> {
    
    		let variables: BadgesInterventionsBaseVariables = {
    			id: id
    		}
    				return this.badgesInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.badges));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistInterventionsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfIntervention,
		@Args('filter?') filter?: FilterOfIntervention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIntervention> {
    
    		let variables: FindRecyclesInterventionsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfIntervention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesInterventionsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.countRecycles));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfIntervention,
		@Args('filter?') filter?: FilterOfIntervention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIntervention> {
    
    		let variables: FindArchivedInterventionsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfIntervention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllInterventionsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfIntervention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsInterventionsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedAlerts(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAlert> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('alerts', fields, null, [
			GqlSubFieldArg.create('filterOfAlerts', 'filter'),
			GqlSubFieldArg.create('optionsOfAlerts', 'options'),
		]),
		])
		extendedVariables['filterOfAlerts'] = filter;
		extendedVariables['optionsOfAlerts'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.alerts));
            }
            else{
                let result: ServiceListResultOfAlert = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlert> {
    
    		let variables: FindUnassociatedAlertsInterventionsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAlertsInterventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.findUnassociatedAlerts));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: InterventionInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfIntervention> {
    
    		let variables: InsertInterventionsBaseVariables = {
    			entity: entity
    		}
    				return this.insertInterventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfIntervention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfIntervention> {
    
    		let variables: UpdateInterventionsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateInterventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataInterventionsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataInterventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfIntervention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyInterventionsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyInterventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteInterventionsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteInterventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileIds') fileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFilesInterventionsBaseVariables = {
    			id: id,
			fileIds: fileIds
    		}
    				return this.addFilesInterventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.addFiles));
    	}

    	/**  */
    	@InjectArgs
    	public removeFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileIds') fileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFilesInterventionsBaseVariables = {
    			id: id,
			fileIds: fileIds
    		}
    				return this.removeFilesInterventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.removeFiles));
    	}

    	/**  */
    	@InjectArgs
    	public uploadChunkFile(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('visibleByHelpDeskUser') visibleByHelpDeskUser: boolean,
		@Args('size') size: number,
		@Args('outgoingEmails') outgoingEmails: boolean,
		@Args('lastChunk') lastChunk: boolean,
		@Args('indexChunk') indexChunk: number,
		@Args('type?') type?: string,
		@Args('name?') name?: string,
		@Args('fileId?') fileId?: string,
		@Args('entityId?') entityId?: string,
		@Args('chunks?') chunks?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: UploadChunkFileInterventionsBaseVariables = {
    			name: name,
			size: size,
			type: type,
			chunks: chunks,
			indexChunk: indexChunk,
			lastChunk: lastChunk,
			fileId: fileId,
			entityId: entityId,
			visibleByHelpDeskUser: visibleByHelpDeskUser,
			outgoingEmails: outgoingEmails
    		}
    				return this.uploadChunkFileInterventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.uploadChunkFile));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreInterventionsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreInterventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleInterventionsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleInterventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedInterventionsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedInterventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedInterventionsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedInterventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldInterventionsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldInterventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldInterventionsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldInterventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('alertIds') alertIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAlertsInterventionsBaseVariables = {
    			id: id,
			alertIds: alertIds
    		}
    				return this.addAlertsInterventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.addAlerts));
    	}

    	/**  */
    	@InjectArgs
    	public removeAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('alertIds') alertIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveAlertsInterventionsBaseVariables = {
    			alertIds: alertIds
    		}
    				return this.removeAlertsInterventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventions.removeAlerts));
    	}
    
}