import { AssetAcquisitionModeBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';

@Injectable({ providedIn: 'root' })
@Authorize({
  policies: [
    'asset.manage-acquisition-mode',
    'contract.manage-acquisition-mode',
  ],
  operator: 'or',
})
export class AssetAcquisitionModeCoreService extends AssetAcquisitionModeBaseService {
  constructor(injector: Injector) {
    super(injector);
  }
}
