import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ModelDataSourceContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { layout } from 'devexpress-dashboard/model/metadata/_dashboard';
import {
  MobileListEvent,
  MobileListEventData,
} from '@clarilog/shared2/components/mobile-list/mobile-list.component';
import { MobileHeaderEvent } from '@clarilog/layouts2/mobile-layout/mobile-header/mobile-header.component';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { GC, GCFactory } from '@clarilog/core';

@Component({
  selector: 'cl-mobile-manage-list',
  templateUrl: './mobile-manage-list.component.html',
  styleUrls: ['./mobile-manage-list.component.scss'],
})
export class MobileManageListComponent implements OnInit, OnDestroy {
  get modelState(): ModelState {
    return this._modelState;
  }

  @Input() set modelState(value: ModelState) {
    if (this._modelState === undefined && value != undefined) {
      this.source = value?.model?.grid?.layout?.filters[0]?.items[0]?.list
        ?.source as ModelDataSourceContext;
    }
    this._modelState = value;
  }

  @Input() layoutState: ModelState;
  @Input() dataRowTemplateName: string;
  @Input() disableOnRowClickNavigation: boolean = false;

  @Output() onRowClick: EventEmitter<any> = new EventEmitter<any>();
  private _modelState: ModelState;

  public source: ModelDataSourceContext;
  private gc: GC;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private gcFactory: GCFactory,
  ) {
    this.gc = gcFactory.create();
  }

  @Input() onNewClick: (e: any) => void = (e) => {
    this.router.navigate(['new'], { relativeTo: this.route });
  };

  rowClick(e) {
    this.onRowClick.emit(e);

    if (this.router.url.includes('/favorite')) {
      const urlTree: UrlTree = this.router.parseUrl(this.router.url);

      const segments = urlTree.root.children['primary'].segments.map(
        (segment) => segment.path,
      );

      const filteredSegments = segments.filter(
        (segment) => segment !== 'favorite',
      );

      if (filteredSegments.length == 1) {
        if (e.data?.__typename != null)
          filteredSegments.push(e.data.__typename.toLowerCase());
      }

      filteredSegments.push('edit', e.value);

      this.router.navigate(filteredSegments, {
        queryParamsHandling: 'preserve',
        replaceUrl: true,
      });
    } else {
      this.router.navigate(['edit', e.value], { relativeTo: this.route });
    }
  }

  manageBtn() {
    let addData = {
      icon: 'add',
      hint: 'add',
      eventActionReturnType: MobileListEvent.ADD,
    } as MobileListEventData;
    this.layoutState.on.emit({
      eventName: MobileHeaderEvent.ACTION,
      eventData: addData,
    });
    this.gc.forDispose(
      this.layoutState.on.subscribe((event) => {
        if (event.eventName === MobileListEvent.ADD) {
          this.onNewClick(event.eventData);
        }
      }),
    );
  }

  ngOnInit(): void {
    this.manageBtn();
  }

  protected readonly layout = layout;

  removeActionBtn() {
    let addData = {
      eventActionReturnType: MobileListEvent.ADD,
    } as MobileListEventData;
    this.layoutState.on.emit({
      eventName: MobileHeaderEvent.ACTION_REMOVE,
      eventData: addData,
    });
  }

  ngOnDestroy(): void {
    this.gc.dispose();
    this.removeActionBtn();
  }
}
