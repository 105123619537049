import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetTicketEmailsBaseVariables, FirstTicketEmailsBaseVariables, CountTicketEmailsBaseVariables, FindTicketEmailsBaseVariables, SearchTicketEmailsBaseVariables, ExportSchemaTicketEmailsBaseVariables, ExportDataTicketEmailsBaseVariables, CustomQueryTicketEmailsBaseVariables, CustomQueryIdTicketEmailsBaseVariables, FindFilesTicketEmailsBaseVariables, FindAttachmentFilesTicketEmailsBaseVariables, FindHelpDeskUserAttachmentFilesTicketEmailsBaseVariables, UsedTicketEmailsBaseVariables, FindTicketsTicketEmailsBaseVariables, FindInformationOnEmailTicketEmailsBaseVariables, FindAttachmentByEmailTicketEmailsBaseVariables, FindTicketEmailLogTicketEmailsBaseVariables, FindReceiveLogByConnectorTicketEmailsBaseVariables, PurgeTicketEmailLogTicketEmailsBaseVariables, ExistTicketEmailsBaseVariables, InsertTicketEmailsBaseVariables, UpdateTicketEmailsBaseVariables, ImportDataTicketEmailsBaseVariables, UpdateManyTicketEmailsBaseVariables, DeleteTicketEmailsBaseVariables, AddFilesTicketEmailsBaseVariables, RemoveFilesTicketEmailsBaseVariables, UploadChunkFileTicketEmailsBaseVariables, SendBackTicketEmailsBaseVariables } from '../gqls'
import { GetTicketEmailsDocument, FirstTicketEmailsDocument, CountTicketEmailsDocument, FindTicketEmailsDocument, SearchTicketEmailsDocument, ExportSchemaTicketEmailsDocument, ExportDataTicketEmailsDocument, CustomQueryTicketEmailsDocument, CustomQueryIdTicketEmailsDocument, FindFilesTicketEmailsDocument, FindAttachmentFilesTicketEmailsDocument, FindHelpDeskUserAttachmentFilesTicketEmailsDocument, UsedTicketEmailsDocument, FindTicketsTicketEmailsDocument, FindInformationOnEmailTicketEmailsDocument, FindAttachmentByEmailTicketEmailsDocument, FindTicketEmailLogTicketEmailsDocument, FindReceiveLogByConnectorTicketEmailsDocument, PurgeTicketEmailLogTicketEmailsDocument, ExistTicketEmailsDocument, InsertTicketEmailsDocument, UpdateTicketEmailsDocument, ImportDataTicketEmailsDocument, UpdateManyTicketEmailsDocument, DeleteTicketEmailsDocument, AddFilesTicketEmailsDocument, RemoveFilesTicketEmailsDocument, UploadChunkFileTicketEmailsDocument, SendBackTicketEmailsDocument } from '../gqls'
import { ServiceSingleResultOfTicketEmail, QueryContextOfTicketEmail, FilterOfTicketEmail, ServiceSingleResultOfInt64, ServiceListResultOfTicketEmail, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceListResultOfFileModel, QueryContextOfFileModel, FilterOfFileModel, ServiceSingleResultOfBoolean, ServiceListResultOfMailSlurpAttachmentMetaData, QueryContextOfMailSlurpAttachmentMetaData, FilterOfMailSlurpAttachmentMetaData, TicketEmailAction, TicketEmailInput, FieldUpdateOperatorOfTicketEmail, ServiceSingleResultOfGuid } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class TicketEmailBaseService {
    
public modelName = 'ticketEmail';
public modelPluralName = 'ticketEmails';

	private getTicketEmailsQuery: GetTicketEmailsDocument;
	private firstTicketEmailsQuery: FirstTicketEmailsDocument;
	private countTicketEmailsQuery: CountTicketEmailsDocument;
	private findTicketEmailsQuery: FindTicketEmailsDocument;
	private searchTicketEmailsQuery: SearchTicketEmailsDocument;
	private exportSchemaTicketEmailsQuery: ExportSchemaTicketEmailsDocument;
	private exportDataTicketEmailsQuery: ExportDataTicketEmailsDocument;
	private customQueryTicketEmailsQuery: CustomQueryTicketEmailsDocument;
	private customQueryIdTicketEmailsQuery: CustomQueryIdTicketEmailsDocument;
	private findFilesTicketEmailsQuery: FindFilesTicketEmailsDocument;
	private findAttachmentFilesTicketEmailsQuery: FindAttachmentFilesTicketEmailsDocument;
	private findHelpDeskUserAttachmentFilesTicketEmailsQuery: FindHelpDeskUserAttachmentFilesTicketEmailsDocument;
	private usedTicketEmailsQuery: UsedTicketEmailsDocument;
	private findTicketsTicketEmailsQuery: FindTicketsTicketEmailsDocument;
	private findInformationOnEmailTicketEmailsQuery: FindInformationOnEmailTicketEmailsDocument;
	private findAttachmentByEmailTicketEmailsQuery: FindAttachmentByEmailTicketEmailsDocument;
	private findTicketEmailLogTicketEmailsQuery: FindTicketEmailLogTicketEmailsDocument;
	private findReceiveLogByConnectorTicketEmailsQuery: FindReceiveLogByConnectorTicketEmailsDocument;
	private purgeTicketEmailLogTicketEmailsQuery: PurgeTicketEmailLogTicketEmailsDocument;
	private existTicketEmailsQuery: ExistTicketEmailsDocument;
	private insertTicketEmailsMutation: InsertTicketEmailsDocument;
	private updateTicketEmailsMutation: UpdateTicketEmailsDocument;
	private importDataTicketEmailsMutation: ImportDataTicketEmailsDocument;
	private updateManyTicketEmailsMutation: UpdateManyTicketEmailsDocument;
	private deleteTicketEmailsMutation: DeleteTicketEmailsDocument;
	private addFilesTicketEmailsMutation: AddFilesTicketEmailsDocument;
	private removeFilesTicketEmailsMutation: RemoveFilesTicketEmailsDocument;
	private uploadChunkFileTicketEmailsMutation: UploadChunkFileTicketEmailsDocument;
	private sendBackTicketEmailsMutation: SendBackTicketEmailsDocument;

	constructor(private injector: Injector) {
		this.getTicketEmailsQuery = this.injector.get(GetTicketEmailsDocument);
		this.firstTicketEmailsQuery = this.injector.get(FirstTicketEmailsDocument);
		this.countTicketEmailsQuery = this.injector.get(CountTicketEmailsDocument);
		this.findTicketEmailsQuery = this.injector.get(FindTicketEmailsDocument);
		this.searchTicketEmailsQuery = this.injector.get(SearchTicketEmailsDocument);
		this.exportSchemaTicketEmailsQuery = this.injector.get(ExportSchemaTicketEmailsDocument);
		this.exportDataTicketEmailsQuery = this.injector.get(ExportDataTicketEmailsDocument);
		this.customQueryTicketEmailsQuery = this.injector.get(CustomQueryTicketEmailsDocument);
		this.customQueryIdTicketEmailsQuery = this.injector.get(CustomQueryIdTicketEmailsDocument);
		this.findFilesTicketEmailsQuery = this.injector.get(FindFilesTicketEmailsDocument);
		this.findAttachmentFilesTicketEmailsQuery = this.injector.get(FindAttachmentFilesTicketEmailsDocument);
		this.findHelpDeskUserAttachmentFilesTicketEmailsQuery = this.injector.get(FindHelpDeskUserAttachmentFilesTicketEmailsDocument);
		this.usedTicketEmailsQuery = this.injector.get(UsedTicketEmailsDocument);
		this.findTicketsTicketEmailsQuery = this.injector.get(FindTicketsTicketEmailsDocument);
		this.findInformationOnEmailTicketEmailsQuery = this.injector.get(FindInformationOnEmailTicketEmailsDocument);
		this.findAttachmentByEmailTicketEmailsQuery = this.injector.get(FindAttachmentByEmailTicketEmailsDocument);
		this.findTicketEmailLogTicketEmailsQuery = this.injector.get(FindTicketEmailLogTicketEmailsDocument);
		this.findReceiveLogByConnectorTicketEmailsQuery = this.injector.get(FindReceiveLogByConnectorTicketEmailsDocument);
		this.purgeTicketEmailLogTicketEmailsQuery = this.injector.get(PurgeTicketEmailLogTicketEmailsDocument);
		this.existTicketEmailsQuery = this.injector.get(ExistTicketEmailsDocument);
		this.insertTicketEmailsMutation = this.injector.get(InsertTicketEmailsDocument);
		this.updateTicketEmailsMutation = this.injector.get(UpdateTicketEmailsDocument);
		this.importDataTicketEmailsMutation = this.injector.get(ImportDataTicketEmailsDocument);
		this.updateManyTicketEmailsMutation = this.injector.get(UpdateManyTicketEmailsDocument);
		this.deleteTicketEmailsMutation = this.injector.get(DeleteTicketEmailsDocument);
		this.addFilesTicketEmailsMutation = this.injector.get(AddFilesTicketEmailsDocument);
		this.removeFilesTicketEmailsMutation = this.injector.get(RemoveFilesTicketEmailsDocument);
		this.uploadChunkFileTicketEmailsMutation = this.injector.get(UploadChunkFileTicketEmailsDocument);
		this.sendBackTicketEmailsMutation = this.injector.get(SendBackTicketEmailsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketEmail> {
    
    		let variables: GetTicketEmailsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getTicketEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.get));
            }
            else{
                let result:ServiceSingleResultOfTicketEmail={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketEmail,
		@Args('filter?') filter?: FilterOfTicketEmail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketEmail> {
    
    		let variables: FirstTicketEmailsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstTicketEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketEmail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountTicketEmailsBaseVariables = {
    			filter: filter
    		}
    				return this.countTicketEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketEmail,
		@Args('filter?') filter?: FilterOfTicketEmail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketEmail> {
    
    		let variables: FindTicketEmailsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findTicketEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfTicketEmail,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketEmail> {
    
    		let variables: SearchTicketEmailsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchTicketEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaTicketEmailsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaTicketEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketEmail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataTicketEmailsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataTicketEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfTicketEmail,
		@Args('filter?') filter?: FilterOfTicketEmail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketEmail> {
    
    		let variables: CustomQueryTicketEmailsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryTicketEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfTicketEmail,
		@Args('filter?') filter?: FilterOfTicketEmail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketEmail> {
    
    		let variables: CustomQueryIdTicketEmailsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdTicketEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.customQueryId));
    	}

    	/** Récupère les fichiers liés. */
    	@InjectArgs
    	public findFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindFilesTicketEmailsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findFilesTicketEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.findFiles));
    	}

    	/** Récupère les fichiers attachés liés. */
    	@InjectArgs
    	public findAttachmentFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindAttachmentFilesTicketEmailsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAttachmentFilesTicketEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.findAttachmentFiles));
    	}

    	/** Récupère les fichiers attachés liés visible pour les users help desk. */
    	@InjectArgs
    	public findHelpDeskUserAttachmentFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindHelpDeskUserAttachmentFilesTicketEmailsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findHelpDeskUserAttachmentFilesTicketEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.findHelpDeskUserAttachmentFiles));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedTicketEmailsBaseVariables = {
    			ids: ids
    		}
    				return this.usedTicketEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.used));
    	}

    	/**  */
    	@InjectArgs
    	public findTickets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketEmail,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicketEmail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketEmail> {
    
    		let variables: FindTicketsTicketEmailsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findTicketsTicketEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.findTickets));
    	}

    	/**  */
    	@InjectArgs
    	public findInformationOnEmail(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketEmail,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicketEmail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketEmail> {
    
    		let variables: FindInformationOnEmailTicketEmailsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findInformationOnEmailTicketEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.findInformationOnEmail));
    	}

    	/**  */
    	@InjectArgs
    	public findAttachmentByEmail(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfMailSlurpAttachmentMetaData,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfMailSlurpAttachmentMetaData,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMailSlurpAttachmentMetaData> {
    
    		let variables: FindAttachmentByEmailTicketEmailsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAttachmentByEmailTicketEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.findAttachmentByEmail));
    	}

    	/**  */
    	@InjectArgs
    	public findTicketEmailLog(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: TicketEmailAction,
		@Args('options?') options?: QueryContextOfTicketEmail,
		@Args('filter?') filter?: FilterOfTicketEmail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketEmail> {
    
    		let variables: FindTicketEmailLogTicketEmailsBaseVariables = {
    			type: type,
			filter: filter,
			options: options
    		}
    				return this.findTicketEmailLogTicketEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.findTicketEmailLog));
    	}

    	/**  */
    	@InjectArgs
    	public findReceiveLogByConnector(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketEmail,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicketEmail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketEmail> {
    
    		let variables: FindReceiveLogByConnectorTicketEmailsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findReceiveLogByConnectorTicketEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.findReceiveLogByConnector));
    	}

    	/** Purge les journaux entrant ou sortant */
    	@InjectArgs
    	public purgeTicketEmailLog(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: TicketEmailAction,
		@Args('start?') start?: Date,
		@Args('end?') end?: Date,
		@Args('action?') action?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: PurgeTicketEmailLogTicketEmailsBaseVariables = {
    			type: type,
			action: action,
			start: start,
			end: end
    		}
    				return this.purgeTicketEmailLogTicketEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.purgeTicketEmailLog));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistTicketEmailsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existTicketEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: TicketEmailInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketEmail> {
    
    		let variables: InsertTicketEmailsBaseVariables = {
    			entity: entity
    		}
    				return this.insertTicketEmailsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketEmail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketEmail> {
    
    		let variables: UpdateTicketEmailsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateTicketEmailsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataTicketEmailsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataTicketEmailsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketEmail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyTicketEmailsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyTicketEmailsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTicketEmailsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteTicketEmailsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileIds') fileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFilesTicketEmailsBaseVariables = {
    			id: id,
			fileIds: fileIds
    		}
    				return this.addFilesTicketEmailsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.addFiles));
    	}

    	/**  */
    	@InjectArgs
    	public removeFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileIds') fileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFilesTicketEmailsBaseVariables = {
    			id: id,
			fileIds: fileIds
    		}
    				return this.removeFilesTicketEmailsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.removeFiles));
    	}

    	/**  */
    	@InjectArgs
    	public uploadChunkFile(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('visibleByHelpDeskUser') visibleByHelpDeskUser: boolean,
		@Args('size') size: number,
		@Args('outgoingEmails') outgoingEmails: boolean,
		@Args('lastChunk') lastChunk: boolean,
		@Args('indexChunk') indexChunk: number,
		@Args('type?') type?: string,
		@Args('name?') name?: string,
		@Args('fileId?') fileId?: string,
		@Args('entityId?') entityId?: string,
		@Args('chunks?') chunks?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: UploadChunkFileTicketEmailsBaseVariables = {
    			name: name,
			size: size,
			type: type,
			chunks: chunks,
			indexChunk: indexChunk,
			lastChunk: lastChunk,
			fileId: fileId,
			entityId: entityId,
			visibleByHelpDeskUser: visibleByHelpDeskUser,
			outgoingEmails: outgoingEmails
    		}
    				return this.uploadChunkFileTicketEmailsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.uploadChunkFile));
    	}

    	/**  */
    	@InjectArgs
    	public sendBack(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: SendBackTicketEmailsBaseVariables = {
    			id: id
    		}
    				return this.sendBackTicketEmailsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmails.sendBack));
    	}
    
}