<cl-work-page>
  <cl-work-page-header>
    <cl-work-page-header-content style="border-left: 0px !important">
      <cl-work-page-header-content-body>
        <div class="cl-hint">{{ hint }}</div>
        <cl-toolbar class="action">
          <cl-toolbar-items [items]="toolbar?.itemsChildren.toArray()">
            <cl-toolbar-item-button
              [text]="'associate' | translate"
              icon="far fa-link"
              (onClick)="associatePopup = true"
              [visible]="selectSource != undefined && addSource && !readOnly"
            >
            </cl-toolbar-item-button>
            <cl-toolbar-item-button
              [text]="'dissociate' | translate"
              icon="far fa-unlink"
              [disabled]="selectedKeys.length === 0"
              (onClick)="dissociate($event)"
              [visible]="
                !readOnly &&
                removeSource &&
                mode == LinkListMode.Current &&
                selectSource != undefined &&
                canDeleted &&
                canDissociated === true
              "
            >
            </cl-toolbar-item-button>
            <cl-toolbar-item-button
              [text]="'manage' | translate"
              [visible]="route !== undefined"
              (onClick)="onGoTo()"
              icon="fas fa-arrow-right"
            ></cl-toolbar-item-button>
            <cl-toolbar-item-button
              [text]="titleButton"
              [visible]="activeButton != undefined && activeButton !== false"
              (onClick)="onGoTo(true)"
              icon="far fa-plus"
            >
            </cl-toolbar-item-button>
            <cl-toolbar-item-button
              [text]="'cancel' | translate"
              [disabled]="selectedKeys.length === 0"
              (onClick)="cancel($event)"
              [visible]="
                mode != LinkListMode.Current &&
                (selectSource != undefined || canDeleted)
              "
            >
            </cl-toolbar-item-button>
          </cl-toolbar-items>
        </cl-toolbar>
      </cl-work-page-header-content-body>
    </cl-work-page-header-content>
  </cl-work-page-header>
  <cl-work-page-left
    *ngIf="mode === LinkListMode.Current"
    [hide]="
      displayFilterOnlyForPopup === true ||
      filters === undefined ||
      (filters.length === 1 && filters[0].items.length === 1)
    "
  >
    <clc-nav-menu>
      <clc-nav-group *ngFor="let filter of filters" [text]="filter.text">
        <clc-nav-menu-item
          *ngFor="let item of filter.items"
          [active]="item === currentFilter"
          [text]="item.text"
          [source]="item.source"
          [filterExpr]="item.filterExpr"
          [parentIdExpr]="item.parentIdExpr"
          [keyExpr]="item.keyExpr"
          [inContext]="item.inContext"
          [displayExpr]="item.displayExpr"
          [multiple]="item.multiple"
          [recursive]="item.recursive"
          (onClick)="currentFilter = item; onNavFilterClick($event)"
          [filterOperation]="item.filterOperator"
          (onItemSelectionChanged)="onItemSelectionChanged($event)"
          (onItemSelected)="onItemSelected($event)"
          [filterContext]="item.filterContext"
          [deniedCumulateFilter]="true"
        ></clc-nav-menu-item>
      </clc-nav-group>
    </clc-nav-menu>
  </cl-work-page-left>
  <cl-work-page-content>
    <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
    <nav
      mat-tab-nav-bar
      [tabPanel]="tabPanel"
      class="tab-nav-bar"
      mat-stretch-tabs="false"
      *ngIf="selectSource != undefined || canDeleted"
    >
      <a
        mat-tab-link
        (click)="mode = LinkListMode.Current; current()"
        [active]="mode == LinkListMode.Current"
        >{{ 'current-data' | translate }}</a
      >
      <a
        mat-tab-link
        (click)="mode = LinkListMode.Added; addedList()"
        *ngIf="itemsAdded.length > 0"
        [active]="mode == LinkListMode.Added"
        ><span [matBadge]="itemsAdded.length" matBadgeOverlap="false">{{
          'add-data' | translate : { count: itemsAdded.length }
        }}</span></a
      >
      <a
        mat-tab-link
        (click)="mode = LinkListMode.Removed; removedList()"
        *ngIf="itemsRemoved.length > 0"
        [active]="mode == LinkListMode.Removed"
      >
        <span [matBadge]="itemsRemoved.length" matBadgeOverlap="false">{{
          'remove-data' | translate : { count: itemsRemoved.length }
        }}</span></a
      >
    </nav>

    <clc-list
      class="list"
      [isLiveUpdated]="isLiveUpdated"
      [canSelect]="selectSource != undefined || canDeleted || canSelect"
      [source]="currentSource"
      [type]="type == undefined ? 'Grid' : type"
      [columns]="columns"
      [multiple]="multiple && !readOnly"
      [keyExpr]="keyExpr"
      [parentIdExpr]="parentIdExpr"
      [masterDetail]="masterDetail"
      [recursive]="recursive"
      [(selectedKeys)]="selectedKeys"
      [(selectedData)]="selectedData"
      (onRowClick)="onRowListClick($event)"
      [forceLinkEmpty]="route != undefined"
      [modelState]="modelState"
      [toolbarItems]="toolbarItems"
      [allowExportPanel]="allowExportPanel && !isMobile"
      [allowFilterPanel]="!isMobile"
    >
    </clc-list>
  </cl-work-page-content>
</cl-work-page>

<dx-popup
  [width]="
    filters == undefined ||
    (filters.length === 1 && filters[0].items.length === 1)
      ? 800
      : 1050
  "
  [height]="'80%'"
  [showTitle]="false"
  [dragEnabled]="true"
  class="add-item-popup"
  #popup
  [resizeEnabled]="true"
  [hideOnOutsideClick]="true"
  [(visible)]="associatePopup"
  (onShown)="show($event)"
  (onHidden)="hidden($event)"
>
  <clc-select-list
    #selectList
    [label]="label"
    [type]="type == undefined ? 'Grid' : type"
    [multiple]="true"
    [recursive]="recursive"
    [parentIdExpr]="parentIdExpr"
    [columns]="findColumns()"
    (onClosed)="associatePopup = false"
    (onRowClick)="onRowListClick($event)"
    [forceLinkEmpty]="false"
    [filters]="filterForSelect"
  >
  </clc-select-list>
</dx-popup>
