import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetReportsBaseVariables, FirstReportsBaseVariables, CountReportsBaseVariables, FindReportsBaseVariables, SearchReportsBaseVariables, ExportSchemaReportsBaseVariables, ExportDataReportsBaseVariables, CustomQueryReportsBaseVariables, CustomQueryIdReportsBaseVariables, FindFilesReportsBaseVariables, UsedReportsBaseVariables, FindTemplatesReportsBaseVariables, ExistReportsBaseVariables, FindRecyclesReportsBaseVariables, CountRecyclesReportsBaseVariables, ReadOnlyReportsBaseVariables, FindArchivedReportsBaseVariables, CountAllReportsBaseVariables, FindDynamicPropertyFieldsReportsBaseVariables, InsertReportsBaseVariables, UpdateReportsBaseVariables, ImportDataReportsBaseVariables, UpdateManyReportsBaseVariables, DeleteReportsBaseVariables, UploadChunkFileReportsBaseVariables, RestoreReportsBaseVariables, RecycleReportsBaseVariables, RestoreArchivedReportsBaseVariables, ArchivedReportsBaseVariables, AddFileDynamicFieldReportsBaseVariables, RemoveFileDynamicFieldReportsBaseVariables, AddFileLinkReportsBaseVariables, RemoveFileLinkReportsBaseVariables } from '../gqls'
import { GetReportsDocument, FirstReportsDocument, CountReportsDocument, FindReportsDocument, SearchReportsDocument, ExportSchemaReportsDocument, ExportDataReportsDocument, CustomQueryReportsDocument, CustomQueryIdReportsDocument, FindFilesReportsDocument, UsedReportsDocument, FindTemplatesReportsDocument, ExistReportsDocument, FindRecyclesReportsDocument, CountRecyclesReportsDocument, ReadOnlyReportsDocument, FindArchivedReportsDocument, CountAllReportsDocument, FindDynamicPropertyFieldsReportsDocument, InsertReportsDocument, UpdateReportsDocument, ImportDataReportsDocument, UpdateManyReportsDocument, DeleteReportsDocument, UploadChunkFileReportsDocument, RestoreReportsDocument, RecycleReportsDocument, RestoreArchivedReportsDocument, ArchivedReportsDocument, AddFileDynamicFieldReportsDocument, RemoveFileDynamicFieldReportsDocument, AddFileLinkReportsDocument, RemoveFileLinkReportsDocument } from '../gqls'
import { ServiceSingleResultOfReport, QueryContextOfReport, FilterOfReport, ServiceSingleResultOfInt64, ServiceListResultOfReport, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceListResultOfFileModel, QueryContextOfFileModel, FilterOfFileModel, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfReport, ReportInput, ServiceSingleResultOfGuid } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ReportBaseService {
    
public modelName = 'report';
public modelPluralName = 'reports';

	private getReportsQuery: GetReportsDocument;
	private firstReportsQuery: FirstReportsDocument;
	private countReportsQuery: CountReportsDocument;
	private findReportsQuery: FindReportsDocument;
	private searchReportsQuery: SearchReportsDocument;
	private exportSchemaReportsQuery: ExportSchemaReportsDocument;
	private exportDataReportsQuery: ExportDataReportsDocument;
	private customQueryReportsQuery: CustomQueryReportsDocument;
	private customQueryIdReportsQuery: CustomQueryIdReportsDocument;
	private findFilesReportsQuery: FindFilesReportsDocument;
	private usedReportsQuery: UsedReportsDocument;
	private findTemplatesReportsQuery: FindTemplatesReportsDocument;
	private existReportsQuery: ExistReportsDocument;
	private findRecyclesReportsQuery: FindRecyclesReportsDocument;
	private countRecyclesReportsQuery: CountRecyclesReportsDocument;
	private readOnlyReportsQuery: ReadOnlyReportsDocument;
	private findArchivedReportsQuery: FindArchivedReportsDocument;
	private countAllReportsQuery: CountAllReportsDocument;
	private findDynamicPropertyFieldsReportsQuery: FindDynamicPropertyFieldsReportsDocument;
	private insertReportsMutation: InsertReportsDocument;
	private updateReportsMutation: UpdateReportsDocument;
	private importDataReportsMutation: ImportDataReportsDocument;
	private updateManyReportsMutation: UpdateManyReportsDocument;
	private deleteReportsMutation: DeleteReportsDocument;
	private uploadChunkFileReportsMutation: UploadChunkFileReportsDocument;
	private restoreReportsMutation: RestoreReportsDocument;
	private recycleReportsMutation: RecycleReportsDocument;
	private restoreArchivedReportsMutation: RestoreArchivedReportsDocument;
	private archivedReportsMutation: ArchivedReportsDocument;
	private addFileDynamicFieldReportsMutation: AddFileDynamicFieldReportsDocument;
	private removeFileDynamicFieldReportsMutation: RemoveFileDynamicFieldReportsDocument;
	private addFileLinkReportsMutation: AddFileLinkReportsDocument;
	private removeFileLinkReportsMutation: RemoveFileLinkReportsDocument;

	constructor(private injector: Injector) {
		this.getReportsQuery = this.injector.get(GetReportsDocument);
		this.firstReportsQuery = this.injector.get(FirstReportsDocument);
		this.countReportsQuery = this.injector.get(CountReportsDocument);
		this.findReportsQuery = this.injector.get(FindReportsDocument);
		this.searchReportsQuery = this.injector.get(SearchReportsDocument);
		this.exportSchemaReportsQuery = this.injector.get(ExportSchemaReportsDocument);
		this.exportDataReportsQuery = this.injector.get(ExportDataReportsDocument);
		this.customQueryReportsQuery = this.injector.get(CustomQueryReportsDocument);
		this.customQueryIdReportsQuery = this.injector.get(CustomQueryIdReportsDocument);
		this.findFilesReportsQuery = this.injector.get(FindFilesReportsDocument);
		this.usedReportsQuery = this.injector.get(UsedReportsDocument);
		this.findTemplatesReportsQuery = this.injector.get(FindTemplatesReportsDocument);
		this.existReportsQuery = this.injector.get(ExistReportsDocument);
		this.findRecyclesReportsQuery = this.injector.get(FindRecyclesReportsDocument);
		this.countRecyclesReportsQuery = this.injector.get(CountRecyclesReportsDocument);
		this.readOnlyReportsQuery = this.injector.get(ReadOnlyReportsDocument);
		this.findArchivedReportsQuery = this.injector.get(FindArchivedReportsDocument);
		this.countAllReportsQuery = this.injector.get(CountAllReportsDocument);
		this.findDynamicPropertyFieldsReportsQuery = this.injector.get(FindDynamicPropertyFieldsReportsDocument);
		this.insertReportsMutation = this.injector.get(InsertReportsDocument);
		this.updateReportsMutation = this.injector.get(UpdateReportsDocument);
		this.importDataReportsMutation = this.injector.get(ImportDataReportsDocument);
		this.updateManyReportsMutation = this.injector.get(UpdateManyReportsDocument);
		this.deleteReportsMutation = this.injector.get(DeleteReportsDocument);
		this.uploadChunkFileReportsMutation = this.injector.get(UploadChunkFileReportsDocument);
		this.restoreReportsMutation = this.injector.get(RestoreReportsDocument);
		this.recycleReportsMutation = this.injector.get(RecycleReportsDocument);
		this.restoreArchivedReportsMutation = this.injector.get(RestoreArchivedReportsDocument);
		this.archivedReportsMutation = this.injector.get(ArchivedReportsDocument);
		this.addFileDynamicFieldReportsMutation = this.injector.get(AddFileDynamicFieldReportsDocument);
		this.removeFileDynamicFieldReportsMutation = this.injector.get(RemoveFileDynamicFieldReportsDocument);
		this.addFileLinkReportsMutation = this.injector.get(AddFileLinkReportsDocument);
		this.removeFileLinkReportsMutation = this.injector.get(RemoveFileLinkReportsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfReport> {
    
    		let variables: GetReportsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getReportsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reports.get));
            }
            else{
                let result:ServiceSingleResultOfReport={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfReport,
		@Args('filter?') filter?: FilterOfReport,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfReport> {
    
    		let variables: FirstReportsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstReportsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reports.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfReport,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountReportsBaseVariables = {
    			filter: filter
    		}
    				return this.countReportsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reports.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfReport,
		@Args('filter?') filter?: FilterOfReport,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfReport> {
    
    		let variables: FindReportsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findReportsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reports.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfReport,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfReport> {
    
    		let variables: SearchReportsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchReportsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reports.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaReportsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaReportsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reports.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfReport,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataReportsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataReportsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reports.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfReport,
		@Args('filter?') filter?: FilterOfReport,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfReport> {
    
    		let variables: CustomQueryReportsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryReportsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reports.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfReport,
		@Args('filter?') filter?: FilterOfReport,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfReport> {
    
    		let variables: CustomQueryIdReportsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdReportsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reports.customQueryId));
    	}

    	/** Récupère les fichiers liés. */
    	@InjectArgs
    	public findFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindFilesReportsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findFilesReportsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reports.findFiles));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedReportsBaseVariables = {
    			ids: ids
    		}
    				return this.usedReportsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reports.used));
    	}

    	/**  */
    	@InjectArgs
    	public findTemplates(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfReport,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfReport> {
    
    		let variables: FindTemplatesReportsBaseVariables = {
    			filter: filter
    		}
    				return this.findTemplatesReportsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reports.findTemplates));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistReportsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existReportsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reports.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfReport,
		@Args('filter?') filter?: FilterOfReport,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfReport> {
    
    		let variables: FindRecyclesReportsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesReportsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reports.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfReport,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesReportsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesReportsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reports.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyReportsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyReportsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reports.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfReport,
		@Args('filter?') filter?: FilterOfReport,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfReport> {
    
    		let variables: FindArchivedReportsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedReportsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reports.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfReport,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllReportsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllReportsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reports.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfReport,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsReportsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsReportsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reports.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ReportInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfReport> {
    
    		let variables: InsertReportsBaseVariables = {
    			entity: entity
    		}
    				return this.insertReportsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.reports.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfReport,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfReport> {
    
    		let variables: UpdateReportsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateReportsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.reports.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataReportsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataReportsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.reports.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfReport,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyReportsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyReportsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.reports.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteReportsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteReportsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.reports.delete));
    	}

    	/**  */
    	@InjectArgs
    	public uploadChunkFile(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('visibleByHelpDeskUser') visibleByHelpDeskUser: boolean,
		@Args('size') size: number,
		@Args('outgoingEmails') outgoingEmails: boolean,
		@Args('lastChunk') lastChunk: boolean,
		@Args('indexChunk') indexChunk: number,
		@Args('type?') type?: string,
		@Args('name?') name?: string,
		@Args('fileId?') fileId?: string,
		@Args('entityId?') entityId?: string,
		@Args('chunks?') chunks?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: UploadChunkFileReportsBaseVariables = {
    			name: name,
			size: size,
			type: type,
			chunks: chunks,
			indexChunk: indexChunk,
			lastChunk: lastChunk,
			fileId: fileId,
			entityId: entityId,
			visibleByHelpDeskUser: visibleByHelpDeskUser,
			outgoingEmails: outgoingEmails
    		}
    				return this.uploadChunkFileReportsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.reports.uploadChunkFile));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreReportsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreReportsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.reports.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleReportsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleReportsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.reports.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedReportsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedReportsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.reports.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedReportsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedReportsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.reports.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldReportsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldReportsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.reports.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldReportsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldReportsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.reports.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addFileLink(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileId') fileId: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileLinkReportsBaseVariables = {
    			id: id,
			fileId: fileId
    		}
    				return this.addFileLinkReportsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.reports.addFileLink));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileLink(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileId') fileId: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileLinkReportsBaseVariables = {
    			id: id,
			fileId: fileId
    		}
    				return this.removeFileLinkReportsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.reports.removeFileLink));
    	}
    
}