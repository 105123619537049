import { Component, Injector, OnInit } from '@angular/core';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { MobileWorkItem, TranslateService, WorkItem } from '@clarilog/shared2';
import { MobileLayoutNavService } from '@clarilog/layouts2/mobile-layout/mobile-layout-nav.service';
import { LocalStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/local-storage-service';

export enum MobileLayoutNavigationEvent {
  NAVIGATE = 'MOBILE_NAVIGATE',
  LOADING = 'MOBILE_NAV_LOADING',
}
@Component({
  selector: 'cl-mobile-layout',
  templateUrl: './mobile-layout.component.html',
  styleUrls: ['./mobile-layout.component.scss'],
})
export class MobileLayoutComponent implements OnInit {
  constructor(
    private injector: Injector,
    public localStorageService: LocalStorageService,
    public mobileLayoutNavService: MobileLayoutNavService,
  ) {
    this.state = new ModelState(injector);
    this.localStorageService.user.isOperator().subscribe((s) => {
      if (s == true) {
        this.items = mobileLayoutNavService.getNavItems(true);
      } else {
        this.items = mobileLayoutNavService.getNavItems(false);
      }
    });

    this.state.on.subscribe((event) => {
      setTimeout((_) => {
        console.log(event);
        if (event.eventName === 'test') {
          this.isInit = true;
          this.test = event.eventData;
        }
      });
    });
  }

  items: MobileWorkItem[];

  state: ModelState;
  isInit = false;
  test: [{ title: string }];

  ngOnInit(): void {}

  onOutletLoaded(component) {
    component['state'] = this.state;
  }
}
