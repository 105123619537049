import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { DownloadFileFileManagersBaseVariables, FileInfoFileManagersBaseVariables, TokenFileFileManagersBaseVariables, FindTicketAttachmentsFileManagersBaseVariables, FindAttachmentTicketInfoFileManagersBaseVariables, UploadChunkFileFileManagersBaseVariables, AddCommentaryFileManagersBaseVariables, UpdateVisibilityHelpDeskFileManagersBaseVariables, UpdateOutgoingEmailsFileManagersBaseVariables, DeleteTicketAttachmentFileManagersBaseVariables } from '../gqls'
import { DownloadFileFileManagersDocument, FileInfoFileManagersDocument, TokenFileFileManagersDocument, FindTicketAttachmentsFileManagersDocument, FindAttachmentTicketInfoFileManagersDocument, UploadChunkFileFileManagersDocument, AddCommentaryFileManagersDocument, UpdateVisibilityHelpDeskFileManagersDocument, UpdateOutgoingEmailsFileManagersDocument, DeleteTicketAttachmentFileManagersDocument } from '../gqls'
import { ServiceSingleResultOfString, ServiceSingleResultOfFileModel, ServiceListResultOfFileModel, QueryContextOfFileModel, FilterOfFileModel, ServiceListResultOfTicket, ServiceSingleResultOfGuid, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class FileManagerBaseService {
    
public modelName = 'fileManager';
public modelPluralName = 'fileManagers';

	private downloadFileFileManagersQuery: DownloadFileFileManagersDocument;
	private fileInfoFileManagersQuery: FileInfoFileManagersDocument;
	private tokenFileFileManagersQuery: TokenFileFileManagersDocument;
	private findTicketAttachmentsFileManagersQuery: FindTicketAttachmentsFileManagersDocument;
	private findAttachmentTicketInfoFileManagersQuery: FindAttachmentTicketInfoFileManagersDocument;
	private uploadChunkFileFileManagersMutation: UploadChunkFileFileManagersDocument;
	private addCommentaryFileManagersMutation: AddCommentaryFileManagersDocument;
	private updateVisibilityHelpDeskFileManagersMutation: UpdateVisibilityHelpDeskFileManagersDocument;
	private updateOutgoingEmailsFileManagersMutation: UpdateOutgoingEmailsFileManagersDocument;
	private deleteTicketAttachmentFileManagersMutation: DeleteTicketAttachmentFileManagersDocument;

	constructor(private injector: Injector) {
		this.downloadFileFileManagersQuery = this.injector.get(DownloadFileFileManagersDocument);
		this.fileInfoFileManagersQuery = this.injector.get(FileInfoFileManagersDocument);
		this.tokenFileFileManagersQuery = this.injector.get(TokenFileFileManagersDocument);
		this.findTicketAttachmentsFileManagersQuery = this.injector.get(FindTicketAttachmentsFileManagersDocument);
		this.findAttachmentTicketInfoFileManagersQuery = this.injector.get(FindAttachmentTicketInfoFileManagersDocument);
		this.uploadChunkFileFileManagersMutation = this.injector.get(UploadChunkFileFileManagersDocument);
		this.addCommentaryFileManagersMutation = this.injector.get(AddCommentaryFileManagersDocument);
		this.updateVisibilityHelpDeskFileManagersMutation = this.injector.get(UpdateVisibilityHelpDeskFileManagersDocument);
		this.updateOutgoingEmailsFileManagersMutation = this.injector.get(UpdateOutgoingEmailsFileManagersDocument);
		this.deleteTicketAttachmentFileManagersMutation = this.injector.get(DeleteTicketAttachmentFileManagersDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/**  */
    	@InjectArgs
    	public downloadFile(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fileId') fileId: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: DownloadFileFileManagersBaseVariables = {
    			fileId: fileId
    		}
    				return this.downloadFileFileManagersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fileManagers.downloadFile));
    	}

    	/**  */
    	@InjectArgs
    	public fileInfo(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fileId') fileId: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFileModel> {
    
    		let variables: FileInfoFileManagersBaseVariables = {
    			fileId: fileId
    		}
    				return this.fileInfoFileManagersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fileManagers.fileInfo));
    	}

    	/**  */
    	@InjectArgs
    	public tokenFile(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fileId') fileId: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: TokenFileFileManagersBaseVariables = {
    			fileId: fileId
    		}
    				return this.tokenFileFileManagersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fileManagers.tokenFile));
    	}

    	/**  */
    	@InjectArgs
    	public findTicketAttachments(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketType?') ticketType?: string,
		@Args('options?') options?: QueryContextOfFileModel,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindTicketAttachmentsFileManagersBaseVariables = {
    			ticketType: ticketType,
			filter: filter,
			options: options
    		}
    				return this.findTicketAttachmentsFileManagersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fileManagers.findTicketAttachments));
    	}

    	/**  */
    	@InjectArgs
    	public findAttachmentTicketInfo(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicket> {
    
    		let variables: FindAttachmentTicketInfoFileManagersBaseVariables = {
    			id: id
    		}
    				return this.findAttachmentTicketInfoFileManagersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fileManagers.findAttachmentTicketInfo));
    	}
    
    	/**  */
    	@InjectArgs
    	public uploadChunkFile(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('visibleByHelpDeskUser') visibleByHelpDeskUser: boolean,
		@Args('size') size: number,
		@Args('outgoingEmails') outgoingEmails: boolean,
		@Args('lastChunk') lastChunk: boolean,
		@Args('indexChunk') indexChunk: number,
		@Args('type?') type?: string,
		@Args('name?') name?: string,
		@Args('entityId?') entityId?: string,
		@Args('chunks?') chunks?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: UploadChunkFileFileManagersBaseVariables = {
    			name: name,
			size: size,
			type: type,
			chunks: chunks,
			indexChunk: indexChunk,
			lastChunk: lastChunk,
			entityId: entityId,
			visibleByHelpDeskUser: visibleByHelpDeskUser,
			outgoingEmails: outgoingEmails
    		}
    				return this.uploadChunkFileFileManagersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.fileManagers.uploadChunkFile));
    	}

    	/**  */
    	@InjectArgs
    	public addCommentary(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fileIds') fileIds: Array<string>,
		@Args('commentary?') commentary?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddCommentaryFileManagersBaseVariables = {
    			fileIds: fileIds,
			commentary: commentary
    		}
    				return this.addCommentaryFileManagersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.fileManagers.addCommentary));
    	}

    	/**  */
    	@InjectArgs
    	public updateVisibilityHelpDesk(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fileIds') fileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateVisibilityHelpDeskFileManagersBaseVariables = {
    			fileIds: fileIds
    		}
    				return this.updateVisibilityHelpDeskFileManagersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.fileManagers.updateVisibilityHelpDesk));
    	}

    	/**  */
    	@InjectArgs
    	public updateOutgoingEmails(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fileIds') fileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateOutgoingEmailsFileManagersBaseVariables = {
    			fileIds: fileIds
    		}
    				return this.updateOutgoingEmailsFileManagersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.fileManagers.updateOutgoingEmails));
    	}

    	/**  */
    	@InjectArgs
    	public deleteTicketAttachment(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('ticketType?') ticketType?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTicketAttachmentFileManagersBaseVariables = {
    			ids: ids,
			ticketType: ticketType
    		}
    				return this.deleteTicketAttachmentFileManagersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.fileManagers.deleteTicketAttachment));
    	}
    
}