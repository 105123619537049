import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization/authorization.service';
import { EmailPrototypeCoreService } from '@clarilog/core/services2/graphql/generated-types/services/email-prototype.service';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { DxTextBoxComponent } from 'devextreme-angular';
import { camelCase } from 'lodash';
import { EventArgs } from '../../nav-menu';
import { TemplatesService } from '../../templates/templates.service';
import { FormGroupHelpers } from '../work-form/form-group-helpers';
import { WorkItemFieldMode } from '../work-item-field/work-item-field.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DEFAULT_COLORS } from '../../html-editor/constant/colors-html-editor';

/** Représente un composant dynamique. */
@Component({
  selector: 'clc-work-item-control-dynamic',
  templateUrl: './work-item-control-dynamic.component.html',
  styleUrls: ['./work-item-control-dynamic.component.scss'],
})
export class CoreWorkItemControlDynamicComponent
  implements OnInit, AfterViewInit
{
  // private controlChanges: EventEmitter<any> = new EventEmitter();
  public parent: FormGroupDirective;
  private firstControl: any;
  valueChange: boolean = false;
  /** Obtient ou définit le mode. */
  @Input() mode: WorkItemFieldMode = 'default';
  /** Obtient ou définit les controls à afficher. */
  @Input() controls: any[];
  /** Active ou désactive l'auto-focus sur le premier control. */
  @Input() autoFocus: boolean = false;

  //destination de l'info bulle
  target;
  //texte de l'info bulle
  text;
  /** Obtient ou définit l'id du timeOut pour le popover' */
  timeOutId: any;

  @Output() onInitialized = new EventEmitter<
    EventArgs<CoreWorkItemControlDynamicComponent>
  >();

  /** Evenement action d'un bouton */
  @Output() onButtonActionClicked = new EventEmitter<any>();

  /** Bind la classe css par rapport au mode. */
  @HostBinding('class')
  public get getCssClasses(): any {
    return this.mode;
  }

  defaultVisible: string = '';

  /** Obtient la valeur si le champs est systeme */
  isSystemValue: boolean = false;

  /** Obtient la valeur si le champs est systeme */
  isMobile: boolean = false;

  /**
   * Couleurs par défaut
   */
  public arrayColors: string[] = DEFAULT_COLORS;
  /** Used for designer **/

  @Input() designerMode = false;

  /** end section **/

  constructor(
    /** Récupère le FormGroup parent. */
    private formGroupDirective: FormGroupDirective,
    public templateService: TemplatesService,
    private emailPrototypeService: EmailPrototypeCoreService,
    private cd: ChangeDetectorRef,
    private authorizationService: AuthorizationCoreService,
    private deviceDetectorService: DeviceDetectorService,
  ) {
    this.parent = this.formGroupDirective;
    this.onCopyClick = this.onCopyClick.bind(this);
    this.onMouseTextboxEnter = this.onMouseTextboxEnter.bind(this);
    this.onMouseTextboxLeave = this.onMouseTextboxLeave.bind(this);

    this.onButtonActionClick = this.onButtonActionClick.bind(this);
    this.applyResolutionModel = this.applyResolutionModel.bind(this);
    this.applyCommentaryModel = this.applyCommentaryModel.bind(this);

    this.apply = this.apply.bind(this);
    this.focusInResolutionModel = this.focusInResolutionModel.bind(this);
    this.focusInCommentaryModel = this.focusInCommentaryModel.bind(this);

    this.isMobile = this.deviceDetectorService.isMobile();
  }

  public apply(e) {
    let control = this.controls[0];
  }

  public applyResolutionModel(e) {
    let control = this.controls[0];
    let fieldControl = FormGroupHelpers.formControlByName(
      this.formGroupDirective.form,
      control.fieldName,
    );
    let value = e.itemData[control.options.resolutionModel.contentExpr];

    fieldControl.reset();
    fieldControl.markAsDirty();
    setTimeout(() => {
      fieldControl.setValue(value, { emitEvent: true });
      fieldControl.markAsDirty();
    }, 50);
  }

  public applyCommentaryModel(e) {
    let control = this.controls.find(
      (x) => x.options?.commentaryModel != undefined,
    );
    let fieldControl = FormGroupHelpers.formControlByName(
      this.formGroupDirective.form,
      control.fieldName,
    );
    let value = e.itemData[control.options.commentaryModel.contentExpr];

    fieldControl.reset();
    fieldControl.markAsDirty();
    setTimeout(() => {
      fieldControl.setValue(value, { emitEvent: true });
      fieldControl.markAsDirty();
    }, 50);
  }

  onButtonActionClick(e) {
    if (this.state != undefined) {
      this.state.onButtonActionClicked.emit(e);
    }
  }

  public focusInResolutionModel(e) {
    let control = this.controls[0];
    let datasource = control.options.resolutionModel.source.datasource;
    datasource.reload();
  }

  public focusInCommentaryModel(e) {
    let control = this.controls.find(
      (x) => x.options?.commentaryModel != undefined,
    );
    let datasource = control.options.commentaryModel.source.datasource;
    datasource.reload();
  }

  public onMouseTextboxEnter(e, textbox: DxTextBoxComponent, control) {
    this.timeOutId = setTimeout(() => {
      this.target = textbox;
      this.text = textbox.value;
      this.defaultVisible = control.name;
    }, 500);
  }
  public onMouseTextboxLeave(e, textbox: DxTextBoxComponent, control) {
    clearTimeout(this.timeOutId);
    this.defaultVisible = undefined;
  }
  onCheckboxContentReady(e, control) {
    if (control.options != undefined && control.options.isHTML === true) {
      let ele = e.element.querySelector('.dx-checkbox-text');
      if (ele != undefined) {
        ele.innerHTML = control.options.text;
      }
    }
  }
  @Input() state: ModelState;
  /** @inheritdoc */
  canInit: boolean = false;
  ngOnInit() {
    setTimeout(() => {
      this.canInit = true;
      this.cd.detectChanges();
    }, 100);
    this.onInitialized.emit({
      component: this,
      event: undefined,
    });
  }

  onDateTimeComponentFocusOut(e, control) {
    if (e.component.option('validationStatus') === 'invalid') {
      //  this.formGroupDirective.control.setValue(e.component.option('text'));
      control.setErrors({ datetime: true });
    } else {
      control.updateValueAndValidity();
    }
  }

  onValueChanged(e, control = null) {
    if (e.component.NAME == 'dxColorBox' && e.value !== null) {
      control.defaultValue = e.value;
    }
    if (e.component.NAME == 'dxHtmlEditor' && e.value != undefined) {
      e.value = ModelFieldCompilerService.createElementFromHTML(e.value);
    }

    if (e.component.NAME === 'dxDateBox' && e.value === null) {
      if (e.previousValue != null && e.value === null) {
        e.component.option('value', undefined);
      }
    }
    if (
      e.component.NAME === 'dxNumberBox' &&
      control.options.defaultValue != undefined &&
      e.value === null &&
      e.event?.type != 'keyup'
    ) {
      e.component.option('value', control.options.defaultValue);
    }
    // Ajout du bouton de restauration de la valeur
    if (
      control != undefined &&
      control.type == 'TextBoxComponent' &&
      control.fieldName == 'name'
    ) {
      if (
        this.state != undefined &&
        this.state.sharedContext.params != undefined &&
        this.state.sharedContext.params._params['defaultSystemValue'] !=
          undefined
      ) {
        let systemValue =
          this.state.sharedContext.params.get('defaultSystemValue');
        if (systemValue != undefined) {
          let value = e.component.option('value');
          e.component.option('systemDefaultValue', systemValue);
          this.isSystemValue = value != systemValue;
        }
      }
    }

    // Gestion du checkbox
    if (control != undefined && control.type == 'CheckBoxComponent') {
      if (
        control.options != undefined &&
        control.options.useIndeterminateState == true
      ) {
        // Gestion du statut indéterminé
        if (e.component.skipOnValueChanged) {
          e.component.skipOnValueChanged = false;
          return;
        }
        if (e.component.setUndefinedNextTime) {
          e.component.setUndefinedNextTime = false;
          e.component.skipOnValueChanged = true;
          e.component.option('value', undefined);
          return;
        }
        if (e.value == false) {
          e.component.setUndefinedNextTime = true;
        }
      }
    }

    this.valueChange = true;
    // Detect le changement
    setTimeout(() => (this.valueChange = false), 50);
  }

  onPasted(e) {
    setTimeout(() => {
      e.component.option('value', e.component.option('text'));
    }, 50);
  }

  /** Récupère la visibilité du controle. */
  getVisible(control): boolean {
    let formControl = FormGroupHelpers.formControlByName(
      this.parent.form.root as UntypedFormGroup,
      control.name,
    );

    if (control.visible != undefined && !control.visible) {
      return control.visible;
    }
    return formControl.visibled;
  }

  /** Récupère le control via son nom. */
  getFormControl(name): UntypedFormControl {
    let formControl = FormGroupHelpers.formControlByName(
      this.parent.form.root as UntypedFormGroup,
      name,
    );

    if (formControl == undefined) {
      debugger;
    }

    return formControl;
  }

  htmlComponent: any;
  /** Initialisation des composants devextreme. */
  onComponentInitialized(e, control) {
    this.htmlComponent = e.component;
    if (
      this.state != undefined &&
      this.state.sharedContext != undefined &&
      this.state.sharedContext.lock != undefined &&
      this.state.sharedContext.lock._params != undefined &&
      this.state.sharedContext.lock._params[control.fieldName] != undefined
    ) {
      e.component.option('readOnly', true);
    }
    // Récupération du premier control
    if (this.controls.length > 0 && this.controls[0] === control) {
      this.firstControl = e.component;
      // Fait une fois.
      this.onActivated();
    }
    if (control.type == 'DateTimeComponent') {
      if (
        control.options.dateSerializationFormat != undefined &&
        control.options.dateSerializationFormat == 'yyyy-MM-dd'
      ) {
        const isoDate = e.component.option('value');
        if (isoDate != undefined) {
          let date = isoDate.substring(0, 10);

          e.component.option({ value: date });
        }
      }
    }
    if (
      control.type == 'HtmlEditorComponent' ||
      control.type == 'HtmlEditorSimpleComponent'
    ) {
      control.options = control.options || {};
      if (
        control.options.mentions != undefined &&
        control.options.mentions.type == 'EmailPrototype'
      ) {
        control.options.mentions.displayExpr = 'name';
        control.options.mentions.valueExpr = 'key';
        control.options.mentions.searchExpr = 'name';

        this.getMention(control);
      }
    }
    if (
      control.type == 'ColorBoxComponent' &&
      e.component.option('value') !== null
    ) {
      control.defaultValue = e.component.option('value');
    }
  }

  /** Lorsque le composant n'est plus actif. */
  onDeactivated() {}
  /** Lorsque le composant devient actif. */
  onActivated() {
    if (this.autoFocus === true && this.firstControl != undefined) {
      setTimeout(() => this.firstControl.focus(), 400);
    }
  }
  onInitializedCheckBox(e, control) {
    // Fix : lors de la coche, le false ne se propage pas
    (<HTMLElement>e.element).addEventListener('mousedown', () =>
      this.onFocusIn(e, control.name),
    );
    this.onComponentInitialized(e, control);

    if (control != undefined && control.type == 'CheckBoxComponent') {
      if (
        control.options != undefined &&
        control.options.useIndeterminateState == true &&
        control.defaultValue != undefined
      ) {
        // Gestion du statut indéterminé
        if (control.defaultValue == true || control.defaultValue == false) {
          e.component.option('value', control.defaultValue);
        } else {
          e.component.option('value', undefined);
        }
      }
    }
  }

  onFocusIn(e, name) {
    // this.getFormControl(name).markAsTouched();
  }

  onCopyClick(e) {
    let textbox = e.component.option('textbox') as DxTextBoxComponent;

    let textarea = document.createElement('textarea');
    textarea.style.position = 'fixed';
    textarea.style.left = '0';
    textarea.style.top = '0';
    textarea.style.opacity = '0';
    textarea.value = textbox.value;
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }

  /** Ouvre le lien dans un nouvelle onglet */
  onLinkClick(e) {
    let value = (e.component.option('textbox') as DxTextBoxComponent).value;
    // - st
    if (value != undefined) {
      if (value.toLocaleLowerCase().indexOf('http') < 0) {
        value = 'http://' + value;
      }
      window.open(value);
    }
  }

  /** Effectue un mail to sur l'adresse */
  onMailtoClick(e) {
    let value = (e.component.option('textbox') as DxTextBoxComponent).value;
    if (value != undefined) {
      window.location.href = 'mailto:' + value;
    }
  }

  /** @inheritdoc */
  ngAfterViewInit() {}
  /** Fix : selection dans une dropdown pour pouvoir resélectionner s'il n'y a qu'1 élément. */
  dropDownReset(e, control) {
    // Première fois
    if (e.component._reset == undefined) {
      e.component._reset = false;
    }
    if (
      e.component._reset === false &&
      e.value == undefined &&
      control.defaultValue != undefined
    ) {
      e.component._reset = true;
      e.component.beginUpdate();
      // Remet à null pour le reset.
      control.defaultValue = null;

      e.component.endUpdate();
      e.component._reset = false;
    }
  }
  /** Contrôle la valeur de de la zone numérique */
  onFocusOutNumberBox(e, control) {
    let value = e.component.option('value');
    if (value == undefined) {
      if (control.defaultValue != undefined) {
        e.component.option('value', control.defaultValue);
      } else {
        if (e.component.option('min') != undefined) {
          e.component.option('value', e.component.option('min'));
        } else {
          e.component.option('value', 0);
        }
      }
    }
  }

  /** Permet de traduire les valeur d'un tagBox sur son template */
  onTagBoxTranslate(value: string, path: string): string {
    if (!path.endsWith('/')) {
      path = path + '/';
    }
    let translate = TranslateService.get(path + value);
    if (translate.indexOf('[') >= 0 && translate.indexOf('[') >= 0) {
      return value;
    } else {
      return translate;
    }
  }

  mentionDataSource;
  getMention(control) {
    let typeEmailPrototype = this.state.sharedContext.entry.get('type');
    if (typeEmailPrototype == undefined) {
      typeEmailPrototype = this.state.sharedContext.entry.get('entityType');
    }
    if (
      typeEmailPrototype != undefined &&
      this.mentionDataSource == undefined &&
      control.options.mentions.type == 'EmailPrototype'
    ) {
      typeEmailPrototype = camelCase(typeEmailPrototype);
      this.mentionDataSource = [];
      this.emailPrototypeService
        .findFieldSubsitute(
          this.emailPrototypeService.getFields(),
          typeEmailPrototype,
        )
        .subscribe(async (res) => {
          res.data.forEach((r) => {
            r.name = TranslateService.get(
              'entities/' +
                typeEmailPrototype +
                '/' +
                (r.key.charAt(0).toLowerCase() + r.key.substring(1)),
            );
          });
          this.mentionDataSource = res.data.filter(
            (f) => f.name.indexOf('[') < 0,
          );
        });
    }
    return this.mentionDataSource;
  }

  onContentReady(e) {
    e.element.childNodes[0].onkeydown = function (event) {
      var x = event.which || event.keyCode;
      if (x == 13) {
        e.component.undo();
      }
    };
  }

  /** Restaure la valeur initiale */
  onDefaultClick(e) {
    // uniquement si le control system est connu
    let systemValue = e.component
      .option('textbox')
      .instance.option('systemDefaultValue');
    if (systemValue != undefined) {
      e.component.option('textbox').instance.option('value', systemValue);
    }
  }

  stampButtonOptions: any = {
    stylingMode: 'text',
    icon: 'fas fa-stamp',
    onClick: () => {
      if (this.htmlComponent != undefined) {
        let value = this.htmlComponent.option('value');
        let stamp = TranslateService.get('cl-HtmlEditor-stamp');

        stamp = stamp.replace(
          '[0]',
          this.authorizationService.user.displayName(),
        );
        stamp = stamp.replace('[1]', new Date().toLocaleString());
        if (value != undefined) {
          value += '<BR>';
        } else {
          value = '';
        }
        value +=
          '<span style="font-weight:bold" >' + stamp + '</span> <br> <br>  ';
        this.htmlComponent.option('value', value);
        this.htmlComponent.focus();
        this.htmlComponent.setSelection(value.length + 1, 1);
      }
    },
  };

  /** Focus Out */
  onTextBoxFocusOut(e, control) {
    if (control?.options?.forceTrim === true) {
      let value = e.component.option('value');
      if (value?.trim != undefined) {
        e.component.option('value', value.trim());
      }
    }
  }
}
