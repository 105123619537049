import { Authorize } from '@clarilog/core/services/graphql';
import { ContractLicenseTypeBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Authorize('contract.manage-license-type')
export class ContractLicenseTypeCoreService extends ContractLicenseTypeBaseService {
  constructor(injector: Injector) {
    super(injector);
  }
}
