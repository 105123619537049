import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { FieldMappingBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { GqlField, GqlSubField } from '../helpers';
import {
  LdapFieldMapping,
  ServiceListResultOfFieldMapping,
  ServiceSingleResultOfFieldMapping,
} from '../types';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
@Authorize('scan-configuration.manage-field-mapping')
export class FieldMappingCoreService extends FieldMappingBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  @InjectArgs
  public defaultMapping(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Promise<Array<LdapFieldMapping>> {
    fields = [
      GqlSubField.create('data', [
        GqlSubField.create('ldapFieldMappings', [
          GqlField.create('id'),
          GqlField.create('externalObjectId'),
          GqlField.create('ldapField'),
          GqlField.create('entityField'),
          GqlField.create('phoneType'),
          GqlField.create('updateLdapValue'),
          GqlField.create('type'),
        ]),
      ]),
    ];
    return this.default(fields, extendedVariables)
      .pipe(
        map((s) => {
          let data = s?.data?.ldapFieldMappings;

          data.forEach((el) => {
            delete el['__typename'];
            delete el['id'];
          });
          return data;
        }),
      )
      .toPromise();
  }
}
